/**
 * Common JavaScript Operations (JSO)
 *
 * Consists of various common JavaScript operations that we use, similar to CO/JO in Java.
 *
 * @author Jozef Boer <jb@atsc.nl>
 */

JSO = {};

/*
 * For developers:
 *
 * 1) Please ensure that every addition to this file is wrapped in a function body.
 *
 *    This allows the use of private variables/methods and makes the public API cleaner.
 *
 * 2) Code in blue is the public API, this explains how to use the functionality and
 *    should ALWAYS be included and maintained.
 *
 */

(function()
{
	var parentFrame;
	var parentForm;

	/**
	 * Usage: JSO.getParentFrame()
	 *
	 * Gets the frame that caused the current window to exist (i.e., the parent). This may not be the same as window.parent/wt.parent,
	 * nor may it be the same as getFrame(getPreviousFrameName()).
	 *
	 * @returns The frame that opened this frame.
	 * @author Jozef Boer <jb@atsc.nl>
	 */
	JSO.getParentFrame = function()
	{
		if (!parentFrame)
		{
			if (ui.isFrameset(wt) && ui.previousFrameName)		{ parentFrame = wt.getFrame(ui.previousFrameName); }
			else
			{
				var dialogParentFrame							= ui.dialog.getParentWindow(window);

				if (dialogParentFrame)							{ return dialogParentFrame; }
				else											{ parentFrame = wt.parent; }
			}
		}

		// New algorithm, should work better than the above. Not enabled due to time constraints (2016-03-23). -JB
		/*
		if (!parentFrame)
		{
			if (ui.isFrameset(window.parent) && ui.previousFrameName)		{ parentFrame = window.parent.getFrame(ui.previousFrameName); }
			else
			{
				var dialogParentFrame										= ui.dialog.getParentWindow(window);

				if		(dialogParentFrame)									{ return dialogParentFrame; }
				else if (ui.isFrameset(window.parent))						{ parentFrame = wt.parent; }
				else														{ parentFrame = window.parent; }
			}
		}
		 */

		return parentFrame;
	};

	/**
	 * Usage: JSO.getParentItem(id)
	 *
	 * Gets the item on the parent frame (parentForm).
	 *
	 * @param id The id of the item to retrieve.
	 * @returns The item or null if the item could not be found.
	 * @author Jozef Boer <jb@atsc.nl>
	 */
	JSO.getParentItem = function(id)
	{
		try
		{
			var frame											= JSO.getParentFrame();

			if (frame && frame.jQuery)							{ return frame.jQuery("#" + id)[0]; }
			else												{ return null; }
		}
		catch (ex)												{ $.atsc.log.error(ex); return null; }
	};

	/**
	 * Usage: JSO.getParentItemValue(id)
	 *
	 * Gets the value of an item on the parent frame (parentForm).
	 *
	 * @param id The id of the item to retrieve.
	 * @returns The value of the provided item.
	 * @author Jozef Boer <jb@atsc.nl>
	 */
	JSO.getParentItemValue = function(id)
	{
		try														{ return JSO.toString(JSO.getParentFrame().jQuery(JSO.getParentItem(id)).val()); }
		catch (ex)												{ return ""; }
	};

	/**
	 * Usage: JSO.getParentItemFuidValue(id)
	 *
	 * Gets the fuidvalue of an item on the parent frame (parentForm).
	 *
	 * @param id The id of the item to retrieve.
	 * @returns The fuidvalue of the provided item.
	 * @author Jozef Boer <jb@atsc.nl>
	 */
	JSO.getParentItemFuidValue = function(id)
	{
		try														{ return JSO.toString(JSO.getParentFrame().jQuery(JSO.getParentItem(id)).attr("fuidvalue")); }
		catch (ex)												{ return ""; }
	};

	/**
	 * Usage: JSO.getGrandParentItem(id)
	 *
	 * Gets the item on the grand parent frame (grandParentForm).
	 *
	 * This is the same as locating the parent frame and executing JSO.getParentItem on it.
	 *
	 * @param id The id of the item to retrieve.
	 * @returns The item  or null if the item could not be found.
	 * @author Jozef Boer <jb@atsc.nl>
	 */
	JSO.getGrandParentItem = function(id)
	{
		try														{ return JSO.getParentFrame().JSO.getParentItem(id); }
		catch (ex)												{ return null; }
	};

	/**
	 * Usage: JSO.getGrandParentItemValue(id)
	 *
	 * Gets the value of an item on the grand parent frame (grandParentForm).
	 *
	 * This is the same as locating the parent frame and executing JSO.getParentItemValue on it.
	 *
	 * @param id The id of the item to retrieve.
	 * @returns The value of the provided item.
	 * @author Jozef Boer <jb@atsc.nl>
	 */
	JSO.getGrandParentItemValue = function(id)
	{
		try														{ return JSO.getParentFrame().JSO.getParentItemValue(id); }
		catch (ex)												{ return ""; }
	};

	/**
	 * Usage: JSO.getGrandParentItemFuidValue(id)
	 *
	 * Gets the fuidvalue of an item on the grand parent frame (grandParentForm).
	 *
	 * This is the same as locating the parent frame and executing JSO.getParentItemFuidValue on it.
	 *
	 * @param id The id of the item to retrieve.
	 * @returns The fuidvalue of the provided item.
	 * @author Jozef Boer <jb@atsc.nl>
	 */
	JSO.getGrandParentItemFuidValue = function(id)
	{
		try														{ return JSO.getParentFrame().JSO.getParentItemFuidValue(id); }
		catch (ex)												{ return ""; }
	};
})();

(function()
{
	/**
	 * Usage: JSO.toString(o)
	 *
	 * Converts the given value to a String. Returns a blank String if a conversion is not possible.
	 *
	 * @param o A JavaScript object to convert to a String.
	 * @returns The JavaScript object in String format, or a blank String.
	 */
	JSO.toString = function(o)
	{
		if (typeof(o) == "undefined")							{  return ""; }

		var ret													= (o === null ? "" : (o + ""));
		if (typeof(ret) != "string")							{ ret = ""; }

		return ret;
	};
})();

(function()
{
	/**
	 * Usage: JSO.getParameterFromURL(url, param)
	 *
	 * Retrieves the given value from the provided query string.
	 *
	 * @param url The URL to get the parameters from.
	 * @param param The parameter to get from the query string.
	 * @returns The parameter as retrieved from the query string, or an empty string if the parameter was not found.
	 */
	JSO.getParameterFromURL = function(url, param)
	{
		var stparam				= url.indexOf(param + "=");
		var res					= "";

		if (stparam > -1)
		{
			var enparam			= url.indexOf("&", stparam + param.length);
			if (enparam > -1)	{ res = url.substr(stparam + param.length + 1, enparam - (stparam + param.length + 1)); }
			else				{ res = url.substr(stparam + param.length + 1); }
		}

		//return res;
		return decodeURIComponent(res.replace(/\+/g, '%20')); // [MT-6731]
	};

	/**
	 * Usage: JSO.getParameter(param)
	 *
	 * Retrieves the given value from the query string.
	 *
	 * @param param The parameter to get from the query string.
	 * @param wnd Teh window to get the parameter from. (defaults to current window)
	 * @returns The parameter as retrieved from the query string, or an empty string if the parameter was not found.
	 */
	JSO.getParameter = function(param, wnd)
	{
		if (!wnd)				{ wnd = window; }

		return JSO.getParameterFromURL(wnd.location.search, param);
	};
})();

(function()
{
	/**
	 * Usage: JSO.toBigDecimal(text)
	 *
	 * Converts the provided parameter to a BigDecimal object, returning a zero-value BigDecimal on failure.
	 *
	 * @param param The value to convert to a BigDecimal representation.
	 * @returns A valid BigDecimal object.
	 */
	JSO.toBigDecimal = function(text)
	{
		try						{ return new BigDecimal(JSO.toString(text).replace(/,/g, ".")); }
		catch (ex)				{ return new BigDecimal("0.00"); }
	};
})();

(function()
{
	/**
	 * Usage: JSO.isZero(text)
	 *
	 * Converts the provided parameter to a BigDecimal object and returns wether or not it is ZERO.
	 *
	 * @param param The value to convert to a BigDecimal representation to be compared.
	 * @returns A valid BigDecimal object.
	 */
	JSO.isZero = function(text)
	{
		return JSO.toBigDecimal(text).compareTo(new BigDecimal("0.00")) == 0;
	};
})();

(function()
{
	JSO.getWindowLocation = function(wnd, stripHost, stripQueryString)
	{
		var res													= "";

		try
		{
			if (wnd.location.href)								{ res = stripHost ? wnd.location.href.right(wnd.location.host) : wnd.location.href; }
			else												{ throw ""; }
		}
		catch (ex)
		{
			try
			{
				var element;

				element											= wnd.parent.jQuery.atsc.window.getFrameFromWindow(wnd);
				if (element != null)							{ res = stripHost ? $(element).attr("src").right(wnd.location.host) : $(element).attr("src"); }

				element											= wnd.parent.jQuery.atsc.window.getIFrameFromWindow(wnd);
				if (element != null)							{ res = stripHost ? $(element).attr("src").right(wnd.location.host) : $(element).attr("src"); }
			}
			catch (ex2)											{ /* no problem */ }
		}

		if (stripQueryString)									{ res = res.left("?"); }

		return res;
	};
})();

(function()
{
	/**
	 * Usage: JSO.isFrameParentOf(a, b, alsoCheckReverse)
	 *
	 * Checks if frame {@link b} has {@link a} as one of the parents (or grandparents).
	 *
	 * @param a A frame which may or may not be the parent of {@link b}.
	 * @param b The frame which may or may not have {@link a} as its parent.
	 * @param alsoCheckReverse {@code true} to perform a reverse check after performing this one. This is a convenience parameter that calls JSO.isFrameParentOf(b, a, false) if it were to return false.
	 * @returns {@code true} if {@link a} is one of the parents of {@link b}, {@code false} if not.
	 */
	JSO.isFrameParentOf = function(a, b, alsoCheckReverse)
	{
		var res							= false;

//		console.log("QUERY: is " + a.name + " (" +  JSO.getWindowLocation(a, true, true) + ") parent of " + b.name +  " (" + JSO.getWindowLocation(b, true, true) + ")?");

		if (a != b)
		{
			try
			{
				var c					= b;

				while (c && c.parent && c.parent != c)
				{
					c					= c.parent;

					if (c == a)
					{
						res				= true;
						break;
					}
				}
			}
			catch (ex)					{ $.atsc.log.warn(ex); /* no problem */ }
		}

		if (!res && alsoCheckReverse)	{ return JSO.isFrameParentOf(b, a, false); }
		else							{ return res; }
	};
})();

(function()
{
	function getIframes(element, arr)
	{
		var nodes																							= element.childNodes;
		var l																								= nodes.length;

		for (var i = 0; i < l; i = i + 1)
		{
			if (nodes[i].tagName == "IFRAME")																{ arr.push(nodes[i]); }
			else																							{ getIframes(nodes[i], arr); }
		}
	}

	function dialogExists(prop, focus)
	{
		/*
		 * For a given property (either LocalSettings - bound to wt - or GlobalSettings - bound to topMostFrame) check the dialog.
		 * A dialog opened on a given frame should not cause its parents to claim there is a dialog. But a dialog opened on a higher frame should.
		 *
		 * A dialog may open another dialog. Dialogs may be opened on any Window. Only the last dialog is checked, because we don't want input to
		 * be locked on an overlapping dialog because it opened in a different window.
		 *
		 * Technical: Nested windows: A->B->C
		 * If dialog is open on C -> no effect on B or A.
		 * If dialog is open on B -> affects C, no effect on A.
		 * If dialog is open on A -> affects everyone.
		 */

		if (prop)
		{
			if (prop.colorboxes && prop.colorboxes.length > 0)
			{
				var dialogProperty																			= prop.colorboxes[(prop.colorboxes.length - 1)];

				//                                    [MT-6109] Check if dialogProperty.window is a parent of wt, but NOT the other way around.
				var ok																						= dialogProperty.window == wt || JSO.isFrameParentOf(wt, dialogProperty.window, false);

				if (ok)																						{ return ok; }
			}

			if (prop.dialogs && prop.dialogs.length > 0)
			{
	//			console.log("perform check for window: " + JSO.getWindowLocation(window));
				var dialogProperty;
				var index																					= prop.dialogs.length;

				do
				{
					index																					= index - 1;
					dialogProperty																			= prop.dialogs[index];
				}
				while (dialogProperty && !dialogProperty.opened);

				if (dialogProperty)
				{
					// Exception to the rule: If the current Window object itself is within this dialog then no dialog exists as far as we're concerned.
					var arr																					= [];

					getIframes(dialogProperty.element[0], arr);

					if (arr.length > 0) // dialog contains one or more iframes
					{
						for (var i = 0; i < arr.length; i = i + 1)
						{
							try
							{
								if (arr[i].contentWindow == window || arr[i].contentWindow.wt == wt)		{ return false; }
							}
							catch (ex)																		{ /* no problem */ }
						}
					}

	//				console.log("window: " + JSO.getWindowLocation(dialogProperty.window) + " / " + JSO.getWindowLocation(wt));

					//                                    [MT-6109] Check if dialogProperty.window is a parent of wt, but NOT the other way around.
					var ok																					= dialogProperty.window == wt || JSO.isFrameParentOf(wt, dialogProperty.window, false);

					if (focus) // [MT-11402]
					{
						try																					{ dialogProperty.element.dialog("option").buttons[dialogProperty.element.dialog("option").focusedButtonId].elem.focus(); }
						catch (ex)																			{ /* no problem */}
					}

					return ok;
				}
			}

			return false;
		}
		else																								{ return false; }
	}

	/**
	 * Usage: JSO.dialogExists()
	 *
	 * Checks if any dialogs exist on the current Window OR parent Window objects.
	 *
	 * The original Window that caused the dialog to open is checked, rather than the Window the dialog is on.
	 *
	 * Only checks the most recently opened dialog according to the current context (LocalSettings or GlobalSettings).
	 *
	 * If this Window object is itself contained by the most recently opened dialog window then this function will behave as if no dialog exists at all.
	 *
	 * @returns {@code true} if a dialog was found, {@code false} if not.
	 *
	 */
	JSO.dialogExists = function(skipComponentCheck, checkDashboard, focus)
	{
		// See above for the actual implementation of dialogExists.
		// This is an optimalization: If there is a dialog ANYWHERE on the document we'll check. Otherwise, return false immediately.

		var prop																							= ui.properties.getGlobalProperties();

		if (prop.dialogCount && prop.dialogCount > 0)
		{
			var res																							= dialogExists(prop, focus) || dialogExists(ui.properties.getLocalProperties(), focus);

			// If this Window is contained by a UIWrapperComponent-type Window then check every iframe on that window individually (except this one).
			if (!skipComponentCheck && !res && wt.parent && ui.isComponent(wt.parent) && !JSO.isDialogOf(wt.parent))
			{
				wt.parent.jQuery("iframe").each(function()
				{
					try
					{
						if (this.contentWindow != wt)														{ res = this.contentWindow.JSO.dialogExists(true, undefined, focus); }

						return !res;
					}
					catch (ex)																				{ /* no problem */ }
				});
			}

			// [MT-10613][MT-10623]
			if (!res && !skipComponentCheck && checkDashboard)
			{
				var dashboard																				= ui.window.getDashboardFrame();

				if (dashboard)																				{ return dashboard.JSO.dialogExists(true, undefined, focus); }
			}

			return res;
		}
		else																								{ return false; }
	};

	JSO.focusDialog = function()
	{
		JSO.dialogExists(undefined, undefined, true);
	};

	JSO.isDialogOf = function(frame)
	{
		var elem																							= frame.jQuery.atsc.window.getIFrameFromWindow(wt);

//		return elem != null && elem.length > 0 && elem.parents(".ui-dialog-content").length > 0;

		if (elem != null && elem.length > 0)
		{
			var p																							= elem.parent();

			while (p && p.length > 0 && !p.is(".ui-dialog-content"))										{ p = p.parent(); }

			return p && p.length > 0 && p.is(".ui-dialog-content");
		}
		else																								{ return false; }
	};

	/**
	 * Usage: JSO.increaseDialogCounter()
	 *
	 * Increases the global dialog counter. Not intended for global use.
	 */
	JSO.increaseDialogCounter = function()
	{
		var prop																							= ui.properties.getGlobalProperties();

		if (!prop.dialogCount)																				{ prop.dialogCount = 0; }
		prop.dialogCount																					= prop.dialogCount + 1;
	};

	/**
	 * Usage: JSO.decreaseDialogCounter()
	 *
	 * Decreases the global dialog counter. Not intended for global use.
	 */
	JSO.decreaseDialogCounter = function()
	{
		var prop																							= ui.properties.getGlobalProperties();

		if (prop.dialogCount)																				{ prop.dialogCount = Math.max(0, prop.dialogCount - 1); }
	};
})();

(function()
{
	/**
	 * Usage: JSO.toInt(a, b)
	 *
	 * Converts 'a' to an integer if possible, returning b if not.
	 *
	 * @param a The object or string to convert to an integer.
	 * @param b The default value to return if the conversion was unsuccessful. Defaults to 0.
	 * @returns A valid integer.
	 */
	JSO.toInt = function(a, b)
	{
		if (typeof(b) == "undefined")	{ b = 0; }

		try
		{
			var ret						= parseInt(JSO.toString(a));

			if (!isNaN(ret))			{ return ret; }
			else						{ throw ""; }
		}
		catch (ex)						{ return b; }
	};

	/**
	 * Usage: JSO.toFloat(a, b)
	 *
	 * Converts 'a' to a float if possible, returning b if not.
	 *
	 * @param a The object or string to convert to a float.
	 * @param b The default value to return if the conversion was unsuccessful. Defaults to 0.00.
	 * @returns A valid float.
	 */
	JSO.toFloat = function(a, b)
	{
		if (typeof(b) == "undefined")	{ b = 0.00; }

		try
		{
			var ret						= parseFloat(JSO.toString(a).replace(",", "."));

			if (!isNaN(ret))			{ return ret; }
			else						{ throw ""; }
		}
		catch (ex)						{ return b; }
	};
})();

(function()
{
	/**
	 * Usage: JSO.getOurWindowTop()
	 *
	 * Returns the topmost window that we can control.
	 *
	 * @returns The topmost Window object we can control.
	 */
	try
	{
		if (window.parent.JSO && window.parent.JSO.getOurWindowTop)		{ JSO.getOurWindowTop = window.parent.JSO.getOurWindowTop; }
		else															{ throw ""; }
	}
	catch (ex)															{ JSO.getOurWindowTop = function(){ return window; }; }

	/**
	 * Usage: JSO.isFrameAccessible(frame)
	 *
	 * Checks if the frame can be accessed (CORS).
	 * Note: This generates an error in the console for Apple devices, but this is NOT an error.
	 *
	 * @returns true if accessible, false if access was denied.
	 */
	JSO.isFrameAccessible = function(frame)
	{
		try																{ var x = frame.location.href; }
		catch (ex)														{ return false; }

		return true;
	};
})();

(function()
{
	/**
	 * Usage: JSO.repeat(c, i)
	 *
	 * Repeats the string 'c' for 'i' times.
	 *
	 * @returns The string that results from the above operation.
	 */
	JSO.repeat = function(c, i)
	{
		var res								= "";

		for (var j = 0; j < i; j = j + 1)	{ res = res + c; }

		return res;
	};
})();


(function()
{
	/**
	 * Usage: JSO.showMessage(message, timeout, callback, closeOnClick=false)
	 *
	 * Shows a temporary message on the screen which automatically disappears after
	 * a given time.
	 *
	 * With dashboard: Message is displayed in a DIV element on the screen.
	 * Without dashboard: Message is displayed in an dialog.
	 *
	 * @param message The message to display. Required.
	 * @param timeout The timeout to use before the message is meant to disappear, in milliseconds. Must be a positive value. Default value is 3000 (3 seconds).
	 * @param callback An optional callback which is invoked after the message has disappeared.
	 * @param closeOnClick true to close the message on click, false to wait.
	 *
	 */
	var frame											= JSO.getOurWindowTop()["wmain"];

	if		(frame && frame == window)
	{
		JSO.showMessage = function(message, timeout, callback, closeOnClick)
		{
			if (closeOnClick === undefined) { closeOnClick = true; }
			JSO.lastActionHero("JSO.showMessage", function(processEvent)
			{
				if (JSO.toInt(timeout) <= 0)			{ timeout = 3000; }

				$("#userMessage").attr("data-closeonclick", closeOnClick);
				$("#userMessage").css("cursor", closeOnClick ? "" : "default");
				$("#userMessage span.userMessageMessage").text(message);
				$("#userMessage span.userMessageMessage").html($("#userMessage span.userMessageMessage").text().replace(/\n/g, "<br />"));
				$("#userMessage").show();

				if (timeout <= 9999999999)
				{
					JSO.lastActionHero("JSO.showMessage.fade", function (processEvent)
					{
						frame.jQuery("#userMessage").fadeOut(400, function ()
						{
							frame.jQuery("#userMessage span.userMessageMessage").text("");

							if (callback) { callback(); }

							processEvent();
						});
					}, true, timeout);
				}

				processEvent();
			});
		};
	}
	else if (frame)
	{
		// inherit from wmain frame
		// needs use of setTimeout because the wmain frame may not be (fully) loaded yet

		function inherit()
		{
			if (frame.JSO && frame.JSO.showMessage)		{ JSO.showMessage = frame.JSO.showMessage; }
			else										{ setTimeout(inherit, 100); }
		}

		inherit();
	}
	else
	{
		var secs;

		var countdown = function(dialog, seconds) {
			var timeleft		= seconds;
			var downloadTimer	= setInterval(function(){
				timeleft--;

				$("#dialog_" + dialog.data("ui.dialog.id") + "_button_0", $(dialog).parent()).css("padding", "0.4em 1em");
				$("#dialog_" + dialog.data("ui.dialog.id") + "_button_0", $(dialog).parent()).text("Ok " + "(" + timeleft + ")");

				if (timeleft <= 0) { clearInterval(downloadTimer); }
			}, 1000);
		};

		JSO.showMessage = function(message, timeout, callback)
		{
			// Use of lastActionHero to mimic functionality in dashboard mode.
			// If this is disabled multiple alert dialogs may be shown (one after the other is closed).
			JSO.lastActionHero("JSO.showMessage", function(processEvent)
			{
				if (JSO.toInt(timeout) <= 0)			{ timeout = 3000; }

				secs									= (timeout / 1000);

				ui.dialog.alert(message, {
					buttons: [{
						text: "Ok (" + secs + ")",
						click: function() { this.dialog("close"); }
					}],
					open: function(e, eui, srcWindow) {
						var d				= this;
						countdown(d, secs);
						setTimeout(function ()
						{
							try { d.dialog("close"); }
							catch (ex) { /*no problem*/ }
						}, (secs * 1000));
					},
					close: callback
				});

				processEvent();
			});
		};
	}
})();

(function()
{
	JSO.openDocLink = function(url, forceUseGivenURL)
	{
		showFrameOrOpenTab(url, true, forceUseGivenURL);
	};
})();

(function()
{
	JSO.isFunction = function(v)
	{
		return typeof(v) == "function";
	};
})();

(function()
{
	JSO.dataURLToBlob = function(dataURL)
	{
		var BASE64_MARKER										= ';base64,';

		if (dataURL.indexOf(BASE64_MARKER) == -1)
		{
			var parts											= dataURL.split(',');
			var contentType										= parts[0].split(':')[1];
			var raw												= decodeURIComponent(parts[1]);

			return new Blob([raw], { type: contentType });
		}
		else
		{
			var parts											= dataURL.split(BASE64_MARKER);
			var contentType										= parts[0].split(':')[1];
			var raw												= window.atob(parts[1]);
			var rawLength										= raw.length;

			var uInt8Array										= new Uint8Array(rawLength);

			for (var i = 0; i < rawLength; ++i)					{ uInt8Array[i] = raw.charCodeAt(i); }

			return new Blob([uInt8Array], { type: contentType });
		}
	};
})();

(function()
{
	JSO.xmlToString = function(xmlDoc)
	{
		try
		{
			xmlDoc												= $(xmlDoc)[0];

			return xmlDoc.xml ? xmlDoc.xml : (new XMLSerializer()).serializeToString(xmlDoc);
		}
		catch (ex)												{ return null; }
	};

	JSO.createXMLDocument = function()
	{
		if		(document.implementation && document.implementation.createDocument)	{ newDoc = document.implementation.createDocument("", "", null); }
		else if (window.ActiveXObject)												{ newDoc = new ActiveXObject("Microsoft.XMLDOM"); }
		else																		{ throw new Error("Unsupported/outdated browser."); }

		return newDoc;
	};

	JSO.stringToXml = function(str)
	{
		if (isNull(str))										{ str = ""; }

		var xmlDoc;

		try														{ xmlDoc = (new DOMParser()).parseFromString(str, "text/xml"); }
		catch (e1) // doctype afhankelijk??? onderstaande werkt n.l. in categorie.jsp van A4A en bovenstaande niet
		{
			try
			{
				xmlDoc											= JSO.createXMLDocument();
				xmlDoc.async									= "false";
				xmlDoc.loadXML(str);
			}
			catch (e2)											{ $.atsc.log.error("XML: " + str, e2); }
		}

		return xmlDoc;
	};
})();

(function()
{
	JSO.getParentFrameElement = function(wnd)
	{
		if (!wnd)												{ wnd = window; }

		if (wnd.parent && wnd.parent.jQuery)
		{
			var elem											= wnd.parent.jQuery.atsc.window.getIFrameFromWindow(window);

			if (elem == null || !elem.length)					{ elem = wnd.parent.jQuery.atsc.window.getFrameFromWindow(window); }

			return elem == null ? wnd.parent.jQuery() : elem;
		}
		else													{ return $(); }
	};
})();

(function()
{
	function stripParameter(url, param)
	{
		var l										= ("?" + param + "=").length;

		// Strip existing parameter.
		var pos										= url.indexOf("?" + param + "=");

		if (pos > -1)
		{
			var pos2								= url.indexOf("&", pos + l);

			if (pos2 > -1)							{ url = url.substring(0, pos) + "?" + url.substring(pos2 + 1); }
			else									{ url = url.substring(0, pos); }
		}

		pos											= url.indexOf("&" + param + "=");

		if (pos > -1)
		{
			var pos2								= url.indexOf("&", pos + l);

			if (pos2 > -1)							{ url = url.substring(0, pos) + "&" + url.substring(pos2 + 1); }
			else									{ url = url.substring(0, pos); }
		}

		return url;
	}

	function addParameter(url, param, value)
	{
		pos											= url.indexOf("?");

		if (pos > -1)								{ url = url + "&"; }
		else										{ url = url + "?"; }

		url											= url + param + "=" + encodeURIComponent(value);

		return url;
	}

	JSO.convertWindowLocation = function(wnd, url)
	{
		url											= stripParameter(url, "_tid");

		var tid										= null;

		var frame									= ui.window.getDashboardFrame();

		if (frame)
		{
			var tab									= null;

			// MT-6700
			if (frame.ui && frame.ui.dashboard)		{ tab = frame.ui.dashboard.getTabByWindow(wnd); }

			if (tab)								{ tid = tab.id; }

			// Add parameters, if the URL is not blank.
			if (url != "about:blank" && url != BLANKURL && !url.startsWith("javascript:") && !url.startsWith("atscdav:"))
			{
				url									= addParameter(url, "_tid", ui.getUniqueID() + (tid ? "_" + tid : ""));
			}
		}

		return url;
	};

	JSO.setWindowLocation = function(wnd, url)
	{
		if (wnd && wnd.location) { wnd.location.replace(JSO.convertWindowLocation(wnd, url)); }
	};
})();

(function()
{
	// [MT-5719]
	JSO.createArray = function(){ return []; };
})();

(function()
{
	var checkValues									= null;

	JSO.isValueChanged = function(name, checkFunction)
	{
		if (!checkValues)							{ checkValues = {}; }
		if (!checkValues[name])						{ checkValues[name] = ""; }

		var result;

		try											{ result = checkFunction(); }
		catch (ex)
		{
			$.atsc.log.warn(ex);

			result									= "";
		}

		var b										= (result === checkValues[name]);

		if (!b)										{ checkValues[name] = result; }

		return !b;
	};
})();

(function()
{
	var actionlock;

	function getCurrentTimestamp()										{ return new Date().getTime(); }

	function getValue(list, key)
	{
		if (list)														{ return list[key]; }
		else															{ return undefined; }
	}

	function setValue(list, key, value)
	{
		if (!list)														{ list = {}; }

		list[key]														= value;

		return list;
	}

	function clearValue(list, key)
	{
		if (list)														{ delete list[key]; }

		var found														= false;
		for (var a in list)												{ found = true; break; }

		if (!found)														{ list = null; }

		return list;
	}

	/* ActionLock*/
	function hasLock(lockName)
	{
		if (actionlock && actionlock[lockName])
		{
			var ret														= getValue(actionlock, lockName) && getValue(actionlock, lockName) > getCurrentTimestamp();

			if (!ret)													{ actionlock = clearValue(actionlock, lockName); }

			return ret;
		}
		else															{ return false; }
	}

	/**
	 * Usage: JSO.actionLock(lockName, delayInMillis)
	 *
	 * Locks an action from being performed for a given amount of time.
	 *
	 * @param lockName A unique name for the lock. Note that this isn't shared between frames.
	 * @param delayInMillis A delay in milliseconds for which to maintain the lock.
	 * @returns True if the action maybe performed, false if the action should be aborted.
	 */
	JSO.actionLock = function(lockName, delayInMillis)
	{
		if (hasLock(lockName))											{ return false; }
		else
		{
			if (delayInMillis > 0)										{ actionlock = setValue(actionlock, lockName, getCurrentTimestamp() + delayInMillis); }

			return true;
		}
	};

	/* Last Action Hero(TM) */
	var counters;
	var locked;

	/**
	 * Usage: JSO.lastActionHero(lockName, callback, atomic, delayInMillis)
	 *
	 * Executes a provided function after at least delayInMillis, aborting if the same lockName
	 * is reused.
	 *
	 * Used to prevent an action from being executed multiple times.
	 *
	 * @param lockName A unique name for the lock. Note that this isn't shared between frames.
	 * @param callback The function to execute after the delay has expired.
	 * @param atomic True to wait until the function has finished executing before clearing the lock. If this is false only the initial delay is locked.
	 * @param delayInMillis A delay in milliseconds for which to maintain the lock.
	 */
	JSO.lastActionHero = function(lockName, callback, atomic, delayInMillis)
	{
		if (typeof(delayInMillis) == "undefined")						{ delayInMillis = 250; } // 250ms
		if (typeof(atomic) == "undefined")								{ atomic = true; }

		var c															= JSO.toInt(getValue(counters, lockName)) + 1;

		counters														= setValue(counters, lockName, c);

		setTimeout(function()
		{
			if (c == getValue(counters, lockName))
			{
				if (atomic && getValue(locked, lockName) === true)		{ JSO.lastActionHero(lockName, callback, atomic, delayInMillis); }
				else
				{
					if (atomic)											{ locked = setValue(locked, lockName, true); }

					callback(function()
					{
						if (atomic)										{ locked = clearValue(locked, lockName); }

						if ((atomic || c == getValue(counters, lockName)) && c > 1024)
						{
							counters									= clearValue(counters, lockName);
						}
					});
				}
			}
		}, delayInMillis);
	};
})();

(function()
{
	/**
	 * Usage: JSO.each(array, loop_callback, end_callback)
	 *
	 * Executes a function over an array. Used to loop through an array asynchronously.
	 *
	 * @param array The array to loop through.
	 * @param loop_callback The function to execute for each item in the array.
	 *                      Sends 3 parameters: The item being looped through, a function to be called after processing and the iteration.
	 *                      Return 'true' to continue with the next item, 'false' to call the function again or
	 *                      return 'null' to break from the loop.
	 * @param end_callback The function to execute after looping through each item.
	 */
	JSO.each = function(array, loop_callback, end_callback)
	{
		function loop(i)
		{
			if (i >= array.length)
			{
				if (end_callback)										{ end_callback(); }
			}
			else
			{
				loop_callback(array[i], function(res)
				{
					if (res === null)
					{
						if (end_callback)								{ end_callback(); }
					}
					else if (res === true)								{ loop(i + 1); }
					else												{ loop(i); }
				}, i);
			}
		}

		loop(0);
	};
})();

(function()
{
	JSO.tooltip = function(element, html_or_callback)
	{
		$(element).data("jso-tooltip-source", html_or_callback);

		if (!$(element).data("jso-tooltip"))
		{
			var tooltipElem												= $("<div class=\"tooltip jso-tooltip\" style=\"display: none;\"></div>");

			$("body").append(tooltipElem);

			$(element).data("jso-tooltip", tooltipElem);

			function show(e)
			{
				if (e.target == this || $(e.target).parent()[0] == this)
				{
					JSO.lastActionHero("tooltip", function()
					{
						var offset										= $(element).offset();
						var source										= $(element).data("jso-tooltip-source");

						function cont(html)
						{
							tooltipElem.html(html);
							tooltipElem.css({ "position": "absolute", "left": offset.left + "px", "top": (offset.top + $(element).outerHeight()) + "px", "min-width": $(element).outerWidth() + "px" });
							tooltipElem.show();
						}

						if (typeof(source) == "function")				{ source(cont, tooltipElem); }
						else											{ cont(source); }
					}, false, 250);
				}
			}

			function hide()
			{
				tooltipElem.hide();
			}

			$(element).on("mouseover", show).on("mouseout", hide);
		}
	};
})();

(function()
{
	JSO.sanitizeRegex = function(str)
	{
		return str.replace(/([\[\]\^\$\|\(\)\\\+\*\?\{\}\=\!])/gi, "\\$1");
	};

	JSO.replaceParameter = function(url, key, value)
	{
		key																= JSO.toString(key);
		value															= JSO.toString(value);

		return url.replace(new RegExp("(\\?|&)" + JSO.sanitizeRegex(key) + "=([^&]*)(&?)"), "$1" + key.replace(/\\$/g, "\\$") + "=" + value.replace(/\\$/g, "\\$") + "$3");
	};
})();

(function()
{
	JSO.createForeignUNIDParameters = function(foreignUNIDs, prefix)
	{
		if (!foreignUNIDs)												{ return ""; }

		if (!prefix)													{ prefix = ""; }
		var foreignUNIDName												= "";
		var foreignUNIDValue											= "";

		for (var key in foreignUNIDs)
		{
			if (foreignUNIDName != "")
			{
				foreignUNIDName											= foreignUNIDName + ",";
				foreignUNIDValue										= foreignUNIDValue + ",";
			}

			if (foreignUNIDs[key])
			{
				foreignUNIDName											= foreignUNIDName + key;
				foreignUNIDValue										= foreignUNIDValue + foreignUNIDs[key];
			}
		}

		if (foreignUNIDName)											{ return prefix + "foreignUNIDName=" + foreignUNIDName + "&foreignUNIDValue=" + foreignUNIDValue; }
		else															{ return ""; }
	};
})();

(function()
{
	var cssClassId														= 0;
	var cssClasses;

	JSO.createCSSClass = function(properties)
	{
		var style														= '{';

		for (var key in properties)										{ style = style + key + ': ' + properties[key] + ';'; }

		style															= style + '}';

		if (!cssClasses)												{ cssClasses = {}; }

		for (var className in cssClasses)
		{
			if (cssClasses[className] == style)
			{
				return className;
			}
		}

		var className													= 'gen' + (++cssClassId);
		cssClasses[className]											= style;

		var css															= '.' + className + ' ' + style;
		var head														= $('head')[0];
		var element														= document.createElement('style');

		element.type													= 'text/css';

		if (element.styleSheet)											{ element.styleSheet.cssText = css; }
		else															{ element.appendChild(document.createTextNode(css)); }

		head.appendChild(element);

		return className;
	}
})();

(function()
{
	var calling = false;

	JSO.callPhoneNumber = function(phoneNumber, row_unid)
	{
		if (phoneNumber != "" && !calling)
		{
			calling = true;

			$.ajax({
				url: "/click2dial/dial?number=" + phoneNumber,
				type: "get",
				cache: false,
				async: true,
				success: function()
				{
					JSO.showMessage("Nummer " + phoneNumber + " wordt gebeld...", 2000, function(){ calling = false; });

					try			{ specificAfterCallPhoneNumber(row_unid); }
					catch (e)	{ /* no problem */ }
				},
				error: function()
				{
					JSO.showMessage("Nummer " + phoneNumber + " kan niet worden gebeld.\nNeem contact op met de helpdesk van ATSC.", 2000, function(){ calling = false; });
				}
			});
		}
	};
})();

(function()
{
	JSO.copyToClipboard = function(text)
	{
		if (text)
		{
			var el							= document.createElement("textarea");
			el.value						= text;

			document.body.appendChild(el);
			el.select();
			document.execCommand("copy");
			document.body.removeChild(el);
		}
	};
})();


(function()
{
	JSO.getHexColor = function(unid)
	{
		if (unid)
		{
			var myrng						= new Math.seedrandom(unid);
			var r							= myrng();
			var g							= myrng();
			var b							= myrng();

			var rhex						= Number(parseInt(JSO.toInt(r * 255), 10)).toString(16);
			var ghex						= Number(parseInt(JSO.toInt(g * 255), 10)).toString(16);
			var bhex						= Number(parseInt(JSO.toInt(b * 255), 10)).toString(16);

			return rhex+ghex+bhex;
		}

		return "000000";
	};
})();

(function()
{
	JSO.getRGB = function(b)
	{
		if (b&&b.constructor==Array&&b.length==3) 																				{ return b; }
		else if (a=/rgb\(\s*([0-9]{1,3})\s*,\s*([0-9]{1,3})\s*,\s*([0-9]{1,3})\s*\)/.exec(b)) 									{ return[parseInt(a[1]),parseInt(a[2]),parseInt(a[3])]; }
		else if (a=/rgb\(\s*([0-9]+(?:\.[0-9]+)?)\%\s*,\s*([0-9]+(?:\.[0-9]+)?)\%\s*,\s*([0-9]+(?:\.[0-9]+)?)\%\s*\)/.exec(b))	{ return[parseFloat(a[1])*2.55,parseFloat(a[2])*2.55,parseFloat(a[3])*2.55]; }
		else if (a=/#([a-fA-F0-9]{2})([a-fA-F0-9]{2})([a-fA-F0-9]{2})/.exec(b)) 												{ return[parseInt(a[1],16),parseInt(a[2],16),parseInt(a[3], 16)]; }
		else if (a=/#([a-fA-F0-9])([a-fA-F0-9])([a-fA-F0-9])/.exec(b)) 															{ return[parseInt(a[1]+a[1],16),parseInt(a[2]+a[2],16),parseInt(a[3]+a[3],16)]; }
		else 																													{ return null; }
	};
})();


(function()
{
	JSO.getLuminance = function(color)
	{
		var rgb		= JSO.getRGB(color);

		if (!rgb)	{ return null; }
		else		{ return 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2]; }
	};
})();

(function()
{
	JSO.prompt = function(title, message, value, callback, options)
	{
		ui.dialog.createModalDialog({
			title: title,
			html: "<p>" + message + "</p><p><input name='prompt_value' value='" + (value || "") + "' style='width: 100%;'" + (options && options.maxlength ? " maxlength=" + options.maxlength : "") + "/></p>",
			width: "auto",
			height: "auto",
			closeOnEscape: true,
			closable: true,
			setFocus: function(){ this.find("input[name=prompt_value]").focus(); },
			buttons: [
				{
					text: "Ok",
					click: function()
					{
						var prompt_value				= this.find("input[name=prompt_value]").val();

						this.dialog("close");

						if (JSO.isFunction(callback))	{ callback(prompt_value); }
					}
				},
				{
					text: "Annuleren",
					click: function()					{ this.dialog("close"); }
				}
			]
		});
	};
})();

var JSO_lookup;

(function()
{
	JSO.lookup = function(title, queryid, allowmultiplerows, foreignUNIDValues, callback)
	{
		ui.dialog.createIFrameDialog("/jsp/atsc/UITable.jsp?mode=3&JSO_lookup=true&allowmultiplerows=" + (allowmultiplerows == true) + "&queryid=" + queryid + JSO.createForeignUNIDParameters(foreignUNIDValues, "&"),
		{
			title: title,
			titlebar: true,
			width: 1250,
			height: 486,
			closeOnEscape: true,
			closable: true,
			afterFrameLoad: function(srcWindow, pelem) { JSO_lookup = pelem; },
			close: function() { JSO_lookup = undefined; },
			buttons: [
				{
					text: "Ok",
					click: function()
					{
						if (JSO.isFunction(callback))
						{
							try
							{
								var srcWindow			= this.dialog("option", "srcWindow");

								callback(srcWindow.ui.table.getSelectedUnids(true));
							}
							catch (e)					{ JSO_lookup = undefined; }
						}

						this.dialog("close");
					}
				},
				{
					text: "Annuleren",
					click: function()					{ this.dialog("close"); }
				}
			]
		});
	};
})();

(function()
{
	JSO.getSelectOptions = function(queryid, selectColumns, foreignUNIDValues, callback)
	{
		$.ajax({
			url: "/table?queryid=" + queryid + "&mode=" + MODE_READ + "&selectColumns=" + selectColumns + JSO.createForeignUNIDParameters(foreignUNIDValues, "&"),
			type: "get",
			dataType: "json",
			cache: false,
			success: function(jsonResponse, textStatus, jqXHR)
			{
				var selectOptions						= "";

				for (var i = 0; i < jsonResponse.length; ++i)
				{
					selectOptions						= selectOptions + "<option value=\"" + jsonResponse[i].key + "\">" + jsonResponse[i].value + "</option>";
				}

				callback(selectOptions);
			}
		});
	};
})();

(function()
{
	JSO.forceReload = function()
	{
		var url											= new URL(window.location.href);

		url.searchParams.delete("ts");
		url.searchParams.append("ts", Date.now());

		window.location.href 							= url.href;
	};
})();

(function()
{
	JSO.getMenuFunction = function()
	{
		try												{ return JSO.getMenuFunctionElement().text(); }
		catch (e)										{ return ""; }
	};

	JSO.getMenuFunctionElement = function()
	{
		return $(getStatusDocument().getElementById("mnufnccod"));
	};
})();

if (typeof(ui) == "undefined" || !ui)
{
	var ui;

	(function()
	{
		var initialItems = [];

		ui = {
			initialized: false,
			debug: false,
			debug_loadtimes: false,
			deferEndInit: false,
			unloading: false,
			lost_connection: false,
			previousFrameName: JSO.getParameter("_pfn"),

			getUniqueID: function()
			{
				var prop											= ui.properties.getGlobalProperties();

				if (prop)
				{
					if (!prop["app_uniqueid"])						{ prop["app_uniqueid"] = new Date().getTime(); }

					return prop["app_uniqueid"];
				}
				else												{ throw new Error("Unable to get uniqueid!"); }
			},

			init: function()
			{
				if(document.readyState != "complete" || (typeof(ui.defer_initialization) != "undefined" && ui.defer_initialization == true))
				{
					setTimeout(function() {ui.init();}, 25);
//					$.atsc.log.debug("deferred for " + JSO.getWindowLocation(window));
					return;
				}

				var s;
				if (ui.debug_loadtimes)		{ s = new Date(); }
				for (var item in ui)
				{
					if ($.inArray(item, initialItems) == -1)
					{
//						$.atsc.log.println("Loading " + item + " for " + JSO.getWindowLocation(window));

						for (var func in ui[item])
						{
							if(func.substring(0, 5) == "_init" && typeof(ui[item][func]) == "function")
							{
								try
								{
									func = ui[item][func];
									func();
								}
								catch (ex)
								{
									$.atsc.log.error(ex);
								}
							}
						}

						if (typeof(ui[item].init) == "function")
						{
							try					{ ui[item].init(); }
							catch (ex)			{ $.atsc.log.error(ex); }
						}
					}
				}

				if(ui.debug_loadtimes){ $.atsc.log.debug((new Date() - s) + "ms for " + JSO.getWindowLocation(window)); }

				ui.endInit();
			},

			waitForInitialization: function(callback)
			{
				if (ui.initialized)				{ callback(); }
				else							{ setTimeout(ui.waitForInitialization.bind(null, callback), 25); }
			},

			setDeferEndInit: function(val)
			{
				ui.deferEndInit				= val;
			},

			endInit: function()
			{
				if (ui.deferEndInit)
				{
					setTimeout(function() {ui.endInit();}, 25);
					return;
				}

				/*
				 * Disable Twitter bootstrap dropdown menu keyboard events.
				 */
				$(document).off("keydown.dropdown.data-api", "[data-toggle=dropdown], [role=menu]");

				ui.initialized								= true;

				ui.events.call("ui.afterFrameLoad", window);

				try											{ parent.ui.afterFrameLoad(window); }
				catch (e)									{ /* no problem */ }
			},

			getUniqueId: function()
			{
				var locations								= JSO.getWindowLocation(window);
				var oldParent								= null;
				var parent									= window;
				do
				{
					parent									= parent.parent;
					if (parent == null || parent == oldParent)
					{
						break;
					}

					locations								+= "_<SEP>_" + JSO.getWindowLocation(parent);
					oldParent								= parent;
				}
				while (true);

				return locations;
			},

			isValidWindow: function(frame)
			{
				if (typeof(frame) == "undefined")			{ return true; } // current window is always valid
				else										{ return typeof(frame.ui) != "undefined"; }
			},

			isMenu: function(frame)
			{
				if (typeof(frame) == "undefined")			{ ui.isMenu(window); }
				if (frame == null)							{ return false; }

				try											{ return typeof(frame.ui) != "undefined" && typeof(frame.ui.menu) != "undefined"; }
				catch (ex)									{ return false; } //if the frame origin is different
			},

			isTable: function(frame)
			{
				if(typeof(frame) == "undefined")			{ return ui.isTable(window); }
				if(frame == null)							{ return false; }

				try											{ return typeof(frame.ui) != "undefined" && typeof(frame.ui.table) != "undefined"; }
				catch (ex)									{ return false; } //if the frame origin is different
			},

			isRecord: function(frame)
			{
				if (typeof(frame) == "undefined")			{ return ui.isRecord(window); }
				if (frame == null)							{ return false; }

				try											{ return typeof(frame.ui) != "undefined" && typeof(frame.ui.table) == "undefined" && typeof(frame.ui.record) != "undefined" && (typeof(frame.isRecord) == "undefined" || frame.isRecord) && typeof(frame.record_name) != "undefined" && frame.record_name != null && frame.record_name != ""; }
				catch (ex)									{ return false; } //if the frame origin is different
			},

			isCalendar: function(frame)
			{
				if(typeof(frame) == "undefined")			{ return ui.isCalendar(window); }
				if(frame == null)							{ return false; }

				try											{ return typeof(frame.multitrader) != "undefined" && typeof(frame.multitrader.calendar) != "undefined"; }
				catch (ex)									{ return false; } //if the frame origin is different
			},

			isCalendarWidget: function(frame)
			{
				if(typeof(frame) == "undefined")			{ return ui.isCalendarWidget(window); }
				if(frame == null)							{ return false; }

				try											{ return frame.jQuery("#mycalendarframe").length > 0; }
				catch (ex)									{ return false; } //if the frame origin is different
			},

			hasTabs: function(frame)
			{
				if (typeof(frame) == "undefined")			{ return ui.hasTabs(window); }
				if (frame == null)							{ return false; }

				try											{ return typeof(frame.ui) != "undefined" && typeof(frame.ui.tab) != "undefined"; }
				catch (ex)									{ return false; } //if the frame origin is different
			},

			isFaUI: function(frame)
			{
				if (typeof(frame) == "undefined")			{ return ui.isFaUI(window); }
				if (frame == null)							{ return false; }

				try											{ return typeof(frame.ui) != "undefined" && typeof(frame.ui.faui) != "undefined"; }
				catch (ex)									{ return false; } //if the frame origin is different
			},
			isFaUIFrameset: function(frame)
			{
				if (typeof(frame) == "undefined")			{ return ui.isFaUIFrameset(window); }
				if (frame == null)							{ return false; }

				return JSO.getWindowLocation(frame).indexOf("/jsp/fa/index.jsp") > -1;
			},
			isWrapperFrameset: function(frame)
			{
				if (typeof(frame) == "undefined")			{ return ui.isWrapperFrameset(window); }
				if (frame == null)							{ return false; }

				return JSO.getWindowLocation(frame).indexOf("/jsp/atsc/UIWrapperFrameset.jsp") > -1;
			},
			isComponent: function(frame)
			{
				if (typeof(frame) == "undefined")			{ return ui.isComponent(window); }
				if (frame == null)							{ return false; }

				try											{ return typeof(frame.ui) != "undefined" && typeof(frame.ui.component) != "undefined"; }
				catch (ex)									{ return false; } //if the frame origin is different
			},
			isFrameset: function(frame)
			{
				if (typeof(frame) == "undefined")			{ return ui.isFrameset(window); }
				if (frame == null)							{ return false; }

				try											{ return frame.jQuery("frameset").length > 0; }
				catch (ex)									{ return false; } //if the frame origin is different
			},
			isMTFrameset: function(frame)
			{
				if (typeof(frame) == "undefined")			{ return ui.isMTFrameset(window); }
				if (frame == null)							{ return false; }

				try											{ return frame.jQuery("frameset").length > 0 && frame.program; }
				catch (ex)									{ return false; } //if the frame origin is different
			},
			isDashboard: function(frame)
			{
				if (typeof(frame) == "undefined")			{ return ui.isDashboard(window); }
				if (frame == null)							{ return false; }

				try											{ return typeof(frame.ui) != "undefined" && typeof(frame.ui.dashboard) != "undefined"; }
				catch (ex)									{ return false; } //if the frame origin is different
			},
			hasDashboard: function(frame)
			{
				if (ui.isDashboard(frame))										{ return true; }
				else
				{
					var found													= false;

					if (ui.isFrameset(frame))
					{
						try
						{
							$(frame.document).find("iframe,frame").each(function()
							{
								try
								{
									if (ui.hasDashboard(this.contentWindow))	{ found = true; return false; }
								}
								catch (ex)										{ $.atsc.log.println(ex); /* no problem */ }
							});
						}
						catch (ex)												{ /* no problem */ }
					}

					return found;
				}
			},
			isWidgetDashboard: function(frame)
			{
				if (typeof(frame) == "undefined")			{ return ui.isWidgetDashboard(window); }
				if (frame == null)							{ return false; }

				return JSO.getWindowLocation(frame).indexOf("/jsp/atsc/dashboard/dashboard.jsp") > -1;
			},
			isStandaloneFrame: function(frame)
			{
				if (typeof(frame) == "undefined")			{ return ui.isStandaloneFrame(window); }

				return ui.isWrapperFrameset(frame) && (frame.parent == null || frame.parent == frame);
			},
			getTinyMCEByFrame: function(frame)
			{
				var parentFrame;
				if (typeof(frame) == "undefined")			{ frame = window; }

				try
				{
					if (typeof(frame.parent.tinyMCE) != "undefined")
					{
						var ed;
						for (var id in frame.parent.tinyMCE.editors)
						{
							ed								= frame.parent.tinyMCE.editors[id];
							if (ed.getWin() == frame)		{ return ed; }
						}
					}
				}
				catch (ex)									{ /* no problem */ }

				return null;
			},
			getTinyMCEByBody: function(elem)
			{
				try
				{
					if (typeof(tinyMCE) != "undefined")
					{
						var ed;
						for (var id in tinyMCE.editors)
						{
							ed								= tinyMCE.editors[id];
							if (ed.getBody() == elem)		{ return ed; }
						}
					}
				}
				catch (ex)									{ /* no problem */ }

				return null;
			},
			getTinyMCEByID: function(eid)
			{
				try
				{
					if (typeof(tinyMCE) != "undefined")
					{
						var ed;
						for (var id in tinyMCE.editors)
						{
							ed								= tinyMCE.editors[id];
							if (ed.id == eid)				{ return ed; }
						}
					}
				}
				catch (ex)									{ /* no problem */ }

				return null;
			},
			printMessage: function(msg)
			{
				$.ajax({url: IOSERVLETURL + "?action=" + JSMESSAGE + "&message=" + encodeURIComponent(msg), async: false, cache: false});
			},
			isUnloading:									function(){ return ui.unloading || (document && document.visibilityState == "unloaded"); },
			addItemToSkip:									function(item){ initialItems.push(item); },
			hasFocus: function(wnd)
			{
				if (wnd == undefined)						{ wnd = window; }

				try
				{
					if (wnd.parent && wnd.parent != wnd && wnd.parent.document.activeElement)
					{
						return (wnd.parent.document.activeElement.contentWindow == window);
					}
				}
				catch (ex)									{ return true; }
			}
		};

		for (var item in ui)								{ initialItems.push(JSO.toString(item)); }

		initialItems.push("afterFrameLoad");
		initialItems.push("defer_initialization");
	})();

	$(window).on("unload", function(){ ui.unloading = true; });
}

// Workaround for IE-specific issue where the help screen still opens
if (IE)													{ document.onhelp = function(){ return false; }; }

$(document).ajaxSuccess(function(e, jqXHR, ajaxSettings, errorThrown)
{
	try													{ ui.window.getTopmostFrame().ui.lost_connection = false; }
	catch (ex)											{ /* no problem */ }
});

// For Ajax requests where the error callback is NOT used -> announce an error has occurred to the user.
$(document).ajaxError(function(e, jqXHR, ajaxSettings, errorThrown)
{
	if ((!ui || !(ui.unloading || (document && document.visibilityState == "unloaded"))) && !ajaxSettings["error"] && !ajaxSettings["complete"])
	{
		var lost_connection;

		try												{ lost_connection = ui.window.getTopmostFrame().ui.lost_connection; }
		catch (ex)										{ lost_connection = false; }

		if (!lost_connection)
		{
			//if (jqXHR.status != 503 || ENVIRONMENT != "DEVELOPMENT")
			{
				if (ui.isFrameset(window) || wt.getFrame(wt.getCurrentFrameName()) == window) // [MT-5851] Suppress error message if this is not the current window
				{
					if ((jqXHR.readyState == 0 && (jqXHR.statusText == "error" || jqXHR.statusText.indexOf("NetworkError") > -1 || jqXHR.statusText.indexOf("Forbidden") > -1)) || (jqXHR.status == 503 || jqXHR.status == 403))
					{
						// Only warn the user once.

						try								{ ui.window.getTopmostFrame().ui.lost_connection = true; }
						catch (ex)						{ /* no problem */ }

						ui.dialog.alert(MSG_CONNECTION_SERVER_LOST + (errorThrown ? "\n - Error: " + errorThrown : ""));
					}
					else								{ ui.dialog.alert(MSG_UNKNOWN_ERROR + "\n - Error: " + errorThrown); }
				}
			}
		}
	}
});

$(window).on("load", function()
{
	ui.init();
});

$(window).on("load", function()
{
	if (!ui.isRecord(window) && !ui.isTable(window) && !ui.isMenu(window))
	{
		// Redirect keyDown events.
		$(document).on("keydown", function(e)
		{
			var kdEvent									= ui.events.call("ui.dashboard.specificKeyDown", window.parent, null, { "event": e }, false);
			if (kdEvent.isDefaultPrevented())
			{
				e.preventDefault();
				e.stopPropagation();
				return false;
			}
		});

		// Redirect keyUp events.
		$(document).on("keyup", function(e)
		{
			var kdEvent									= ui.events.call("ui.dashboard.specificKeyUp", window.parent, null, { "event": e }, false);
			if (kdEvent.isDefaultPrevented())
			{
				e.preventDefault();
				e.stopPropagation();
				return false;
			}
		});
	}
});

(function($)
{
	ui.findRecordFrames = function(name)
	{
		var frames																			= [];

		$("iframe").each(function()
		{
			try																				{ frames = frames.concat($.atsc.window.getWindowFromIFrame(this).ui.findRecordFrames(name)); }
			catch (ex)																		{ /* no problem */ }
		});

		$("frame").each(function()
		{
			try																				{ frames = frames.concat($.atsc.window.getWindowFromFrame(this).ui.findRecordFrames(name)); }
			catch (ex)																		{ /* no problem */ }
		});

		if (ui.isRecord(window) && window.record_name == name)								{ frames.push(window); }

		return frames;
	};

	ui.findTableFrames = function(queryid)
	{
		var frames																			= [];

		$("iframe").each(function()
		{
			try																				{ frames = frames.concat($.atsc.window.getWindowFromIFrame(this).ui.findTableFrames(queryid)); }
			catch (ex)																		{ /* no problem */ }
		});

		$("frame").each(function()
		{
			try																				{ frames = frames.concat($.atsc.window.getWindowFromFrame(this).ui.findTableFrames(queryid)); }
			catch (ex)																		{ /* no problem */ }
		});

		if (ui.isTable(window) && window.ui.table.queryid == queryid)						{ frames.push(window); }

		return frames;
	};
}(jQuery));

(function()
{
	ui.addItemToSkip("properties");

	var Properties = function()
	{
		this.window = window;
	};

	ui.properties = {
		properties: null,

		_getProperties: function(frame)
		{
			if (frame && frame.ui && frame.ui.properties)											{ return frame.ui.properties.getWindowProperties(); }
			else																					{ return null; }
		},

		getGlobalProperties: function()
		{
			var res																					= ui.properties._getProperties(ui.properties.getGlobalFrame());

			if (res == null || !res.window)
			{
				// [MT-7752] This should only occur when the global frame is being unloaded, in which case the entire window is about to close.

				var frame																			= ui.properties.getGlobalFrame();

				return new Properties();
			}
			else																					{ return res; }
		},

		getParentProperties: function()																{ return ui.properties._getProperties(ui.properties.getParentFrame()); },
		getLocalProperties: function()																{ return ui.properties._getProperties(ui.properties.getLocalFrame()); },

		getLocalOrGlobalProperties: function()
		{
			var prop																				= ui.properties.getLocalProperties();

			if (!prop)																				{ prop = ui.properties.getGlobalProperties(); }

			return prop;
		},

		getWindowProperties: function()
		{
			if (!ui.properties.properties)															{ ui.properties.properties = new Properties(); }

			return ui.properties.properties;
		},

		getGlobalFrame: function()																	{ return ui.window.getTopmostFrame(); },

		getParentFrame: function()																	{ return JSO.getParentFrame(); },

		getLocalFrame: function()
		{
			if (ui.isDashboard())																	{ return null; }
			else if (wt)
			{
				if (window != wt && wt.ui && wt.ui.properties)										{ return wt.ui.properties.getLocalFrame(); }
				else if (!window.parent || window.parent == wt || ui.isDashboard(window.parent))	{ return window; }
				else if (wt && JSO.isFrameAccessible(wt.parent) && wt.parent && wt.parent.ui && wt.parent.ui.properties)				{ return wt.parent.ui.properties.getLocalFrame(); }
				else																				{ return window; }
			}
			else																					{ return window; }
		},

		_savePersistentProperties: function(dic)
		{
			try
			{
				window.localStorage["jsProperties"]													= JSON.stringify(dic);

				$.cookie("jsProperties", "", { path: "/", expires: -1 });
			}
			catch (ex)
			{
				$.cookie("jsProperties", JSON.stringify(dic), { path: "/", expires: 31556926 }); // 1 year
			}
		},

		_loadPersistentProperties: function(prop)
		{
			var data																				= null;

			try																						{ data = window.localStorage["jsProperties"]; }
			catch (ex)																				{ /* no problem */ }

			if (!data)
			{
				try																					{ data = JSO.toString($.cookie("jsProperties")); }
				catch (ex)																			{ /* no problem */ }
			}

			try																						{ data = JSON.parse(data); }
			catch (ex)																				{ data = {}; }

			prop["persistentProperties"]															= data;
		},

		_getPersistentProperties: function()
		{
			var prop																				= ui.properties.getGlobalProperties();

			if (!prop["persistentProperties"])														{ ui.properties._loadPersistentProperties(prop); }

			return prop["persistentProperties"];
		},

		getPersistentProperty: function(key)
		{
			return ui.properties._getPersistentProperties()[key];
		},

		setPersistentProperty: function(key, value)
		{
			var dic																					= ui.properties._getPersistentProperties();

			if (value === null)																		{ delete dic[key]; }
			else																					{ dic[key] = value; }

			ui.properties._savePersistentProperties(dic);
		}
	}
})();

(function()
{
	ui.addItemToSkip("validation");

	ui.validation = {
		cell_types: [
			"A1", "A2", "A3", "A4", "A5", "A6",
			"BTWID", "IBAN", "BIC",
			"N", "NL", "N2", "N3", "N4", "NE", "NE2", "MY",
			"P", "P2", "P3", "P4", "M", "M1", "M3", "M4",
			"D", "D2", "D3", "DT", "T", "K", "S", "IP4", "PW", "PW2", "PWN", "EM", "WS", "TN"
		],

		needsValidCheck: function(type)
		{
			for(var i in ui.validation.cell_types)
			{
				if(ui.validation.cell_types[i] == type)
				{
					return true;
				}
			}

			return false;
		},

		isValidInputKey: function(keycode)
		{
			return !((keycode == 35) || (keycode == 36) || (keycode == 37) || (keycode == 39) || (keycode == 9) || (keycode == 13) || (keycode == 16))
		},

		/**
		 * Usage: ui.validation.isValid(str, type, arg1, arg2, strict, array)
		 *
		 * Determines if the provided string is valid given a type and optional arguments.
		 *
		 * @param str The string to validate.
		 * @param type The ftype of the string.
		 * @param arg1 Argument 1, function depends on type.
		 * @param arg2 Argument 2, function depends on type.
		 * @param strict <code>true</code> to perform strict validation, <code>false</code> for loose validation.
		 * Strict validation is done when the field is being marked as errornous, loose validation is performed while the user is typing.
		 * @param array <code>true</code> if this is an array, <code>false</code> if this is a single item.
		 * @returns <code>true</code> if valid, <code>false</code> if not.
		 */
		isValid: function(str, type, arg1, arg2, strict, array)
		{
			type																		= type.toUpperCase();
			if (arg1 != null)															{ arg1 = parseInt(arg1 + ""); }
			if (arg2 != null)															{ arg2 = parseInt(arg2 + ""); }

			if (isNull(arg1))															{ arg1 = null; }
			if (isNull(arg2))															{ arg2 = null; }
			if (isNull(strict))															{ strict = false; }
			if (isNull(array))															{ array = false; }
			if (isNull(str) && str !== 0 && typeof(str) != "string")					{ str = ""; }

			if (array)
			{
				var sep																	= ",";

				if (type.startsWith("N"))												{ sep = ";"; }

				var values																= str.split(sep);
				var valid																= true;

				for (var i = 0; i < values.length; i++)									{ valid = valid && ui.validation.isValid($.trim(values[i]), type, arg1, arg2, strict, false); }

				return valid;
			}

			function validRegex(expr, str)
			{
				var result																= null;

				try
				{
					var regexp															= new RegExp(expr, "g");
					result																= regexp.exec(str);
				}
				catch (ex)																{ $.atsc.log.debug(ex); }

				return result != null && result[0] == str;
			}

			var valid																	= true;

			// Keep in sync with RecordField#validate
			switch (type)
			{
				case "D":
				{
					// Format: DD-MM-YYYY

					if (strict)
					{
						if ($.trim(str) == "")											{ valid	= true; }
						else
						{
							str															= str.replace(/^(\d{1})-/, "0$1-");
							str															= str.replace(/^(\d{2})-(\d{1})-/, "$1-0$2-");

							valid														= validRegex("\\d{2}-\\d{2}-\\d{4}", str);
							valid														= valid && isProperDate(str);
						}
					}
					else																{ valid = validRegex("(\\d{1,2}-\\d{1,2}-\\d{0,4})|(\\d{1,2}-\\d{0,3})|(\\d{0,3})", str); }

					break;
				}

				case "D2":
				{
					if (strict)
					{
						if ($.trim(str) == "")											{ valid = true; }
						else
						{
							valid														= validRegex("\\d{2}-\\d{2}-\\d{4}", str) || validRegex("\\d{2}-\\d{4}", str) || validRegex("\\d{4}", str);

							if (valid)
							{
								// [MT-11221]
								if		(str.length == 4)								{ valid = valid && isProperYear(str); }
								else if	(str.length == 7)								{ valid = valid && isProperMY(str); }
								else if (str.length == 10)								{ valid = valid && isProperDate(str); }
							}
						}
					}
					else																{ valid = validRegex("(\\d{1,2}-\\d{1,2}-\\d{0,4})|(\\d{1,2}-\\d{0,4})|(\\d{0,4})", str); }

					break;
				}

				case "D3":
				{
					if (strict)
					{
						if ($.trim(str) == "")											{ valid = true; }
						else															{ valid = validRegex("\\d{2}-\\d{2}", str); }
					}
					else																{ valid = validRegex("(\\d{1,2}-\\d{1,2})|(\\d{0,2}-?)", str); }

					break;
				}

				case "DT":
				{
					// Format: DD-MM-YYYY of DD-MM-YYYY HH:MM

					if (strict)
					{
						if ($.trim(str) == "")											{ valid	= true; }
						else
						{
							str															= str.replace(/^(\d{1})-/, "0$1-");
							str															= str.replace(/^(\d{2})-(\d{1})-/, "$1-0$2-");
							str															= str.replace(/^(\d{2})-(\d{2})-(\d{4}) (\d{1}):/, "$1-$2-$3 0$4:");

							valid														= validRegex("\\d{2}-\\d{2}-\\d{4}\\s{1}\\d{2}\\:\\d{2}", str);

							if (valid)
							{
								valid													= valid && isProperDate(str.substring(0, 10));
							}
							else
							{
								valid													= validRegex("\\d{2}-\\d{2}-\\d{4}", str);
								valid													= valid && isProperDate(str);
							}
						}
					}
					else																{ valid = validRegex("(\\d{1,2}-\\d{1,2}-\\d{1,4})\\s{0,1}\\d{1,2}\\:\\d{0,2}|(\\d{1,2}-\\d{1,2}-\\d{1,4})\\s{0,1}\\d{0,3}|(\\d{1,2}-\\d{1,2}-\\d{0,4})|(\\d{1,2}-\\d{0,3})|(\\d{0,3})", str); }

					break;
				}

				case "IBAN":
				case "BIC":
				{
					valid																= true; // IBAN/BIC kan alleen achteraf beoordeeld worden (zie UIRecord.js: checkInput(...))

					break;
				}

				case "IP4":
				{
					// Format: 0.0.0.0 to 255.255.255.255 (standard IPv4 address)

					valid																= str == "" || validRegex("(\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3})|(\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.)|(\\d{1,3}\\.\\d{1,3}\\.\\d{1,3})|(\\d{1,3}\\.\\d{1,3}\\.)|(\\d{1,3}\\.\\d{1,3})|(\\d{1,3}\\.)|(\\d{1,3})", str);

					if (valid && strict && str != "")
					{
						try
						{
							var spl														= str.split(".");
							valid														= valid && (spl.length == 4);

							if (valid)
							{
								var temp;
								for (var i = 0; i < 4; i++)
								{
									temp												= parseInt(spl[i]);
									if (temp < 0 || temp > 255)
									{
										valid											= false;
										break;
									}
								}
							}
						}
						catch (ex)														{ valid = false; }
					}

					break;
				}

				case "A1":
				case "A4":
				case "A6":
				case "PW":
				case "TN":
				{
					// Restrictions: Anything goes.

					if (strict || arg1 == -1)											{ valid = validRegex("(\\w|\\s|\\W)*", str); }
					else																{ valid = validRegex("(\\w|\\s|\\W){0," + arg1 + "}", str); }

					break;
				}

				case "A2":
				case "A4":
				case "PW2":
				{
					// Restrictions: No lowercase letters.

					if (strict)
					{
						valid															= validRegex("[^a-z]*", str);
					}
					else
					{
						if (arg1 == -1)													{ valid = validRegex("(\\w|\\s|\\W)*", str); }
						else															{ valid = validRegex("(\\w|\\s|\\W){0," + arg1 + "}", str); }
					}

					break;
				}

				case "A3":
				{
					if (strict)
					{
						valid															= validRegex("(^[^a-z]+(\\w|\\s|\\W)*)|(^[^a-z]?)", str);
					}
					else
					{
						if (arg1 == -1)													{ valid = validRegex("(\\w|\\s|\\W)", str); }
						else															{ valid = validRegex("(\\w|\\s|\\W){0," + arg1 + "}", str); }
					}

					break;
				}

				case "A5":
				{
					// Restrictions: Alphanumeric characters, underscores (_), pipe symbols (|) and dashes (-) only.

					valid																= validRegex("^([a-zA-Z0-9_]|-)*", str);
					break;
				}

				case "N":
				case "NL":
				case "PWN":
				case "N2":
				case "NE":
				case "NE2":
				case "P":
				case "P2":
				case "P3":
				case "P4":
				{
					// Restrictions: A number with [arg1] digits before the comma and [arg2] digits after the comma.
					// If arg2 <= 0 no decimals are allowed.

					var mustBePositive													= (type == "N" || type == "NL" || type == "PWN" || type == "NE" || type == "P" || type == "P3");
					var prefix															= mustBePositive ? "" : "-?";
					var maxNumber														= arg1;
					var maxDecimal														= arg2;
					var isPercentage													= (type == "P" || type == "P2" || type == "P3" || type == "P4");

					// The ftype for percentages (P, P2, P3, P4) have fixed bounds.
					if (type == "P" || type == "P2")
					{
						maxNumber														= 5;
						maxDecimal														= 2;
					}
					else if (type == "P3" || type == "P4")
					{
						maxNumber														= 6;
						maxDecimal														= 2;
					}

					if (maxNumber == -1)												{ maxNumber = 19; }
					if (maxDecimal == -1)												{ maxDecimal = 10; }

					if (maxDecimal > 0)													{ valid = validRegex(prefix + "\\d{0," + (maxNumber - maxDecimal) + "}([\\.,]\\d{0," + (maxDecimal) + "})?", str); }
					else																{ valid	= validRegex(prefix + "\\d{0," + (maxNumber) + "}", str); }

					if (valid && strict && $.trim(str) != "" && isPercentage)
					{
						try
						{
							// [MT-1543]
							if ($.trim(str) == "-" && !mustBePositive)					{ str = "-0"; }

							var i														= parseFloat(str.replace(/,/, "."));

							if (type == "P" || type == "P2")							{ valid = i >= -100 && i <= 100; }
							else														{ valid = i >= -1000 && i <= 1000; }
						}
						catch (ex)
						{
							$.atsc.log.error(ex);

							valid														= false;
						}
					}

					break;
				}
				case "MY":
				{
					// Format: MM-YYYY

					if (strict)
					{
						if ($.trim(str) == "")
						{
							valid														= true;
						}
						else
						{
							str															= str.replace(/^([2-9]{1})/, "0$1-");

							valid														= validRegex("\\d{2}-\\d{4}", str);
							valid														= valid && isProperMY(str);
						}
					}
					else
					{
						valid															= validRegex("(\\d{1,2}-\\d{0,4})|(\\d{0,3})", str);
					}

					break;
				}
				case "M":
				case "M1":
				case "M4":
				case "WS":
				case "CB":
				{
					// Restrictions: None.
					// Depending on the ftype additional validation may be performed elsewhere.

					valid																= true;
					break;
				}

				case "K":
				{
					// Format: XX-YY-ZZ, XX-YYY-ZZ, XX-YYY, XX
					// This string should represent a valid license plate.

					if (strict)
					{
						if ($.trim(str) == "")											{ valid = validRegex("\\w{0,1}", str); }
						else															{ valid = validRegex("(\\w{2}-\\w{2,3}-\\w{1,2})", str); }
					}
					else																{ valid = validRegex("(\\w{2}-\\w{2,3}-\\w{0,2})|(\\w{2}-\\w{0,3})|(\\w{0,2})", str); }

					break;
				}

				case "S":
				{
					// Format: 123456789
					// This string should represent a valid Dutch BSN number.

					if (strict)
					{
						if ($.trim(str) != "")
						{
							valid														= validRegex("\\d{9}", str) && bValidateSofinummer(str);
						}
					}
					else
					{
						valid															= validRegex("\\d{0,9}", str);
					}

					break;
				}

				case "EM":
				{
					if (strict)															{ valid = validRegex(/(\"[^\"]*\")?(([^@]+)@([^@ ,\\']+)){0,255}/, str); } // See RecordField.java and TestVarious.java
					else																{ valid = validRegex("(\\w|\\s|\\W)*", str); }

					break;
				}

				case "T":
				{
					// Format: 00:00:00 to 23:59:59
					// arg2 can be used to specify the maximum amount of hours.

					if (arg2 <= 0)														{ arg2 = 2; }

					valid																= validRegex("\\d{0," + arg2 + "}\\:?", str) || validRegex("\\d{0," + arg2 + "}\\:\\d{0,2}\\:?", str) || validRegex("\\d{0," + arg2 + "}\\:\\d{0,2}\\:\\d{0,2}", str);
					if (strict && $.trim(str) != "")									{ valid = valid && isProperTime(str, arg2); }

					break;
				}
			}

	//		$.atsc.log.debug("valid: "  + valid + "\nstring: " + str + "\ntype: " + type);

			return valid;
		},

		transform: function(str, type, arg1, arg2, array, fromClipboard, preventTrimming)
		{
			if (isNull(array))															{ array = false; }
			if (isNull(type))															{ type = ""; }
			if (isNull(fromClipboard))													{ fromClipboard = false; }
			if (isNull(preventTrimming))												{ preventTrimming = false; }

			if (array)
			{
				var sep																	= ",";

				if (type.startsWith("N"))												{ sep = ";"; }

				var values																= str.split(sep);
				var valid																= true;
				var value;

				str																		= "";

				for (var i = 0; i < values.length; i++)
				{
					value																= values[i];

					if (!preventTrimming || i != (values.length - 1))					{ value = $.trim(value); }
					else																{ value = value.replace(/^\s+/, ""); } // ltrim

					value																= ui.validation.transform(value, type, arg1, arg2, false);

					str																	= str + value;

					// [MT-4048] This construction prevents information from being lost.
					if		(value != "" && i < (values.length - 1))					{ str = str + sep + " "; }
					// [MT-10787]
					else if (value == "" && i == (values.length - 1))					{ str = str.trim(); }
				}

				return str;
			}

			type																		= type.toUpperCase();

			if (arg1 != null)															{ arg1 = parseInt(arg1 + ""); }
			if (arg2 != null)															{ arg2 = parseInt(arg2 + ""); }

			if (isNull(arg1))															{ arg1 = null; }
			if (isNull(arg2))															{ arg2 = null; }

			function validRegex(expr, str)
			{
				var result																= null;

				try
				{
					var regexp															= new RegExp(expr, "g");
					result																= regexp.exec(str);
				}
				catch (ex)																{ $.atsc.log.debug(ex); }

				return result != null && result[0] == str;
			}

			//DEBUG: Uncomment this to check the validation type of the field you're editing.
			/*
			if(typeof(ui.validation.found) == "undefined" || ui.validation.found != type)
			{
				ui.validation.found = type;
				alert("New validation used: " + type);
			}
			*/

			// Translate Alt-255 characters to spaces.
			str																			= str.replace(/\u00A0/g, " ");

			// Strip zero-width spaces.
			str																			= str.replace(/\u200B/g, "");

			switch (type)
			{
				//Dates
				case "D":
				case "D2":
				case "D3":
				case "DT":
				{
					//Add a leading zero if the first number does not have one. (day part)
					str																	= str.replace(/^(\d{1})-$/, "0$1-");

					//Add a leading zero if the first number does not have one. (month part)
					str																	= str.replace(/^(\d{2})-(\d{1})-$/, "$1-0$2-");

					if (type == "D" || type == "D3" || type == "DT" || ((str.length >= 2 && parseInt(str.substring(0, 2)) <= 18) || str.length > 4))
					{
						//Add a dash (-) after the first two numbers. (day -> month)
						str																= str.replace(/^(\d{2})(\d)/, "$1-$2");
					}

					var pos;
					if (type == "D2")													{ pos = str.indexOf("-"); }

					if (type == "D" || type == "DT" || (pos > -1 && str.length >= 5 && parseInt(str.substring(pos + 1, pos + 3)) <= 12))
					{
						//Add a dash (-) after the first two numbers. (month -> year)
						str																= str.replace(/^(\d{2}-\d{2})(\d)/, "$1-$2");
					}

					if (type == "DT")
					{
						//Add a space ( ) after the date. (date -> time)
						str																= str.replace(/^(\d{2}-\d{2}-\d{4})(\d)/, "$1 $2");
						//Add a colon (:) after the hour. (hour -> min)
						str																= str.replace(/^(\d{2}-\d{2}-\d{4}\s{1}\d{2})(\d)/, "$1:$2");
					}

					str																	= $.trim(str);

					break;
				}

				case "MY":
				{
					//Add a leading zero if the first number does not have one. (month part)
					str																	= str.replace(/^(\d{1})-$/, "0$1-");
					str																	= str.replace(/^([2-9]{1})$/, "0$1-");

					//Add a dash (-) after the first two numbers. (month -> year)
					str																	= str.replace(/^(\d{2})(\d)/, "$1-$2");

					var pos;

					str																	= $.trim(str);

					break;
				}
				case "BTWID":
				{
					str																	= str.toUpperCase();
					str																	= str.replace(/\s/g, "");
					str																	= str.replace(/[.]/g, "");

					break;
				}
				case "IBAN":
				case "BIC":
				{
					str																	= str.toUpperCase();
					str																	= str.replace(/\s/g, "");

					break;
				}

				//IPv4 addresses
				case "IP4":
				{
					//Add dots if the user specifies a zero for one of the octets.
					str																	= str.replace(/^([0])(\d)$/, "$1.$2");
					str																	= str.replace(/^(\d{1,3}\.[0]{1})(\d)$/, "$1.$2");
					str																	= str.replace(/^(\d{1,3}\.\d{1,3}\.[0]{1})(\d)$/, "$1.$2");

					//Add dots after the user has specified 3 numbers for an octet.
					str																	= str.replace(/^(\d{3})(\d)$/, "$1.$2");
					str																	= str.replace(/^(\d{1,3}\.\d{3})(\d)$/, "$1.$2");
					str																	= str.replace(/^(\d{1,3}\.\d{1,3}\.\d{3})(\d)$/, "$1.$2");

					//If there is an octet with more than 3 numbers merge it with an adjacent one.
					str																	= str.replace(/(\d{3})(\d{1,15})/, "$1");

					//If there are two dots next to each other remove one of them.
					str																	= str.replace(/(\.)\./, "$1");

					//Replace multiple zeroes in the last octet.
					str																	= str.replace(/(0)(0{1,15})/, "$1");

					//Strip leading zeros from the octets. (Other blocks)
					str																	= str.replace(/(\.[0]{1,3})(\d{1,3})/, ".$2");

					//Strip leading zeroes from the octets. (First block)
					str																	= str.replace(/^0(\d{1,3})/, "$1");

					str																	= $.trim(str);

					break;
				}

				//Uppercase inputs
				case "A2":
				case "A4":
				case "PW2":
				{
					str																	= str.toUpperCase();
					break;
				}

				//Lowercase inputs
				case "A6":
				{
					str																	= str.toLowerCase();
					break;
				}

				//Proper case inputs ("Not Like This" "But like this")
				case "A3":
				{
					var prefix															= str.substring(0, 1);
					var suffix															= str.substring(1);
					str																	= prefix.toUpperCase() + suffix;

					break;
				}

				//Numeric inputs
				case "N":
				case "NL":
				case "N2":
				case "NE":
				case "NE2":
				case "P":
				case "P2":
				case "P3":
				case "P4":
				{
					if (fromClipboard)
					{
						// [MT-2866] Strip additional ',' characters so that the user may paste numbers such as "1.234,56"
						// [MT-8131] 1.234 (without decimals)
						// [MT-10064] Plakken van koersen (5 decimalen) werkte niet
						var pos_comma													= str.indexOf(",");
						var pos_point													= str.indexOf(".");

						if (pos_comma >= 0 && pos_point >= 0)
						{
							var decimalpos												= pos_comma;

							if (pos_point > pos_comma)									{ decimalpos = pos_point; }

							if (decimalpos >= 0)										{ str = str.replaceAt(decimalpos, '~'); }

							str															= str.replace(/[\.,]/g, '');
							str															= str.replace('~', ',');
						}
					}

					var mustBePositive													= (type == "N" || type == "NL" || type == "PWN" || type == "NE" || type == "P" || type == "P3");
					var prefix															= mustBePositive ? "" : "-?";
					var maxNumber														= arg1;
					var maxDecimal														= arg2;

					//The ftype for percentages (P, P2, P3, P4) have fixed bounds.
					if (type == "P" || type == "P2")
					{
						maxNumber														= arg2 > 0 ? 5 : 3;
						maxDecimal														= arg2 > 0 ? 2 : 0;
					}
					else if (type == "P3" || type == "P4")
					{
						maxNumber														= arg2 > 0 ? 6 : 4;
						maxDecimal														= arg2 > 0 ? 2 : 0;
					}

					if (maxNumber == -1)												{ maxNumber = 19; }
					if (maxDecimal == -1)												{ maxDecimal = 10; }

					if (maxDecimal > 0)
					{
						//Add a comma if the maximum amount of numbers have been used.
						str																= str.replace(new RegExp("^(" + prefix + "\\d{" + (maxNumber - maxDecimal).toString() +  "})(\\d)", "g"), "$1,$2");

						//If we start with a dot or a comma make the number start with a zero. (converts ",00" to "0,00")
						if (prefix == "")												{ str = str.replace(new RegExp("^(\\,|\\.)(\\d*)", "g"), "0,$2"); }
						else															{ str = str.replace(new RegExp("^(" + prefix + ")(\\,|\\.)(\\d*)", "g"), "$10,$3"); }

						//Replace a dot with a comma.
						str																= str.replace(/\./, ",");

						//If we have too many numbers on the decimal part (as a result of holding down a number key) strip the last number(s) from it.
						str																= str.replace(new RegExp("(\\,\\d{1," + (maxDecimal) + "})(\\d*)$", "g"), "$1");
					}
					else
					{
						//If we have too many numbers (as a result of holding down a number key) strip the last number(s) from it.
						str																= str.replace(new RegExp("(\\d{1," + (maxNumber) + "})(\\d*)$", "g"), "$1");
					}

					//For percentage fields only:
					if (type == "P" || type == "P2" || type == "P3" || type == "P4")
					{
						//The field actually only accepts up to 100,00 or 1000,00 and not 999,99 or 9999,99.
						//This strips the last digit from the number part if it's too large.
						var maxN														= "";
						for (var i = 0; i < maxNumber - maxDecimal - 1; i++)			{ maxN = maxN + "9"; }
						maxN															= parseInt(maxN) + 1;

						if (parseInt(str.replace(/\\,/g, ".")) > maxN)
						{
							str															= str.replace(new RegExp("^(" + prefix + "\\d{" + (maxNumber - maxDecimal - 1) + "})(\\d+)", "g"), "$1");
						}
					}

					//For percentage fields only:
					if (type == "P" || type == "P2" || type == "P3" || type == "P4")
					{
						//Strip leading zeros from the number part. (000,00 -> 0,00)
						str																= str.replace(/^00+/g, "0");

						//Remove the first zero from the number part if another number follows it. (01,00 -> 1,00)
						str																= str.replace(/^0(\d)/g, "$1");
					}

					str																	= $.trim(str);

					// [MT-9914]: Zie ook HTMLInputField.java
					if (arg2 == "4")
					{
						if (str.indexOf(",") > -1)
						{
							var decimals												= str.right(",");

							if (decimals.length == 4)
							{
								var  v													= str;

								if (decimals.charAt(3) == '0')	{ v = str.substring(0, v.length - 1); }
								if (decimals.charAt(2) == '0')	{ v = str.substring(0, v.length - 1); }

								str														= v;
							}
						}
					}

					break;
				}

				//License plate numbers (Dutch cars)
				case "K":
				{
					//Force the string to be uppercase.
					str																	= str.toUpperCase();

					//Add a dash after the first two characters typed. ("ABC" -> "AB-C")
					str																	= str.replace(/^(\w{2})(\w)/, "$1-$2");

					//Add another dash after typing three more characters. ("AB-CDEF" -> "AB-CDE-F")
					str																	= str.replace(/^(\w{2}-\w{3})(\w)/, "$1-$2");

					//Strip excess characters from the result. ("AB-CD-EFG" -> "AB-CD-EF")
					str																	= str.replace(/^(\w{2}-\w{2,3}-\w{2})\w+/, "$1");

					str																	= $.trim(str);

					break;
				}

				//Timestamps (hour:minute:second)
				case "T":
				{
					if (!arg2 || arg2 <= 0)												{ arg2 = 2; }

					//Add a zero to the start if the indicated hours is less than 10. ("1:" -> "01:")
					str																	= str.replace(/^(\d{1}):$/, "0$1:");

					//Place a colon inbetween the hour and the minute. ("111" -> "11:1")
					str																	= str.replace(new RegExp("^(\\d{" + arg2 + "})(\\d)"), "$1:$2");

					//Place a colon inbetween the minute and the second. ("11:111" -> "11:11:1")
					str																	= str.replace(new RegExp("^(\\d{" + arg2 + "}:\\d{2})(\\d)"), "$1:$2");

					//Strip excess numbers. ("11111111" -> "11:11:11")
					str																	= str.replace(new RegExp("^(\\d{" + arg2 + "}:\\d{2}:\\d{2})\\d+"), "$1");

					str																	= $.trim(str);

					break;
				}
			}

			switch (type)
			{
				//Text inputs
				case "A1":
				case "A5":
				case "S":
				case "EM":
				case "WS":
				case "CB":
				case "TN":
				{
					//Enforce the length of the string typed.
					if(arg1 > 0 && str.length > arg1)									{ str = str.substring(0, arg1); }

					break;
				}
			}

			return str;
		},
		isZipcodeValid: function(zipcode, countrycode)
		{
			var regex										= ui.validation.getZipcodeRegex(countrycode);
			if(regex != "")									{ return ($.atsc.string.validateRegex(regex, zipcode)); }

			return true;
		},
		_getZipcodeData: function(countrycode)
		{
			if (!countrycode)								{ return; }

			var xmlDoc;

			if (countrycode.length <= 3)
			{
				var jqXHR									= $.ajax({
					url: IOSERVLETURL + "?action=100&name=Land&landcod=" + encodeURIComponent(countrycode),
					dataType: "xml",
					cache: false,
					async: false,
					error: function(jqXHR, textStatus, errorThrown)
					{
						alert(MSG_CONNECTION_SERVER_LOST + "\n - Status: " + textStatus);
					}
				});

				xmlDoc										= $(jqXHR.responseXML);
			}
			else											{ xmlDoc = $(); }

			if (xmlDoc.find("pattern").length > 0)
			{
				zipcodeCache[countrycode]					= {"mask": xmlDoc.find("pattern").text(), "example": xmlDoc.find("example").text()};
			}
			else
			{
				jqXHR										= $.ajax({
					url: IOSERVLETURL,
					type: "get",
					data: {"action": "100", "name": "Land", "omschr": countrycode},
					dataType: "xml",
					cache: false,
					async: false,
					error: function(jqXHR, textStatus, errorThrown)
					{
						alert(MSG_CONNECTION_SERVER_LOST + "\n - Status: " + textStatus);
					}
				});

				xmlDoc										= $(jqXHR.responseXML);
				if (xmlDoc.find("pattern").text() != "")	{ zipcodeCache[countrycode] = {"mask": xmlDoc.find("pattern").text(), "example": xmlDoc.find("example").text()}; }
			}
		},
		getZipcodeMask: function(countrycode)
		{
			if (!zipcodeCache[countrycode])					{ ui.validation._getZipcodeData(countrycode); }
			if (!zipcodeCache[countrycode])					{ return ""; }
			return zipcodeCache[countrycode]["example"];
		},
		getZipcodeRegex: function(countrycode)
		{
			if (!zipcodeCache[countrycode])					{ ui.validation._getZipcodeData(countrycode); }
			if (!zipcodeCache[countrycode])					{ return new RegExp(".*", "ig"); }
			return new RegExp(zipcodeCache[countrycode]["mask"], "ig");
		}
	};

	var zipcodeCache										= {};

	ui.validation.performStartEndDateValidation = function(startdatid, einddatid, errmsg)
	{
		if (typeof(errmsg) == "undefined")					{ errmsg = "De einddatum mag niet voor de startdatum liggen!"; }

		var d1												= setDutchDate($("#" + startdatid).val());
		var d2												= setDutchDate($("#" + einddatid).val());

		if (d1 && d2)
		{
			if (d2 < d1)									{ $("#" + einddatid).attr("valid", "false").attr("errmsg", errmsg); }
			else											{ makeValid($("#" + einddatid)); }

			setErrorClass($("#" + einddatid));
		}
	}
})();

function getErrorMessageStart() { return "De volgende fouten zijn gevonden"; }
function getItemErrorMessage() { return "is ongeldig"; }

function specialCharCheck(input)
{
	input = JSO.toString(input);
	var amposand=/&/g;
	var lesserThan=/</g;
	var greaterThan=/>/g;

	input = input.replace(amposand,"&amp;");
	input = input.replace(lesserThan,"&lt;");
	input = input.replace(greaterThan,"&gt;");

	if (input.indexOf("") > -1)	{ input = input.replace("", "\u241D"); } // ASCII group separator (ALT-029: invisible!) -> UTF-8 group separator

	return input;
}

//de eerste classindicatie is het type van het veld!
function getFType(item)
{
	item												= $(item);

	if (!$(item).length)								{ return ""; }

	if (item.parent().find(".CodeMirror").length > 0)	{ return "CE"; }

	var className										= $(item)[0].className;

	if (typeof(className) == "string")					{ return className.split(" ")[0]; }
	else												{ return ""; }
}

(function($)
{
//	ui.addItemToSkip("form");

	ui.form = {};

	function processForm($form)
	{
		$form.find(".captcharefresh").on("click", function()
		{
			$(".captchaimage").attr("src", "/CaptchaServlet?captchaid=" + $form.find("input[name=uniqueid]").val() + "&_=" + new Date());
		});

		$form.data("ui.form.submit", function()
		{
			try
			{
				ui.form.save($form, {
					closeWindow: true,
					afterCompleteCallback: function()
					{
						if ($form.data("isClosed"))
						{
							// sysfrm
							if ($form.find("input[type=hidden][name=record],input[type=hidden][name=version]").length == 2)
							{
								$form.find("input[type=hidden][name=mode]").val(MODE_ADD);

								$.ajax({
									url: IOSERVLETURL,
									data: { "name": "sysfrmdef", "action": "102" },
									success: function(xmlDoc, textStatus, jqXHR)
									{
										$form.find("input[type=hidden][name=uniqueid]").val($(xmlDoc).find("uniqueid").text());
									}
								});
							}

							try							{ onFormSave($form); }
							catch (ex)
							{
								var returnpage			= $form.find("input[type=hidden][name=returnpage]").val();

								if (returnpage)			{ JSO.setWindowLocation(window, returnpage); }
								else					{ window.location.reload(false); }
							}
						}
					}
				});
			}
			catch (ex)
			{
				$.atsc.log.warn(ex);
			}
		})

		if ($form.attr("data-bind-submit") != "false")
		{
			$form.on("submit", function(e)
			{
				e.preventDefault();

				$form.data("ui.form.submit")();
			});
		}
	}

	$(document).on("ready", function()
	{
		$("form").each(function()
		{
			$(this).data("onSave", false);
			$(this).data("onQuit", false);
			$(this).data("isSaved", false);
			$(this).data("isClosed", false);
			$(this).data("isConcept", false);
		});
	});

	$(document).on("ready", function()
	{
		$("form").each(function()
		{
			if (!$(this).attr("action") && $(this).find("input[type=hidden][name=formname],input[type=hidden][name=formversion]").length == 2)
			{
				processForm($(this));
			}
		});
	});

	ui.form.createXML = function(form, action, timestamp, version)
	{
		return createXML($(form), action, timestamp, version);
	}

	function isRecordAndFirstForm($form)											{ return ui.isRecord(window) && $form[0] == $("form:not(.uiform_skip)").first()[0]; }

	function createXML($form, action, timestamp, version)
	{
		if ($form && $form.length > 0)
		{
			var form																= $form[0];

			var el																	= form.elements;
			var ell																	= el.length;
			var i																	= 0;
			var radioButton															= "";
			var xml																	= "<form id=\"" + $form.find("input[type=hidden][name=formname]").val() + "\" version=\"" + version + "\" action=\"" + action + "\" uniqueid=\"" + $("#uniqueid", form).val() + "\" isSaved=\"" + $form.data("isSaved") + "\" isConcept=\"" + $form.data("isConcept") + "\" isFromGUI=\"true\" timestamp=\"" + (timestamp ? timestamp : new Date().getTime()) + "\">";
			var eli;

			var options																= getMenuOptions();

			for (var j = 0; j < options.length; j = j + 1)							{ xml = xml + "<menuoption>" + options[j] + "</menuoption>"; }

			if (isRecordAndFirstForm($form))
			{
				if (ui.record.selectedParentTableName != null)						{ xml = xml + "<field id=\"selectedParentTableName\">" + ui.record.selectedParentTableName + "</field>"; }
				if (ui.record.selectedParentTableRowUNID != null)					{ xml = xml + "<field id=\"selectedParentTableRowUNID\">" + ui.record.selectedParentTableRowUNID + "</field>"; }
				if (ui.record.selectedParentTableRowUNIDs != null)					{ xml = xml + "<field id=\"selectedParentTableRowUNIDs\">" + ui.record.selectedParentTableRowUNIDs + "</field>"; }
			}

			while (i < ell)
			{
				eli																	= el[i];

				if (eli.getAttribute("block") == null)
				{
					switch(eli.tagName)
					{
						case "INPUT":
						{
							switch(eli.type)
							{
								case "text":
								case "email":
								case "number":
								{
									xml												= xml + addValue(xml, eli) ;
									xml												= xml + addFuidValue(xml, eli)

									break;
								}

								case "hidden":
								{
									xml												= xml + addValue(xml, eli);

									break;
								}

								case "checkbox":
								{
									if (getAttribute(eli, "group") != null && getAttribute(eli, "group") == "true")
									{
										// alleen bij de 1e input van de checkboxgroup de xml opbouwen
										var id										= eli.id;

										if ($(eli).attr("first") == "true")			{ xml = xml + getCheckboxGroupValues(eli); }
									}
									else											{ xml = xml + "<field id=\"" + eli.id + "\">" + eli.checked + "</field>"; }

									break;
								}

								case "radio":
								{
									var rbname										= eli.name;

									if(radioButton != rbname) // Er mag maar een field tag op het xmlform
									{
										var tmpval									= JSO.toString($("input[name=" + rbname + "]:checked").val());

										xml											= xml + addValue(xml, eli, rbname, tmpval)
										//xml = xml + "<field id=\"" + rbname + "\">" + specialCharCheck($("input[name=" + rbname + "]:checked").val()) + "</field>";
									}

									break;
								}

								case "password":
								{
									xml												= xml + addValue(xml, eli);

									if (getAttribute(eli, "fuidname"))
									{
										var fuidValue								= getAttribute(eli, "fuidvalue");

										if (fuidValue == null)						{ fuidValue = ""; }

										xml											= xml + "<field id=\"" + getAttribute(eli, "fuidname") + "\">" + fuidValue + "</field>";
									}

									break;
								}

								case "file":
								{
									if ($(eli).data("value"))						{ xml = xml + "<field id=\"" + eli.name + "\"><![CDATA[" + $(eli).data("value") + "]]></field>"; }

									break;
								}
							}

							break;
						}
						case "SELECT":
						{
							xml														= xml + addValue(xml, eli);
							xml														= xml + addFuidValue(xml, eli);

							break;
						}

						case "TEXTAREA":
						{
							if (getFType(eli) == "M4")
							{
								try													{ tinyMCE.triggerSave(); }
								catch (ex)											{ /* no problem */ }
							}

							xml														= xml + addValue(xml, eli);

							break;
						}
					} // end switch
				}

				i																	= i + 1;
			}

			xml																		= xml + "</form>";

			wt.previousXML															= xml;
//			console.log(xml);
//			alert(xml);
			return xml;
		}
		else																		{ return ""; }
	}

	function addValue(xml, eli, fieldname, fieldvalue)
	{
		if (!fieldname)																{ fieldname = eli.id; }

		if (fieldname == "MT-5485")													{ return ""; }

		if (!fieldvalue)															{ fieldvalue = eli.value; }

		if ($(eli).attr("data-array") == "true")
		{
			var sep																	= getArraySeparator(eli);
			var arr																	= getValues(eli, fieldvalue);

			fieldvalue																= "";

			for (var i = 0; i < arr.length; i = i + 1)
			{
				if (i > 0)															{ fieldvalue = fieldvalue + sep; }

				fieldvalue															= fieldvalue + specialCharCheck($.trim(arr[i]));
			}
		}
		else																		{ fieldvalue = specialCharCheck(fieldvalue); }

		return "<field id=\"" + fieldname + "\">" + fieldvalue + "</field>";
	}

	function getArraySeparator(item)
	{
		if (getFType(item).startsWith("N"))											{ return ";"; }
		else																		{ return ","; }
	}

	ui.form.getArraySeparator														= getArraySeparator;

	function getValues(item, fieldvalue)
	{
		if ($(item).attr("data-array") == "true")									{ return JSO.toString(fieldvalue ? fieldvalue : $(item).val()).split(getArraySeparator(item)); }
		else																		{ return JSO.toString(fieldvalue ? fieldvalue : $(item).val()); }
	}

	function getFuidValues(item, fuidvalue)
	{
		if ($(item).attr("data-array") == "true")
		{
			var tmp																	= JSO.toString(fuidvalue ? fuidvalue : $(item).attr("fuidvalue"));

			if (tmp.indexOf("_sep_") > -1)											{ return tmp.split(/_sep_/); }
			else																	{ return tmp.split(/,/); }
		}
		else																		{ return JSO.toString(fuidvalue ? fuidvalue : $(item).attr("fuidvalue")); }
	}

	ui.form.getValues																= getValues;
	ui.form.getFuidValues															= getFuidValues;

	ui.form.getForeignFields = function(foreignUNIDName, foreignUNIDValue)
	{
		var foreignFields = {};

		var names = foreignUNIDName.split(",");
		var values = foreignUNIDValue.split(",");

		for (var i = 0; i < names.length; i = i + 1)
		{
			if (values.length > i)
			{
				foreignFields[names[i]] = values[i];
			}
		}

		return foreignFields;
	};

	function addFuidValue(xml, eli)
	{
		var tmpXML																	= "";

		if (JSO.toString($(eli).attr("fuidname")) != "")
		{
			var fuidValue															= getFuidValues(eli);

			if ($(eli).attr("data-array") == "true")								{ fuidValue = fuidValue.join("_sep_"); }

			tmpXML																	= "<field id=\"" + getAttribute(eli, "fuidname") + "\">" + fuidValue + "</field>";
		}

		return tmpXML;
	}

	function getCheckboxGroupValues(eli)
	{
		var rname																	= $(eli).attr("name");
		var unid																	= $(eli).attr("fuidname");
		var xml																		= "<field id=\"" + rname + "\"><![CDATA[";
		var xml_unid																= "";

		if (unid)																	{ xml_unid = "<field id=\"" + unid + "\"><![CDATA["; }

		var items																	= $("input[name=" + rname + "]");
		var first																	= true;

		items.each(function()
		{
			if ($(this).is(":checked"))
			{
				if (!first)
				{
					xml																= xml + "_sep_";
					if (unid)														{ xml_unid = xml_unid + "_sep_"; }
				}

				first																= false;

				xml																	= xml + specialCharCheck($(this).val());
				if (unid)															{ xml_unid = xml_unid + specialCharCheck($(this).attr("fuidvalue")); }
			}
		});

		xml																			= xml + "]]></field>";
		if (unid)																	{ xml_unid = xml_unid + "]]></field>"; }

		return xml + xml_unid;
	}

	ui.form.checkForm = function(form)
	{
		form																		= $(form)[0];

		var invalidElements															= [];
		var valid																	= true;
		var elem;

		for (var i = 0; i < form.elements.length; i = i + 1)
		{
			elem																	= $(form.elements[i]);

			if (elem.attr("valid") == "false" && JSO.toString(elem.attr("block")) == "")
			{
				valid																= false;

				invalidElements.push(elem);
			}
		}

		if (!valid)
		{
			var msg																	= getErrorMessageStart() + ":\n\n";

			var labelElem;

			for (var i = 0; i < invalidElements.length; i = i + 1)
			{
				elem																= invalidElements[i];
				labelElem															= $("#l" + (elem.attr("id") ? elem.attr("id") : elem.attr("name")).replace(/_hidden/g, ""));

				if (labelElem.length > 0)											{ msg = msg + "'" + replaceAll(labelElem.html(), " .", "") + "' " + getItemErrorMessage() + "!\n"; }
				else																{ msg = msg + "'" + elem.attr("id") + "' " + getItemErrorMessage() + "!\n"; }

				if (JSO.toString(elem.attr("errmsg")) != "")						{ msg = msg + "     " + JSO.toString(elem.attr("errmsg")).replace(/\n/g, "\n     ") + "\n"; }
			}

			function afterSwitchTo()
			{
				try
				{
					if (!isLookupOpen() && !JSO.dialogExists())						{ invalidElements[0].focus(); }
					else															{ focusLookup(); }
				}
				catch (ex)															{ $.atsc.log.warn(ex); }
			}

			function doSwitch()
			{
				if (typeof(ui.tab) != "undefined")									{ ui.tab.switchTo(ui.tab.getTab(invalidElements[0].attr("id")), true, afterSwitchTo); }
				else																{ afterSwitchTo(); }
			}

			if (!ui.tab || ui.tab.getTab(invalidElements[0].attr("id")) == ui.tab.activeTab){ ui.dialog.alert(msg, doSwitch); }
			else																	{ doSwitch(); }
		}

		return valid;
	}

	/**
	 * Save the record associated with the provided form element.
	 *
	 * @param form The form to save.
	 * @param options A dictionary of options.
	 */
	ui.form.save = function(form, options)
	{
		if (ui.initialized && !$(form).data("onSave") && !$(form).data("onQuit"))
		{
			$(form).data("onSave", true);

			var settings = {
				closeWindow: undefined,          // true if the current window should be closed after the save process, false if not.
				afterSuccessCallback: undefined, // An optional function to use as a callback if the record was successfully saved
				afterErrorCallback: undefined,   // An optional function to use as a callback if the record was not successfully saved
				afterCompleteCallback: undefined,// Executed regardless of success or failure at the END of the save (or save&close) action.
				userAction: false                // true if this is a user-initiated action, false if this is done through code.
			};

			if (options)											{ $.fn.extend(settings, options); }

			var closeWindow											= settings["closeWindow"];
			var afterSuccessCallback								= settings["afterSuccessCallback"];
			var afterErrorCallback									= settings["afterErrorCallback"];
			var afterCompleteCallback								= settings["afterCompleteCallback"];
			var userAction											= settings["userAction"];
			var startHandler										= settings["startHandler"];
			var fmode												= $(form).find("input[type=hidden][name=mode]").val();
			var name												= settings["name"] || $(form).find("input[type=hidden][name=formname]").val();
			var version												= settings["version"] || $(form).find("input[type=hidden][name=formversion]").val();

			var timestamp											= new Date().getTime();

			var afterCompleteCallbackOriginal						= afterCompleteCallback;

			afterCompleteCallback = function(b)
			{
				if (isFunction(afterCompleteCallbackOriginal))		{ afterCompleteCallbackOriginal(b); }

				if ($(form).data("retrySave"))
				{
					$(form).data("retrySave", false);

					if (!b)											{ ui.form.save(form, options); }
				}
			};

			//Define handlers.
			var successHandler = function (xmlDoc, textStatus, jqXHR)
			{
				// [MT-4155] Must be executed immediately when the call is finished.
				try													{ settings["endHandler"](jqXHR); }
				catch (ex)											{ /* no problem */ }

				function afterSuccess(closeWindow, postprocess, userAction)
				{
					if (!$(form).data("isConcept"))
					{
						$(form).data("isSaved", true);

						if (isRecordAndFirstForm($(form)))
						{
							isSaved = true;
							ui.record.setIsChanged(false);
						}

						if (fmode == MODE_FORCEADD || fmode == MODE_ADD)
						{
							$(form).find("input[type=hidden][name=mode]").val(MODE_EDIT);
							if (isRecordAndFirstForm($(form)))		{ mode = MODE_EDIT; }
						}

						$.atsc.cleanTinyMCE();

						if (isRecordAndFirstForm($(form)))
						{
							_refreshTitle(false);
						}

						try { afterSave(closeWindow); } catch(e) { /*no problem*/ }

						ui.events.call("ui.record.specificAfterSave", window, function(e)
						{
							closeWindow = e.data.closeWindow;
						}, { "closeWindow": closeWindow, "userAction": userAction });
					}
					else $(form).data("isConcept", false);

					var b = false;

					if (postprocess)
					{
						if (closeWindow) { ui.form.quit(form, settings); }
						else
						{
							b = true;

							if (lastitem != null)
							{
								try { lastitem.focus(); }
								catch (e) {}
							}
						}
					}
					else { b = true; }

					if (b && isFunction(afterCompleteCallback))	{ afterCompleteCallback(true); }
				}

				try
				{
					wt.addXmlDocToProcess(xmlDoc);

					var error										= $(xmlDoc).find("error").text();
					var msg											= $(xmlDoc).find("msg").text();
					var alertType									= $(xmlDoc).find("alerttype").text();
					var doModeChangeOnError							= $(xmlDoc).find("domodechangeonerror").text() == "true";

					if 		(msg == "_<process_state>_")			{ ui.progress.start(IOSERVLETURL + "?action=" + PROCESS_STATE + "&name=" + name + "&uniqueid=" + uniqueid + "&timestamp=" + timestamp, "", function(withErrors, progress, message){ successHandler($.parseXML(message)); }, true); }
					else if (error == "")
					{
						if (alertType == "SHOWMESSAGE")
						{
							JSO.showMessage(msg);

							afterSuccess(closeWindow, true, userAction);

							if (isFunction(afterSuccessCallback)) afterSuccessCallback(true, xmlDoc);
						}
						else
						{
							ui.dialog.alert([msg], function()
							{
								afterSuccess(closeWindow, true, userAction);

								if (isFunction(afterSuccessCallback)) afterSuccessCallback(true, xmlDoc);
							});
						}
					}
					else
					{
						if (doModeChangeOnError)
						{
							switch (fmode)
							{
								case MODE_ADD:
								case MODE_FORCEADD:
								{
									afterSuccess(false, false, userAction);
									break;
								}
							}
						}

						if (isRecordAndFirstForm($(form)))		{ ui.record.setIsChanged(true); }

						$(form).data("isConcept", false);

						ui.dialog.alert(error, function()
						{
							// Set focus to field, modal is already gone
							undoModal(true);

							if (isFunction(afterErrorCallback))	{ afterErrorCallback(true); }
							if (isFunction(afterCompleteCallback)) { afterCompleteCallback(false); }

							try									{ specificErrorHandler(); }
							catch (ex)							{ /* no problem */ }
						});

						// [MT-2862] Notify other frames that we've aborted the save request.
						ui.events.call("ui.record.specificAfterCancel", window);
					}
				}
				catch (ex)
				{
					$.atsc.log.error(ex, true);
					alert(MSG_UNKNOWN_ERROR);
					if (closeWindow)								{ ui.form.quit(form, true, undefined, undefined, userAction); }
				}
				finally												{ $(form).data("onSave", false); }
			};

			var errorHandler = function (jqXHR, textStatus)
			{
				try
				{
					// [MT-4155] Must be executed immediately when the call is finished.
					try												{ settings["endHandler"](jqXHR); }
					catch (ex)										{ /* no problem */ }

					if (!ui.isUnloading())
					{
						if (textStatus == "timeout")				{ alert("Er is een timeout opgetreden bij het verwerken van uw verzoek."); }
						else										{ alert(MSG_CONNECTION_SERVER_LOST + "\n - Status: " + textStatus); }
					}

					if (isFunction(afterErrorCallback))				{ afterErrorCallback(true); }

					if (closeWindow)								{ ui.form.quit(form, true, undefined, undefined, userAction); }
					else
					{
						if (isFunction(afterCompleteCallback))		{ afterCompleteCallback(false); }
					}
				}
				catch (ex)
				{
					$.atsc.log.error(ex, true);
					alert(MSG_UNKNOWN_ERROR);
				}
				finally												{ $(form).data("onSave", false); }
			};

			//Send the ajax request.
			try
			{
				var cont											= true;

				try													{ cont = beforeSave(closeWindow, form); }
				catch (ex)											{ /* no problem */ }

				ui.events.call("ui.record.beforeSave", window, function(e)
				{
					cont											= cont && !e.isDefaultPrevented();

					try
					{
						$(".CodeMirror").each(function()
						{
							var cm									= this.CodeMirror;
							$(cm.getTextArea()).val(cm.getValue());
							checkItem(cm.getTextArea()); // [MT-11474]
						});
					}
					catch (ex)
					{
						cont										= false;
						$.atsc.log.error(ex);
					}

					// alle change velden die wel ingevuld zijn maar niet zijn aangevinkt worden leeg gemaakt.
					// [MT-4828] try/catch ivm uiform/uirecord splitsing
					try { clearChangeFields(); } catch (ex){}

					var errorInRecord								= cont ? !ui.form.checkForm(form) : true;

					if (cont && (!isRecordAndFirstForm($(form)) || (fmode != MODE_READ && (fmode == MODE_ADD || isChanged))) && !errorInRecord)
					{
						var xml										= ui.form.createXML($(form), SAVEFORM, timestamp, version);

						if (xml != null && xml != "")
						{
							// Dit wordt gedaan om met SSL de 12030 (keepalive) error tegen te gaan.
							sendEmptyRequest();

							$.ajax({
								"url":			IOSERVLETURL,
								"type":			"post",
								"contentType":	"text/xml; charset=UTF-8",
								"data":			xml,
								"dataType":		"xml",
								"timeout":		600000, //10 minutes (only works if async is true)
								"async":		true,
								"beforeSend":	startHandler,
								"success":		successHandler,
								"error":		errorHandler
							});

							ui.events.call("ui.record.specificWhileSave", window, null, { "closeWindow": closeWindow, "userAction": userAction });
						}
					}
					else
					{
						$(form).data("onSave", false);
						$(form).data("isConcept", false);

						if (cont && !errorInRecord)
						{
							if (isFunction(afterSuccessCallback))	{ afterSuccessCallback(false); }
						}
						else
						{
							if (isFunction(afterErrorCallback))		{ afterErrorCallback(false); }
						}

						if (isFunction(afterCompleteCallback))		{ afterCompleteCallback(false); }
					}
				}, { "closeWindow": closeWindow, "userAction": userAction }, true, false);
			}
			catch (ex)
			{
				$(form).data("onSave", false);
				$.atsc.log.error(ex, true);
				alert(MSG_UNKNOWN_ERROR);
			}
		}
	}

	/**
	 * Closes the record associated with the provided form element.
	 *
	 * @param form The form to close.
	 * @param options A dictionary of options.
	 */
	ui.form.quit = function(form, options)
	{
		if (ui.initialized && !$(form).data("onQuit") && !$(form).data("isClosed"))
		{
			$(form).data("onQuit", true);

			var settings = {
				closeWindow: undefined,          // true if the current window should be closed after the record closing process, false if not.
				afterSuccessCallback: undefined, // An optional function to use as a callback if the record was successfully closed.
				afterErrorCallback: undefined,   // An optional function to use as a callback if the record was not successfully closed.
				afterCompleteCallback: undefined,// Executed regardless of success or failure at the END of the save (or save&close) action.
				userAction: false                // true if this is a user-initiated action, false if this is done through code.
			};

			if (options)											{ $.fn.extend(settings, options); }

			var closeWindow											= settings["closeWindow"];
			var afterSuccessCallback								= settings["afterSuccessCallback"];
			var afterErrorCallback									= settings["afterErrorCallback"];
			var afterCompleteCallback								= settings["afterCompleteCallback"];
			var userAction											= settings["userAction"];
			var startHandler										= settings["startHandler"];
			var name												= settings["name"] || $(form).find("input[type=hidden][name=formname]").val();
			var version												= settings["version"] || $(form).find("input[type=hidden][name=formversion]").val();

			//Define handlers.

			function successHandler(xmlDoc, textStatus, jqXHR)
			{
				try
				{
					// [MT-4155] Must be executed immediately when the call is finished.
					try												{ settings["endHandler"](jqXHR); }
					catch (ex)										{ /* no problem */ }

					wt.addXmlDocToProcess(xmlDoc);

					var error										= $(xmlDoc).find("error").text();
					var msg											= $(xmlDoc).find("msg").text();

					if (error == "")
					{
						if (msg != "")								{ alert(msg); }

						if (closeWindow)
						{
							sendalive								= false;

							if (isRecordAndFirstForm($(form)))		{ ui.record.setIsChanged(false); }

							undoModal(false);

							if (!$(form).data("isClosed"))
							{
								$(form).data("isClosed", true);

								if (isRecordAndFirstForm($(form)))
								{
									isClosed						= true;

									var newURL						= null;

									try								{ newURL = openNewURLAfterClose(xmlDoc); }
									catch (ex)						{ /* no problem */ }

									if (newURL == null && startmode == MODE_ADD && ui.record.shift_f6_savequit)
									{
										newURL						= window.location.href;
										newURL						= newURL.replace(/\?mode=\d+/, "?mode=" + MODE_ADD);
										newURL						= newURL.replace(/\&mode=\d+/, "&mode=" + MODE_ADD);
										newURL						= newURL.replace(/\?uniqueid=\d+/, "");
										newURL						= newURL.replace(/\&uniqueid=\d+/, "");
									}

									showPreviousFrame($(form).data("isSaved"), newURL);
								}
							}

							try										{ specificAfterClose(); }
							catch (ex)								{ /* no problem */ }

							ui.events.call("ui.record.specificAfterClose", window, null, { "userAction": userAction, "xmlDoc": xmlDoc });
						}

						if (isFunction(afterSuccessCallback))		{ afterSuccessCallback(true); }
						if (isFunction(afterCompleteCallback))		{ afterCompleteCallback(true); }
					}
					else
					{
						alert(error);

						$(form).data("isSaved", false);

						if (isRecordAndFirstForm($(form)))
						{
							isSaved = false;
							ui.record.setIsChanged(true);
						}

						if (!undoModal(true))						{ focusCurrentFrame(false); }

						if (isFunction(afterErrorCallback))			{ afterErrorCallback(false); }
						if (isFunction(afterCompleteCallback))		{ afterCompleteCallback(false); }

						// [MT-2862] Notify other frames that we've aborted the close request.
						ui.events.call("ui.record.specificAfterCancel", window);
					}
				}
				catch (ex)
				{
					$.atsc.log.error(ex, true);
					alert(MSG_UNKNOWN_ERROR);
				}
				finally												{ $(form).data("onQuit", false); }
			}

			function errorHandler(jqXHR, textStatus)
			{
				try
				{
					// [MT-4155] Must be executed immediately when the call is finished.
					try												{ settings["endHandler"](jqXHR); }
					catch (ex)										{ /* no problem */ }

					// [MT-7855]
					var unloading									= ui.isUnloading() || jqXHR.status == 0;

					if (!unloading)
					{
						if (textStatus == "timeout")				{ alert("Er is een timeout opgetreden bij het verwerken van uw verzoek."); }
						else										{ alert(MSG_CONNECTION_SERVER_LOST + "\n - Status: " + textStatus); }
					}
					else											{ $(form).data("onQuit", false); } // "finally" case is not executed when the frame is being unloaded

					if (isFunction(afterErrorCallback))				{ afterErrorCallback(false); }
					if (isFunction(afterCompleteCallback))			{ afterCompleteCallback(false); }
				}
				catch (ex)
				{
					$.atsc.log.error(ex, true);
					alert(MSG_UNKNOWN_ERROR);
				}
				finally												{ $(form).data("onQuit", false); }
			}

			//Send the ajax request.
			try
			{
				var cont											= true;

				try													{ cont = beforeClose(closeWindow); }
				catch (ex)											{ /* no problem */ }

				ui.events.call("ui.record.beforeClose", window, function(e)
				{
					cont											= cont && !e.isDefaultPrevented();

					if (cont)
					{
						var xml										= ui.form.createXML($(form), CLOSEFORM, undefined, version);

						if (xml != null && xml != "")
						{
							// Dit wordt gedaan om met SSL de 12030 (keepalive) error tegen te gaan.
							sendEmptyRequest();

							$.ajax({
								"url":			IOSERVLETURL,
								"type":			"post",
								"contentType":	"text/xml; charset=UTF-8",
								"data":			xml,
								"dataType":		"xml",
								"timeout":		600000, //10 minutes (only works if async is true)
								"async":		true,
								"beforeSend":	startHandler,
								"success":		successHandler,
								"error":		errorHandler
							});
						}
						//This is possible when:
						//1) there is no form element on the record
						//2) the ui.form.quit function is called when the page isn't fully loaded yet
						//Set onQuit to false to allow the user to retry closing the record.
						else											{ $(form).data("onQuit", false); }
					}
					else
					{
						$(form).data("onQuit", false);

						if (lastitem != null)
						{
							try											{ lastitem.focus(); }
							catch (ex)									{ focusCurrentFrame(false); }
						}
					}
				}, { "closeWindow": closeWindow, "userAction": userAction }, true, false);
			}
			catch (ex)
			{
				$(form).data("onQuit", false);
				$.atsc.log.error(ex, true);
				alert(MSG_UNKNOWN_ERROR);
			}
		}
	}

	$(window).on("load", function()
	{
		$("input[type=file].SUL").on("change", function()
		{
			var $this												= $(this);
			var maxsize												= parseFloat($this.attr("arg1")) * 1000;

			$this.attr("valid", "true").removeAttr("errmsg");

			if (this.files && this.files.length > 0)
			{
				var xml												= "";
				var currentsize										= 0;

				JSO.each($this[0].files, function(file, process, i)
				{
					currentsize										= currentsize + file.size;

					if (currentsize > maxsize)
					{
						xml											= "";

						$this.data("value", "");
						$this.attr("valid", "false").attr("errmsg", "Het gekozen bestand is te groot! Maximaal " + convertFileSize(maxsize) + " toegestaan!");

						process(null);
					}
					else
					{
						var reader									= new FileReader();

						reader.onloadend = function(e)
						{
							xml										= xml + reader.result;

							xml										= xml + "</file>";

							i										= i + 1

							process(true);
						};

						xml											= xml + "<file name=\"" + file.name + "\" type=\"" + file.type + "\">";

						reader.readAsDataURL(file);
					}
				}, function()
				{
					$this.data("value", xml);
				});
			}
			else													{ $this.data("value", ""); }
		})
	})
}(jQuery));

/*
 * This file opens a connection on the topmost frame that we can control to get notification messages from the server.
 *
 * It will attempt Websocket first, if that fails it will fall back to polling.
 *
 * Any messages passed through by the socket are expected to be in JSON format and are passed to
 * the "ui.notification.onMessage" event (asynchronously).
 */

(function()
{
	ui.addItemToSkip("notification");

	var wnd																					= JSO.getOurWindowTop();
	var isPopup																				= false; // [MT-11265]

	try																						{ isPopup = wnd.getParam("popup") == "true";}
	catch (ex)																				{ /* no problem */ }

	if (window == wnd && !isPopup)
	{
		var hasConnection																	= false;
		var connection																		= null;
		var sessionID;

		$(window).on("load reset:websocket", function(e)
		{
			// Only proceed if window.top is a frameset (i.e., classic or dashboard, not cms or webshop)
			if (ui.isFrameset(window))
			{
				if (e.type == "reset:websocket")
				{
					if (connection)															{  return; } // Indien er nog een connectie is hersteld die zichzelf (en anders krijg je een websocket connectie extra)
				}

				function processMessage(data)
				{
					if (data["type"] == "_websocket_id")									{ sessionID = data["id"]; }
					else
					{
						if		(data["record_name"])
						{
							JSO.each(ui.findRecordFrames(data["record_name"]), function(frame, processEvent)
							{
								try															{ frame.ui.events.call("ui.notification.onMessage", window, null, data); }
								catch (ex)													{ $.atsc.log.warn(ex); }

								processEvent(true);
							});
						}
						else if (data["table_queryid"])
						{
							JSO.each(ui.findTableFrames(data["table_queryid"]), function(frame, processEvent)
							{
								try															{ frame.ui.events.call("ui.notification.onMessage", window, null, data); }
								catch (ex)													{ $.atsc.log.warn(ex); }

								processEvent(true);
							});
						}
						else
						{
							ui.events.call("ui.notification.onMessage", window, null, data);
						}
					}
				}

				var fallbackBool															= false;
				var fallbackCounter															= 0;

				function stopFallback()
				{
					fallbackBool															= false;
					fallbackCounter															= fallbackCounter + 1;
				}

				function fallback()
				{
					return; // TH: Fallback uitgezet

					if (!fallbackBool)
					{
						fallbackBool														= true;

						var c																= ++fallbackCounter;

						function poll()
						{
							if (c == fallbackCounter)
							{
								// console.log("Falling back to poll");

								$.ajax({
									url: "/socket-emulator",
									type: "get",
									cache: false,
									dataType: "json",
									success: function(data, textStatus, jqXHR)
									{
										try
										{
											if (data && data.length > 0)
											{
												for (var i = 0; i < data.length; i = i + 1)	{ processMessage(data[i]); }
											}
										}
										catch (ex)											{ $.atsc.log.warn(ex); }

										setTimeout(poll, (1000 * 30));
									},
									error: function()
									{
										setTimeout(poll, (1000 * 30));
									}
								});
							}
						}

						poll();
					}
				}

				// How many attempts to try to make a websocket connection?
				var retries																	= 60; // try for 30 seconds
				var connecting																= false;

				function openConnection()
				{
					if (!connecting)
					{
						try
						{
							sessionID														= null;
							connecting														= true;

							connection														= new WebSocket((window.location.protocol.indexOf("https") > -1 ? "wss://" : "ws://") + window.location.host + "/socket");

							connection.onopen = function(ev)
							{
								hasConnection												= true;
							}

							connection.onerror = function(ev)
							{
								// console.log("Websocket error: " + retries);

								connecting													= false;
								hasConnection												= false;

								if (--retries >= 0)											{ setTimeout(openConnection, 500); }
								else														{ connection = null; fallback(); }
							};

							connection.onclose = function(ev)
							{
								connecting													= false;
								hasConnection												= false;

								// console.log("Websocket close: " + retries + ",  code = " + ev.code);

								if (ev.code == 1008) // 1008: "This connection was established under an authenticated HTTP session that has ended."
								{
									connection												= null;
								}
								else
								{
									if (--retries >= 0)										{ setTimeout(openConnection, 500); }
									else													{ connection = null; fallback(); }
								}
							}

							connection.onmessage = function(e)
							{
								connecting													= false;
								hasConnection												= true;

								stopFallback();

								processMessage(JSON.parse(e.data));
							}
						}
						catch (ex)															{ /* no problem */ }
					}
				}

				openConnection();
			}
		});

		ui.notification																		= {};

		ui.notification.getSessionID = function()
		{
			return hasConnection ? sessionID : null;
		}
	}
	else																					{ ui.notification = wnd.ui.notification; }
})();

function resetWebsocket()
{
	$(window).trigger("reset:websocket");
}

(function ($)
{
	try
	{
		ui.addItemToSkip("contextmenu");

		var wnd																							= JSO.getOurWindowTop();

		if (window == wnd)
		{
			ui.contextmenu																				= {};

			var MenuItem																				= function (icon, label, action)
			{
				this.icon																				= icon;
				this.label																				= label;
				this.action																				= isFunction(action) ? action.bind(this) : action;
			}

			ui.contextmenu.MenuItem																		= MenuItem;

			var contextMenus																			= null;

			function close (cm)
			{
				if (cm.subMenu)																			{ close(cm.subMenu); }

				$("i", cm).off("click");
				cm.remove();
			}

			function closeAll ()
			{
				if (contextMenus)
				{
					for (var i = 0; i < contextMenus.length; i++)										{ close(contextMenus[i]); }

					contextMenus																		= null;
				}
			}

			// Als we ergens in de applicatie klikken (m.u.v. in het contextmenu zelf) dan moeten alle context menus dicht.
			function onClick (e, processEvent, srcWindow)
			{
				try
				{
					if (contextMenus)
					{
						var $target																		= $(e.target);

						for (var i = 0; i < contextMenus.length; i++)
						{
							if ($target.is(contextMenus[i]) || $target.is("*", contextMenus[i]))		{ return; }
						}

						closeAll();
					}
				}
				catch (ex)																				{ /* no problem */ }
				finally																					{ processEvent(); }
			}

			$(window).on("load", function ()
			{
				ui.events.bind("ui.dashboard.specificOnClick",	onClick);
				ui.events.bind("ui.table.specificOnClick",		onClick);
				ui.events.bind("ui.record.specificOnClick",		onClick);
				ui.events.bind("ui.menu.specificOnClick",		onClick);
				ui.events.bind("ui.macmenu.specificOnClick",	onClick);
			})

			ui.contextmenu.closeAll = closeAll;

			ui.contextmenu.createContextMenu															= function (wnd, items, offsetLeft, offsetTop, elementOuterWidth, elementOuterHeight)
			{
				if (items.length > 0)
				{

					// Context menu aanmaken, toevoegen aan globale lijst
					var cm																				= wnd.jQuery("<div class=\"contextmenu\"></div>");

					if (!contextMenus)																	{ contextMenus = []; }

					contextMenus.push(cm);

					var action;
					var elem;

					// Snelkoppelingen toevoegen
					for (var i = 0; i < items.length; i++)
					{
						elem																			= wnd.jQuery("<i class=\"" + items[i].icon + "\"></i>").append(wnd.jQuery("<span></span>").text(items[i].label));

						elem.on("mouseover", function (e)
						{
							if (cm.subMenu)
							{
								close(cm.subMenu);

								cm.subMenu																= null;
							}
						});

						if (isFunction(items[i].action))
						{
							elem.on("click", closeAll)
								.on("click", items[i].action);
						}
						else if (items[i].action && items[i].action.length > 0)
						{
							elem.data("item", items[i]);

							elem.append("<span style=\"float: right;\"><i class=\"fas fa-caret-right\"></i></span>");

							elem.on("mouseover", function (e)
							{
								var offset																= wnd.jQuery(this).offset();

								var outerWidth															= wnd.jQuery(this).outerWidth();

								cm.subMenu																= ui.contextmenu.createContextMenu(wnd, wnd.jQuery(this).data("item").action, offset.left + outerWidth, offset.top, outerWidth, wnd.jQuery(this).outerHeight())
							});
						}

						cm.append(elem);

						wnd.ui.events.call("ui.contextmenu.specificAlterElement", wnd, null, { "element": elem });
					}

					cm.css("visibility", "hidden");

					var bodyElement																		= wnd.jQuery("body");

					bodyElement.append(cm);

					// Bepaal waar het contextmenu opent. Het menu moet altijd in een van de hoeken openen.

					var maxWidth																		= wnd.jQuery(wnd).outerWidth();
					var maxHeight																		= wnd.jQuery(wnd).outerHeight();
					var width																			= cm.outerWidth();
					var height																			= cm.outerHeight();
					var top;

					if (offsetLeft === "right")															{ cm.css("right", "0px"); }
					else
					{
						var left;

						if (offsetLeft + width > maxWidth)												{ left = offsetLeft - width - (elementOuterWidth ? elementOuterWidth : 0); }
						else																			{ left = offsetLeft; }

						// [MT-9743]
						if (left < 0)																	{ left = 0; }

						cm.css("left",	left);
					}

					if (offsetTop + height > maxHeight)													{ top = offsetTop - height - (elementOuterHeight ? elementOuterHeight : 0); }
					else																				{ top = offsetTop; }

					// [MT-9656]
					if (top < 24) 																		{ top = 24; }
					maxHeight																			= (maxHeight - top - 24); // -24 voor een "padding" van 24 px aan de onderkant

					cm.css("top",	top);
					cm.css("max-height", Math.max(maxHeight, 250) + "px");

					cm.css("visibility", "");

					return cm;
				}
			}
		}
		else																							{ ui.contextmenu = wnd.ui.contextmenu; }

		$(document).on("contextmenu", function()
		{
			try																							{ ui.contextmenu.closeAll(); }
			catch (ex)																					{ /* [MT-7987] */ }
		})
	}
	catch (ex)																							{ $.atsc.log.warn(ex); }
})(jQuery);

(function ($)
{
	ui.addItemToSkip("macmenu");
	ui.macmenu = {};

	var Shortcut																						= function (icon, label, action, primary, id, important, contextmenuonly, hoverColor)
	{
		this.id																							= id;
		this.icon																						= icon;
		this.label																						= label;
		this.important																					= important;
		this.contextmenuonly																			= contextmenuonly;

		if (MT_THEME === "atscmaterial" && !ISKLASSIEK)													{ this.hoverColor = hoverColor === undefined ? "var(--mt-color-blue-300)" : hoverColor; }

		this.action																						= function (e)
		{
			var kdEvent																					= ui.events.call("ui.macmenu.specificOnClick", window, null, { "event": e }, false);

			if (kdEvent.isDefaultPrevented())
			{
				e.preventDefault();
				e.stopPropagation();
				return false;
			}

			return action.call(this, e);
		};

		this.primary																					= primary;
		this.element																					= null;
	};

	ui.macmenu.Shortcut																					= Shortcut;

	var shortcuts;
	var additionalContextItems;
	var contextMenuCounter																				= 0;

	function generateMacMenu()
	{
		if (!shortcuts)																					{ shortcuts = []; }

		for (var i = 0; i < shortcuts.length; i = i + 1)												{ shortcuts[i].element = null; }

		$("#macmenu").empty();

		var hasNonPrimary																				= additionalContextItems && additionalContextItems.length > 0 ? true : false;
		var mainElement;

		var nOfPrimaryShortcuts																			= 0;
		for (var i = 0; i < shortcuts.length; i = i + 1)												{ if (!shortcuts[i].contextmenuonly && shortcuts[i].primary) { nOfPrimaryShortcuts = nOfPrimaryShortcuts + 1; } }

		for (var i = 0; i < shortcuts.length; i = i + 1)
		{
			if (!shortcuts[i].contextmenuonly)
			{
				if (shortcuts[i].primary)
				{
					if (shortcuts[i].important)															{ mainElement = $("<span class=\"fa-stack\"><i class=\"" + shortcuts[i].icon + " fa-stack-2x\"></i><i class=\"fas fa-exclamation fa-stack-2x important\"></i></span>"); }
					else
					{
						if (MT_THEME === "atscmaterial" && !ISKLASSIEK)
						{
							var elStyle = "style=\" --mt-custom-hover:" + shortcuts[i].hoverColor + "; \"";
							mainElement = $("<i class=\"" + shortcuts[i].icon + "\"" + elStyle + "></i>");
						}
						else
						{
							mainElement = $("<i class=\"" + shortcuts[i].icon + "\"></i>");
						}
					}

					shortcuts[i].element																= mainElement.attr("title", shortcuts[i].label).on("click", shortcuts[i].action.bind(shortcuts[i]));

					if (i == (nOfPrimaryShortcuts - 1))													{ shortcuts[i].element.addClass("last"); }

					ui.events.call("ui.macmenu.specificAlterShortcut", window, null, { "shortcut": shortcuts[i] });

					$("#macmenu").prepend(shortcuts[i].element);
				}
				else																					{ hasNonPrimary = true; }
			}
		}

		var element																						= $("<i class=\"fas fa-bars mobile" + (hasNonPrimary ? " hasnonprimary" : "") + "\"></i>").attr("title", "Meer...");

		element.on("click", function(e)
		{
			var kdEvent																					= ui.events.call("ui.macmenu.specificOnClick", window, null, { "event": e }, false);
			if (kdEvent.isDefaultPrevented())
			{
				e.preventDefault();
				e.stopPropagation();
				return false;
			}

			var offset																					= $(this).offset();

			if ($(window).width() < 1000) /* [MT-8885] */												{ ui.macmenu.createContextMenu(ui.macmenu.getShortcuts(), "right", offset.top + $(this).parent().outerHeight()); }
			else
			{
				var items																				= [];

				for (var i = 0; i < shortcuts.length; i = i + 1)
				{
					if (!shortcuts[i].primary)															{ items.push(new ui.contextmenu.MenuItem(shortcuts[i].icon, shortcuts[i].label, shortcuts[i].action)); }
				}

				if (additionalContextItems)
				{
					for (var i = 0; i < additionalContextItems.length; i = i + 1)						{ items.push(additionalContextItems[i]); }
				}

				ui.contextmenu.createContextMenu(window, items, "right", offset.top + $(this).parent().outerHeight());
			}
		});

		if (hasNonPrimary)																				{ $("#macmenu i, #macmenu span.fa-stack").removeClass("last"); }

		element.addClass("last");

		$("#macmenu").prepend(element);

		$("#macmenu > i, #macmenu > span.fa-stack").each(function ()
		{
			var title																					= $(this).attr("title");

			$(this).attr("title", "");

			$(this).append("<span>" + title + "</span>");
		}).addClass("normal");
	}

	ui.macmenu.rebuildMenu																				= generateMacMenu;

	ui.macmenu.addShortcut																				= function(shortcut, rebuildMenu, prepend)
	{
		if (typeof(rebuildMenu) == "undefined")															{ rebuildMenu = true; }

		if (shortcut)
		{
			if (!shortcuts)																				{ shortcuts = []; }

			if (prepend)																				{ shortcuts.unshift(shortcut); }
			else																						{ shortcuts.push(shortcut); }

			if (rebuildMenu)																			{ generateMacMenu(); }
		}
	};

	ui.macmenu.addShortcuts																				= function (s)
	{
		if (s && s.length > 0)
		{
			if (!shortcuts)																				{ shortcuts = []; }

			for (var i = 0; i < s.length; i = i + 1)													{ ui.macmenu.addShortcut(s[i], false); }

			generateMacMenu();
		}
	}

	ui.macmenu.removeShortcut																			= function (s, rebuildMenu)
	{
		if (typeof(rebuildMenu) == "undefined")															{ rebuildMenu = true; }

		if (s)
		{
			var index																					= -1;

			if (typeof(s) == 'string')
			{
				for (var i = 0; i < shortcuts.length; i = i + 1)
				{
					if (shortcuts[i].id == s)															{ index = i; break; }
				}
			}
			else																						{ index = $.inArray(s, shortcuts); }

			if (index > -1)
			{
				shortcuts.splice(index, 1);

				if (rebuildMenu)																		{ generateMacMenu(); }
			}
		}
	}

	ui.macmenu.getShortcuts																				= function ()	{ return shortcuts; }

	ui.macmenu.createContextMenu																		= function (shortcuts, offsetLeft, offsetTop)
	{
		var items																						= [];

		var miscMenu																					= null;

		for (var i = 0; i < shortcuts.length; i = i + 1)
		{
			if (shortcuts[i].primary)																	{ items.push(new ui.contextmenu.MenuItem(shortcuts[i].icon, shortcuts[i].label, shortcuts[i].action.bind(shortcuts[i]))); }
			else
			{
				if (!miscMenu)
				{
					miscMenu																			= new ui.contextmenu.MenuItem("fas fa-bars", "Meer...", []);
					items.push(miscMenu);
				}

				miscMenu.action.push(new ui.contextmenu.MenuItem(shortcuts[i].icon, shortcuts[i].label, shortcuts[i].action.bind(shortcuts[i])));
			}
		}

		if (additionalContextItems)
		{
			if (!miscMenu)
			{
				miscMenu																				= new ui.contextmenu.MenuItem("fas fa-bars", "Meer...", []);
				items.push(miscMenu);
			}

			for (var i = 0; i < additionalContextItems.length; i = i + 1)								{ miscMenu.action.push(additionalContextItems[i]); }
		}

		var items_more_last																				= [];
		var item;
		var item_more;

		for (var i = 0; i < items.length; i++)
		{
			item																						= items[i];

			if (item.label == "Meer...")																{ item_more = item; }
			else																						{ items_more_last.push(item); }
		}

		if (item_more)																					{ items_more_last.push(item_more); }

		return ui.contextmenu.createContextMenu(window, items_more_last, offsetLeft, offsetTop);
	}

	ui.macmenu.setAdditionalContextItems																= function (items)
	{
		// [MT-5542]
		additionalContextItems																			= null;

		try
		{
			if (items && items.length > 0)																{ additionalContextItems = items; }
		}
		catch (ex)																						{ /* no problem */ }

		generateMacMenu();
	}
})(jQuery);

(function()
{
	ui.dialog																				= {};

	// Id counter for getting unique ids per dialog, per window.
	var dialogIdCounter																		= 0;

	// Array of dialogs that exist on this Window.
	var dialogs																				= [];

	function focusButton($this)
	{
		var currentButtonId																	= $this.dialog("option", "defaultButton");

		if (currentButtonId == -1)															{ currentButtonId = $this.dialog("option", "focusButton"); }

		var lstButtons																		= $this.parent().find(" > .ui-dialog-buttonpane .ui-button");

		if (lstButtons.length > 0 && currentButtonId >= 0 && currentButtonId <= lstButtons.length)
		{
			$this.dialog("option", "defaultButton", -1);
			$this.dialog("option", "focusedButtonId", currentButtonId);

			$(lstButtons[currentButtonId]).focus();
		}
		else																				{ $this.focus(); }
	}

	function setFocus($this)
	{
		var f																				= $this.dialog("option", "setFocus");

		if (f)
		{
			try																				{ f.call($this, window); }
			catch (ex)																		{ $.atsc.log.warn(ex); }
		}
	}

	function onKeyDown(e, eui)
	{
		var $this																			= $(this).find(".ui-dialog-content");

		var keyDownFunc																		= $this.dialog("option", "onKeyDown");
		var returnval																		= null;

		try																					{ returnval = keyDownFunc.call($this, e, eui); }
		catch (ex)																			{ /* no problem */ }

		if (returnval != null)
		{
			if (returnval == false)
			{
				e.preventDefault();
				e.stopPropagation();
			}

			return returnval;
		}

		if ($this.dialog("option", "ignoreKeydown"))										{ return true; }

		// Get the currently selected item.
		var focusedElement																	= $(":focus");
		var isInputElement																	= focusedElement.length > 0 && focusedElement.is("input,select,textarea");

		if		(isInputElement && e.which != 27 && (focusedElement.is("textarea") || e.which != 13))
		{
			// Perform the default action when dealing with an input/select/textarea element, but allow
			// the Enter key to press the selected button, unless it's a textarea.

			return true;
		}
		else if ((e.ctrlKey && e.which != 65 && e.which != 67) || e.altKey || (e.shiftKey && e.which != 9)) // [MT-10647]
		{
			// Don't do anything if a modifier key is held down, except for Ctrl+A, Ctrl+C and Shift+Tab.
			e.preventDefault();
			e.stopPropagation();

			return false;
		}
		else
		{
			if		(e.which == 27)
			{
				e.preventDefault();
				e.stopPropagation();

				// This is built-in jQuery UI functionality, but it won't execute because we're overriding everything here.
				if ($this.dialog("option", "closeOnEscape"))								{ $this.dialog("close"); }

				return false;
			}
			else if (e.ctrlKey && e.which == 65) /* Ctrl+A */								{ return true; } // [MT-10647]
			else if (e.ctrlKey && e.which == 67) /* Ctrl+C */								{ return true; } // [MT-10647]
			else if (e.which == 116)														{ return true; }
			else
			{
				var lstButtons																= $this.dialog("option", "buttons");
				var lstButtonElements														= $this.parent().find(" > .ui-dialog-buttonpane .ui-button");

				e.preventDefault();
				e.stopPropagation();

				switch (e.which)
				{
					case 13: // Enter: Press the currently selected button.
					{
						var currentButtonId													= $this.data("ui.dialog.button");

						e.preventDefault();
						e.stopPropagation();

						if(lstButtonElements.length > 0 && currentButtonId >= 0 && currentButtonId < lstButtonElements.length)
						{
							var buttonElem													= $(lstButtonElements[currentButtonId]);

							setTimeout(function() { buttonElem.trigger("click"); }, 0); // setTimeout to stop keypress event being continued if click throws an alert;
						}

						break;
					}

					case 9: // Tab: Move forward through the button elements. SHIFT-Tab: Move backward through the button elements.
					{
						// Any button will do, even buttons in the dialog contents.
						var SELECTOR														= "input:enabled:not([readonly]), select:enabled:not([readonly]), textarea:enabled:not([readonly]), button:enabled:not([readonly])";
						var elements														= $.merge($this.find(SELECTOR), lstButtonElements);

						var focusedIndex													= elements.index(focusedElement);
						var newIndex;

						if (e.shiftKey)														{ newIndex = focusedIndex - 1; }
						else																{ newIndex = focusedIndex + 1; }

						if		(newIndex >= elements.length)								{ newIndex = 0; }
						else if (newIndex < 0)												{ newIndex = elements.length - 1; }

						focusedElement.blur();

						var element															= elements.eq(newIndex);

						if (element != null && element.length > 0)
						{
							var index														= newIndex;

							while (element && element.length > 0 && (!$.atsc.isItemVisible(element) || element.get(0) == focusedElement))
							{
								index														= index + (e.shiftKey ? -1 : 1);
								element														= elements.eq(index);
							}

							if (element != null && element.length > 0)						{ element[0].focus(); }
						}

						break;
					}

					case 37: // Left Arrow: Move backward through the button elements.
					case 39: // Right Arrow: Move forward through the button elements.
					{
						var currentButtonId													= $this.data("ui.dialog.button");

						if (!currentButtonId)												{ currentButtonId = 0; }

						if (lstButtonElements.length > 0)
						{
							var button														= $(lstButtonElements[currentButtonId]);
							button.blur();

							if (e.which == 37)												{ currentButtonId = currentButtonId - 1; }
							else															{ currentButtonId = currentButtonId + 1; }

							if		(currentButtonId >= lstButtonElements.length)			{ currentButtonId = 0; }
							else if (currentButtonId < 0)									{ currentButtonId = lstButtonElements.length - 1; }

							$(lstButtonElements[currentButtonId]).focus();
						}

						break;
					}

					default: // Default: Try to find a button with the right hotkey.
					{
						var button;

						for (var i = 0; i < lstButtons.length; i++)
						{
							button															= lstButtons[i];

							if (button.hotkey && button.hotkey.charCodeAt(0) == e.which)
							{
								var buttonElem												= $(lstButtonElements[i]);

								setTimeout(function() { buttonElem.trigger("click"); }, 0); // setTimeout to stop keypress event being continued if click throws an alert;

								return;
							}
						}

						break;
					}
				}
			}
		}
	}

	function getDialogIndexFromProperties(prop, element)
	{
		if (prop && prop.dialogs && prop.dialogs.length > 0)
		{
			for (var index = 0; index < prop.dialogs.length; index = index + 1)
			{
				if (prop.dialogs[index]["element"][0] == element)							{ return index; }
			}
		}

		return -1;
	}

	function openDialog(e, eui)
	{
		var $this																			= $("#" + $(this).attr("id"));
		var $container																		= $(e.target).parents(".ui-dialog");

		// Hide scrollbars when showing a dialog window. This prevents 'double scrollbars' in the event that the dialog's content is too large. Don't do this when the dialog has positionFixed.
		if (!$this.dialog("option", "positionFixed"))										{ $("body").css("overflow", "hidden"); }

		// If width=auto and minWidth is used then enforce the minimum. jQuery UI doesn't do this by default.
		// [MT-5328] Note that height=auto is supported by jQuery UI.
		try
		{
			if ($this.dialog("option", "width") == "auto")
			{
				if ($this.width() < $this.dialog("option", "minWidth"))						{ $this.dialog("option", "width", $this.dialog("option", "minWidth")); }
			}
		}
		catch (ex)																			{ $.atsc.log.warn(ex); } // if no errors after 16-08-2015, unwrap this try/catch

		// Don't escape HTML code in the titlebar.
		$container.find(".ui-dialog-titlebar").find(".ui-dialog-title").html($this.dialog("option", "title"));

		// Disable the titlebar if titlebar=false.
		if (!$this.dialog("option", "titlebar"))
		{
			$container.find(".ui-dialog-titlebar").hide();

			var buttons = $this.dialog("option", "buttons");

			if (!buttons || !buttons.length)												{ $container.addClass("no-titlebar-and-statusbar"); }
			else																			{ $container.addClass("no-titlebar"); }
		}

		// Disable scrolling if scroll=false
		if (!$this.dialog("option", "scroll"))												{ $this.css({ "overflow": "hidden" }); }

		// Position dialog fixed.
		if ($this.dialog("option", "positionFixed"))										{ $container.css({ "position": "fixed", "top": "50%", "transform": "translate(0, -50%)"}); }

		// Custom top position
		var positionTop																		= $this.dialog("option", "positionTop");

		if (positionTop)
		{
			if (JSO.toString(parseInt(positionTop)) == positionTop)							{ $container.css({ "top": positionTop + "px" }); }
			else																			{ $container.css({ "top": positionTop }); }
		}

		var positionLeft																	= $this.dialog("option", "positionLeft");

		if (positionLeft) 																	{ $container.css({ "left": positionLeft + "px" }); }
		else 																				{ $container.css({ "left": "calc(50vw - " + $this.dialog("option", "width") / 2 + "px)" }); }

		// Quick menu no longer works if there's a dialog open.
		try																					{ ui.quickmenu.disableQuickmenuItems(); }
		catch (ex)																			{ /* no problem */ }

		var targetWindow																	= $this.data("ui.dialog.window");

		// Store the dialog in the local properties object.
		var prop																			= targetWindow && targetWindow.ui && targetWindow.ui.properties ? targetWindow.ui.properties.getLocalOrGlobalProperties() : ui.properties.getGlobalProperties();
		var index																			= getDialogIndexFromProperties(prop, $this[0]);

		if (index > -1)																		{ prop.dialogs[index]["opened"] = true; }

		// Increase the amount of dialogs in use.
		JSO.increaseDialogCounter();

		$container.off("keydown");
		$container.on("keydown", onKeyDown);

		// [MT-741] Prevent the mouse wheel from being overruled by other documents. (It's being overruled in UITable.js which prevents scrolling when a dialog window is opened with F10)
		$container.off("mousewheel").on("mousewheel", function(e)
		{
			e.stopPropagation();

			return true;
		});

		$this.attr("tabIndex", -1)	// In non-IE browsers the tabIndex needs to be set in order to allow DIV elements to gain focus.
			 .css("outline", 0);	// In non-IE browsers the "outline" CSS field needs to be set to 0 to prevent a yellow box from being drawn over the element when it has focus.

		// Apply z-index
		$container.css("z-index", $this.dialog("option", "dlgZIndex"));

		// Hide the "X" in the corner if closable=false
		$container.find(".ui-dialog-titlebar-close").attr("tabIndex", "-1").toggle($this.dialog("option", "closable"));

		// Make the "X" in the corner mobile-friendly.
		$container.find(".ui-dialog-titlebar-close").off("touchstart").on("touchstart", function(e){ $(this).trigger("click"); });

		// Clicking outside the dialog window should bring the focus to the dialog.
		$($this.data("uiDialog").overlay).css("z-index", $this.dialog("option", "dlgZIndex") - 1).on("click", function(){ setFocus($this); });

		$this.removeClass("selectable");

		// If selectable=true allow the contents to be selected.
		if ($this.dialog("option", "selectable"))											{ $this.addClass("selectable"); }
		else
		{
			// If not selectable make any click redirect to the buttons.

			$container.on("click", function(){ setFocus($this); });
		}

		// Fix to allow dialogs to be dragged when they're not selectable.
		if ($this.dialog("option", "draggable"))											{ $container.find(".ui-dialog-titlebar").addClass("selectable"); }

		// Callback for opening a dialog.
		if ($this.data("ui.dialog.open"))
		{
			try																				{ $this.data("ui.dialog.open").call($this, e, eui); }
			catch (ex)																		{ $.atsc.log.warn(ex); }
		}

		if ($this.parents(".ui-dialog").length)												{ setFocus($this); }

		if ($this.dialog("option", "resizable") || $this.dialog("option", "draggable")) // [MT-8220]
		{
			var storageKey																	= "dialog.settings:" + $this.dialog("option", "title");

			$container.on("dialogdragstop dialogresizestop", function()
			{
				var settings																= { "width": $this.dialog("option", "width"), "height": $this.dialog("option", "height"), "positionMy" : $this.dialog("option", "position").my, "positionAt" : $this.dialog("option", "position").at };

				localStorage[storageKey]													= JSON.stringify(settings);
			});

			if (localStorage[storageKey])
			{
				var originalsettings														= { "width": $this.dialog("option", "width"), "height": $this.dialog("option", "height"), "positionMy" : $this.dialog("option", "position").my, "positionAt" : $this.dialog("option", "position").at };
				var settings																= JSON.parse(localStorage[storageKey]);

				$this.dialog("option", "width", settings.width);
				$this.dialog("option", "height", settings.height);
				$this.dialog("option", "position", { my: settings.positionMy, at: settings.positionAt, of: window });

				// Test offscreen
				var rect																	= $container.get(0).getBoundingClientRect();
				var offscreen																= (rect.x + rect.width) < 0 || (rect.y + rect.height) < 0 || (rect.x > window.innerWidth || rect.y > window.innerHeight);

				if (offscreen)
				{
					$this.dialog("option", "width", originalsettings.width);
					$this.dialog("option", "height", originalsettings.height);
					$this.dialog("option", "position", { my: originalsettings.positionMy, at: originalsettings.positionAt, of: window });
				}
			}
		}
	}

	function closeDialog(e, eui)
	{
		var $this																			= $("#" + $(this).attr("id"));
		var targetWindow																	= $this.data("ui.dialog.window");

		// Mark the dialog as closed in the local properties object.
		var prop																			= targetWindow.ui.properties.getLocalOrGlobalProperties();
		var index																			= getDialogIndexFromProperties(prop, $this[0]);

		if (index > -1)																		{ prop.dialogs[index]["opened"] = false; }

		// Decrease the amount of dialogs in use.
		JSO.decreaseDialogCounter();

//			if (typeof(specificDialogClose) == "function")									{ specificDialogClose(e, eui); }

		// Callback for closing a dialog.
		if ($this.data("ui.dialog.close"))
		{
			try																				{ $this.data("ui.dialog.close").call($this, e, eui); }
			catch (ex)																		{ $.atsc.log.warn(ex); }
		}

		var afterClose																		= $this.data("ui.dialog.afterClose");

		// If destroyOnClose=true remove the dialog element.
		if ($this.dialog("option", "destroyOnClose"))
		{
			// Remove the dialog from the local properties object.
			if (index > -1)																	{ prop.dialogs.splice(index, 1); }

			index																			= $.inArray($this[0], dialogs);

			if (index > -1)																	{ dialogs.splice(index, 1); }

			// Possible memory leak prevention: Set the window to null to prevent jQuery from retaining a reference.
			$this.data("ui.dialog.window", null);

			if (iframeDialogLoaded && iframeDialogLoaded[$this[0]])							{ delete iframeDialogLoaded[$this[0]]; }

			var parentElem																	= $this.parent()

			$this.remove();

			parentElem.remove();
		}

		// Enable the quick menu if there are no other dialogs open.
		try
		{
			if (!JSO.dialogExists())														{ ui.quickmenu.enableQuickmenuItems(); }
		}
		catch (ex)																			{ /* no problem */ }

		// If we didn't use e.preventDefault in the 'close' callback -> reset the focus.
		if (!e.isDefaultPrevented())
		{
			if (targetWindow)																{ targetWindow.focusCurrentFrame(true); }
			else																			{ focusCurrentFrame(true); }
		}

		// Callback for closing a dialog (after it has been destroyed).
		if (afterClose)
		{
			try																				{ afterClose.call($this, e, eui); }
			catch (ex)																		{ $.atsc.log.warn(ex); }
		}

		// If no dialog exists then show scrollbars.
		if (!JSO.dialogExists())															{ $("body").css("overflow", ""); }
	}

	function generateHotkeys(settings, dialogGenElem, dialogElem)
	{
		var lstHotkeys																		= new Array();

		dialogGenElem.find(".ui-dialog-buttonpane .ui-button").each(function(index)
		{
			// If there's already a hotkey specified: convert to uppercase.
			if (settings["buttons"][index].hotkey)											{ settings["buttons"][index].hotkey = JSO.toString(settings["buttons"][index].hotkey).toUpperCase(); }

			var $this																		= $(this);
			var hotkey																		= settings["buttons"][index].hotkey;
			var text																		= JSO.toString($this.text()).toUpperCase();

			settings["buttons"][index].elem													= $this;

			if (hotkey === undefined)
			{
				// Figure out which key to use for the hotkey by looping through the label.

				var pos																		= 0;
				var c;

				while (pos != null && pos < text.length)
				{
					c																		= text.substring(pos, pos + 1);

					if (c != " ")
					{
						// Is the key already in use?
						var i																= null;

						for (i = 0; i < lstHotkeys.length; i++)
						{
							if (lstHotkeys[i] == c)											{ break; }
						}

						if (i === null || i >= lstHotkeys.length)
						{
							hotkey															= c;

							break;
						}
					}

					pos																		= pos + 1;
				}
			}

			var hotkeyPos;

			if (hotkey)
			{
				lstHotkeys.push(hotkey);

				hotkeyPos																	= text.indexOf(hotkey);

				if (hotkeyPos < 0)
				{
					hotkey																	= null;
					hotkeyPos																= null;
				}

			}

			settings["buttons"][index].hotkey												= hotkey;
			settings["buttons"][index].hotkeyPos											= hotkeyPos;

			// Each button gets its own ID.
			$this.attr("id", "dialog_" + dialogElem.data("ui.dialog.id") + "_button_" + index);

			// [MT-1102] To fix the border clipping, is fixed in jquery-ui-atsc.css for all buttons
			// $this.css("position", "static");

			// When the focus is on this button, set the focusButton option accordingly.
			$this.on("focus", function(){ dialogElem.data("ui.dialog.button", index); });

			$this.off("click").on("click", function(e, eui)
			{
				if (settings["buttons"][index].click)
				{
					try																		{ settings["buttons"][index].click.call(dialogElem, e, eui, window); }
					catch (ex)																{ $.atsc.log.warn(ex); }
				}
			})

			// Underline the character used for the hotkey.

			if (hotkey)
			{
				text																		= $(this).text();

				$this.find(" .ui-button-text").html(text.substring(0, hotkeyPos) + "<u>" + text.substring(hotkeyPos, hotkeyPos + 1) + "</u>" + text.substring(hotkeyPos + 1));
			}
		});
	}

	ui.dialog.createDialog = function(settings){ ui.dialog._createDialog(settings, window); }

	ui.dialog._createDialog = function(settings, targetWindow)
	{
		/*
		 * Prevent dialogs from being created if the current window is still loading.
		 */

		// We can't create a dialog if we're on a frameset, so defer to the current active frame or "wmain".
		if (ui.isFrameset(window))
		{
			var frame																		= getFrame("wmain", true);

			if (!frame)																		{ frame = getFrame(getCurrentFrameName(), true); }

			if (!ui.isValidWindow(frame))													{ frame = getFrame(getPreviousFrameName(), true); }

			if (ui.isValidWindow(frame))
			{
				if (frame != window)														{ return frame.ui.dialog.createDialog(settings); }
				else																		{ $.atsc.log.warn("ERROR: Unable to create dialog because it has been opened on a frameset."); }
			}
		}
		else if (!(ui.unloading || (document && document.visibilityState == "unloaded")) && (wt.focusCurrentFrameDone || document == wt.document || (ui.isTable(window) && ui.table.lookup_mode)))
		{
			// Default settings. These can be overridden by the user.
			settings = $.fn.extend({
				// jQuery UI dialog options
				minWidth:		120,
				minHeight:		120,
				setFocus:		function(){ focusButton(this); },

				modal:			false,
				closeOnEscape:	true,			// if true the Escape key will close the dialog
				draggable:		true,			// if true the dialog can be dragged around
				resizable:		true,			// if true the dialog can be resized

				destroyOnClose:	true,			// if true the dialog element is destroyed after it is closed, otherwise it's kept around (and can be reopened later)

				title:			"",				// title in the titlebar
				buttons:						// define buttons to be pressed here. send an empty array to remove the footer
				[
					{
						text:	"OK",
						click:	function(){ this.dialog("close"); }
					}
				],

				// ATSC-specific options

				html:			"",				// html of the contents of the dialog, ignored when using an existing element. can be a string or a (jQuery) element
				defaultButton:	0,				// array index of the default selected button, use -1 to disable this feature (allows Enter to be overruled)
				element:		null,			// optional existing element to convert to a dialog, if null a new dialog is created from scratch
				titlebar:		true,			// true to show a titlebar
				scroll:			true,			// allow scrolling in the content window?
				dlgZIndex:		400000,			// z-index of the dialog (for appearing over other dialogs, such as TinyMCE dialogs)
				onKeyDown:		null,			// event handler for keydown events, has no effect if ignoreKeyDown=true
				ignoreKeyDown:	false,			// true to remove the keydown event altogether
				selectable:		true,			// if true, contents can be selected with the mouse
				closable:		true,			// if false the "x" in the titlebar is removed
				positionTop:	null,			// the top position of the dialog, null to center vertically
				positionLeft:	null,			// the left position of the dialog, null to center horizontally
				positionFixed:	false,			// Use fixed position (center) for the dialog
				_preload:		false,			// true to preload the frame instead of opening a dialog

				// Callbacks

				create:			null,			// callback for when the dialog has been created
				open:			null,			// callback for when the dialog has opened
				close:			null,			// callback for when the dialog is closing
				beforeClose:	null,			// callback for when the dialog is closing (but before it has called the close function)
				afterClose:		null			// callback for when the dialog has been closed (ATSC-specific)
			}, settings ? settings : {});

			var preload																		= settings["_preload"];

			// If width=auto or height=auto and maxWidth/maxHeight is used then enforce the maximum based on the height of the body element.
			try
			{
				if (settings["width"] == "auto")
				{
					if ($("body").width() > settings["maxWidth"])							{ settings["width"] = settings["maxWidth"]; }
				}

				if (settings["height"] == "auto")
				{
					if ($("body").height() > settings["maxHeight"])							{ settings["height"] = settings["maxHeight"]; }
				}
			}
			catch (ex)																		{ $.atsc.log.warn(ex); } // if no errors after 16-08-2015, unwrap this try/catch

			// Check if the dialog will fit on this window, move to a parent window if it doesn't.
			var moveToParent																= false;

			var maxWidth																	= $(window).width() - 20;
			var maxHeight																	= $(window).height() - 20;
			var width, height, parentWindow;

			if		(settings["width"] === undefined || settings["width"] === null)			{ width = 480; } // [MT-6302]
			else if (JSO.toString(settings["width"]).indexOf("px") > -1)					{ width = parseFloat(settings["width"].left("px")); }
			else if (JSO.toString(settings["width"]).indexOf("%") > -1)						{ width = null; }
			else																			{ width = parseFloat(settings["width"]); }

			if		(settings["height"] === undefined || settings["height"] === null)		{ height = 240; } // [MT-6302]
			else if (JSO.toString(settings["height"]).indexOf("px") > -1)					{ height = parseFloat(settings["height"].left("px")); }
			else if (JSO.toString(settings["height"]).indexOf("%") > -1)					{ height = null; }
			else																			{ height = parseFloat(settings["height"]); }

			moveToParent																	= (width && width > 0 && width > maxWidth) || (height && height > 0 && height > maxHeight);

			if (moveToParent)
			{
				try
				{
					if (JSO.isFrameAccessible(window.parent))
					{
						parentWindow														= window.parent == window ? null : window.parent;

						while (ui.isFrameset(parentWindow))									{ parentWindow = JSO.isFrameAccessible(parentWindow.parent) && parentWindow.parent != parentWindow ? parentWindow.parent : null; }

						if (!parentWindow || !parentWindow.ui || !parentWindow.ui.dialog)	{ throw ""; }
					}
					else																	{ throw ""; }
				}
				catch (ex)
				{
					moveToParent															= false;

					if (width && width > 0 && width > maxWidth)								{ settings["width"] = maxWidth; }
					if (height && height > 0 && height > maxHeight)							{ settings["height"] = maxHeight; }
				}
			}

			if (moveToParent)
			{
				preventFocusStealerEnd();
				parentWindow.ui.dialog._createDialog(settings, targetWindow);
			}
			else
			{
				delete settings["_preload"];

				settings["autoOpen"]														= false;

				var dialogId																= ++dialogIdCounter;

				// Determine the element containing the contents of the dialog.
				var element																	= settings["element"];

				if (!element)																{ element = $("<div id=\"dialog_" + dialogId + "\"></div>").insertAfter("body"); }

				element.data("ui.dialog.id", dialogId);

				element.data("ui.dialog.button", settings["defaultButton"]);

				// Override whatever we need to from jQuery UI dialog.

				element.data("ui.dialog.open", settings["open"]);
				element.data("ui.dialog.close", settings["close"]);
				element.data("ui.dialog.afterClose", settings["afterClose"]);
				element.data("ui.dialog.window", targetWindow);

				settings["open"]															= openDialog;
				settings["close"]															= closeDialog;

				element.css("overflow", "auto");
				element.dialog(settings);

				if (!dialogs)																{ dialogs = []; }

				dialogs.push(element[0]);

				// Store the dialog in the local properties object.
				var prop																	= targetWindow.ui.properties.getLocalOrGlobalProperties();

				if (prop)
				{
					if (!prop.dialogs)														{ prop.dialogs = prop.window.JSO.createArray(); }

					prop.dialogs.push({ "element": element, "window": window, "opened": false });
				}

				// Get the dialog container element (generated by jQuery UI by the .dialog call)
				var dialogGenElem															= element.parent();

				dialogGenElem.attr("id", "dialog_" + dialogId + "_parent");

				generateHotkeys(settings, dialogGenElem, element);

				if (settings["create"])
				{
					try																		{ settings["create"].call(element, window); }
					catch (ex)																{ $.atsc.log.warn(ex); }
				}

				// Fill the contents of the dialog only after it has been initialized. Otherwise, contents may be loaded twice (due to movement within the DOM).
				if (settings["html"])
				{
					if (typeof(settings["html"]) == "string")								{ element.html(settings["html"]); }
					else																	{ element.append(settings["html"]); }
				}

				// Open the dialog immediately, unless we're preloading.
				if (!preload)																{ element.dialog("open"); }
			}
		}
		else																				{ setTimeout(ui.dialog.createDialog.bind(this, settings), 100); }
	}

	ui.dialog.focus = function(dlg)
	{
		setFocus(dlg);
	}

	ui.dialog.createModalDialog = function(settings)
	{
		ui.dialog.createDialog($.fn.extend({
			modal:			true,
			closeOnEscape:	false,
			draggable:		false,
			resizable:		false,
			closable:		false,
			selectable:		false
		}, settings ? settings : {}));
	}

	var alertMessages																		= [];
	var alertCallbacks																		= [];
	var alertOptions																		= [];
	var hasAlert																			= false;

	/*
	 * Usage:
	 *     ui.dialog.alert(message)
	 *     ui.dialog.alert(message, callback)
	 *     ui.dialog.alert(message, options)
	 *     ui.dialog.alert(message, callback, options)
	 */
	ui.dialog.alert = function(arg1, arg2, arg3)//options2 = options, options = callback function OR options2 = undefined, options = options
	{
		if (arg1 !== undefined || arg2 !== undefined || arg3 !== undefined)
		{
			var message																		= arg1;
			var settings;
			var callback;

			if (arg2)
			{
				if (JSO.isFunction(arg2))
				{
					callback																= arg2;
					settings																= arg3;
				}
				else
				{
					callback																= arg2["close"];
					settings																= arg2;
				}
			}
			else if (arg3)
			{
				if (JSO.isFunction(arg3))
				{
					callback																= arg3;
					settings																= arg2;
				}
				else
				{
					callback																= arg3["close"];
					settings																= arg3;
				}
			}

			settings = $.fn.extend({
				title:				getApplicationName(),
				minHeight:			80,
				closable:			true,
				closeOnEscape:		true,
				destroyOnClose:		true,
				selectable:			true,

				onKeyDown: function(e)
				{
					// CTRL-A
					if (e.ctrlKey && e.which == 65)
					{
						e.preventDefault();

						this[0].focus();

						// Workaround for browsers that don't support selecting ranges if the area is not contentEditable (IE)
						this.attr("contenteditable", true);

						var doc																= $.atsc.window.getDocumentFromJQuery(this);
						var wnd																= $.atsc.window.getWindowFromDocument(doc);

						var selection														= wnd.getSelection();
						var range															= doc.createRange();

						range.selectNodeContents(this[0]);
						selection.removeAllRanges();
						selection.addRange(range);

						// Workaround for browsers that don't support selecting ranges if the area is not contentEditable (IE)
						this.attr("contenteditable", false);

						return false;
					}

					return null; // [MT-11530]
				}
			}, settings ? settings : {});

			if (message)
			{
				// Optionally allow an array of messages to be sent. We'll loop through each one, and if we find a non-empty one we show an alert.
				// We go through the entire list of messages one by one. This allows "chaining" of messages followed by a callback.

				if (typeof(message) == "object")
				{
					for (var i = 0; i < message.length; i++)
					{
						alertMessages.push(message[i]);
						alertCallbacks.push((i == message.length - 1) ? callback : null);
						alertOptions.push(settings);
					}
				}
				else
				{
					alertMessages.push(message);
					alertCallbacks.push(callback);
					alertOptions.push(settings);
				}
			}
		}

		if (!hasAlert)
		{
			hasAlert																		= true;

			if (alertMessages.length > 0)
			{
				message																		= alertMessages[0];
				callback																	= alertCallbacks[0];
				settings																	= alertOptions[0];

				var funcOpen																= settings["open"];

				settings["open"] = function(e, ui)
				{
					// Disable all buttons on the popup and re-enable them after 10ms.
					// This ensures that the popup becomes visible on the clients' screen as the client
					// cannot press the Enter key while the popup has no enabled buttons.

					var $this																= this;

					$this.parent().find(".ui-dialog-buttonset button").prop("disabled", true);

					// [MT-4138]
					setTimeout(function()
					{
						$this.parent().find(".ui-dialog-buttonset button").prop("disabled", false);
						$this.dialog("option", "defaultButton", $this.dialog("option", "focusedButtonId"));
						focusButton($this);
					}, 10);

					$this.css("height", "auto");

					if (!settings["height"])
					{
						if ($this.height() > 400)											{ $this.css("height", "400px"); }
					}

					if (funcOpen)															{ funcOpen.call(this, e, ui); }
				}

				settings["close"]															= null;

				settings["afterClose"] = function()
				{
					if (JSO.isFunction(callback))
					{
						try																	{ callback(); }
						catch (ex)															{ $.atsc.log.warn(ex); }
					}

					hasAlert																= false;

					ui.dialog.alert();
				}

				alertMessages.splice(0, 1);
				alertCallbacks.splice(0, 1);
				alertOptions.splice(0, 1);

				if (message)
				{
					try
					{
						var messageLength													= getStringLength(message);
						var newlines														= countChars(message, "\n");

						message																= message.replace(/&#10;/g, "\n").replace(/\n/g, "<br />");

						settings["html"]													= "<p>" + message + "</p>";

						if (!settings["minWidth"])											{ settings["minWidth"] = Math.max(250, Math.min(messageLength * 6, 800)); }
						if (!settings["height"])											{ settings["height"] = 450; }

						// If the user has specified any options, let them override the settings.

						ui.dialog.createModalDialog(settings);
					}
					catch (ex)
					{
						$.atsc.log.warn(ex);

						settings["afterClose"]();
					}
				}
				else																		{ settings["afterClose"](); }
			}
			else
			{
				hasAlert																	= false;

				alertMessages																= [];
				alertCallbacks																= [];
				alertOptions																= [];
			}
		}
	}

	ui.dialog.confirm = function(arg1, arg2, arg3)
	{
		if (arg1 !== undefined || arg2 !== undefined || arg3 !== undefined)
		{
			var message																		= arg1;
			var settings;
			var callback;

			if (arg2)
			{
				if (JSO.isFunction(arg2))
				{
					callback																= arg2;
					settings																= arg3;
				}
				else
				{
					callback																= arg2["close"];
					settings																= arg2;
				}
			}

			if (message)
			{
				settings = $.fn.extend({
					title:		getApplicationName(),
					html:		"<p>" + message + "</p>",

					minWidth:	200,
					minHeight:	80,

					choices:	[ "Ja", "Nee" ],
					buttons:	[],

					open: function(e, ui)
					{
						// Disable all buttons on the popup and re-enable them after 10ms.
						// This ensures that the popup becomes visible on the clients' screen as the client
						// cannot press the Enter key while the popup has no enabled buttons.

						var $this															= this;

						$this.parent().find(".ui-dialog-buttonset button").prop("disabled", true)

						// [MT-4138]
						setTimeout(function()
						{
							$this.parent().find(".ui-dialog-buttonset button").prop("disabled", false);
							$this.dialog("option", "defaultButton", $this.dialog("option", "focusedButtonId"));
							focusButton($this);
						}, 10);

						$this.css("height", "auto");

						if ($this.height() > 400)											{ $this.css("height", "400px"); }
					}
				}, settings ? settings : {});

				// Generate buttons for the choices.

				if (settings["choices"])
				{
					var buttons																= settings["buttons"];

					for (var i = 0; i < settings["choices"].length; i = i + 1)
					{
						buttons.push({
							text: settings["choices"][i],
							click: function(e)
							{
								// [MT-5211]
								var target													= e.target;

								if (target.className.indexOf("ui-button-text") == -1)		{ target = target.parentNode; }

								this.data("ui.dialog.confirm.choice", $("<span>" + target.innerHTML + "</span>").text());

								this.dialog("close");
							}
						});
					}

					settings["buttons"]														= buttons;
				}

				var closeFunc																= settings["close"];

				settings["close"] = function(e, eui)
				{
					try																		{ callback.call(this, this.data("ui.dialog.confirm.choice"), e, eui); }
					catch (ex)																{ /* [MT-10853] */ }

					if (closeFunc)
					{
						try																	{ closeFunc.call(this, e, eui); }
						catch (ex)															{ /* [MT-10853] */ }
					}
				}

				ui.dialog.createModalDialog(settings);
			}
		}
	}

	ui.dialog.createMultipleChoiceDialog = function(arg1, arg2, arg3)
	{
		if (arg1 !== undefined || arg2 !== undefined || arg3 !== undefined)
		{
			var message																		= arg1;
			var settings;
			var callback;

			if (arg2)
			{
				if (JSO.isFunction(arg2))
				{
					callback																= arg2;
					settings																= arg3;
				}
				else
				{
					callback																= arg2["close"];
					settings																= arg2;
				}
			}

			if (message)
			{
				settings = $.fn.extend({
					title:		getApplicationName(),
					html:		"<p>" + message + "</p>",

					minWidth:	200,
					minHeight:	80,

					choices:	[ "Keuze 1", "Keuze 2" ],
					buttons:	[],

					open: function(e, ui)
					{
						// Disable all buttons on the popup and re-enable them after 10ms.
						// This ensures that the popup becomes visible on the clients' screen as the client
						// cannot press the Enter key while the popup has no enabled buttons.

						var $this															= this;

						$this.parent().find(".ui-dialog-buttonset button").prop("disabled", true);

						// [MT-4138]
						setTimeout(function()
						{
							$this.parent().find(".ui-dialog-buttonset button").prop("disabled", false);
							$this.dialog("option", "defaultButton", $this.dialog("option", "focusedButtonId"));
							focusButton($this);
						}, 10);

						$this.css("height", "auto");

						if ($this.height() > 400)											{ $this.css("height", "400px"); }
					}
				}, settings ? settings : {});

				// Generate buttons for the choices.

				if (settings["choices"])
				{
					var html = "";

					for (var i = 0; i < settings["choices"].length; i = i + 1)
					{
						html																= html + '<div>' + $("<div></div>").append($("<label></label>").append($("<input type=\"radio\" name=\"choice\"" + (i == 0 ? " checked=\"checked\"" : "") + " />").attr("value", settings["choices"][i])).append($("<span></span>").text(settings["choices"][i]))).html() + '</div>';
					}

					settings["html"]														= settings["html"] + "<div style=\"margin-bottom: 10px;\">" + html + "</div>";

					settings["buttons"] = [
						{
							text: "OK",
							click: function(e, eui)
							{
								this.data("ui.dialog.multipleChoice.choice", this.find("input[type=radio]:checked").val());

								this.dialog("close");
							}
						},
						{
							text: "Annuleren",
							click: function(e, eui)
							{
								this.data("ui.dialog.multipleChoice.choice", "");

								this.dialog("close");
							}
						}
					]
				}

				var closeFunc																= settings["close"];

				settings["close"] = function(e, eui)
				{
					try																		{ callback.call(this, this.data("ui.dialog.multipleChoice.choice"), e, eui); }
					catch (ex)																{ $.atsc.log.warn(ex); }

					if (closeFunc)
					{
						try																	{ closeFunc.call(this, e, eui); }
						catch (ex)															{ $.atsc.log.warn(ex); }
					}
				}

				ui.dialog.createModalDialog(settings);
			}
		}
	}

	var iframeId																			= 0;

	function getFrameID()
	{
		// [MT-2669] IFrame dialog windows may share the same name on different windows in IE.
		// In Chrome this problem is averted because the counter used in the code below is considered a unique counter.

		if (IE)
		{
			var fr																			= "";
			var frame																		= window;

			while (frame != null)
			{
				try																			{ fr = fr + (frame.name || "frame") + "_"; }
				catch (ex)																	{ fr = fr + "error_"; }

				try
				{
					if (frame.parent != frame)												{ frame = frame.parent; }
					else																	{ frame = null; }
				}
				catch (ex)																	{ frame = null; }
			}

			return fr;
		}
		else																				{ return "iframedlg_"; }
	}

	var iframeDialogLoaded;

	function onAfterFrameLoad(pelem, srcWindow)
	{
		if (!iframeDialogLoaded)															{ iframeDialogLoaded = {}; }

		if (!iframeDialogLoaded[pelem[0].id])
		{
			iframeDialogLoaded[pelem[0].id]													= true;

			preventFocusStealerEnd();
			srcWindow.focusCurrentFrame(true);

			if (ui.isTable(srcWindow))														{ srcWindow.ui.table.focusTable(); }

			try
			{
				var afterFrameLoad															= pelem.dialog("option", "afterFrameLoad");

				if (afterFrameLoad && isFunction(afterFrameLoad))							{ afterFrameLoad(srcWindow, pelem); }

				pelem.dialog("option", "srcWindow", srcWindow);
			}
			catch (ex)																		{ $.atsc.log.warn(ex); }
		}
	}

	ui.dialog._createIFrameDialogOnLoad = function(elem)
	{
		try
		{
			var frame																		= $.atsc.window.getWindowFromIFrame(elem);

			if (ui.isFrameset(frame))														{ frame = frame.getFrame(frame.getCurrentFrameName()); }

			// [MT-1591] + [MT-2033] It's possible that the frame or document does not exist if the frame is unloading.
			if (frame && frame.document)
			{
				var parent																	= $(elem).parent();
				var errormsg																= $("#errormsg", frame.document).html();

				if (errormsg != null)
				{
					preventFocusStealerEnd();

					JSO.showMessage(errormsg.trim());

					parent.dialog("close");
				}
				else
				{
					if (parent.dialog("option", "closeOnEscape")) // [MT-8208]
					{
						$(frame.document).on("keydown", function(e)
						{
							if (e.which == 27)												{ parent.dialog("close"); }
						});
					}
				}
			}
		}
		catch (ex)																			{ /* [MT-9097] */ }
	}

	var persistentDialogs																	= null;

	function preventFocusStealerStart()
	{
		var dbf																				= ui.window.getDashboardFrame();
		var prop																			= ui.properties.getGlobalProperties();

		if (dbf && prop && prop.uiDialogFocusStealer === null)
		{
			dbf.focus();

			prop.uiDialogFocusStealer = dbf.setInterval(function()
			{
				dbf.focus();
			}, 0);
		}
	}

	function preventFocusStealerEnd()
	{
		var dbf																				= ui.window.getDashboardFrame();
		var prop																			= ui.properties.getGlobalProperties();

		if (dbf && prop)
		{
			if (prop.uiDialogFocusStealer)													{ dbf.clearInterval(prop.uiDialogFocusStealer); }

			prop.uiDialogFocusStealer														= null;
		}
	}

	ui.dialog.createIFrameDialog = function(url, settings)
	{
		var id																				= ++iframeId;
		var frameID																			= getFrameID() + id;

		// [MT-4396] Chrome needs a little encouragement to properly render iframes within dialogs.
		var cssfix																			= CHROME ? " position: absolute; top: 0px;" : "";

		settings = $.fn.extend({
			width:			990,
			height:			565,
			closable:		false,
			selectable:		true,
			closeOnEscape:	false,
			destroyOnClose: true,
			titlebar:		false,
			scroll:			false,
			buttons:		[],
			setFocus:		function()
			{
				try { $.atsc.window.getWindowFromIFrame(frameID).focusCurrentFrame(true); } catch (ex) { /* [MT-10705] */ }
				try { preventFocusStealerEnd(); } catch (ex) { /* [MT-10705] */ }
				try { $.atsc.window.getWindowFromIFrame(frameID).focusCurrentFrame(true); } catch (ex) { /* [MT-10705] */ }
			},
			close:			function()
			{
				try { preventFocusStealerEnd(); } catch (ex) { /* [MT-10705] */ }

				var frame																	= this.data("ui.dialog.window");

				if (frame)
				{
					if (ui.isDashboard(frame))												{ frame.ui.dashboard.focusCurrentTab(); }
					else
					{
						frame.focusCurrentFrame(true);

						// Tables are a bit shy!
						if (ui.isTable(frame))												{ frame.ui.table.focusTable(); }
					}
				}
			},

			// createIFrameDialog-specific
			persistent: false, // true to allow the same URL to be re-used

			// callbacks
			beforeFrameClose:	null,
			afterFrameLoad:		null
		}, settings ? settings : {});

		if (!settings["_preload"])															{ preventFocusStealerStart(); }

		if (!settings["noFrameset"])														{ url = "/jsp/atsc/UIWrapperFrameset.jsp?startPage=" + encodeURIComponent(url) + "&frames=1" + (settings["frames"] ? settings["frames"] : 4); }

		// Don't destroy the dialog on close in persistent mode.
		if (settings["persistent"])															{ settings["destroyOnClose"] = false; }

		if (settings["persistent"] && persistentDialogs && persistentDialogs[url])
		{
			if (!settings["_preload"])
			{
				// Overwrite the settings.
				for (var key in settings)
				{
					if (key != "open" && key != "close" && ((key != "width" && key != "height") || settings[key] != "auto"))
					{
						persistentDialogs[url].dialog("option", key, settings[key]);
					}
				}

				// Overwrite the 'open' callback.
				persistentDialogs[url].data("ui.dialog.persistentOpen", settings["open"]);

				// Overwrite the 'close' callback.
				persistentDialogs[url].data("ui.dialog.persistentClose", settings["close"]);

				if (iframeDialogLoaded)														{ iframeDialogLoaded[persistentDialogs[url][0].id] = false; }

				persistentDialogs[url].dialog("open");

				// If the frame is still loading while the popup is recalled we need to wait until the frame has finished
				// loading before passing it on to onAfterFrameLoad.
				// This only applies for preloaded persistent iframes. To reproduce: quickly open a lookup before it has a
				// chance to fully (pre)load.

				var loopCheck																= 100;

				function waitForFrame()
				{
					if (--loopCheck > 0)
					{
						var element															= persistentDialogs[url].find("iframe");
						var frame															= null;

						if (element.length > 0)												{ frame = element[0].contentWindow; }

						if (frame && ui.isWrapperFrameset(frame))							{ frame = frame.getFrame("w0"); }

						// Call the onAfterFrameLoad routine with the frame that was originally opened.
						if (frame && frame.ui && frame.ui.initialized)						{ onAfterFrameLoad(persistentDialogs[url], frame); }
						else																{ setTimeout(waitForFrame, 10); }
					}
					else																	{ /* no problem */ }
				}

				waitForFrame();
			}
		}
		else
		{
			// The content area has some padding, we need to remove this if we have a titlebar or there will be a visual glitch.
			var originalFunction1															= settings["open"];

			settings["open"] = function(e, eui, srcWindow)
			{
				if (this.dialog("option", "titlebar"))
				{
					this.css("padding", "0px");
					this.css("overflow", "hidden");
				}

				if (originalFunction1)
				{
					try																		{ originalFunction1.call(this, e, eui, srcWindow); }
					catch (ex)																{ /* no problem */ }
				}
			}

			settings["html"]																= "<iframe id=\"" + frameID + "\" name=\"" + frameID + "\" src=\"" + url + "\" style=\"width: 100%; height: 100%;" + cssfix + "\" frameborder=\"0\" marginwidth=\"0\" marginheight=\"0\" onload=\"ui.dialog._createIFrameDialogOnLoad(this);\"></iframe>";

			if (settings["persistent"])
			{
				// Store the dialog on open.
				settings["create"] = function(e, eui, srcWindow)
				{
					if (!persistentDialogs)													{ persistentDialogs = {}; }

					persistentDialogs[url]													= this;
				}

				// Call the defined open function once we've done this.
				// The open function may be overruled in subsequent calls, so we have an if/else here.

				var originalFunction2														= settings["open"];

				settings["open"] = function(e, eui, srcWindow)
				{
					var currentFunction														= this.data("ui.dialog.persistentOpen");

					if		(currentFunction)
					{
						try																	{ currentFunction.call(this, e, eui, srcWindow); }
						catch (ex)															{ /* no problem */ }
					}
					else if (originalFunction2)
					{
						try																	{ originalFunction2.call(this, e, eui, srcWindow); }
						catch (ex)															{ /* no problem */ }
					}
				}

				var originalFunction3														= settings["close"];

				settings["close"] = function(e, eui, srcWindow)
				{
					if (this.dialog("option", "destroyOnClose") && persistentDialogs)
					{
						delete persistentDialogs[url];

						if (persistentDialogs.length <= 0)									{ persistentDialogs = null; }
					}

					var currentFunction														= this.data("ui.dialog.persistentClose");

					if		(currentFunction)
					{
						try																	{ currentFunction.call(this, e, eui, srcWindow); }
						catch (ex)															{ /* no problem */ }
					}
					else if (originalFunction3)
					{
						try																	{ originalFunction3.call(this, e, eui, srcWindow); }
						catch (ex)															{ /* no problem */ }
					}
				}
			}

			ui.dialog.createModalDialog(settings);
		}
	}

	ui.dialog.preloadIFrameDialog = function(url, settings)
	{
		settings																			= $.fn.extend(settings ? settings : {}, { _preload: true, persistent: true });

		ui.dialog.createIFrameDialog(url, settings);
	}

	function getElement(srcWindow, checkSource)
	{
		// Only return the element if we're dealing with the frame that we originally requested in the URL.
		var elem																			= null;

		if (!checkSource || !ui.isWrapperFrameset(srcWindow))
		{
			// Non-wrapped iframe
			try																				{ elem = srcWindow.parent.jQuery.atsc.window.getIFrameFromWindow(srcWindow); }
			catch (ex)																		{ /* [MT-5301] */ }

			try
			{
				// Wrapped iframe
				if (!elem && (!checkSource || srcWindow.wt.getFrame("w0") == srcWindow))	{ elem = srcWindow.wt.parent.jQuery.atsc.window.getIFrameFromWindow(srcWindow.wt); }
			}
			catch (ex)																		{ /* [MT-5301] and [MT-5899] */ }
		}

		if (elem && checkSource)
		{
			var doc																			= $.atsc.window.getDocumentFromJQuery(elem);
			var wnd																			= $.atsc.window.getWindowFromDocument(doc);

			if (wnd != window)																{ elem = null; }
		}

		if (elem)
		{
			// [MT-5311] Look up the parent manually, .parents does not work as intended here.
			while (elem && elem.length > 0 && !elem.is(".ui-dialog-content"))				{ elem = elem.parent(); }

			return elem;
		}
		else																				{ return $(); }
	}

	ui.dialog.getParentWindow = function(srcWindow)
	{
		var pelem																			= getElement(srcWindow, false);

		if (pelem)																			{ return pelem.data("ui.dialog.window"); }
		else																				{ return null; }
	}

	ui.dialog.init = function()
	{
		ui.events.bind("ui.record.specificAfterClose", function(e, processEvent, srcWindow)
		{
			try
			{
				// When a record within an iframe is closed we'll also close the dialog.
				if (srcWindow == srcWindow.wt.getFrame("w0"))
				{
					var pelem																= getElement(srcWindow, true);

										// [MT-7956]
					if (pelem.length > 0/* && pelem.is(":visible")*/)
					{
						try
						{
							// [MT-4247] Allow files to be processed when closing a dialog.
							srcWindow.wt.processUserFiles();

							var beforeFrameClose											= pelem.dialog("option", "beforeFrameClose");

							if (beforeFrameClose && isFunction(beforeFrameClose))			{ beforeFrameClose(srcWindow, pelem, e.data.xmlDoc); }
						}
						catch (ex)															{ /* [MT-5536] */ }
						finally																{ pelem.dialog("close"); }

						if (ui.isRecord(srcWindow) && srcWindow.isSaved)
						{
							var wnd															= ui.dialog.getParentWindow(srcWindow);

							if (ui.isTable(wnd))											{ wnd.ui.table.forceRefresh(); }
						}
					}
				}
			}
			catch (ex)																		{ $.atsc.log.warn(ex); }

			processEvent();
		});

		ui.events.bind("ui.afterFrameLoad", function(e, processEvent, srcWindow)
		{
			try
			{
				var pelem																	= getElement(srcWindow, true);

				if (pelem.length > 0 && pelem.is(":visible"))
				{
					onAfterFrameLoad(pelem, srcWindow)
				}
			}
			catch (ex)																		{ $.atsc.log.warn(ex); }

			processEvent();
		});
	}

	$(window).on("beforeunload", function()
	{
		try
		{
			// Close all dialogs when the Window object is destroyed.
			// This prevents a memory leak whereby Window objects from previous dialogs are preserved.

			// For dialogs that are opened on this Window.
			if (dialogs && dialogs.length > 0)
			{
				var dlg;

				for (var i = 0; i < dialogs.length; i = i + 1)
				{
					try
					{
						dlg																	= $(dialogs[i]);

						dlg.dialog("option", "destroyOnClose", true);
						dlg.dialog("close");
					}
					catch (ex)																{ /* no problem */ }
				}
			}

			// For dialogs that were opened by this Window (but are opened on a parent Window due to size constraints)
			var prop																		= ui.properties.getLocalOrGlobalProperties();

			if (prop && prop.dialogs)
			{
				var toRemove																= [];

				for (var i = 0; i < prop.dialogs.length; i = i + 1)
				{
					try
					{
						if (prop.dialogs[i].element.data("ui.dialog.window") == window)		{ toRemove.push(prop.dialogs[i].element); }
					}
					catch (ex)																{ /* no problem */ }
				}

				var index;

				for (var i = 0; i < toRemove.length; i = i + 1)
				{
					try
					{
						toRemove[i].dialog("option", "destroyOnClose", true);
						toRemove[i].dialog("close");

						if (toRemove[i] != null && toRemove[i].length > 0)
						{
							// Mark the dialog as closed in the local properties object.
							index															= getDialogIndexFromProperties(prop, toRemove[i][0]);

							if (index > -1)													{ prop.dialogs.splice(index, 1); }

							var wnd															= $.atsc.window.getWindowFromDocument($.atsc.window.getDocumentFromJQuery(toRemove[i]));;

							wnd.setTimeout(toRemove[i].remove.bind(toRemove[i], true), 0);
						}
					}
					catch (ex)																{ /* no problem */ }
				}
			}
		}
		catch (ex)																			{ /* no problem */ }
	})

	ui.dialog.pause = function(settings, callback)
	{
		var options																			= $.fn.extend({
			titlebar:		false,
			buttons:		[],
			title: "",
			html: "<p style=\"text-align: center;\">Een ogenblik geduld a.u.b.</p>",
		}, settings ? settings : {});

		var fO																				= options["open"];

		options["open"] = function()
		{
			if (fO)
			{
				try																			{ fO(); }
				catch (ex)																	{ /* no problem */ }
			}

			var dlg																			= this;

			callback(function(message)
			{
				dlg.dialog("close");

				if (message)
				{
					options["open"]															= null;
					options["html"]															= message;

					ui.dialog.createModalDialog(options);
				}
			});
		}

		ui.dialog.createModalDialog(options);
	}
})($);

(function()
{
	ui.lookup																				= {};

	var LOOKUPURL																			= "/jsp/atsc/UITable.jsp?lookup=true&mode=3";
	var lookupWindow, lookupDialog;
	var open																				= false;
	var closing																				= false;

	ui.lookup.open = function(item, url, width)
	{
		startLoading();

		try
		{
			if (open)																		{ setTimeout(ui.lookup.open.bind(this, item, url, width), 100); }
			else
			{
				var lookuptitle;

				try																			{ lookuptitle = "Opzoeken '" + document.getElementById("l" + item.id).innerHTML + "'"; }
				catch (ex)																	{ lookuptitle = "Opzoeken..."; }

				open																		= true;

				ui.dialog.createIFrameDialog(LOOKUPURL, {
					title:		lookuptitle,
					titlebar:	true,
					closable:	true,
					noFrameset:	true,
					width:		"auto",
					minWidth:	600,
					maxWidth:	950,
					height:		400,
					persistent:	true,

					afterFrameLoad: function(srcWindow, pelem)
					{
						lookupDialog														= pelem;
						lookupWindow														= srcWindow;

						stopLoading();

						srcWindow.ui.table.reset();

						//[MT-926] Determine if we can select multiple records.
						srcWindow.ui.table.allowMultipleRecords								= ($(item).attr("allowmultiplevalues") == "true");

						srcWindow.ui.table.userHasSelectedRecords							= false;

						if (srcWindow.ui.table.allowMultipleRecords)
						{
							// [MT-3316] Restore the selected items.
							var values														= toString($(item).attr("fuidvalue")).replace(/,/g, "_sep_").split("_sep_");

							srcWindow.ui.table.setSelectedRecords(values);

							srcWindow.ui.table.addRefreshCallback(function(){ srcWindow.ui.table.checkSelectedRecords(); });
						}
						else
						{
							//[MT-926] Make sure no records are selected by default.
							srcWindow.ui.table.selectedRecords								= null;
						}

						srcWindow.table.location											= url;
					},

					close: function()
					{
						if (!closing)
						{
							closing															= true;

							ui.lookup.close(false);
						}
					}
				});
			}
		}
		catch (ex)																			{ stopLoading(); }
	}

	function beginCloseLookup(ok, recordWindow, callback)
	{
		if (lookupWindow && lookupWindow.ui && lookupWindow.ui.initialized && !lookupWindow.ui.table.isFrameLoading())
		{
			/*
			 * A holder for the affected items. All "lookup keys" are considered affected items and will be modified immediately. Lookup fields will be
			 * modified using a second ajax lookup, which does not take place here.
			 */
			var affectedItems																= [];

			var item;
			var names																		= lookupWindow.ui.table.target.split("_<split>_");

			for (var i = 0; i < names.length; i++)
			{
				item																		= recordWindow.document.getElementById(names[i]);

				if (item != null)															{ affectedItems.push(item); }
			}

			if (affectedItems.length <= 0)													{ throw new Error("No items found!"); }

			/*
			 * Legacy target support (where only the first target is set)
			 */
			var target																		= "";
			try																				{ target = affectedItems[0].id; }
			catch (ex)																		{ /* no problem */ }

			recordWindow.ui.events.call("ui.window.specificCloseLookupBegin", window, function(e)
			{
				endCloseLookup(affectedItems, ok, target, recordWindow, callback);
			},
			{
				"frame": lookupWindow,
				"target": target,
				"item": (affectedItems != null && affectedItems.length > 0 ? affectedItems[0] : null),
				"affectedItems": affectedItems,
				"ok": ok,
				"": ""
			}, true, false);
		}
		else
		{
			if (recordWindow)
			{
				recordWindow.zoom															= false;
				recordWindow.stopLoading();
			}

			callback();
		}
	}

	function endCloseLookup(affectedItems, ok, target, recordWindow, callback)
	{
		try
		{
			/*
			 * From this point forward the 'item' variable references the first item in the list. (the first lkey)
			 */
			var item																		= affectedItems != null && affectedItems.length > 0 ? affectedItems[0] : null;
			var fkey																		= $(item).attr("special-fkey");

			/*
			 * If there are no items we throw an error. Most likely the lkey is not pointing to a valid item, or none have been provided.
			 */
			if (!item)																		{ $.atsc.log.error("BUG: No item found! Tried to get item \"" + lookupWindow.ui.table.target + "\" but it wasn't found on the page. Perhaps you forgot to define a key field for the lookup field?"); }
			else
			{
				/*
				 * If an item was selected we'll modify the appropriate lkey fields immediately.
				 */
				if (ok)
				{
					var sourceList															= lookupWindow.ui.table.source.split("_<split>_");
					var kitem;

					var itemFUIDValueIsSet													= false;

					for (var i = 0; i < affectedItems.length; i++)
					{
						/*
						 * For every lkey field: Set the value to the appropriate value for that field.
						 */
						kitem																= affectedItems[i];

						if (sourceList[i] == lookupWindow.ui.table.table_name + "_unid")	{ kitem.value = lookupWindow.ui.table.getCell(0, lookupWindow.ui.table.row).parent().attr("unid"); }
						else
						{
							var v															= lookupWindow.ui.table.getValueFromID(sourceList[i], lookupWindow.ui.table.row);

							if (v === null)													{ continue; } // [MT-1781] Don't go past this point if the value is null.

							if (kitem.getAttribute("allowmultiplevalues") == "true")
							{
								/*
								 * [MT-926] If the field allows multiple values but only one value is selected we'll append it to the list of selected values.
								 * If multiple values are selected, the values are replaced in its entirety.
								 */

								if (lookupWindow.ui.table.allowMultipleRecords && lookupWindow.ui.table.selectedRecords != null && lookupWindow.ui.table.selectedRecords.length > 0)
								{
									/*
									 * Selecting multiple records (i.e., value + uniqueid/fuidvalue)
									 */

									var uniqueids											= "";
									var first												= true;

									for (var i = 0; i < lookupWindow.ui.table.selectedRecords.length; i++)
									{
										if (!first)											{ uniqueids += "_<sep>_"; }
										uniqueids											= uniqueids + lookupWindow.ui.table.selectedRecords[i];

										first												= false;
									}

									$.ajax({
										url: IOSERVLETURL,
										type: "get",
										data: {"action": GETSOURCEVALUES, "queryid": lookupWindow.ui.table.queryid, "uniqueids": uniqueids, "fieldName":  lookupWindow.ui.table.source},
										dataType: "xml",
										async: false, //[MT-1114] This must be a synchronous request.
										success: function(xmlDoc, textStatus, jqXHR)
										{
											var fuidvalues									= [];
											var values										= [];
											var itemValue									= "";
											var sep											= recordWindow.ui.form.getArraySeparator(kitem);

											$(xmlDoc).find("record").each(function()
											{
												var uniqueid								= $(this).attr("uniqueid");
												var value									= $(this).attr("value");

												if ($.inArray(value, values) == -1)
												{
													fuidvalues.push(uniqueid);
													values.push(value);

													if (itemValue == "")					{ itemValue = value; }
													else									{ itemValue = itemValue + sep + " " + value; }
												}
											});

											var itemValues									= "";
											var first										= true;

											for (var i = 0; i < values.length; i++)
											{
												if (!first)									{ itemValues += "_sep_"; }
												itemValues									= itemValues + values[i];
												first										= false;
											}

											var itemFuidvalues								= "";
											first											= true;

											for (var i = 0; i < fuidvalues.length; i++)
											{
												if (!first)									{ itemFuidvalues += "_sep_"; }
												itemFuidvalues								= itemFuidvalues + fuidvalues[i];
												first										= false;
											}

											kitem.setAttribute("values", itemValues);
											kitem.setAttribute("fuidvalue", itemFuidvalues);
											kitem.value										= itemValue;

											if (kitem.id == item.id)						{ itemFUIDValueIsSet = true; }
										},
										error: function(jqXHR, textStatus, errorThrown)
										{
											alert(MSG_CONNECTION_SERVER_LOST + "\n - Status: " + textStatus);
										}
									});
								}
								else
								{
									/*
									 * Selecting values only (no fuidvalue)
									 */
									kitem.setAttribute("values", v);
									kitem.value												= v;
								}
							}
							else
							{
								/*
								 * This field does not support multiple values, so just set the value directly.
								 *
								 * We don't need to set the fuidvalue unless frame.ui.table.moreresults=true (if it's false it will be looked up afterwards).
								 */

								var cont;

								try															{ cont = recordWindow.isQmapLookupAllowed(item, kitem.id, true, v); }
								catch (ex)													{ cont = true; }

								if (cont)
								{
									kitem.value												= v;

									if (i > 0)
									{
										kitem.setAttribute("oldQmapValue", "_<null>_");
										kitem.setAttribute("fuidvalue", "");

										//lookup needs to be false, or checkItem won't be able to do its job
										// TODO: what? -JB
										var oldLookup										= recordWindow.lookup;
										recordWindow.lookup									= false;
										recordWindow.checkItem(kitem);
										recordWindow.lookup									= oldLookup;
									}
								}
							}
						}
					}

					if (lookupWindow.ui.table.moreresults)
					{
						/*
						 * If there was more than one result (and the user has just chosen the specific item they want) set the unique id and
						 * perform set the qmap fields manually.
						 */

						var errorFields														= [];
						var vars															= lookupWindow.ui.table.getValues(lookupWindow.ui.table.row);

						if (!itemFUIDValueIsSet)											{ item.setAttribute("fuidvalue", lookupWindow.ui.table.getUNIDFromRow(lookupWindow.ui.table.row)); }

						var qMapFields														= getAttribute(item, "qmapping").split("_<split>_");
						var elem;
						var v;
						var cont;

						for (var i = 2; i < qMapFields.length; i += 2)
						{
							elem															= recordWindow.document.getElementById(qMapFields[i + 1]);

							if (elem != null)
							{
								v															= vars[qMapFields[i]];

								// [MT-7393]
								if (v == null)												{ v = ""; }

								if (v != null)
								{
									v														= specialCharCheck(v);

									try														{ cont = recordWindow.isQmapLookupAllowed(item, qMapFields[i + 1], true, v); }
									catch (ex)												{ cont = true; }

									if (cont)
									{
										elem.value											= v;

										elem.setAttribute("oldQmapValue", "_<null>_");
//										elem.setAttribute("qmapDoneByCloseLookup", "true");
										elem.setAttribute("fuidvalue", "");

										//lookup needs to be false, or checkItem won't be able to do its job
										// TODO: what? -JB
										var oldLookup										= recordWindow.lookup;
										recordWindow.lookup									= false;
										recordWindow.checkItem(elem);
										recordWindow.lookup									= oldLookup;
									}
								}
								else														{ errorFields.push(qMapFields[i]); }
							}
						}

						if (errorFields.length > 0)
						{
							var msg															= "Er is een fout opgetreden! De lookup kon niet voltooid worden omdat de volgende velden niet opgevraagd konden worden:";
							for (var i = 0; i < errorFields.length; i++)					{ msg += "\n    - " + errorFields[i]; }
							alert(msg);
						}

						destroyTable														= false;
					}
				}

				/*
				 * Tell the frame that there is no longer a lookup window present.
				 */
				recordWindow.lookup															= false;
				recordWindow.lookupKey														= "";

				if (ok)
				{
					/*
					 * If the request was successful we'll order the qmap fields for all items to be updated appropriately.
					 * (This is only guaranteed to work so long as frame.ui.table.moreresults is false, otherwise it may skip elements.)
					 */
					var kitem;

					for (var i = 0; i < affectedItems.length; i++)
					{
						kitem																= affectedItems[i];

						if (lookupWindow.ui.table.moreresults)
						{
							/*
							 * If there was more than one result we've already filled out the fields with valid ones retrieved from the server,
							 * so mark the field as being valid.
							 */

							if (kitem.getAttribute("allowmultiplevalues") == "true")		{ kitem.setAttribute("oldQmapValue", getAttribute(kitem, "values")); }
							else															{ kitem.setAttribute("oldQmapValue", kitem.value); }

							kitem.setAttribute("valid", "true");
							kitem.setAttribute("_closelookup", "true");
						}
						else																{ kitem.setAttribute("oldQmapValue", "_<null>_"); }
					}

					for (var i = 0; i < affectedItems.length; i++)
					{
						kitem																= affectedItems[i];

						recordWindow.jQuery(kitem).attr("isChanged", "true");

						recordWindow.checkItem(kitem);
					}

					recordWindow.jQuery(item).attr("isChanged", "true");

					processNext(item, recordWindow);
				}

				/*
				 * Tell the frame that we're completely done with the lookup.
				 */
				recordWindow.zoom															= false;
				recordWindow.stopLoading();

				/*
				 * [MT-926] Deselect all rows (if any were selected) and jump back to the first column and row to prevent flickering.
				 */
				if (lookupWindow.ui.table.allowMultipleRecords && lookupWindow.ui.table.selectedRecords != null && lookupWindow.ui.table.selectedRecords.length > 0)
				{
					var rows;
					for (var i = 0; i < lookupWindow.ui.table.selectedRecords.length; i++)
					{
						rows																= lookupWindow.ui.table.getRowsByUniqueId(lookupWindow.ui.table.selectedRecords[i]);
						for (var r = 0; r < rows.length; r++)								{ lookupWindow.ui.table.unHighlightRow(rows[r]); }
					}

					lookupWindow.ui.table.selectedRecords									= null;
				}

				lookupWindow.ui.table.focusCell(0, lookupWindow.ui.table.row_start);

				/*
				 * Call the event system.
				 */
				recordWindow.ui.events.call("ui.window.specificCloseLookupEnd", window, null, { "frame": lookupWindow, "target": target, "item": item, "fkey": fkey, "affectedItems": affectedItems, "ok": ok }, true, false);

				try																			{ if (typeof(recordWindow.specificCloseLookupEnd) != "undefined" && isFunction(recordWindow.specificCloseLookupEnd)){ recordWindow.specificCloseLookupEnd(target, ok); } }
				catch (ex)																	{ $.atsc.log.warn(ex); }
			}

			//[MT-1733] Remove the special-fkey attribute.
			$(item).removeAttr("special-fkey");
		}
		finally																				{ callback(); }
	}

	ui.lookup.close = function(ok)
	{
		var recordWindow																	= lookupWindow ? ui.dialog.getParentWindow(lookupWindow) : window;

		/*
		 * The lookup table must be explicitly deselected to prevent focus problems. (Otherwise the table may receive focus even when it's not visible.)
		 */
		ui.window.deselectOtherFrames(recordWindow);

		function finish()
		{
			if (lookupWindow)
			{
				lookupWindow.zoom															= false; // TODO: does this do anything? -JB

				/*
				 * We need to set the inner iframe (containing UITableIFrame.jsp) to a blank url. Unfortunately, IE throws "Access denied" if the frame
				 * is pointing to an external domain (even if the containing iframe is on our own domain), so this is wrapped in a try/catch.
				 */
				try																			{ lookupWindow.ui.table.getDocument().body.style.display = "none"; }
				catch (ex)																	{ /* no problem */ }
			}

			setStatusMessage("");

			// Blur every selected field. Fields that receive focus while the lookup is closing don't blur properly.
			try																				{ recordWindow.jQuery(".selected").blur(); }
			catch (ex)																		{ /* no problem */ }

			if (lookupWindow)
			{
				lookupWindow.ui.table.destroyData();

				lookupWindow.table.src														= BLANKURL;
			}

			ui.window.deselectOtherFrames(recordWindow);

//			recordWindow.focusCurrentFrame(true); // commented out, this is handled by close method below!
			if (lookupDialog)
			{
				lookupDialog.dialog("close");
			}

			closing																			= false;
			open																			= false;
		}

		try																					{ beginCloseLookup(ok, recordWindow, finish); }
		catch (ex)
		{
			$.atsc.log.warn(ex);

			finish();
		}
	}

	ui.lookup.isOpen = function(){ return open; }

	ui.lookup.init = function()
	{
		var lookupwindow													= document.getElementById("lookupwindow");

		// [MT-11359]
		if (false && USERNAME != "" && ui.isRecord(window) && mode != MODE_READ)
		{
			ui.dialog.preloadIFrameDialog(LOOKUPURL, {
				width:		"auto",
				maxWidth:	950,
				height:		400,
				noFrameset: true
			});
		}
	}
})();

(function()
{
	ui.addItemToSkip("progress");

	ui.progress = {};

	ui.progress.start = function(pollURL, title, callback, closeOnSuccess, afterOkCallback) //notifyCallback(finished, withErrors, progress, message)
	{
		try
		{
			if (typeof title == "undefined")						{ title = "Proces"; }

			var progressbar											= "<div class=\"progress\" style=\"position: relative; border: 1px solid #333333; border-radius: 6px; width: 500px; margin-left: 50px; height: 32px;\">"
																	+ "<div class=\"progress_inner\" style=\"position: absolute; left: -1px; border: 1px solid #008ED7; border-radius: 4px; background-color: #008ED7; width: 100%; height: 30px;\"></div>"
																	+ "<div class=\"progress_label\" style=\"position: absolute; left: 50%; margin-left: -30px; top: 4px; width: 60px; text-align: center;\">100%</div>"
																	+ "</div>";

			ui.dialog.createModalDialog(
			{
				title: title,
				width: 600,
				height: 285,
				html: "<p align=\"center\">" + title + "</p><p class=\"status\" align=\"center\"></p>" + progressbar,
				closable: false,
				selectable: false,
				buttons: [],
				open: function()
				{
					var dlg											= this;

					dlg.parent().find(".ui-dialog-titlebar").hide();
					dlg.parent().css({"border": "1px solid #333333"});
					dlg.find(".progress_inner").css("width", "0px");
					dlg.find(".progress_label").text("0%");

					var progress									= 0;
					var message										= "";
					var finished									= false;
					var withErrors									= false;
					var buttons										= null;
					var data;

					function loop(r, first)
					{
						if (r > 50)
						{
							$.atsc.log.error("ui.progress.Progress failed to poll after " + r + " attempts.");

							if (isFunction(callback))				{ callback(true); }

							return;
						}

						function handleResponse(data)
						{
							progress								= data["progress"];
							message									= data["message"];
							finished								= data["finished"];
							withErrors								= data["withErrors"];
							buttons									= data["buttons"];

							if (finished)
							{
								try
								{
									dlg.dialog("close");

									if (withErrors || !closeOnSuccess)
									{
										if (!buttons || !buttons.length){ buttons = [{text: "OK", click: function()
											{
												this.dialog("close");

												if (isFunction(afterOkCallback)) { afterOkCallback(); }
											}}];
										}

										ui.dialog.createModalDialog(
										{
											title: title,
											width: 600,
											height: 285,
											html: "<p align=\"center\">" + title + "</p><p align=\"center\">" + message + "</p>" + progressbar,
											"buttons": buttons,
											selectable: true,
											open: function()
											{
												this.parent().css({"border": "1px solid #333333"})
															 .find(".ui-dialog-titlebar").hide();
											}
										});
									}

									if (isFunction(callback))		{ callback(withErrors, progress, message); }
								}
								catch (ex)
								{
									if (!(ui.unloading || (document && document.visibilityState == "unloaded"))){ throw ex; }
								}
							}
							else
							{
								dlg.find(".status").html(message);
								dlg.find(".progress_inner").css("width", progress + "%");
								dlg.find(".progress_label").text((progress + "").replace(/\./g, ",") + "%");

								loop(0);
							}
						}

						if (isFunction(pollURL))					{ pollURL(first, function(data){ handleResponse(data); }); }
						else
						{
							$.ajax({
								url: pollURL + (first ? (pollURL.indexOf("?") > -1 ? "&first=true" : "?first=true") : ""),
								type: "get",
								dataType: "xml",
								success: function(xmlDoc, textStatus, jqXHR)
								{
									if ($(xmlDoc).find("progress").length > 0)
									{
										data = {
											"progress": $(xmlDoc).find("progress").text(),
											"message": $(xmlDoc).find("message").text(),
											"finished": ($(xmlDoc).find("finished").text() == "true"),
											"withErrors": ($(xmlDoc).find("withErrors").text() == "true"),
											"buttons": $(xmlDoc).find("buttons").text()
										}
									}
									else
									{
										data = {
												"progress": 100.00,
												"message": "",
												"finished": true,
												"withErrors": false,
												"buttons": ""
										}
									}

									handleResponse(data);
								},
								error: function(jqXHR, textStatus, errorThrown)
								{
									alert(MSG_CONNECTION_SERVER_LOST + "\n - Status: " + textStatus);
								}
							});
						}
					}

					loop(0, true);
				}
			});
		}
		catch (ex)													{ $.atsc.log.error(ex); }
	}

	function upload(settings, state, currentProgress, maxProgress)
	{
		var originalData											= settings["data"];

		var formData												= null;

		if (settings["data"] && !settings["uploadDirectly"])
		{
			// Not uploading directly, so wrap the data in a FormData object.

			formData												= new FormData();

			if (settings["data"].wrapped_upload_file)
			{
				formData.append("file", settings["data"]["file"], settings["data"]["file"].name);

				if (settings["data"]["file"].name)					{ formData.append("filename", settings["data"]["file"].name); }
			}
			else if ((settings["data"] instanceof File) || (settings["data"] instanceof Blob))
			{
				formData.append("file", settings["data"], settings["data"].name);

				if (settings["data"].name)							{ formData.append("filename", settings["data"].name); }
			}
			else
			{
				for (var key in settings["data"])
				{
					if (settings["data"][key] instanceof File)		{ formData.append(key, settings["data"][key], settings["data"][key].name); }
					else if (settings["data"][key] instanceof Blob)	{ formData.append(key, settings["data"][key], ""); }
					else											{ formData.append(key, settings["data"][key]); }
				}
			}

			settings["data"]										= formData;
		}

		settings["cache"]											= false;
		settings["contentType"]										= false;
		settings["processData"]										= false;
		settings["xhr"] = function()
		{
			var xhr													= new window.XMLHttpRequest();

			function updateProgress(e)
			{
				if (e.lengthComputable)
				{
					var percentage									= (((e.loaded / e.total) * maxProgress) + currentProgress).toFixed(2);
					state.progress									= percentage;
					state.message									= settings["message"] + "<br />(" + (e.loaded / 1024).toFixed(0) + " van " + (e.total / 1024).toFixed(0) + " KB)";
				}
			}

			xhr.upload.addEventListener("progress", updateProgress);
			xhr.upload.addEventListener("load", function(e)
			{
				if (e.total == e.loaded)
				{
					state.progress									= (currentProgress + maxProgress).toFixed(2);
					state.message									= settings["message"] + "<br />Bezig met verwerken.<br />&nbsp;";
				}
				else
				{
					state.progress									= maxProgress;
					state.withErrors								= true;
					state.finished									= true;
					state.message									= settings["message"] + "<br />Onvolledige data ontvangen!<br />&nbsp;";
				}
			});

			return xhr;
		}

		if (settings["setup"])
		{
			var fS													= settings["setup"];
			settings["setup"]										= null;

			fS.call(this, settings, originalData);
		}

		if (settings["extraParameters"])
		{
			if (settings["uploadDirectly"])
			{
				var url												= settings["url"];

				for (var key in settings["extraParameters"])
				{
					if (url.indexOf("?") > -1)						{ url = url + "&"; }
					else											{ url = url + "?"; }

					url												= url + key + "=" + encodeURIComponent(settings["extraParameters"][key]);
				}
			}
			else
			{
				if (!settings["data"])								{ settings["data"] = new FormData(); }

				for (var key in settings["extraParameters"])		{ settings["data"].append(key, settings["extraParameters"][key]); }
			}
		}

		var fS														= settings["success"];

		settings["success"] = function(data, textStatus, jqXHR)
		{
			state.progress											= maxProgress;
			state.withErrors										= false;
			state.finished											= true;

			function cont()
			{
				if (state.processed)
				{
					if (fS)
					{
						try											{ fS.call(this, data, textStatus, jqXHR); }
						catch (ex)									{ $.atsc.log.warn(ex); }
					}
				}
				else												{ setTimeout(cont, 100); }
			}

			cont();
		}

		var fE														= settings["error"];

		settings["error"] = function(jqXHR, textStatus)
		{
			state.progress											= maxProgress;
			state.message											= settings["message"] + "<br />Fout tijdens verwerken: " + textStatus + ".<br />&nbsp;";
			state.withErrors										= true;
			state.finished											= true;

			function cont()
			{
				if (state.processed)
				{
					if (fE)
					{
						try											{ fE.call(this, jqXHR, textStatus); }
						catch (ex)									{ $.atsc.log.warn(ex); }
					}
				}
				else												{ setTimeout(cont, 100); }
			}

			cont();
		}

		$.ajax(settings);
	}

	var ProgressState = function()
	{
		this.progress												= 0;
		this.message												= "";
		this.finished												= false;
		this.withErrors												= false;
		this.buttons												= [];
		this.processed												= false;
	}

	ui.progress.upload = function(settings, currentProgress, maxProgress)
	{
		settings = $.fn.extend({
			url:			null,						// URL to post to
			type:			"post",						// Type of request (defaults to POST)
			data:			null,						// Fields to send through, may include File objects
			title:			"Bestand uploaden",			// Title to display while uploading
			message:		"Bezig met uploaden..."		// Message to display while uploading
		}, settings ? settings : {});

		if (settings["data"] && (settings["data"] instanceof Array))
		{
			var s;

			// The maximum percentage for any given upload.
			var percentageSplit										= 100 / settings["data"].length;

			JSO.each(settings["data"], function(item, process, i)
			{
				s													= {};

				for (var key in settings)							{ s[key] = settings[key]; }

				s["data"]											= item;
				s["complete"]										= null;

				s["success"] = function(data, textStatus, jqXHR)
				{
					if (settings["success"])
					{
						settings["success"].call(this, data, textStatus, jqXHR, function(c)
						{
							if		(c === "retry")					{ process(false); }
							else if (c)								{ process(true); }
							else									{ process(null); }
						});
					}
					else											{ process(true); }
				};

				s["error"] = function(jqXHR, textStatus)
				{
					if (settings["error"])							{ settings["error"].call(this, jqXHR, textStatus, function(){ process(null); }); }
				}

				ui.progress.upload(s, percentageSplit * i, percentageSplit);
			}, function()
			{
				if (settings["complete"])							{ settings["complete"].call(this); }
			});
		}
		else
		{
			if (!currentProgress)									{ currentProgress = 0; }
			if (!maxProgress)										{ maxProgress = 100; }

			var state												= new ProgressState();

			upload(settings, state, currentProgress, maxProgress);

			ui.progress.start(function(first, callback)
			{
				var f												= callback.bind(this, { "progress": state.progress, "message": state.message, "finished": state.finished, "withErrors": state.withErrors, "buttons": state.buttons });

				if		(first)										{ f(); }
				else												{ setTimeout(f, 100); }
			}, settings["title"], function(){ state.processed = true; }, true);
		}
	}
})();

(function()
{
	ui.addItemToSkip("dragdrop");

	var div																					= document.createElement("div");

	var dndSupported																		= ("draggable" in div) || ("ondragstart" in div && "ondrop" in div);

	div																						= null;

	ui.dragdrop																				= {
		parseHTML: function(html) {
			var m																			= html.match(/<img .*?src="([^"]*)"/i);
			var url																			= (m != null && m.length > 1) ? m[1] : "";

			if (!url.startsWith("http://") && !url.startsWith("https://"))
			{
				if (url.startsWith("/"))													{ url = origURL.substring(0, origURL.indexOf("/")) + url; }
				else																		{ url = origURL.substring(0, origURL.lastIndexOf("/") + 1) + url; }
			}

			return url;
		},
		getClipboardData: function(cbd, callback) {
			var data																		= [];

			if (cbd.files && cbd.files.length > 0) // drag & drop
			{
				for (var i = 0; i < cbd.files.length; i = i + 1)							{ data.push(cbd.files[i]); }

				callback(data);
			}
			else if (cbd.items) // paste
			{
				var filename;
				var file;
				var tmp;

				JSO.each(cbd.items, function(item, process)
				{
					if		(item.type.indexOf("image/") > -1)								{ file = item.getAsFile(); process(null); }
					else if (item.type.indexOf("x-local-uri") > -1)
					{
						var oReq															= new XMLHttpRequest();

						oReq.open("GET", cbd.getData(item.type), true);

						oReq.responseType													= "arraybuffer";

						oReq.onload = function(oEvent)
						{
							file															= new Blob([oReq.response], { type: oReq.getResponseHeader("Content-Type") });

							process(null);
						};

						oReq.send();
					}
					else if (item.type.indexOf("text/") > -1)
					{
						/*
						 * See https://code.google.com/p/chromium/issues/detail?id=361145 for a Chrome bug regarding getAsFile which returns a Blob instead of a File object.
						 * This probably won't be fixed properly however as the current train of thought is "just send the name 'screenshot.png' or something to that extent".
						 * Our solution is to look at the accompanying image tag (if one is provided) and extract the filename from the URL.
						 */
						tmp																	= cbd.getData(item.type);

						if (tmp.indexOf("src=\"") > -1)
						{
							var path														= tmp.right("src=\"").left("\"");
							filename														= path.backwardsRight("/");

							var progress													= 0;
							var message														= "Bezig met ophalen bestand...";
							var finished													= false;
							var withErrors													= false;
							var buttons														= [];

							// Note: Progress is managed by the code below.
							ui.progress.start(function(first, callback)
							{
								var f														= callback.bind(this, { "progress": progress, "message": message, "finished": finished, "withErrors": withErrors, "buttons": buttons });

								if		(first)												{ f(); }
								else														{ setTimeout(f, 100); }
							}, "Bestand uploaden", function(){ process(true); }, true);

							/*
							 * Create a new Image object (<IMG>) and load the image into it.
							 * We can then access the image using a Canvas object (<CANVAS>) and create a data: URI.
							 * The data: URI is then converted to a Blob which is passed to the upload mechanism.
							 */

							var image														= document.createElement("img");
							var canvas														= document.createElement("canvas");

							image.onload = function()
							{
								canvas.width												= this.width;
								canvas.height												= this.height;

								var context													= canvas.getContext("2d");

								context.drawImage(image, 0, 0);

								var dataurl;

								if (filename.indexOf(".png") > -1)
								{
									dataurl													= canvas.toDataURL("image/png");
									filename												= filename.backwardsLeft(".") + ".png";
								}
								else
								{
									dataurl													= canvas.toDataURL("image/jpeg", 1.0);
									filename												= filename.backwardsLeft(".") + ".jpg";
								}

								file														= JSO.dataURLToBlob(dataurl);

								progress													= 100;
								finished													= true;
							}

							image.onerror = function()
							{
								progress													= 100;
								message														= "Fout bij ophalen bestand.<br />Het opgegeven bestand is geen geldige afbeelding of is niet toegankelijk vanaf onze servers.";
								withErrors													= true;
								finished													= true;
							}

							/*
							 * Unfortunately to prevent CORS security errors we have to fall back on a local servlet that downloads
							 * the file and presents it to the browser. This means files that require authentication cannot be accessed!
							 */
							image.src														= "/file-upload?url=" + encodeURIComponent(path);
						}
						else																{ process(true); }
					}
					else																	{ process(true); }
				}, function()
				{
					if (file)
					{
						if (filename)														{ file.name = filename; }
						else																{ file.name = "screenshot.png"; } // No URL to extract the filename from (image from print screen)

						data.push(file);

						callback(data);
					}
				});
			}
			else { callback(data); }
		}
	};

	if (dndSupported)
	{
		$(window).on("load", function()
		{
			$(document).on("dragover", function(e)
			{
				var $target																	= $(e.target).hasClass("droppable") ? $(e.target) : $(e.target).parents(".droppable");

				if ($target.length > 0)														{ e.originalEvent.dataTransfer.dropEffect = "copy"; }
				else																		{ e.originalEvent.dataTransfer.dropEffect = "none"; }

				return false;
			}).on("drop", function(e)
			{
				var $target																	= $(e.target).hasClass("droppable") ? $(e.target) : $(e.target).parents(".droppable");

				if ($target.length > 0)
				{
					e.preventDefault();

					ui.dragdrop.getClipboardData(e.originalEvent.dataTransfer, function(data)
					{
						if (data && data.length > 0)
						{
							var ev															= $.Event("ui.dragdrop.onDrop");

							ev.target														= e.target;

							// Event data needs to be wrapped in an array, or jQuery will unwrap the data array and add single files as parameters.
							var eventData													= [data];

							$target.trigger(ev, eventData);
						}
					});
				}
			}).on("paste", function(e)
			{
				if (!$(e.target).is("input,textarea,[contenteditable=true]"))
				{
					e.preventDefault();

					ui.dragdrop.getClipboardData(e.originalEvent.clipboardData, function(data)
					{
						if (data && data.length > 0)
						{
							var ev															= $.Event("ui.dragdrop.onPaste");

							ev.target														= e.target;

							// Event data needs to be wrapped in an array, or jQuery will unwrap the data array and add single files as parameters.
							var eventData													= [data];

							$(document).trigger(ev, eventData);
						}
					});
				}
			});
		});
	}
})();

ui.addItemToSkip("quickmenu");

ui.quickmenu = {};

(function()
{
	var isStatusWindow																						= (window.location.href.indexOf("Status.jsp") > -1);

	if (isStatusWindow)
	{
		function hideGroups()																				{ $(".quickmenu_group:not(#quickmenu_left_group_global):not(#quickmenu_middle_group_global):not(#quickmenu_right_group_global)").hide(); }
		function showGroup(id)																				{ $("#quickmenu_middle_group_" + id).show(); }
		var globalFrame;

		function getCurrentFrame()
		{
			var frame;

			if (ui.isDashboard(wt.frames["wmain"]))
			{
				frame																						= wt.frames["wmain"].ui.dashboard.getCurrentFrame();

				if (ui.isFrameset(frame))
				{
					frame																					= frame.getFrame(frame.getCurrentFrameName());

					if ($("#dashboard-widgets", frame.document).length > 0) // Find the active widget
					{
						frame.jQuery("iframe").each(function()
						{
							if (isFrameAccessible(this.contentWindow)) // [MT-9129]
							{
								if (this.contentWindow.document.hasFocus())
								{
									this.contentWindow.jQuery("iframe").each(function()
									{
										if (this.contentWindow.document.hasFocus())
										{
											frame																= this.contentWindow;

											if (ui.isFrameset(frame))											{ frame	= frame.getFrame(frame.getCurrentFrameName()); }
										}
									})
								}
							}
						})
					}
				}
			}
			else																							{ frame = wt.getFrame(wt.getCurrentFrameName()); }

			if (ui.isComponent(frame) && frame.ui.component.currentFrame && frame.frames[frame.ui.component.currentFrame])
			{
				frame																						= frame.frames[frame.ui.component.currentFrame];

				if (ui.isFrameset(frame))																	{ frame = frame.getFrame(frame.getCurrentFrameName()); }
			}

			if (ui.isRecord(frame))
			{
				frame.jQuery("iframe").each(function()
				{
					if (ui.isTable(this.contentWindow) && this.contentWindow.ui.table.isFocused)
					{
						frame																				= this.contentWindow;

						return false;
					}
				})
			}

			// Om het juiste frame focus te laten houden op de ipad
			if (frame.location.href.indexOf("dashboard.jsp") == -1)											{ globalFrame = frame; }
			else																							{ frame = globalFrame; }

			return frame;
		}

		function imageToClass(img)
		{
			return "sprite-atsc-icons-statusbar sprite-" + img.substring(8, img.length).replace(/\//g, "-").replace(/\\/g, "-").replace(/(\.\w+)$/, "");
		}

		$(window).on("load", function()
		{
			var QuickmenuItem = function(name, href_or_func, img, corner_img, className, id)
			{
				this.name																					= name;
				this.href_or_func																			= href_or_func;
				this.img																					= img;
				this.corner_img																				= corner_img;
				this.className																				= className;
				this.id																						= id;
			}

			QuickmenuItem.prototype.createElement = function()
			{
				var html																					= "<div class=\"link\"><div>";

				if (!isNull(this.corner_img))																{ html += "<img src=\"" + this.corner_img + "\" />"; }

				if (!isNull(this.img)) 																		{ html = html + "<div class=\"" + imageToClass(this.img) + "\"></div>"; }

				html																						= html + "<span>" + this.name + "</span>";
				html																						= html + "</div></div>";

				var elem																					= $(html);

				if (this.className)																			{ elem.addClass(this.className); }
				if (this.id)																				{ elem.attr("id", this.id); }

				var href_or_func																			= this.href_or_func;

				if (href_or_func)
				{
					elem.on("click", function(e)
					{
						if (!getStatusWindow().quickmenuBusy)
						{
							if (typeof(href_or_func) == "function")											{ href_or_func(e); }
							else																			{ JSO.setWindowLocation(window, href_or_func); }
						}
					})
				}

				return elem;
			}

			function setMode(mode)
			{
				var frame																					= getCurrentFrame();

				if (ui.isTable(frame) && frame.ui.table.canChangeMode(mode))								{ frame.ui.table.changeMode(mode); }
			}

			function pressKey(which, ctrlKey, altKey, shiftKey)
			{
				var frame																					= getCurrentFrame();

				if (ui.isTable(frame))																		{ frame.ui.table.focusTable(); }
				else
				{
					try																						{ frame.focusCurrentFrame(true); }
					catch (ex)																				{ /* no problem */ }
				}

				if (isNull(ctrlKey))																		{ ctrlKey  = false; }
				if (isNull(altKey))																			{ altKey   = false; }
				if (isNull(shiftKey))																		{ shiftKey = false; }

				try
				{
					var e																					= frame.jQuery.Event("keydown");

					e.which																					= which;
					e.ctrlKey																				= ctrlKey;
					e.altKey																				= altKey;
					e.shiftKey																				= shiftKey;
					e.data																					= "QUICKMENU";

					frame.jQuery(frame.document.activeElement).trigger(e);

					// [MT-1440] In some cases, a keyup event is expected, but the quick menu wasn't providing one.
					// Allow events to finish naturally by providing such an event.
					e																						= frame.jQuery.Event("keyup");

					e.which																					= which;
					e.ctrlKey																				= ctrlKey;
					e.altKey																				= altKey;
					e.shiftKey																				= shiftKey;
					e.data																					= "QUICKMENU";

					frame.jQuery(frame.document.activeElement).trigger(e);
				}
				catch (ex)																					{ /* no problem */ }
			}

			function createGroup(id, location, items)
			{
				var groupElement																			= $("<div></div>").addClass("quickmenu_group").attr("id", location + "_group_" + id);

				if (id != "global")																			{ groupElement.css("display", "none"); }

				// [MT-75] Prevent items in the button bar from being being dragged (default behavior of the browser).
				groupElement.on("mousedown", function(e){ e.preventDefault(); });

				var element;
				var html;

				for (var i = 0; i < items.length; i = i + 1)												{ groupElement.append(items[i].createElement()); }

				var wnd																						= getStatusWindow();

				if (wnd)																					{ wnd.jQuery("#" + location).append(groupElement); }
			}

			createGroup("global", "quickmenu_left", [
				new QuickmenuItem("ESC", pressKey.bind(undefined, 27, false, false, false), "/images/atsc/icons/statusbar/esc.png")
			]);

			createGroup("global", "quickmenu_middle", [
				new QuickmenuItem("Help", pressKey.bind(undefined, 112, false, false, false), "/images/atsc/icons/statusbar/help.png", "/images/atsc/icons/statusbar/f1_corner.png")
			]);

			createGroup("global", "quickmenu_right", [
				new QuickmenuItem("Enter", pressKey.bind(undefined, 13, false, false, false), "/images/atsc/icons/statusbar/enter.png")
			]);

			createGroup("UITable", "quickmenu_middle", [
				new QuickmenuItem("Links",			pressKey.bind(undefined, 37, false, false, false),	"/images/atsc/icons/statusbar/arrow_left.png",									"/images/atsc/icons/statusbar/arrow_left_corner.png"),
				new QuickmenuItem("Rechts",			pressKey.bind(undefined, 39, false, false, false),	"/images/atsc/icons/statusbar/arrow_right.png",									"/images/atsc/icons/statusbar/arrow_right_corner.png"),
				new QuickmenuItem("Omhoog",			pressKey.bind(undefined, 38, false, false, false),	"/images/atsc/icons/statusbar/arrow_up.png",									"/images/atsc/icons/statusbar/arrow_up_corner.png"),
				new QuickmenuItem("Omlaag",			pressKey.bind(undefined, 40, false, false, false),	"/images/atsc/icons/statusbar/arrow_down.png",									"/images/atsc/icons/statusbar/arrow_down_corner.png"),
				new QuickmenuItem("Vorige",			pressKey.bind(undefined, 33, false, false, false),	"/images/atsc/icons/statusbar/book_previous.png",								"/images/atsc/icons/statusbar/page_up_corner.png"),
				new QuickmenuItem("Volgende",		pressKey.bind(undefined, 34, false, false, false),	"/images/atsc/icons/statusbar/book_next.png",									"/images/atsc/icons/statusbar/page_down_corner.png"),
				new QuickmenuItem("Home",			pressKey.bind(undefined, 36, false, false, false),	"/images/atsc/icons/statusbar/arrow_left.png",									"/images/atsc/icons/statusbar/home_corner.png"),
				new QuickmenuItem("End",			pressKey.bind(undefined, 35, false, false, false),	"/images/atsc/icons/statusbar/arrow_right.png",									"/images/atsc/icons/statusbar/end_corner.png"),
				new QuickmenuItem("Sort. opl.",		pressKey.bind(undefined, 113, false, false, false),	"/images/atsc/icons/statusbar/sort_asc.png",									"/images/atsc/icons/statusbar/f2_corner.png"),
				new QuickmenuItem("Sort. afl.",		pressKey.bind(undefined, 114, false, false, false),	"/images/atsc/icons/statusbar/sort_desc.png",									"/images/atsc/icons/statusbar/f3_corner.png"),
				new QuickmenuItem("Zoeken",			pressKey.bind(undefined, 115, false, false, false),	"/images/atsc/icons/statusbar/table_search.png",								"/images/atsc/icons/statusbar/f4_corner.png"),
				new QuickmenuItem("Verversen",		pressKey.bind(undefined, 116, false, false, false),	"/images/atsc/icons/statusbar/refresh.png",										"/images/atsc/icons/statusbar/f5_corner.png"),
				new QuickmenuItem("Toon alles",		pressKey.bind(undefined, 46, false, true, false),	"/images/atsc/icons/statusbar/table_selectall.png",								"/images/atsc/icons/statusbar/alt_del_corner.png"),

				new QuickmenuItem("Raadplegen",		setMode.bind(undefined, MODE_READ),					"/images/atsc/icons/statusbar/table_actions_" + MODE_READ + "_off.png",			"/images/atsc/icons/statusbar/alt_r_corner.png",	"table_actions", "table_actions_" + MODE_READ),
				new QuickmenuItem("Toevoegen",		setMode.bind(undefined, MODE_ADD),					"/images/atsc/icons/statusbar/table_actions_" + MODE_ADD + "_off.png",			"/images/atsc/icons/statusbar/alt_t_corner.png",	"table_actions", "table_actions_" + MODE_ADD),
				new QuickmenuItem("Wijzigen",		setMode.bind(undefined, MODE_EDIT),					"/images/atsc/icons/statusbar/table_actions_" + MODE_EDIT + "_off.png",			"/images/atsc/icons/statusbar/alt_w_corner.png",	"table_actions", "table_actions_" + MODE_EDIT),
				new QuickmenuItem("Verwijderen",	setMode.bind(undefined, MODE_DELETE),				"/images/atsc/icons/statusbar/table_actions_" + MODE_DELETE + "_off.png",		"/images/atsc/icons/statusbar/alt_v_corner.png",	"table_actions", "table_actions_" + MODE_DELETE),
				new QuickmenuItem("Printen",		setMode.bind(undefined, MODE_PRINT),				"/images/atsc/icons/statusbar/table_actions_" + MODE_PRINT + "_off.png",		"/images/atsc/icons/statusbar/alt_p_corner.png",	"table_actions", "table_actions_" + MODE_PRINT),
				new QuickmenuItem("Auto. actie",	setMode.bind(undefined, MODE_AUTOACTION),			"/images/atsc/icons/statusbar/table_actions_" + MODE_AUTOACTION + "_off.png",	"/images/atsc/icons/statusbar/alt_a_corner.png",	"table_actions", "table_actions_" + MODE_AUTOACTION),

				new QuickmenuItem("Layout",			pressKey.bind(undefined, 120, false, false, false),	"/images/atsc/icons/statusbar/select_layout.png",								"/images/atsc/icons/statusbar/f9_corner.png",		undefined, "table_printlayout"),
				new QuickmenuItem("Exporteren",		pressKey.bind(undefined, 69,  true, false,  false),	"/images/atsc/icons/statusbar/table_export.png",								"/images/atsc/icons/statusbar/ctrl_e_corner.png"),
				new QuickmenuItem("Afdrukken",		pressKey.bind(undefined, 79,  true, false,  false),	"/images/atsc/icons/statusbar/table_printselection.png",						"/images/atsc/icons/statusbar/ctrl_o_corner.png")
			]);

			createGroup("UIRecord", "quickmenu_middle", [
				new QuickmenuItem("Opslaan",		pressKey.bind(undefined, 117, false, false, false),	"/images/atsc/icons/statusbar/page_save.png",		"/images/atsc/icons/statusbar/f6_corner.png"),
				new QuickmenuItem("Vorig veld",		pressKey.bind(undefined, 114, false, false, true),	"/images/atsc/icons/statusbar/arrow_left.png",		"/images/atsc/icons/statusbar/f3_shift_corner.png"),
				new QuickmenuItem("Vorige",			pressKey.bind(undefined, 118, false, false, false),	"/images/atsc/icons/statusbar/book_previous.png",	"/images/atsc/icons/statusbar/f7_corner.png"),
				new QuickmenuItem("Volgende",		pressKey.bind(undefined, 119, false, false, false),	"/images/atsc/icons/statusbar/book_next.png",		"/images/atsc/icons/statusbar/f8_corner.png"),
				new QuickmenuItem("Zoeken",			pressKey.bind(undefined, 115, false, false, false),	"/images/atsc/icons/statusbar/magnifier.png",		"/images/atsc/icons/statusbar/f4_corner.png"),
				new QuickmenuItem("F5",				pressKey.bind(undefined, 116, false, false, false),	"/images/atsc/icons/statusbar/f5.png"),
				new QuickmenuItem("F9",				pressKey.bind(undefined, 120, false, false, false),	"/images/atsc/icons/statusbar/f9.png"),
				new QuickmenuItem("F10",			pressKey.bind(undefined, 121, false, false, false),	"/images/atsc/icons/statusbar/f10.png"),
				new QuickmenuItem("F11",			pressKey.bind(undefined, 122, false, false, false),	"/images/atsc/icons/statusbar/f11.png")
			]);

			createGroup("UIMenu", "quickmenu_middle", [
				new QuickmenuItem("Omhoog",			pressKey.bind(undefined, 38,  false, false, false),	"/images/atsc/icons/statusbar/arrow_up.png",		"/images/atsc/icons/statusbar/arrow_up_corner.png"),
				new QuickmenuItem("Omlaag",			pressKey.bind(undefined, 40,  false, false, false),	"/images/atsc/icons/statusbar/arrow_down.png",		"/images/atsc/icons/statusbar/arrow_down_corner.png"),
				new QuickmenuItem("F10",			pressKey.bind(undefined, 121, false, false, false),	"/images/atsc/icons/statusbar/f10.png")
			]);

			// Update quickmenu in case of widgets
			setInterval(function()
			{
				try
				{
					if (quickmenu_open)
					{
						var frame;

						if (ui.isDashboard(wt.frames["wmain"]))
						{
							frame																				= wt.frames["wmain"].ui.dashboard.getCurrentFrame();

							if (ui.isFrameset(frame))
							{
								frame																			= frame.getFrame(frame.getCurrentFrameName());

								if ($("#dashboard-widgets", frame.document).length > 0)							{ ui.quickmenu.updateQuickmenu(); }
							}
						}
					}
				}
				catch (e)																						{ /* no problem */ }
			}, 250)
		});

		ui.quickmenu.disableQuickmenuItems = function()
		{
			try
			{
				var quickmenu																				= getStatusDocument().getElementById("quickmenu");

				if (quickmenu != null && quickmenu.getAttribute("state") != "disabled")
				{
					quickmenu.setAttribute("state", "disabled");

					$("div.link", quickmenu).css({"opacity": "0.4", "filter": "alpha(opacity=40)"}).each(function()
					{
						$(this).data("oldHref", $(this).attr("href")).removeAttr("href");
					});
				}
			}
			catch (ex)																						{ /* no problem */ }
		}

		ui.quickmenu.enableQuickmenuItems = function()
		{
			try
			{
				var quickmenu																				= getStatusDocument().getElementById("quickmenu");

				if (quickmenu != null && quickmenu.getAttribute("state") == "disabled")
				{
					quickmenu.setAttribute("state", "");

					$("div.link", quickmenu).css({"opacity": "1", "filter": "alpha(opacity=100)"}).each(function()
					{
						$(this).attr("href", $(this).data("oldHref"));
					});
				}
			}
			catch (ex)																						{ /* no problem */ }
		}

		ui.quickmenu.updateQuickmenu = function()
		{
			try
			{
				var doc																						= getStatusDocument();
				var quickmenu																				= doc.getElementById("quickmenu_middle");
				var visible																					= false;

				try																							{ visible = quickmenu_open; }
				catch (ex)																					{ /* no problem */ }

				if (quickmenu != null && visible)
				{
					hideGroups();

					var frame																				= getCurrentFrame();

					if		(ui.isTable(frame))
					{
						showGroup("UITable");

						$(".table_actions").toggle(frame.ui.table.canChangeMode(frame.ui.table.mode));
						$("#table_printlayout").toggle((frame.ui.table.mode == MODE_PRINT || frame.ui.table.mode == MODE_FORCEPRINT) && frame.ui.table.printLayoutTypes && frame.ui.table.printLayoutTypes.length > 0);

						ui.quickmenu.setTableMode(frame.ui.table.mode);
					}
					else if (ui.isRecord(frame))															{ showGroup("UIRecord"); }
					else if (!ui.isDashboard(wt.frames["wmain"]) && ui.isMenu(frame))						{ showGroup("UIMenu"); }

					var children																			= $(quickmenu).find("div.link");
					var childWidth																			= 70; // children.first().outerWidth(true) = 70

					$(quickmenu).css("width", (children.length * childWidth));
					$(doc).find("#quickmenu_inner").css("width", wt.jQuery(wt).outerWidth() - childWidth - childWidth - 24);
				}
			}
			catch (ex)																						{ /* [MT-8341] */ }
		}

		ui.quickmenu.setTableMode = function(new_mode)
		{
			$(".table_actions").each(function()
			{
				if ($(this).data("off-state"))																{ $(this).find("div > div").prop("class", $(this).data("off-state")); }
			})

			var m																							= new_mode;

			// [MT-10613]
			if 		(new_mode == MODE_FORCEREAD)															{ m = MODE_READ; }
			else if (new_mode == MODE_FORCEEDIT)															{ m = MODE_EDIT; }
			else if (new_mode == MODE_FORCEADD)																{ m = MODE_ADD; }
			else if (new_mode == MODE_FORCEDELETE)															{ m = MODE_DELETE; }
			else if (new_mode == MODE_FORCEPRINT)															{ m = MODE_PRINT; }
			else if (new_mode == MODE_FORCEAUTOACTION)														{ m = MODE_AUTOACTION; }

			var element																						= $("#table_actions_" + m);

			element.data("off-state", element.find("div > div").prop("class"))
				   .find("div > div").prop("class", imageToClass("/images/atsc/icons/statusbar/table_actions_" + m + "_on.png"));
		}
	}
	else
	{
		ui.quickmenu.disableQuickmenuItems = function()
		{
			var wnd																							= getStatusWindow();
			if (wnd && wnd.ui && wnd.ui.quickmenu)															{ wnd.ui.quickmenu.disableQuickmenuItems(); }
		}

		ui.quickmenu.enableQuickmenuItems = function()
		{
			var wnd																							= getStatusWindow();
			if (wnd && wnd.ui && wnd.ui.quickmenu)															{ wnd.ui.quickmenu.enableQuickmenuItems(); }
		}

		ui.quickmenu.updateQuickmenu = function()
		{
			var wnd																							= getStatusWindow();
			if (wnd && wnd.ui && wnd.ui.quickmenu)															{ wnd.ui.quickmenu.updateQuickmenu(); }
		}

		ui.quickmenu.setTableMode = function(new_mode)
		{
			var wnd																							= getStatusWindow();
			if (wnd && wnd.ui && wnd.ui.quickmenu)															{ wnd.ui.quickmenu.setTableMode(new_mode); }
		}
	}
})();

(function ($)
{
	ui.links																= {};

	ui.links.init = function()
	{
		$("a[data-links-action]").removeAttr("href").on("click", function(e)
		{
			var types														= JSO.toString($(this).attr("data-links-action")).split(".");
			var f															= ui.links;

			for (var i = 0; i < types.length; i = i + 1)
			{
				try															{ f = f[types[i]]; }
				catch (ex)													{ break; }
			}

			if (f && JSO.isFunction(f))
			{
				var options													= {};
				var data													= $(this).data();
				var tmp;

				for (var key in data)
				{
					if (key.startsWith("linksAction"))
					{
						tmp													= key.right("linksAction").replace(/([A-Z])/g, "_$1").toLowerCase().substring(1);

						options[tmp]										= data[key];
					}
				}

				f(options);
			}
		});
	}

	ui.links.wf = {};
	ui.links.wf.createTask = function(options)
	{
		//[MT-9145]: Onderstaande uitgezet anders kun je geen taak aanmaken indien de widget is losgekoppeld.
		//if (ui.window.getDashboardFrame() == null)							{ throw new Error("Unsupported operation."); }
		//else
		{
			var uri															= "/jsp/wf/uiform/uiform_wf1procesinswiz.jsp?mode=" + encodeURIComponent(MODE_ADD);

			if (options["procesdef_unid"])									{ uri = uri + "&wf1procesdef_unid=" + encodeURIComponent(options["procesdef_unid"]); }
			if (options["relation_unid"])									{ uri = uri + "&relnr_gc1rel_unid=" + encodeURIComponent(options["relation_unid"]); }
			if (options["contactperson_unid"])								{ uri = uri + "&afzender_gc1ctp_unid=" + encodeURIComponent(options["contactperson_unid"]); }
			if (options["dossier_unid"])									{ uri = uri + "&rb1dos_unid=" + encodeURIComponent(options["dossier_unid"]); }
			if (options["offhdr_unid"])										{ uri = uri + "&offhdr_gc1offhdr_unid=" + encodeURIComponent(options["offhdr_unid"]); }
			if (options["offnr"])											{ uri = uri + "&offnr=" + encodeURIComponent(options["offnr"]); }
			if (options["foreign_table"])									{ uri = uri + "&foreign_table=" + encodeURIComponent(options["foreign_table"]); }
			if (options["foreign_unid"])									{ uri = uri + "&foreign_unid=" + encodeURIComponent(options["foreign_unid"]); }
			if (options["subject"])											{ uri = uri + "&subject=" + encodeURIComponent(options["subject"]); }
			if (options["memo"])											{ uri = uri + "&memo=" + encodeURIComponent(options["memo"]); }

			var dialogProperties = {
				title:			"",
				width:			961,
				height:			650,
				frames:			1
			};

			dialogProperties["beforeFrameClose"]							= ui.links.refreshActivityStream;

			ui.dialog.createIFrameDialog(uri, dialogProperties);
		}
	}

	ui.links.wf.connectTask = function(options)
	{
		var foreign_table													= options["foreign_table"];
		var foreign_unid													= options["foreign_unid"];
		var relation_unid													= options["relation_unid"];

		JSO.lookup("Kies een taak", "wf1procesins", false, { "wf1procesins.relnr_gc1rel_unid": relation_unid, "foreign_table": "_<isnull>_" }, function(unids)
		{
			$.ajax({
					url: IOSERVLETURL,
					data: { "action": 100, "name": "wf1procesins", "uniqueid": unids[0], "foreign_table": foreign_table, "foreign_unid": foreign_unid },
					success: function(xmlDoc, textStatus, jqXHR)
					{
						var updated											= $(xmlDoc).find("updated").text() == "true";

						if (updated)
						{
							JSO.showMessage("De taak is gekoppeld.");

							ui.links.refreshActivityStream();
						}
						else												{ JSO.showMessage("De taak is NIET gekoppeld!"); }
					}
				});
		});
	}

	ui.links.filterActivityStream = function(relation_unid, project_unid)
	{
		try
		{
			var controller													= getActivityStreamController();

			controller.setRelationUNID(relation_unid);
			controller.setProjectUNID(project_unid);
			controller.refreshActivityStream(true);
		}
		catch (e)															{ /* no problem */ }
	}

	ui.links.refreshActivityStream = function()
	{
		try																	{ getActivityStreamController().refreshActivityStream(true); }
		catch (e)															{ /* no problem */ }
	}

	function getActivityStreamController()
	{
		var iframe															= $("#activityStream > iframe");

		if (iframe.length > 0)
		{
			try																{ return iframe[0].contentWindow.angular.element(iframe[0].contentDocument.querySelector("body")).controller(); }
			catch (e)														{ /* no problem */ }
		}
	}

	ui.links.gc = {};

	function createOfferteOrderBon(type, options)
	{
		var uri																= "/jsp/gc/uiform_gc1vbnhdr.jsp?mode=" + encodeURIComponent(MODE_ADD);

		switch (type)
		{
			case 0:															{ uri = uri + "&recordId=" + encodeURIComponent("gc1offhdr"); }
			case 1:															{ uri = uri + "&recordId=" + encodeURIComponent("gc1vrkordhdr"); }
			default:														{ uri = uri + "&recordId=" + encodeURIComponent("Afleverbon"); }
		}

		if (options["relation_unid"])										{ uri = uri + "&debnr_gc1rel_unid=" + encodeURIComponent(options["relation_unid"]); }

		if (options["showframe"])											{ showFrame(uri); }
		else																{ showFrameOrOpenTab(uri); }
	}

	ui.links.gc.createOfferte												= createOfferteOrderBon.bind(null, 0);
	ui.links.gc.createOrder													= createOfferteOrderBon.bind(null, 1);
	ui.links.gc.createBon													= createOfferteOrderBon.bind(null, 2);

	ui.links.atsc = {};
	ui.links.atsc.openRecord = function(options)
	{
		var uri																= "/jsp/" + options["jsp"] + "?mode=" + encodeURIComponent(options["mode"] || MODE_READ);

		if (options["uniqueid"])											{ uri = uri + "&uniqueid=" + encodeURIComponent(uniqueid); }
		if (options["fuidname"] && options["fuidvalue"])					{ uri = uri + "&" + encodeURIComponent(options["fuidname"])+ "=" + encodeURIComponent(options["fuidvalue"]); }

		if (options["showframe"])											{ showFrame(uri); }
		else																{ showFrameOrOpenTab(uri); }
	}
	ui.links.atsc.openTable = function(options)
	{
		var uri																= "/jsp/atsc/UITable.jsp?queryid=" + encodeURIComponent(options["queryid"]);

		if (options["key"])													{ uri = uri + "&key=" + encodeURIComponent(options["key"]); }
		if (options["searchcol"])											{ uri = uri + "&searchcol=" + encodeURIComponent(options["searchcol"]); }
		if (options["mode"])												{ uri = uri + "&mode=" + encodeURIComponent(options["mode"]); }
		if (options["ordercolmethod"])										{ uri = uri + "&ordercolmethod=" + encodeURIComponent(options["ordercolmethod"]); }

		if (options["showframe"])											{ showFrame(uri); }
		else																{ showFrameOrOpenTab(uri); }
	}
})(jQuery);

/* note: requires ui.validation.js */

function convertFileSize(size)
{
	var suffix					= "bytes";

	if (size > 1024)
	{
		size					= size / 1024;
		suffix					= "KB";

		if (size > 1500)
		{
			size				= size / 1024;
			suffix				= "MB";

			if (size > 1500)
			{
				size			= size / 1024;
				suffix			= "GB";
			}
		}
	}

	return size.toFixed(0) + " " + suffix;
}

function countChars(str, chr)
{
	var pos										= -1;
	var i										= 0;

	do
	{
		pos										= str.indexOf(chr, pos + 1);
		i										= i + 1;
	}
	while (pos > -1);

	return i;
}

function getStringLength(str)
{
	var spl										= str.split("\n");
	var len										= 0;
	var tmp;
	var c;

	for (var i = 0; i < spl.length; i = i + 1)
	{
		tmp										= spl[i].length;

		c										= countChars(spl[i], "\t");

		tmp										= tmp - c;
		tmp										= tmp + (c * 8);

		len										= Math.max(len, tmp);
	}

	return len;
}

function trim(st)
{
	return rTrim(lTrim(st));
}

function lTrim(st)
{
	var tempSt = typeof(st) == "undefined" || !st ? "" : st;
	tempSt = tempSt.replace(/^\s+/,'');
	return tempSt;
}

function rTrim(st)
{
	var tempSt = typeof(st) == "undefined" || !st ? "" : st;
	tempSt = tempSt.replace(/\s+$/, '');
	return tempSt;
}

function transform(st, tp, arg1, arg2, array)
{
	return ui.validation.transform(st, tp, arg1, arg2, array);
}

function isValid(st, tp, arg1, arg2, array)
{
	return ui.validation.isValid(st, tp, arg1, arg2, false, array)
}

function isCorrectType(st, tp, arg1, arg2, array)
{
	return ui.validation.isValid(st, tp, arg1, arg2, true, array);
}

function isProperTime(st, arg2)
{
	if (typeof(arg2) == "undefined" || !arg2)		{ arg2 = 2; }

	var time										= st.split(":");
	var hours										= parseInt(time[0]);
	var minutes										= parseInt(time[1]);
	var seconds										= parseInt(time[2]);

	var b											= true;

	if (isNaN(hours) || (arg2 == 2 && hours > 23))	{ b = false; }

	if (isNaN(minutes) || minutes > 59)				{ b = false; }

	if (seconds > 59)								{ b = false; } // Seconds not required.

	return b;
}

function bValidateSofinummer(obj)
{
	var reSofi = /(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)/;
	var bValid = false;
	var array;
	var lSom = 0;
	if ((obj.length == 0) || (obj == null)) bValid = true;
	else
	{
		bValid = (reSofi.test(obj));
		if (bValid)
		{
			// 11 proef
			array = (obj.match(reSofi));
			for (var i = 1 ; i < 9; i++) lSom += (array[i] * (9-(i-1)));
			bValid = (((lSom -= array[9]) % 11) == 0)
		}
	}

	return (bValid);
}

function validateBankRek(rek)
{
	var s 		= ("" + rek).replace(/\s/g, "");
	var total 	= 0;
	var j 		= s.length;

	// iban nr
	if 		(j > 10 && j <= 34) { return validateIBAN(s); }
	else if (j == 0)			{ return true; }
	else						{ return false; }
//	else if (j == 9 || j == 10) // banken
//	{
//		for (var i = 0; i < s.length; i++ )
//		{
//			total 	= total + (s.charAt(i) * j);
//			j 		= j - 1;
//		}
//
//		return ((total % 11) == 0)
//	}
//	else if (j <= 7) return true;	// postbank
//	else return false;
}

function validateIBAN(iban) // Zie ook CO.validateIBAN
{
	try
	{
		// controleer het formaat
		if (iban.search("[A-Z]{2,2}[0-9]{2,2}[a-zA-Z0-9]{1,30}") == -1)  return false;

		// zet het nummer om tbv de controle (zet de landcode achteraan en verwijder het controlegetal aan het begin)
		var controle 		= iban.substring(4) + iban.substring(0, 2);

		// vervang de letters door cijfers: A=10 t/m Z=35 en voeg 00 toe aan het einde
		controle 			= controle.replace(/\D/g, function(a){return "" + (a.charCodeAt(0) - 55);}) + "00";

		return ((98 - (new BigDecimal(controle).remainder(new BigDecimal("97"))).intValueExact()) == parseInt(iban.substring(2, 4)));
	}
	catch (e)			{ return false; }
}

function validateBIC(bic)
{
	// controleer het formaat
	if (bic == "") 		{ return true; }
	else
	{
		var m			= bic.match("[A-Z]{6,6}[A-Z2-9][A-NP-Z0-9](?:[A-Z0-9]{3,3}){0,1}");

		if (m == null)	{ return false; }
		else			{ return bic == m[0]; }
	}
}

function twoDecimals(s)
{
	if (s == "") s = "0,00";
	else if (s.indexOf(",") == -1) s = s + ",00";
	else if (s.indexOf(",") == s.length - 2) s = s + "0";
	return s;
}

String.prototype.left = function(str)
{
	var i = this.indexOf(str);
	if (i > -1) return this.substring(0, i);
	else        return this;
}

String.prototype.right = function(str)
{
	var i = this.indexOf(str);
	if (i > -1) return this.substring(i + str.length);
	else        return this;
}

String.prototype.backwardsLeft = function(str)
{
	var i = this.lastIndexOf(str);
	if (i > -1) return this.substring(0, i);
	else        return this;
}

String.prototype.backwardsRight = function(str)
{
	var i = this.lastIndexOf(str);
	if (i > -1) return this.substring(i + str.length);
	else        return this;
}

String.prototype.endsWith = function(str)
{
	return (this.match(str.literal() + "$") == str);
}

var regexpSpecialChars = /([\[\]\^\$\|\(\)\\\+\*\?\{\}\=\!])/gi;
String.prototype.literal = function()
{
	return this.replace(regexpSpecialChars, "\\$1");
}

String.prototype.startsWith = function(str)
{
	return (this.match("^" + str.literal()) == str);
}

String.prototype.trim = function(str)
{
	return (trim(this));
}

String.prototype.backwardsright = function(str)
{
	var i = this.lastIndexOf(str);
	if(i > -1) return this.substring(i + str.length);
	else return this;
}

String.prototype.replaceAt=function(index, replacement)
{
	return this.substr(0, index) + replacement+ this.substr(index + replacement.length);
}

function validateZIPCode(zipcodeitem, countrycodeitem, housenumberitem)
{
	if (mode != MODE_READ)
	{
		var zipcode					= toString($(zipcodeitem).val());
		var countrycode				= toString($(countrycodeitem).val());
		var housenumber				= toString($(housenumberitem).val());
		var maskzipcode				= "";
		var maskzipcodemsg			= "";
		var maskhousenumber			= "";
		var maskhousenumbermsg		= "";
		var validzipcode			= true;
		var validhousenumber		= true;

		if (isNull(housenumberitem.getAttribute("initial_req")))
		{
			housenumberitem.setAttribute("initial_req", housenumberitem.getAttribute("req"));
		}

		if (countrycode.toUpperCase() == "NL" || countrycode.toUpperCase() == "NEDERLAND")
		{
			maskhousenumber			= "\\d+";
			maskhousenumbermsg		= "123";

			housenumberitem.setAttribute("req", housenumberitem.getAttribute("initial_req") == "true" ? "true" : "false");
		}
		else
		{
			maskhousenumber			= "\\d*";
			maskhousenumbermsg		= "";

			housenumberitem.setAttribute("req", "false");
		}

		// [MT-10296]
		if (housenumberitem.getAttribute("req") == "true" && $(housenumberitem).val() == "")
		{
			housenumberitem.setAttribute("req", "false");

			$(housenumberitem).addClass("warning");
		}
		else
		{
			$(housenumberitem).removeClass("warning");
		}

		validzipcode				= ui.validation.isZipcodeValid(zipcode, countrycode);

		if (!validzipcode)
		{
			if (zipcode == "" && zipcodeitem.getAttribute("req") != "true")
			{
				zipcodeitem.setAttribute("valid", "true");
				makeValid(zipcodeitem);
			}
			else
			{
				zipcodeitem.setAttribute("valid", "false");
				zipcodeitem.setAttribute("errmsg", "Postcode moet van de vorm \"" + ui.validation.getZipcodeMask(countrycode) + "\" zijn.");
				setErrorClass(zipcodeitem);
			}
		}
		else
		{
			zipcodeitem.setAttribute("valid", "true");
			makeValid(zipcodeitem);
		}

		if (zipcode == "")
		{
			maskhousenumbermsg		= "";

			if (housenumber == "")
			{
				if (housenumberitem.getAttribute("req") == "true")
				{
					validhousenumber = false;
				}
			}
		}
		else
		{
			if (housenumber == "")
			{
				if (housenumberitem.getAttribute("req") == "true")
				{
					validhousenumber = false;
				}
			}
			else if (maskhousenumber != "")
			{
				var re 					= new RegExp(maskhousenumber, "g");
				var result				= re.exec(housenumber);

				validhousenumber		= (result != null && result[0] == housenumber);
			}
		}

		if (validhousenumber)
		{
			housenumberitem.setAttribute("valid", "true");
			makeValid(housenumberitem);
		}
		else
		{
			housenumberitem.setAttribute("valid", "false");
			if (maskhousenumbermsg) { housenumberitem.setAttribute("errmsg", "Huisnummer moet van de vorm \"" + maskhousenumbermsg + "\" zijn."); }
			setErrorClass(housenumberitem);
		}

		return validzipcode && validhousenumber;
	}
	else return true;
}

function Exception(msg) {
	throw "exception: " + msg;
}

jQuery.fn.outerHTML = function(s) {
    return s
        ? this.before(s).remove()
        : jQuery("<p>").append(this.eq(0).clone()).html();
};

// Reverse "plug-in"
jQuery.fn.reverse = [].reverse;

/**
 * Usage: $(":focus")
 * Gives back whichever element currently has focus.
 */
jQuery.extend(jQuery.expr[':'],
{
    focus: function(element)																												{  return element == document.activeElement;  }
});

jQuery.atsc =
{
	log: {
		//Debug messages: Printed to the log only when testing and debug mode is enabled
		debug: function(txt, ex)
		{
			try
			{
				if (isDebugMode())																											{ jQuery.atsc.log._log("debug", txt, ex); }
			}
			catch (ex)																														{ /* no problem */ }
		},

		//Warning messages: Printed to the log, not shown to the user. Use for glitches that do not affect the user experience much.
		warn: function(txt, ex, dontPrint, isStatusDocumentException)
		{
			jQuery.atsc.log._log("warn", txt, ex, dontPrint, isStatusDocumentException);
		},

		println: function(txt, ex, dontPrint, isStatusDocumentException)
		{
			jQuery.atsc.log._log("println", txt, ex, dontPrint, isStatusDocumentException);
		},

		//Error messages: Always printed to the log, warning shown to user
		error: function(txt, ex, suppressErrorMessage)
		{
			if (isNull(suppressErrorMessage))
			{
				if (!isNull(ex) && typeof(ex) != "object")
				{
					if (ex == true)																											{ suppressErrorMessage = true; }
				}
			}

			jQuery.atsc.log._log("error", txt, ex);

			if (!suppressErrorMessage)
			{
				try
				{
					var ex2																													= txt;
					if (typeof(ex2) == "string")																							{ ex2 = new Error(ex2); }

					if (jQuery.atsc.log._lastFatalErrorMsg != ex2.message)
					{
						jQuery.atsc.log._lastFatalErrorMsg																					= ex2.message;
						alert("Er is een fout opgetreden (" + ex2.message + ")\n\nSluit dit scherm!");

						if (ui.isRecord())																									{ gotoFirst(); }
						else																												{ focusCurrentFrame(false); }
					}
				}
				catch (ex2)																													{ /* no problem */ }
			}
		},

		_lastFatalErrorMsg: null,
		_log: function(type, txt, ex, dontPrint, isStatusDocumentException)
		{
			// [MT-2416] isStatusDocumentException added to prevent an infinite loop (as this method typically leads to a getStatusDocument call).

			if (isNull(ex))
			{
				if ((txt instanceof Error) || (txt && txt.message && txt.stack))
				{
					ex																														= txt;
					txt																														= null;
				}
			}

			var message																														= "";

			if (txt)
			{
				message = message + txt;
				message = message + "\n\t\t";
			}

			if (txt && ex)
			{
				message = message + "\n\t\t";
			}

			var trace;

			if (ex) // Developer provides exception
			{
				var amountToSplice																											= 0;

				if (IE && !ex.stack)
				{
					// [MT-1405] IE10+ builds a stack trace, but only if the exception is an Error object that has been thrown (not received).
					try																														{ throw ex; }
					catch (e)																												{ ex = e; }

					//Remove the first two items from the stack trace (points to the current function and the function that called this one)
					amountToSplice																											= 2;
				}

				try																															{ trace = printStackTrace({e: ex}); }
				catch (ex)																													{ trace = []; }

				if (trace.length > 0)
				{
					if (IE && !ex.stack)
					{
						// IE9 and below do not support stack traces for thrown errors. (This can also occur if IE10+ is running in IE7/IE8/IE9/quirks mode.)

						// Remove the first 4 items from the stack trace, as they will be either referencing $.atsc.log or the printStackTrace library.
						trace.splice(0, 4);
						trace																												= trace.join("\n\t\t\t");

						trace																												= ex.name + ": " + ex.message + "\n\t\t\t" + trace;
					}
					else
					{
						// Strip X items from the stacktrace. See above.
						if (amountToSplice > 0)																								{ trace.splice(1, amountToSplice); }

						trace																												= trace.join("\n\t\t");
					}
				}
				else																														{ trace = ""; }
			}
			else if (type == "println")																										{ trace = ""; }
			else // No exception provided - generate one
			{
				if (IE)
				{
					// [MT-1405] IE10+ builds a stack trace, but only if the exception is an Error object that has been thrown.
					try																														{ throw new Error(txt); }
					catch (e)																												{ ex = e; }
				}
				else																														{ ex = new Error(txt); }

				try																															{ trace = printStackTrace({e: ex}); }
				catch (ex)																													{ trace = []; }

				if (trace.length > 0)
				{
					if (IE && !ex.stack)
					{
						// IE9 and below do not support stack traces for thrown errors. (This can also occur if IE10+ is running in IE7/IE8/IE9/quirks mode.)

						// Remove the first 4 items from the stack trace, as they will be either referencing $.atsc.log or the printStackTrace library.
						trace.splice(0, 4);

						// If the stack trace has more than 4 items, we strip them.
						// This is necessary because a log made by <IE9 will contain a lot of untracable entries.
						// (There are no line numbers or file names, only function names - and most of them are anonymous functions.)
						if (trace.length > 4)																								{ trace.splice(4, trace.length - 4); }
						trace																												= trace.join("\n\t\t\t");

						trace																												= "\t" + trace;
					}
					else
					{
						trace.splice(0, 1); // The first item in the stack trace is the error message itself. We already have that, so remove it.
						trace.splice(0, 2); // The next 2 items are the $.atsc.log calls, we remove them from the stack trace.

						// Uitgeschakeld op 16-04-2014, indien dit geen problemen geeft na 16-06-2014 graag verwijderen a.u.v.
						// If the stack trace has more than 4 items, we strip them.
//						if (trace.length > 4)																								{ trace.splice(4, trace.length - 4); }

						trace																												= trace.join("\n\t\t");
					}
				}
				else																														{ trace = ""; }
			}

			message																															= message + trace;

			var debug;

			try																																{ debug = isDebugMode(); }
			catch (ex)																														{ debug = false; }

			$.atsc._jsdebug(message, (!dontPrint && ((typeof(isStatusDocumentException) != "undefined" && isStatusDocumentException) || debug || type == "println" || type == "warn" || type == "error")), type == "println");
		}
	},

	_jsdebug: function(fullmsg, printAlways, oneLiner)
	{
		var msg																																= $.trim(fullmsg);

		try
		{
			if (!oneLiner)
			{
				var msg																														= "Details:\t" + fullmsg + "\nWindow:\t\t" + window.name + " \"" + JSO.getWindowLocation(window) + "\"";

				if (IE)
				{
					msg																														= msg + "\nBrowser:\tInternet Explorer " + IE;

					if (fullmsg.indexOf("at ") == -1 || fullmsg.indexOf("(http://") == -1)													{ msg = msg + "\nNote that the stack trace may be unintelligible due to a browser limitation."; }
				}
				else if (CHROME)
				{
					try																														{ msg = msg + "\nBrowser:\tGoogle Chrome " + window.navigator.appVersion.match(/Chrome\/(.*?) /)[1]; }
					catch (ex)
					{
						try																													{ msg = msg + "\nBrowser:\tGoogle Chrome iOS " + window.navigator.appVersion.match(/CriOS\/(.*?) /)[1]; }
						catch (ex)																											{ msg = msg + "\nBrowser:\tGoogle Chrome (NO VERSION)"; }
					}
				}
				else if (FF)
				{
					try																														{ msg = msg + "\nBrowser:\tMozilla Firefox " + window.navigator.userAgent.match(/Firefox\/(.*)/)[1] + " (build " + window.navigator.buildID + ")"; }
					catch (ex)																												{ msg = msg + "\nBrowser:\tMozilla Firefox (NO VERSION)"; }
				}
				else if (OP)																												{ msg = msg + "\nBrowser:\tOpera"; }
				else if (SAFARI)																											{ msg = msg + "\nBrowser:\tSafari"; }
				else if (IPAD)																												{ msg = msg + "\nBrowser:\tiPad"; }
				else if (IPHONE)																											{ msg = msg + "\nBrowser:\tiPhone"; }
				else if (MAC)																												{ msg = msg + "\nBrowser:\tMac OS X"; }
				else if (EDGE)																												{ msg = msg + "\nBrowser:\tMicrosoft Edge " + window.navigator.userAgent.match(/Edge\/(.*)/)[1]; }
				else																														{ msg = msg + "\nBrowser:\tUnknown"; }
			}
		}
		catch (ex)																															{ /* no problem */ }

		// Send the message to the server for logging purposes.
		try
		{
			$.ajax({
				url: IOSERVLETURL,
				type: "post",
				data: { "action": JSDEBUG, "msg": msg, "printAlways": printAlways, "oneliner": oneLiner, "IE_VERSION": IE },
				cache: false,
				async: false
			});
		}
		catch (ex)																															{ /* no problem (for us) */ }
	},

	//XML-related functions
	xml:
	{
		/**
		 * Usage: $.atsc.xml.processXMLMessage(xmlDoc)
		 * This assumes the given XML response is of the Java type XMLMessage.
		 * It will show an error if there is one, or a message if there is one.
		 * It will carry out any actions that the XMLMessage Java object supports.
		 * @param xmlDoc The XML document to process.
		 * @returns True if execution of the XML document may continue, false if an error message was displayed.
		 */
		processXMLMessage: function(xmlDoc)
		{
			var error																														= $(xmlDoc).find("error").text();
			if (isNull(error) || error == "")																								{ error = $(xmlDoc).find("error").attr("msg"); }
			if (!isNull(error) && error != "")
			{
				setTimeout(function(){ alert(error); }, 0);
				return false;
			}

			var msg																															= $(xmlDoc).find("msg").text();
			if (isNull(msg) || msg == "")																									{ msg = $(xmlDoc).find("msg").attr("msg"); }
			if (!isNull(msg) && msg != "")
			{
				alert(msg);
			}

			try																																{ processMessagesInXMLMessage(xmlDoc); }
			catch (ex)																														{ /* no problem */ }

			try																																{ processFilesInXMLMessage(xmlDoc); }
			catch (ex)																														{ /* no problem */ }

			//Warning! This part is untested! -JB
			var url																															= $(xmlDoc).find("url").text();
			if (!isNull(url) && url != "")
			{
				showPreviousFrame(false, url);
				return false;
			}

			return true;
		}
	},

	//Common string operations
	string:
	{
		/**
		 * Usage: $.atsc.string.validateRegex(pattern, test)
		 * Validates a given XML string.
		 * @param pattern The pattern to use (w/o encapsulating it)
		 * @param test The string to test
		 * @returns True if there is a direct match, false if not, or null if the pattern was invalid.
		 */
		validateRegex: function(pattern, test)
		{
			if (typeof(pattern) == "string")																								{ pattern = new RegExp(pattern, "ig"); }
			var matches																														= pattern.exec(test);
			return (matches && matches.length > 0 && matches[0] == test);
		},

		/**
		 * Usage (direct): $.atsc.string.toChar(charCode, shiftKey, raltKey)
		 * This converts a given character code to a string.
		 * @param charCode The character code to convert.
		 * @param shiftKey True if shift key has been pressed.
		 * @param raltKey True if the right Alt key has been pressed.
		 * @returns The string representation of the character that matches with the given character code and shift/ralt key combination. May return an empty string if no character could be found.
		 */


		// TH: Dit kan toch niet meer? (denk aan AZERTY toetsenbord)

		toChar: function(charCode, shiftKey, raltKey)
		{
			var c																															= charCode;

			//Numpad .
			if (c == 110)																													{ return "."; }

			//Euro sign
			if (raltKey && c == 53)																											{ return "\u20AC"; }

			if (shiftKey)
			{
				switch (c)
				{
					//Numpad keys
					case 111:																												return "\\";
					case 106:																												return "*";
					case 107:																												return "+";
					case 109:																												return "-";

					//Number keys
					case 49:																												return "!";
					case 50:																												return "@";
					case 51:																												return "#";
					case 52:																												return "$";
					case 53:																												return "%";
					case 54:																												return "^";
					case 55:																												return "&";
					case 56:																												return "*";
					case 57:																												return "(";
					case 48:																												return ")";

					//Misc. keys
					case 190:																												return ">";
					case 186:																												return ":";
					case 188:																												return "<";
					case 189:																												return "_";
					case 191:																												return "?";
					case 192:																												return "~";
					case 219:																												return "{";
					case 220:																												return "|";
					case 221:																												return "}";
					case 222:																												return "\"";
				}
			}
			else
			{
				switch(c)
				{
					//Numpad keys
					case 111:																												return "\\";
					case 106:																												return "*";
					case 107:																												return "+";
					case 109:																												return "-";

					//Misc. keys
					case 190:																												return ".";
					case 186:																												return ";";
					case 188:																												return ",";
					case 189:																												return "-";
					case 191:																												return "/";
					case 192:																												return "`";
					case 219:																												return "[";
					case 220:																												return "\\";
					case 221:																												return "]";
					case 222:																												return "'";
				}
			}

			//Numpad 0 - 9: we reduce by 48 so they end up in the range of character codes for the main number keys.
			if (c >= 96 && c <= 105)																										{ c -= 48; }

			//Out of bounds; we return an empty string.
			if (c < 48 || c > 90)																											{ return ""; }

			//Convert the character to upper or lowercase based on the shift key.
			var character																													= String.fromCharCode(c);
			if (shiftKey)																													{ character = character.toUpperCase(); }
			else																															{ character = character.toLowerCase(); }

			return character;
		}
	},

	//Window-related functions
	window:
	{
		/*
		 * Caveats of frames:
		 *
		 * window.frames["framename"] points to either the (i)frame element or the window, depending on the browser.
		 * Don't use it. Rely on $.atsc.window.getWindowFromIFrame or $.atsc.window.getWindowFromFrame instead.
		 */

		/**
		 * Usage: $.atsc.window.getWindowFromFrame(elem)
		 * @param elem A Frame object.
		 * @returns A Window object for the given frame, or null if the elem parameter is not a valid Frame or IFrame object or if the object does not belong to the current window.
		 */
		getWindowFromFrame: function(elem, wnd)																								{ return jQuery.atsc.window.getWindowFromIFrame(elem, wnd); },

		/**
		 * Usage: $.atsc.window.getWindowFromIFrame(elem)
		 * @param elem An IFrame object.
		 * @returns A Window object for the given iframe, or null if the elem parameter is not a valid Frame or IFrame object or if the object does not belong to the current window.
		 */
		getWindowFromIFrame: function(elem, wnd)
		{
			if (isNull(wnd))																												{ wnd = window; }
			if (typeof(elem) == "string")																									{ elem = wnd.document.getElementById(elem); }

			try
			{
				elem																														= $(elem)[0];
				if (!elem || (elem.tagName != "FRAME" && elem.tagName != "IFRAME"))															{ return; }

				try
				{
					elem.contentWindow.document.domain;
					return elem.contentWindow;
				}
				catch (ex)																													{ return null; }
			}
			catch (ex)																														{ $.atsc.log.warn(ex); return null; }
		},

		/**
		 * Usage: $.atsc.window.getIFrameFromWindow(wnd)
		 * @param wnd A Window object.
		 * @returns An IFrame object for the given window, or null if the wnd parameter is not a valid Window or if the object does not belong to the current window.
		 */
		getIFrameFromWindow: function(wnd)
		{
			try
			{
				if (wnd.name)
				{
					var element																												= $("iframe[name=" + wnd.name + "]");
					if (element.length > 0 && element[0].contentWindow == wnd)																{ return element; }
				}

				throw "";
			}
			catch (ex)
			{
				var iframe																													= null;
				$("iframe").each(function()
				{
					if ($.atsc.window.getWindowFromIFrame(this) == wnd)
					{
						iframe																												= $(this);
						return false;
					}
				});

				return iframe;
			}
		},

		/**
		 * Usage: $.atsc.window.getFrameFromWindow(wnd)
		 * @param wnd A Window object.
		 * @returns A Frame object for the given window, or null if the wnd parameter is not a valid Window or if the object does not belong to the current window.
		 */
		getFrameFromWindow: function(wnd)
		{
			try
			{
				if (wnd.name)
				{
					var element																												= $("frame[name=" + wnd.name + "]");
					if (element.length > 0)																									{ return $("frame[name=" + wnd.name + "]") }
				}

				throw "";
			}
			catch (ex)
			{
				var frame																													= null;
				$("frame").each(function()
				{
					if ($.atsc.window.getWindowFromFrame(this) == wnd)
					{
						frame																												= $(this);
						return false;
					}
				});

				return frame;
			}
		},

		/**
		 * Usage: $.atsc.window.getDimensions()
		 * @desc Gets the width and height for the current window.
		 * @return dictionary A dictionary containing "width" and "height" parameters.
		 */
		getDimensions: function()
		{
			var dimensions																													= { width: 0, height: 0 };
			if (document.documentElement)
			{
				dimensions.width																											= document.documentElement.offsetWidth;
				dimensions.height																											= document.documentElement.offsetHeight;
			}
			else
			{
				dimensions.width																											= window.innerWidth;
				dimensions.height																											= window.innerHeight;
			}

			return dimensions;
		},

		getHost: function()																													{ return window.location.protocol + "//" + window.location.host; },

		getWindowFromDocument: function(doc)																								{ return doc.defaultView; },
		getDocumentFromJQuery: function(jq)																									{ return jq.parents("html").parent()[0]; }
	},

	//Event-related functions
	event: {
		/**
		 * Usage (event):  $.atsc.event.isNavigationKey(e)
		 * @returns True if the specified key is used for navigation, false if not.
		 */
		isNavigationKey: function(e)
		{
			var ctrlKey																														= e.ctrlKey;
			var altKey																														= e.altKey;
			var shiftKey																													= e.shiftKey;
			var which																														= e.which;

			//PgUp/PgDn + Home/End + Arrow Keys
			if (which >= 33 && which <= 40)																									{ return true; }

			//WinKey + ContextMenu
			if (which >= 91 && which <= 93)																									{ return true; }

			//          Shift            Ctrl           Alt            CapsLock       Numlock
			if (which == 16 || which == 17 || which == 18 || which == 20 || which == 144)													{ return true; }

			//          VolDown         VolUp           ScrollLock      Pause
			if (which == 174 || which == 175 || which == 145 || which == 19)																{ return true; }

			//Escape Key
			if (which == 27)																												{ return true; }

			//Ctrl      +          C
			if (ctrlKey && which == 67)																										{ return true; }

			return false;
		},

		/**
		 * Usage: $.atsc.event.isCtrlKeyPressed(e)
		 * Determines if the CTRL key (Mac key for Mac clients) has been pressed.
		 * @param e A jQuery event object to check.
		 * @returns True if the ctrl key was pressed, false if not.
		 */
		isCtrlKeyPressed: function(e)
		{
			if (MAC)															{ return e.metaKey; }
			else																{ return e.ctrlKey; }
		},

		/**
		 * Usage: $.atsc.event.isLeftAlt(e)
		 * Universal detection for the Left Alt Key.
		 * @param e A jQuery event object to check.
		 * @returns True if the left alt key was pressed, false if not.
		 */
		isLeftAlt: function(e)
		{
			//Trident (IE)
			if (IE)
			{
				//redundant check
				if (!e.altKey)																												{ return false; }

				//'normal' logic (pre-IE9 mode)
				var returnval																												= (e.originalEvent ? e.originalEvent.altLeft : e.altLeft);

				if (typeof(returnval) == "undefined")
				{
					try
					{
						//fallback logic (IE9+ mode)
						returnval																											= window.event.altLeft;
					}
					catch (ex)																												{ /* no problem */ }
				}

				//default value is false
				if (typeof(returnval) == "undefined")																						{ returnval = false; }

				return returnval;
			}
			else 																															{ return e.altKey && !e.ctrlKey; }
		},
		isRightAlt: function(e)
		{
			return e.altKey && !$.atsc.event.isLeftAlt(e);
		}
	},

	/**
	 * Usage: $.atsc.isItemVisible(item)
	 * Checks if an item is truly visible on the users' screen, taking parent visibility into account.
	 * @param item The item to check.
	 * @returns True if the item is visible
	 */
	isItemVisible: function(item)
	{
		if (!item)																																{ return false; }
		item																																	= jQuery(item);
		if(item.length == 0)																													{ return false; }

		var visible																																= true;

		visible																																	= visible && (item.css("visibility") != "hidden");
		visible																																	= visible && (item.css("display") != "none");
		visible																																	= visible && (typeof(item.get(0).type) == "undefined" || item.get(0).type != "hidden");

		/*
		if (false)
		{
			var debugmsg																														= "";
			if(typeof(item.get(0).id) == "undefined" || !item.get(0).id)																		{ debugmsg = "[no id]"; }
			else																																{ debugmsg = item.attr("id"); }
			debugmsg																															+= " (" + item.get(0).tagName + "): ";
			debugmsg																															+= visible + " (visibility: " + item.css("visibility") + ", display: " + item.css("display") + ", type: " + item.get(0).type + ")";
			$.atsc.log.debug(debugmsg);
		}
		*/

		if (!visible)																															{ return false; }
		else
		{
			var parentitem																														= item.parent();
			if (parentitem.length > 0 && parentitem.get(0).tagName != "HTML")																	{ return $.atsc.isItemVisible(parentitem); }
			else																																{ return true; }
		}
	},

	/**
	 * Usage: $.atsc.cleanTinyMCE()
	 * Cleans all TinyMCE editors on the current frame.
	 */
	cleanTinyMCE: function()
	{
		try
		{
			var ed;
			for (var id in tinyMCE.editors)
			{
				ed																																= tinyMCE.editors[id];
				ed.startContent																													= tinymce.trim(ed.getContent({format : "raw", no_events: 1}));
			}
		}
		catch (ex)																																{ /* no problem */ }
	},

	/**
	 * Usage: $.atsc.getItemHeight(item)
	 * Obtains the height of the item, even when the item is invisible.
	 */
	getItemHeight: function(item, v)
	{
		if (!item)																																{ return 0; }
		item																																	= jQuery(item);
		if(item.length == 0 || !item[0])																										{ return 0; }

		v																																		= false;

//		if (item[0].name == "Afleverbonregels")
//		{
//			v = true;
//		}

		try
		{
			var heightIsRelative																												= item[0].style.height == "" || item[0].clientHeight == 0 || item[0].style.height.endsWith("%");
			var canDetermineHeight																												= !heightIsRelative || $.atsc.isItemVisible(item);

//			$.atsc.log.println("item: " + item[0].className + "/" + item[0].id + ": " + item.css("display") + " / " + item[0].style.height + ": " + canDetermineHeight);

			if (canDetermineHeight)																												{ ret = item[0].clientHeight; }
			else
			{
				var currentHeight																												= item[0].style.height;

				currentHeight																													= parseFloat(currentHeight.substring(0, currentHeight.length - 1));

				var parentHeight																												= jQuery.atsc.getItemHeight(item.parent(), v);

				if (parentHeight > $(window).height() && item.parent().height() > 0)
				{
					parentHeight = item.parent().height();
				}

				if (v)																															{ alert("current (" + item[0].tagName + ", " + item[0].className + "): " + currentHeight + " (raw: " + item[0].style.height + ", " + item[0].clientHeight + "), parentHeight: " + parentHeight + " or " + item.parent().height() + "\n" + (isNaN(currentHeight) ? parentHeight : Math.round((parentHeight / 100) * currentHeight))); }

				ret																																= isNaN(currentHeight) ? parentHeight : Math.round((parentHeight / 100) * currentHeight);
			}

			var wh																																= $(window).height();

			if (wh > 0)																															{ ret = Math.min( wh, ret ); }

			var sh																																= screen.height;

			if (sh > 0)																															{ ret = Math.min( sh, ret ); }

			return Math.max(0, ret);
		}
		catch (ex)
		{
			var itemStr;
			var itemStr2;
			var itemStr3;

			try																																	{ itemStr = (item.length ? item.attr("id") : item) + " (" + item.length + ")"; }
			catch (ex2)																															{ itemStr = item; }

			try																																	{ itemStr2 = item[0]; }
			catch (ex2)																															{ itemStr2 = null; }

			try																																	{ itemStr3 = item[0].style; }
			catch (ex2)																															{ itemStr3 = null; }

			// [MT-7188]
			// $.atsc.log.warn("getItemHeight error, returning 0, item = " + itemStr + ", " + itemStr2 + ", " + itemStr3, ex);

			return 0;
		}
	}
};

//JB: This function should be deprecated in favor of $.inArray()
Array.indexOf																																	= function(arr, obj)
{
	for(var i=0; i<arr.length; i++)
	{
		if(arr[i]==obj)																															{ return i; }
	}

	return -1;
};

Object.size																																		= function(obj)
{
	var size = 0, key;
	for (key in obj)
	{
		if (obj.hasOwnProperty(key))																											{ size++; }
	}

	return size;
};

/**
 * Checks if a given variable is null.
 * @param v The variable to check.
 * @param strict Set this to true to allow empty strings and zero integers to pass.
 * @returns True if the variable is undefined, null, an empty string or zero (0).
 */
function isNull(v, strict)
{
	if (typeof(strict) == "undefined")																											{ strict = false; }

	return typeof(v) == "undefined" || v == null || (!strict && (v == "" || v == 0));
}

function isFunction(v)
{
	return typeof(v) == "function";
}

//JB: Is this function still needed at all?
(function($){
	$.fn.isSelectable = function()
	{
		return true;
		//return this.attr("unselectable") == "on" || this.css("MozUserSelect") == "none";
	};
})( jQuery );

function getEvent(event)
{
	if (!IE || event != null) return event;
	else return window.event;
}

function getElement(event)
{
	var returnval			= null;
	if (event != null)
	{
		if (typeof(event.target) != "undefined")			{ returnval = event.target }
		else												{ returnval = event.srcElement; }
	}

	return returnval;
}

function processXMLDoc(urlstr, func, noAlert, async)
{
	var xmlDoc;

	if (async == null) async = false; // TH: check b.v. bon, laatste regel in specificInit is isChanged = false, dit klopt niet meer als async = true (waarschijnlijk dan ook problemen met functies die in beforessave worden aangeroepen)

	try			{ urlstr += "&mode=" + mode; }
	catch (e)	{ /* no problem */ }

	var request = getXMLHttpRequest();
	if (async)
	{
		request.onreadystatechange = function()
		{
			if (request.readyState == 4)
			{
				xmlDoc = executeFuntion(request, func, noAlert);
			}
		}
	}

	try
	{
		request.open('GET', urlstr, async);
		request.send(null);

		if (!async)
		{
			xmlDoc = executeFuntion(request, func, noAlert);
		}
	}
	catch (e) {/*alert(e.message);*/} // Let op met alert, je kunt dan de melding: "Het downloaden van de opgegeven bron is mislukt" krijgen

	return xmlDoc; // wordt gebruikt in uitable.js
}

function executeFuntion(request, func, noAlert)
{
	var resp 	= request.responseText;

	var xmlDoc	= JSO.stringToXml(resp);

	if (xmlDoc.documentElement != null)
	{
		if (func != null)
		{
			if (isFunction(func))
			{
				func(xmlDoc);
			}
			else
			{
				eval(func + "(xmlDoc)");
			}
		}
	}
	else if (!noAlert) alert(MSG_CONNECTION_SERVER_LOST);

	return xmlDoc;
}

function getXMLHttpRequest()
{
	var xhr = null;

	try
	{
		xhr = new XMLHttpRequest(); //IE7+ with and Non-IE
	}
	catch (e)
	{
		xhr = new ActiveXObject("Microsoft.XMLHTTP"); //IE with ActiveX enabled
	}

	return xhr;
}

// Niet meer gebruikt??
function getNodeValue(maintag, i, childtag, xmlDoc)
{
	var value = "";
	var main = xmlDoc.getElementsByTagName(maintag)[i];
	if (main != null)
	{
		var mcn = main.childNodes;
		var mcnl = mcn.length;
		for (j=0;j<mcnl;j++)
		{
			var cn = mcn[j];
			if (cn.nodeType != 1) continue;
			if (cn.nodeName == childtag)
			{
				if (cn.firstChild != null) value = cn.firstChild.nodeValue;
			}
		}
	}
	return value;
}

function getTag(name,xmlDoc)
{
	tags = xmlDoc.getElementsByTagName(name);
	return tags[0];
}

//idem als getParam, maar nu wordt de variabele url lowercase gemaakt
function getParam2(param)
{
	param = param.toLowerCase();
	var url = window.location.search.toLowerCase();
	var stparam = url.indexOf(param + "=");
	var res = "";
	if (stparam > -1)
	{
		var enparam = url.indexOf("&", stparam + param.length);
		if (enparam>-1) res = url.substr(stparam + param.length + 1, enparam - (stparam + param.length + 1));
		else res = url.substr(stparam + param.length + 1);
	}
	return res;
}

function getParam(param)
{
	param = param.toLowerCase();
	var url = window.location.search;

	var stparam = url.indexOf(param + "=");
	var res = "";
	if (stparam > -1)
	{
		var enparam = url.indexOf("&", stparam + param.length);
		if (enparam>-1) res = url.substr(stparam + param.length + 1, enparam - (stparam + param.length + 1));
		else res = url.substr(stparam + param.length + 1);
	}
	return res;
}

function getParameter(param, decode)
{
	var url					= window.location.search;
	var stparam				= url.indexOf(param + "=");
	var res					= "";

	if (stparam > -1)
	{
		var enparam			= url.indexOf("&", stparam + param.length);
		if (enparam > -1)	{ res = url.substr(stparam + param.length + 1, enparam - (stparam + param.length + 1)); }
		else				{ res = url.substr(stparam + param.length + 1); }
	}

	if (decode)				{ return decodeURIComponent(res); }
	else					{ return res; }
}

function getHashParameter(param)
{
	var url					= window.location.hash;
	var stparam				= url.indexOf(param + "=");
	var res					= "";

	if (stparam > -1)
	{
		var enparam			= url.indexOf("&", stparam + param.length);
		if (enparam > -1)	{ res = url.substr(stparam + param.length + 1, enparam - (stparam + param.length + 1)); }
		else				{ res = url.substr(stparam + param.length + 1); }
	}
	return res;
}

function getCheckedItemFromElements(el)
{
	var b = false;
	var j = 0;

	try
	{
		var ell = el.length;

		while(!b && j < ell)
		{
			if(el[j].checked)
			{
				b = true;
				return el[j];
			}
			j++;
		}
	}
	catch(e)
	{
		return null;
	}
	if(!b) return null;
}


function replaceAll(str, from, to)
{
	if (isNull(str))		{ str = ""; } // [MT-3313]
    var idx = str.indexOf( from );

    while (idx > -1)
    {
        str = str.replace(from, to);
        idx = str.indexOf(from );
    }

    return str;
}

function corr(v)
{
	var vc = v;

	if (vc) // [MT-10514]
	{
		vc = vc.replace(/\+/g, "_<plus>_");
		vc = vc.replace(/\\/g, "_<backslash>_");
		vc = vc.replace(/\%/g, "_<percent>_");
		vc = vc.replace(/\&/g, "_<ampercent>_");
		vc = vc.replace(/\,/g, "_<comma>_");
		vc = vc.replace(/\'/g, "_<quote>_");
		vc = vc.replace(/#/g, "_<hash>_");

		vc = encodeURIComponent(vc);
	}

	return vc;
}

function transformXMLValue2Normal(v)
{
	var vc = v;

	vc = vc.replace(/_<ampercent>_/g, "&");
	vc = vc.replace(/_<percent>_/g, "%");
	vc = vc.replace(/_<plus>_/g, "+");
	vc = vc.replace(/_<backslash>_/g, "\\");
	vc = vc.replace(/_<quote>_/g, "'");
	vc = vc.replace(/_<comma>_/g, ",");
	vc = vc.replace(/_<tab>_/g, "\t");
	vc = vc.replace(/&lt;/g, "<");
	vc = vc.replace(/&gt;/g, ">");

	return vc;
}

/*
* fieldId: The HTML id of the field
* state: Enabled field (false); Disabled field (true)
* empty: Empty field (true); Do not empty field (false)
* req: Field is required (true); Field is not required (false)
* check: Whether the checkSpecial event should be triggered (true/false); ONLY TRIGGERS WHEN THE FIELD ISN'T READ-ONLY!!!
* nr: -
*/
function swapReadOnlyAndEmpty(fieldId, state, empty, req, check, nr)
{
	try
	{
		if (check == null)													{ check = true; }

		var $fld															= fieldId;

		if (typeof($fld) == "string")										{ $fld = $("#" + fieldId); }
		else																{ $fld = $($fld); }

		if ($fld.length > 0)
		{
			var $fldi														= $("#i" + $fld.attr("id"));
			var $fldt														= $("#t" + $fld.attr("id"));

			if ($fld.is("input[type=radio]") && nr == null)
			{
				var r														= $fld.parents("form").first().get(0).elements[$fld.attr("name")];

				for (var i = 0; i < r.length; i++)							{ swapReadOnlyAndEmpty(fieldId, state, empty, req, check, i); }
			}
			else
			{
				if ($fld.is("input[type=radio]"))							{ $fld = $($fld.parents("form").first().get(0).elements[$fld.attr("name")][nr]); }

				if (mode == MODE_READ)										{ state = true; }

				if (typeof(empty) != "undefined" && empty)
				{
					if ($fld.is("input[type=radio],input[type=checkbox]"))	{ $fld.prop("checked", false); }
					else													{ $fld.val(""); }
				}

				if (req != null)											{ $fld.attr("req", (req ? "true" : "false")); }

				// Backwards compatibility with code that still uses CSS "visibility:hidden;" instead of "display:none;"
				// Disabled.

				if ($fldi.length)											{ $fldi.show(); }
				if ($fldt.length)											{ $fldt.show(); }

				if (typeof(state) == "undefined")							{ state = isReadOnly($fld[0]); }

				if (MT_THEME === "atscmaterial" && !ISKLASSIEK)
				{
					$fld.parent().removeClass("readonly");
				}

				$fld.removeClass("readonly");

				if (state)
				{
					var $upl												= $("#UL" + $fld.attr("id"));

					if ($upl.length)
					{
						$upl.hide();

						var $upim											= $("#im" + $fld.attr("id"));
						if ($upim.length)									{ $upim.hide(); }
					}

					if ($fld.is("input[type=radio]"))						{ $fld.prop("disabled", true); }

					if (MT_THEME === "atscmaterial" && !ISKLASSIEK)
					{
						$fld.parent().addClass("readonly");
					}

					$fld.addClass("readonly");
					$fld.prop("readonly", true);

					if ($fldi.length && !$upl.length)						{ $fldi.hide(); }
					if ($fldt.length)										{ $fldt.hide(); }

					$fld.parent().find("i[data-allow-readonly=false]").hide();

					if (empty)												{ $fld.attr("fuidvalue", ""); }

					$fld.attr("valid", "true");
				}
				else
				{
					if (getFType($fld[0]) != "UL")
					{
						$fld.prop("readonly", false);

						var $upl											= $("#UL" + $fld.attr("id"));

						if ($upl.length)
						{
							$upl.show();

							$("#im" + $fld.attr("id")).show();
						}

						if ($fld.is("input[type=radio]"))					{ $fld.prop("disabled", false); }
					}

					// fld.oldQmapValue = "_<null>_"; // TH: Als je dit doet dan worden opgezochte waarden die je zelf veranderd weer overschreven, zie omschrijving van klantenbestelregel, onderstaande code voor in de plaats gezet (alleen controle op required)
					if ($fld.attr("req") == "true" && $fld.val() == "")		{ $fld.attr("valid", "false"); }

					$fld.parent().find("i[data-allow-readonly=false]").show();

					if (getFType($fld[0]) == "CP")							{ $fld.colorpicker("option", "showNoneButton", ($fld.attr("req") != "true")); }

					if (check)												{ checkItem($fld); }
				}

				setErrorClass($fld[0]);
			}
		}
		else
		{
			$.atsc.log.warn("field: " + fieldId + " not found");
		}
	}
	catch (ex)																{ $.atsc.log.warn("field: " + fieldId, ex); }
}
// Wordt gebruikt in EBV
//------------------------------------
// aangepast op 14-04-2005
// Nu kunnen ook waardes meegegeven worden.
function makeFloat(input, val)
{
	try
	{
		if (val == null || !val)
		{
			var value = document.getElementById(input).value.replace(",",".");
			if (value != "") return parseFloat(value);
		}
		else
		{
			if(input != "") return parseFloat(input.replace(",", "."));
		}

		return 0;
	}
	catch(e) { return 0; }
}

function fixed(a, scale)
{
	if (!scale) { scale = 2 }

	var a = ""+a;

	return parseFloat(a.replace(",", ".")).toFixed(scale).replace(".",",");
}

// Wordt gebruikt in EBV
//----------------------------------
// @param element waarin de velden op readonly gezet moeten worden,
//        of null, dan wordt het complete scherm genomen.
// @param empty : boolean : geeft aan of de velden leeggegooid moeten worden.
function makeAllReadOnly(elem, empty)
{
	var elems  = null;
	if (elem  == null)
	{
		elems = document.forms[0].elements;
	}
	else elems = elem.getElementsByTagName("*");

	if (empty == null) empty = false;

//	var elems  = elem.getElementsByTagName("*");
	var elemsl = elems.length;
	for (var i = 0; i < elemsl; i++)
	{
		if (elems[i].id) try { swapReadOnlyAndEmpty(elems[i].id, true, empty); } catch (e) {}
	}
}

function getAttribute(item, attribute)
{
	var v = null;

	try
	{
		v = item.getAttribute(attribute);
	}
	catch (e) {}

	try
	{
		if (v == null) v = item[attribute];
	}
	catch (e) {}

	return v;
}

function isContentEditable(item)
{
	try
	{
		// Workaround for CodeMirror fields.
		if ($(item).data("cm") && ($($(item).data("cm").getTextArea()).is("[readonly]") || $($(item).data("cm").getTextArea()).hasClass("readonly")))
		{
			return false;
		}
	}
	catch (ex)									{ /* no problem */ }

	try { return item.contentEditable != null && item.contentEditable != false && !item.readOnly; }
	catch (ex){ return false; }
}

function getRadioValue(radioField)
{
	var radios					= document.forms[0].elements[radioField];
	var tmp						= "";
	if (radios != null)
	{
		if (!radios.length) //er is maar 1 optie, dus geen array
		{
			radios					= document.getElementById(radioField+ "_0");
			if (radios != null)
			{
			    if (radios.checked)	tmp		= radios.value;
			}
		}
		else
		{
			tmp					= getCheckedItemFromElements(radios);
			if(tmp != null) tmp			= tmp.value; else tmp = "";
		}
	}

	return tmp;
}
/* wellicht deze nieuwe gaan gebruiken ipv bovenstaande
function getRadioValue(radioField)
{
	return $("input[name=" + radioField + "]:checked").val();
}
*/

function sendEmptyRequest()
{
	if (window.location.protocol == 'https:')
	{
		try
		{
			var request = getXMLHttpRequest();
			request.open('GET', "/IOServlet?action=-1", false);
			request.send(null);
		}
		catch (e) {}
	}
}

var actionlock = {};
function _CheckActionLock()
{
	var timestamp = new Date().getTime();
	for (var obj in actionlock)
	{
		for (var lockname in actionlock[obj])
		{
			if (actionlock[obj][lockname] <= timestamp)
			{
				delete actionlock[obj][lockname];
			}
		}
	}
}

/**
 * This function allows you to prevent code from executing for a given duration of time.
 *
 * @param obj (optional) An object to associate the lock with. Send <code>null</code> for global locks, though binding it to an object is preferred.
 * @param lockname A <code>String</code> containing the name of the lock. This may be anything you want, though it's advisable to make it descriptive enough.
 * @param timeout (optional) A timeout to set the lock for. If you do not specify this then this function will only check if a lock exists.
 * @param deleteIfExists (optional) Determine if an existing lock must be removed, even if it's timeout hasn't expired yet.
 * @returns <code>true</code> if the lock was already set. In this case the <code>timeout</code> parameter is ignored. <code>false</code> if the lock wasn't set. If a timeout was provided then the lock will now be set to the specified timeout.
 */
function ActionLock(obj, lockname, timeout, deleteIfExists)
{
	if (isNull(obj))								{ obj = "_<null>_"; }

	if (isNull(actionlock[obj]))					{ actionlock[obj] = {}; }
	_CheckActionLock();

	if (!isNull(actionlock[obj][lockname]))
	{
		if (deleteIfExists)							{ delete actionlock[obj][lockname]; }

		return true;
	}

	if (timeout > 0)								{ actionlock[obj][lockname] = new Date().getTime() + timeout; }

	return false;
}

function itemValueToFloat(o)
{
	var ret											= NaN;
	var item;

	if (typeof(o) === "string") 					{ item = $("#" + o); }
	else											{ item = $(o); }

	if (item.length > 0)							{ ret = parseFloat(item.val().replace(",", ".")); }

	return ret;
}

function itemValueToInt(o)
{
	var ret											= NaN;
	var item;

	if (typeof(o) === "string") 					{ item = $("#" + o); }
	else											{ item = $(o); }

	if (item.length > 0)							{ ret = parseInt(item.val().replace(",", ".")); }

	return ret;
}

function toString(o)
{
//	if (typeof(o) == "undefined")					{  return ""; }
//
//	var ret											= o + "";
//	if (typeof(ret) != "string")					{ ret = ""; }
//
//	return ret;

//	try { if (ISTEST) { $.atsc.log.warn("WORDT NOG GEBRUIKT!!! HIER MOET JE JSO.TOSTRING() GEBRUIKEN!!!"); } }
//	catch (e) { /* no problem */ }

	return JSO.toString(o);
}

function toInt(o)
{
	try
	{
		var res										= parseInt(JSO.toString(o));

		if (isNaN(res))								{ res = 0; }

		return res;
	}
	catch (ex)										{ return 0; }
}

function itemValueToString(o)
{
	var ret											= NaN;
	var item;

	if (typeof(o) === "string") 					{ item = $("#" + o); }
	else											{ item = $(o); }

	if (item.length > 0)							{ ret = item.val(); }

	if (!ret || isNull(ret))						{ ret = ""; }

	return ret;
}

/*
 * Frameset-based asynchronous event processing
 *
 * Problem: Some Javascript functionality, such as the use of Ajax, and some custom
 * functionality, such as the use of ui.window.checkFrame, is done asynchronously.
 * Events are typically executed synchronously, preventing such functions from being
 * used without having to perform manual calls.
 *
 * Another issue is that hand-written events are currently only executed on a specific
 * frame, usually the current frame or its direct parent. This ends up being confusing
 * especially when the event is executed on another frame than the current one.
 *
 * This functionality allows custom events to be bound to named events, and to call those
 * events. The standardardized jQuery event object is used to provide a familiar concept.
 *
 * Usage:
 *
 * ui.events.bind(eventName, handler, insertBefore)
 *      Call this function in order to bind a handler to the event.
 *
 *      Note that multiple handlers may be bound to a single event. They will be
 *      executed sequentially in the same order that they were added.
 *
 *      If the eventName in question is ever executed synchronously instead of asynchronously
 *      then any handlers bound to it must be synchronous as well.
 *
 *      Arguments:
 *           eventName:     The name of the event to bind the function to. This must be a unique
 *                          name per type of event.
 *
 *           handler:       A JavaScript function to execute when the event is called.
 *                          This must be a function that accepts two arguments: the  event
 *                          object (e) and a function to continue processing the event (processEvent).
 *                          Failure to call the processEvent function will cause the event to stop
 *                          being processed, and the original caller will not receive input.
 *
 *                          Use e.preventDefault() on the event object to indicate that the event
 *                          should not be processed by the parent.
 *
 *                          Use e.stopPropagation() to stop further event handlers from being called.
 *                          The event will not be processed further and the caller will be notified
 *                          that the event has finished.
 *
 *                          Use e.stopImmediatePropagation() to stop further event handlers on the
 *                          same frame from being called. This will cause the event to bubble up to
 *                          the parent frame.
 *
 *           insertBefore:  Insert the event at the top of the event handlers for the current window.
 *
 *     Returns:
 *           An integer representing a unique id for the given event handler. This can later
 *           be used to unbind the event.
 *
 *     Example:
 *           ui.events.bind("myEvent", function(e, processEvent, srcWindow)
 *           {
 *           	//do something, possibly asynchronously
 *
 *           	if (srcWindow == window && 1 == 2)
 *           	{
 *           		//If 1 == 2 then we call stopPropagation to prevent other event handlers
 *           		//from being called.
 *           		e.stopPropagation();
 *           	}
 *
 *             //continue processing the event
 *             //if the stopPropagation above was called then the caller will be notified that
 *             //the event has finished, otherwise the next event handler will be called
 *	           processEvent();
 *           });
 *
 * ui.events.unbind(eventName, id)
 *     Remove an event handler from the stack.
 *
 *     Arguments:
 *           eventName: The name of the event to unbind the function from. This must be the
 *                      same as what was provided in the ui.events.bind function.
 *           id:        The unique id returned by ui.events.bind
 *
 *     Returns:
 *           None.
 *
 * ui.events.call(eventName, callback, data, async, bubbles)
 *     Calls the given event on the current frame, bubbling upwards as needed.
 *
 *     Arguments:
 *           eventName: The name of the event to execute.
 *           callback:  A callback function to execute when the event has finished.
 *           data:      An optional object to pass to the event object which will be exposed
 *                      to all event handlers.
 *           async:     If 'true', the call is made asynchronously. If 'false', the call is
 *                      made synchronously. In the latter case all callbacks for the given
 *                      eventName MUST NOT call asynchronous functions (such as setTimeout
 *                      or asynchronous Ajax calls).
 *           bubbles:   If 'true' the event bubbles upward, if 'false' the event stays on the
 *                      same frame.
 *
 *     Returns:
 *           If async is true: Nothing.
 *           If async is false: The final event object after it has passed through all the
 *           configured callbacks.
 *
 *           In both cases the callback function is called when the event has finished.
 *
 *     Example #1:
 *           ui.events.call("myEvent", function(e)
 *           {
 *               if (!e.isDefaultPrevented())
 *               {
 *               	//perform a default action
 *               }
 *           }, { "param1": "value1", "param2": "value2" });
 *
 *     Example #2:
 *           ui.events.call("myEvent", null, { "param1": "value1", "param2": "value2" });
 *
 *     Example #3:
 *           var ev = ui.events.call("myEvent", null, { "param1": "value1", "param2": "value2" }, false);
 *           if (!ev.isDefaultPrevented())
 *           {
 *               //perform a default action
 *           }
 *
 */

ui.addItemToSkip("events");

ui.events = {
	DEBUG: false,
	_events: {},
	_eventId: 0,
	bind: function(eventName, callback, insertBefore)
	{
		if (ui.events.DEBUG)						{ $.atsc.log.println("ui.events: Binding a callback to " + eventName); }// + "\nCallback follows:\n\t" + callback); }

		//Generate a unique id, add the callback to ui.events._events[eventName], and return the generated id.
		if (!ui.events._events[eventName])			{ ui.events._events[eventName] = []; }

		var id										= ++ui.events._eventId;
		var ev										= {"id": id, "callback": callback};

//		var callbackString							= callback + "";
//		var pos										= callbackString.indexOf("srcWindow");
//		if (pos != -1)								{ pos = callbackString.indexOf("srcWindow", pos + 1); }
//		if (pos == -1)								{ $.atsc.log.warn("LET OP: ui.events.bind aangeroepen zonder srcWindow controle!\n\nEvent: " + eventName + "\n\n" + callback); }

		if (insertBefore && ui.events._events[eventName].length > 1)
		{
			var l									= ui.events._events[eventName].length;

			ui.events._events[eventName].push(ev);

			for (var i = l; i >= 0; i--)			{ ui.events._events[eventName][i + 1] = ui.events._events[eventName][i]; }

			ui.events._events[eventName][0]			= ev;
		}
		else										{ ui.events._events[eventName].push(ev); }

		return id;
	},

	unbind: function(eventName, id)
	{
		//Remove the event from ui.events._events[eventName] by using the id to search for the item.
		if (!ui.events._events[eventName])			{ ui.events._events[eventName] = []; }

		if (typeof(id) == "undefined")
		{
			ui.events._events[eventName]			= [];

			if (ui.events.DEBUG)					{ $.atsc.log.println("ui.events: Unbinding all callbacks to " + eventName); }// + "\nCallback follows:"); }
		}
		else
		{
			var f;
			for (var i = 0; i < ui.events._events[eventName].length; i++)
			{
				f									= ui.events._events[eventName][i];

				if (f.id == id)
				{
					if (ui.events.DEBUG)			{ $.atsc.log.println("ui.events: Unbinding a callback to " + eventName); } // + "\nCallback follows:"); $.atsc.log.debug(f.callback); }

					ui.events._events[eventName].splice(i, 1);

					break;
				}
			}
		}
	},

	/**
	 * Usage: ui.events.call(eventName, callback)
	 * Usage: ui.events.call(eventName, callback, data)
	 * Usage: ui.events.call(eventName, callback, data, async)
	 * Usage: ui.events.call(eventName, callback, data, async, bubbles)
	 *
	 * Arguments:
	 * * eventName: The name of the event to call. This may be anything, but please follow the "ui." naming guidelines.
	 * * srcWindow: The window which the event occurs on. This must be checked by anything binding the event.
	 * * callback:  A function object that will be called when the event has finished execution. It accepts one argument: the event object after being processed.
	 * * data:      Optional. A variable to pass through to the event object, which may be subsequently read by its handlers.
	 *              Typical usage is to pass a dictionary object. This allows for future expansion as it can store more than one variable.
	 * * async:     True if the event is meant to be processed asynchronously, false to process synchronously. If false the finalized event object is returned immediately.
	 *              Default: true
	 * * bubbles:   True if the event should bubble upwards, false to keep the event on the current frame ONLY.
	 *              Please note that you can override this behavior and prevent the event from bubbling upward if you use e.stopPropagation within an event handler.
	 *              Default: true
	 */
	call: function(eventName, srcWindow, callback, data, async, bubbles)
	{
		//Creates an event object, populates it with the data provided (optional) and passes it to the internal _call function.
		try
		{
			if (typeof(async) == "undefined")		{ async = true; }
			if (typeof(bubbles) == "undefined")		{ bubbles = true; }
			if (typeof(srcWindow) == "undefined")	{ srcWindow = window; }

			var e									= $.Event(eventName);
			if (data)								{ e.data = data; }
			e.callbacks								= [];

			if (async)								{ ui.events._call(e, callback, bubbles, srcWindow); }
			else
			{
				var finalEventObject				= null;

				ui.events._call(e, function(e)
				{
					finalEventObject				= e;

					if (isFunction(callback))		{ callback(e); }
				}, bubbles, srcWindow);

				if (!finalEventObject || isNull(finalEventObject.finished) || !finalEventObject.finished)
				{
					$.atsc.log.warn("WARNING: Event \"" + eventName + "\" was called synchronously, but there seem to be one or more callbacks that are using asynchronous functionality (such as Ajax and setTimeout).\nYou should search the code for the following string:\n\tui.events.bind(\"" + eventName + "\"" );
				}

				return finalEventObject;
			}
		}
		catch (ex)									{ $.atsc.log.error(ex); }
	},

	_call: function(e, callback, bubbles, srcWindow)
	{
		//Obtains a list of callbacks in the ui.events._events[eventName] list. (e.type = eventName)
		//Calls _process on the first element.
		//Calls the internal function _finish when no callbacks are available.
		try
		{
			if (!ui.events._events[e.type])
			{
				if (ui.events.DEBUG)				{ $.atsc.log.println("ui.events: Tried to call event \"" + e.type + "\", but no events of that type are bound to  \"" + JSO.getWindowLocation(window) + "\"."); }

				ui.events._finish(e, callback, bubbles, srcWindow);
			}
			else
			{
				if (ui.events.DEBUG)				{ $.atsc.log.println("ui.events: Processing event \"" + e.type + "\" on \"" + JSO.getWindowLocation(window) + "\"."); }

				ui.events._process(e, callback, ui.events._events[e.type], 0, bubbles, srcWindow);
			}
		}
		catch (ex)									{ $.atsc.log.error(ex); }
	},

	_process: function(e, callback, queue, index, bubbles, srcWindow)
	{
		//Executes an element within a given list, looping forward until there's nothing more to call.
		//Calls the internal function _finish when completed.

		function icallback()
		{
			if (e.isImmediatePropagationStopped() || e.isPropagationStopped() || (index + 1) >= queue.length)
			{
				ui.events._finish(e, callback, bubbles, srcWindow);
			}
			else
			{
				ui.events._process(e, callback, queue, index + 1, bubbles, srcWindow);
			}
		}

		try
		{
			if (queue[index])
			{
				queue[index].callback(e, icallback, srcWindow);
			}
			else
			{
				icallback();
			}
		}
		catch (ex)
		{
			if (!isAccessDeniedError(ex) && !scriptUnloadedError(ex))			{ $.atsc.log.error(ex); }

			icallback();
		}
	},

	_finish: function(e, callback, bubbles, srcWindow)
	{
		var parentOK;

		try											{ parentOK = window.parent != window && window.parent != null && typeof(window.parent.ui) != "undefined" && typeof(window.parent.ui.events) != "undefined"; }
		catch (ex)									{ parentOK = false; }

		//Calls the internal function ui.events._call on the parent frame.
		//Calls the callback function provided to the original call to the ui.events.call() method if this is the top window
		//or if the parent does not have an ui.events container.
		if (!bubbles || !parentOK || e.isPropagationStopped())
		{
			e.finished								= true;

			if (e.callbacks && e.callbacks.length > 0)
			{
				for (var i = 0; i < e.callbacks.length; i = i + 1)
				{
					try								{ e.callbacks[i](e); }
					catch (ex)						{ $.atsc.log.warn(ex); }
				}
			}

			if (callback != null)					{ callback(e); }
		}
		else
		{
			//Recreate the event object. This needs to be done because if e.stopImmediatePropagation was called
			//it will return true on the next iteration which is undesired.
			//Other variables should be carried over to the new event object.
			var ne									= $.Event(e.type);
			ne.data									= e.data;
			ne.callbacks							= e.callbacks;

			if (e.isDefaultPrevented())				{ ne.preventDefault(); }

			//Attempt to find the previous frame in the UIFrameset chain of frames and execute the event on that frame.
			var found								= false;
			try
			{
				var windowName						= wt.jQuery.atsc.window.getFrameFromWindow(window).attr("name");
				var windowId						= parseInt(windowName.substring(1));

				if (windowId > 0)
				{
					var newFrame					= wt.getFrame("w" + (windowId - 1));

					if (ui.events.DEBUG)			{ $.atsc.log.println("ui.events: Bubbling up to the previous frame (w" + (windowId - 1) + ")."); }

					newFrame.ui.events._call(ne, callback, bubbles, srcWindow);
					found							= true;
				}
			}
			catch (ex)								{ /* no problem */ }

			//If we're at the topmost frame in UIFrameset ("w0") or if the concept of UIFrameset is not being used
			//in this project, continue event execution on window.parent.
			if (!found)
			{
				if (ui.events.DEBUG)				{ $.atsc.log.println("ui.events: Bubbling up to the parent frame."); }

				window.parent.ui.events._call(ne, callback, bubbles, srcWindow);
			}
		}
	}
};

function whoHasFocus(timeout)
{
	function askQuestion()
	{
		var elem										= document.activeElement;
		var wnd;

		if (elem.tagName == "FRAME")					{ wnd = $.atsc.window.getWindowFromFrame(elem); }
		else if (elem.tagName == "IFRAME")				{ wnd = $.atsc.window.getWindowFromIFrame(elem); }

		if (wnd)										{ wnd.whoHasFocus(-1); }
		else
		{
			alert(elem + " (" + elem.tagName + ")" + ": " + $(elem).attr("id"));
		}
	}

	if (timeout > -1)									{ setTimeout(function(){ askQuestion(); }, timeout); }
	else												{ askQuestion(); }
}

function selectElement(element)
{
	if (window.getSelection)
	{
		var sel											= window.getSelection();
		sel.removeAllRanges();

		var range										= document.createRange();

		range.selectNodeContents(element);
		sel.addRange(range);
	}
}

function getTextSelection(element)
{
	if (window.getSelection)
	{
		return window.getSelection().toString();
	}
}

function removeTextSelection(element)
{
	if (window.getSelection)
	{
		var sel											= window.getSelection();

		sel.removeAllRanges();
	}
}

jQuery.fn.setSelectable = function(s)
{
	if (s)
	{
		if (IE == 9)									{ $(this).removeAttr("unselectable"); }

		$(this).css({
			"-webkit-user-select": "all",
			"-khtml-user-select": "all",
			"-moz-user-select": "all",
			"-ms-user-select": "element", // "all" and "text" doesn't work properly, at least in IE10/IE11
			"-o-user-select": "all",
			"user-select": "all"
		})
	}
	else
	{
		if (IE == 9)									{ $(this).attr("unselectable", "on"); }

		$(this).css({
			"-webkit-user-select": "none",
			"-khtml-user-select": "none",
			"-moz-user-select": "none",
			"-ms-user-select": "none",
			"-o-user-select": "none",
			"user-select": "none"
		})
	}

	return this;
};

(function($)
{
	/*
	 * Special logic for IE and Chrome. When you remove an iframe from the DOM it unloads (part of) the JavaScript. This can lead to strange errors
	 * as parts of the JavaScript are executed to find other parts missing. (i.e., onunload or onblur could find jQuery missing)
	 *
	 * To mitigate this problem we set the location of any iframes we find to about:blank and only remove the element 10 seconds after the
	 * last iframe has reported to have finished loading.
	 *
	 * The 10 second delay is necessary because despite changing the location of the iframe parts of the JavaScript are still executed in the background
	 * AFTER the location has changed.
	 */

	var originalRemoveMethod																										= $.fn.remove;

	$.fn.remove = function()
	{
		var frames																													= $(this).is("iframe") ? $(this) : $(this).find("iframe");

		if (frames.length > 0)
		{
			var element																												= this;
			var checkOnly																											= false;

			JSO.each(frames, function(elem, process, i)
			{
				if (elem)
				{
					elem																											= $(elem)[0];

					if (!checkOnly)																									{ elem.src = "about:blank"; }

					var wnd																											= elem.contentWindow;

					try
					{
						if (wnd.location.href == "about:blank" && wnd.document.readyState == "complete")							{ checkOnly = false; process(true); }
						else																										{ checkOnly = true; setTimeout(process.bind(null, false), 100); }
					}
					catch (ex)																										{ checkOnly = false; process(true); } // Access violation
				}
				else																												{ checkOnly = true; setTimeout(process.bind(null, false), 100); }
			}, function()
			{
				setTimeout(function(){ originalRemoveMethod.apply(element, arguments); }, 10000);
			});
		}
		else
		{
			try																														{ originalRemoveMethod.apply(this, arguments); }
			catch (e)																												{ /* MT-7842 */ }
		}
	}
})(jQuery);

function addZeros(value, len)
{
	var str																															= "" + value;

	while (str.length < len)																										{ str = "0" + str; }

	return str;
}

function isAccessDeniedError(ex)
{
	return ((ex instanceof TypeError) && (toString(ex.message).startsWith("Toegang geweigerd") || toString(ex.message).startsWith("Access denied")));
}

function scriptUnloadedError(ex)
{
	return ((ex instanceof Error) && (toString(ex.message).startsWith("Kan programmacode niet uitvoeren vanuit een vrijgegeven script") || toString(ex.message).startsWith("Can't execute code from a freed script")));
}

(function($)
{
	function calculateCheckDigit(input)
	{
		var sum													= 0;
		var fact												= 3;

		for (var i = input.length - 2; i >= 0; i--)
		{
			sum													= sum + (parseInt(input.charAt(i)) * fact);

			if (fact == 1)										{ fact = 3; }
			else												{ fact = 1; }
		}

		var tiental												= sum;

		if (isNaN(tiental))										{ return false; }

		while (tiental % 10 != 0)								{ tiental = tiental + 1; }

		var result												= tiental - sum;

		return result;
	}

	function getCheckDigit(input)								{ return parseInt(input.charAt(input.length - 1)); }

	function isEAN13(input)
	{
		try
		{
			if (input.length == 13)								{ return getCheckDigit(input) == calculateCheckDigit(input); }
			else												{ return false; }
		}
		catch (ex)												{ return false; }
	}

	function isSSCC(input)
	{
		try
		{
			if (input.length == 18)								{ return getCheckDigit(input) == calculateCheckDigit(input); }
			else												{ return false; }
		}
		catch (ex)												{ return false; }
	}

	/**
	 * Usage (direct): $.atsc.string.isBarcode(input)
	 * Determines if a given string represents a barcode.
	 * @param input The string to check.
	 * @param type The type of barcode to check for. Possible values: "EAN-13" or "SSCC".
	 * @returns <code>true</code> if the given input string is a valid barcode, checking against the provided type, <code>false</code> if not.
	 */
	$.atsc.string.isBarcode = function(input, type)
	{
		var res													= false;

		if (!type || type == "EAN-13")							{ res = res || isEAN13(input); }
		if (!type || type == "SSCC")							{ res = res || isSSCC(input); }

		return res;
	};
})(jQuery);

function deleteAllCookies() {
	var cookies													= document.cookie.split(";");
	var cookie;
	var eqPos;
	var name;

	for (var i = 0; i < cookies.length; i++) {
		cookie													= cookies[i];
		eqPos													= cookie.indexOf("=");
		name													= eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

		document.cookie											= name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
	}
}

function escapeHtml(unsafe)
{
	return unsafe
		.replace(/&/g, "&amp;")
		.replace(/</g, "&lt;")
		.replace(/>/g, "&gt;")
		.replace(/"/g, "&quot;")
		.replace(/'/g, "&#39;");
}

var BLANKURL 		= "/jsp/atsc/blank.html";
var nextmsg = "";
var modalitem;
var ifd;
var vArguments = [];
var lwin = null;
var previousWindowURL = "";
var doUpdateFrameSet = true;
var focusCurrentFrameDone = false;
var isWindowClosing = false;

window.onerror = function(message, url, linenum, colnum, error)
{
	if		(("" + message).indexOf("Niet nader omschreven fout") > -1)										{ return true; } // Unknown errors from IE
	else if	(("" + message).indexOf("Kan programmacode niet uitvoeren vanuit een vrijgegeven script") > -1)	{ return true; } // [MT-6068] IE failing to unload JavaScript properly
	else if	(("" + message).indexOf("ResizeObserver loop limit exceeded") > -1)								{ return true; } // Error when using video tag in Chrome
	else if	(("" + message).indexOf("HTMLCanvasElement") > -1)												{ return true; } // [MT-9112]
	else if	(!error && ("" + message) == "Script error.")													{ return true; } // Suppress tomcat log print containing unknown error. Originated initially from Chrome on IOS
	else if (isWindowClosing || document.readyState != "complete")											{ return true; } // I don't CARE about errors during shutdown... or not complete
	else
	{
		try
		{
			if (error)													{ throw error; }
			else														{ throw new Error(message + "\n   " + (IE ? "" : " ") + "at <unknown> (" + url + ":" + linenum + ")"); }
		}
		catch (ex)
		{
			try
			{
				$.atsc.log.warn(ex);

				return !isDebugMode(); // inform the browser that the error has been handled
			}
			catch (ex2)
			{
				try														{ console.log(ex); }
				catch (ex3)												{ /* no problem */ }
			}
		}
	}
}

var wt = getFrameSetWindow();

//[MT-1149] Attempt to use the sessionStorage to generate a unique identifier for this frame.
//Failing that, use the current timestamp to generate one. (This is untested behavior.)
try
{
	if (window.sessionStorage['FRAME_UNIQUEID'])
	{
		FRAME_UNIQUEID = window.sessionStorage['FRAME_UNIQUEID'];
	}
	else
	{
		if (!window.localStorage['FRAME_UNIQUEID'])
		{
			window.localStorage['FRAME_UNIQUEID'] = new Date().getTime();
		}

		FRAME_UNIQUEID = ++window.localStorage['FRAME_UNIQUEID'];
	}
}
catch (ex)									{ FRAME_UNIQUEID = new Date().getTime(); }

try { window.sessionStorage['FRAME_UNIQUEID'] = FRAME_UNIQUEID; }
catch (ex) {}

ui.addItemToSkip("window");

if (wt.ui && wt.ui.window)					{ ui.window = wt.ui.window; }
else
{
	ui.window = {
		primary: false,
		current_frame_name: "w0",
		quickmenuFrameID: null,

		/**
		 * Dynamically allocates frames based on the provided amount of needed frames.
		 * @param neededFrames The amount of frames that are required for this window. Any missing frames are added.
		 */
		allocateFrames: function(neededFrames, callback, errorcallback)
		{
			if ($("#w" + (neededFrames - 1)).length <= 0)
			{
				var windows															= document.getElementById("windows");

				if (windows)
				{
					var currentFrames												= $("#windows frame").length;

					// Create the necessary frames.
					for (var i = currentFrames; i < neededFrames; i = i + 1)
					{
						// IE requires windows.cols to be set properly prior to creating frames.
						windows.cols												= windows.cols + ", 0";

						var frame													= document.createElement("FRAME");

						frame.setAttribute("id", "w" + i);
						frame.setAttribute("name", "w" + i);
						frame.setAttribute("noresize", "noresize");
						frame.setAttribute("frameborder", "0");

						windows.appendChild(frame);
					}

					var win;

					for (var i = 0; i < neededFrames; i = i + 1)
					{
						win															= document.getElementById("w" + i);

						frameCols													= "";

						for (var j = 0; j < neededFrames; j = j + 1)
						{
							if (j > 0)												{ frameCols = frameCols + ", "; }

							if (j == i)												{ frameCols = frameCols + "*"; }
							else													{ frameCols = frameCols + "0"; }

							win.setAttribute("framecols", frameCols);
						}
					}

					if (isFunction(callback))
					{
						var elem;
						var frame;

						function loop()
						{
							for (var i = currentFrames; i < neededFrames; i = i + 1)
							{
								elem												= $("#w" + i);
								frame												= $.atsc.window.getWindowFromFrame(elem);

								if (!frame || !frame.document || frame.document.readyState != "complete" || frame.document.isOldDocument)
								{
									setTimeout(loop, 100);
									return;
								}
							}

							callback();
						}

						loop();
					}
				}
				else
				{
					if (isFunction(errorcallback))									{ errorcallback(); }
				}
			}
			else
			{
				if (isFunction(callback))
				{
					var elem														= $("#w" + (neededFrames - 1));
					var frame;

					function check()
					{
						frame														= $.atsc.window.getWindowFromFrame(elem);

						if (!frame || !frame.document || frame.document.readyState != "complete" || frame.document.isOldDocument)
						{
							setTimeout(check, 100);
						}
						else
						{
							callback();
						}
					}

					check();
				}
			}
		},

		isDashboard: function()
		{
			return ui.window.getDashboardFrame() != null;
		},

		getDashboardFrame: function()
		{
			try
			{
				if (ui.properties.getGlobalProperties().dashboardFrame)
				{
					return ui.properties.getGlobalProperties().dashboardFrame;
				}
			}
			catch (ex)															{ /* no problem */ }

			try
			{
				if (UIWrapper)
				{
					return $.atsc.window.getWindowFromFrame("wmain");
				}
			}
			catch (ex)															{ /* no problem */ }

			var wnd							= window;

			do
			{
				if (ui.isDashboard(wnd))
				{
					break;
				}

				if (wnd == wnd.parent)		{ break; }

				wnd							= wnd.parent;
			}
			while (wnd);

			if (ui.isDashboard(wnd))
			{
				try							{ ui.properties.getGlobalProperties().dashboardFrame = wnd; }
				catch (ex)					{ /* no problem */ }

				return wnd;
			}
			else							{ return null; }
		},

		/**
		 * Usage: ui.window.isInCurrentFrame(wnd)
		 *
		 * Checks if the provided window is part of the current frame.
		 *
		 * @param wnd The window to check.
		 * @returns <code>true</code> if the window is part of the current frame (or an iframe of the current frame, or an iframe of an iframe of the current frame, etc.), <code>false</code> if not.
		 */
		isInCurrentFrame: function(wnd)
		{
			try
			{
				//If this is not a frameset, always assume it's on the current frame.
				if (wt.getFrameSet() == null)								{ return true; }

				//Otherwise, determine if it's on the current frame within our frameset.
				var currentFrame											= wt.getFrame(wt.getCurrentFrameName());
				var parwnd													= wnd;

				while (parwnd != null && currentFrame != parwnd)
				{
					if (parwnd.parent != parwnd)							{ parwnd = parwnd.parent; }
					else													{ parwnd = null; }
				}

				return (parwnd != null && parwnd == currentFrame);
			}
			catch (ex)														{ return true; }
		},

		frame_urls: {},
		updateFrameURL: function(frameName)
		{
			ui.window.frame_urls[frameName] = JSO.getWindowLocation(ui.window.getFrame(frameName));
		},
		getFrameURL: function(frameName)
		{
			return ui.window.frame_urls[frameName];
		},
		getCurrentFrame: function()
		{
			// If this is a popup on a dashboard, the current frame should refer to the popup screen.
			if (ui.isDashboard(window))
			{
				var fr = $.atsc.window.getWindowFromIFrame($("iframe:visible"));

				if (JSO.getWindowLocation(fr) != "about:blank" && JSO.getWindowLocation(fr) != BLANKURL)
				{
					try
					{
						return fr.getFrame(fr.getCurrentFrameName());
					}
					catch (ex)
					{
						return fr;
					}
				}
			}

			if (ui.isComponent(window))
			{
				var fr														= $(".ui-dialog iframe").first();

				if (fr.length > 0)											{ return $.atsc.window.getWindowFromIFrame(fr); }
			}

			if (!ui.isFrameset(window) && !ui.isComponent(window))			{ return window; } //JB: nodig tbv CMS (om Escape toets toe te staan na bewerken cmsdoc) + extra fix voor boekhouding (waarbij het juiste frame wel gevonden moet worden)

			return ui.window.getFrame(getCurrentFrameName());
		},

		getFrame: function(frame_name)
		{
			try
			{
				if (wt && wt != window)											{ return wt.ui.window.getFrame(frame_name); }

				return $.atsc.window.getWindowFromFrame(frame_name);
			}
			catch (ex){ /* no problem */ } //tbv [MT-932]
		},
		isTopWindow: function(wnd)
		{
			if(typeof(wt) == "undefined" || wt == null)	{ return wnd == window.top; }
			else if(wnd == wt)							{ return true; }
			return false;
		},

		getFrameReadyState: function(frame)
		{
			if (isFrameAccessible(frame))
			{
				var fdoc											= frame.document;

				if (!fdoc || fdoc.isOldDocument)					{ return "loading"; }
				else												{ return fdoc.readyState; }
			}
			else													{ return "access_denied"; }
		},
		setFrameLocation: function(frame, newURL)
		{
			if (isFrameAccessible(frame))							{ frame.document.isOldDocument = true; }

			JSO.setWindowLocation(frame, newURL);
		},
		addKeyBlocker: function(doc, srcWindow)
		{
			if (IE)
			{
				// This prevents the focus from being stolen from elements by pressing Alt+R after a file
				// download has taken place.
				var preventAltKeys									= ["W", "T", "V", "P", "A", "R", "I", "O"];
				var key;
				var elem;
				//alert("Adding to " + JSO.getWindowLocation(wnd));
				for(var i = 0; i < preventAltKeys.length; i++)
				{
					key												= preventAltKeys[i];

					elem											= $("<label for=\"preventAlt" + key + "\" accesskey=\"" + key + "\"></label><input type=\"text\" readonly=\"readonly\" id=\"preventAlt" + key + "\" class=\"preventAlt\" style=\"position: absolute; top: -64px;\" />");

					$("body", doc).append(elem);
					$(elem).on("focus", function()
					{
						//Once the focus is set the window will scroll down, so we prevent this here.
						//Note that this may be undesirable behavior outside of MT.
						$("body", doc).scrollTop(0);

						try
						{
							if (srcWindow && srcWindow.lastitem)
							{
								var item							= srcWindow.lastitem;

								item.focus();

								// IE-specific fix for placing the cursor at the end of the input.
								$(item).val($(item).val());
							}
							else									{ srcWindow.focusCurrentFrame(false); }
						}
						catch (ex)									{ focusCurrentFrame(false); }
					});
				}

				if (IE == 10)
				{
					doc.attachEvent("onkeydown", function handleKeyDown(event)
					{
						if (event.ctrlKey)
						{
							switch (event.keyCode)
							{
								// o
								case 79:							{ event.keyCode = 0; return false; }
							}
						}
					});
				}
			}
		},
		openHelp: function(type, item_name, item, edit)
		{
			ui.events.call("ui.window.openHelp", window, function(e)
			{
				if (!e.isDefaultPrevented())
				{
					var frame													= ui.window.getCurrentFrame();
					if(frame.helpidname != "-1")								{ item_name = frame.helpidname; }

					var url														= "/jsp/atsc/UIHelp.jsp?type=" + type;
					if (item_name != null && item_name != "")					{ url += "&name=" + item_name; }
					if (item != null && item != "")								{ url += "&item=" + item.id; }
					if (edit)													{ url += "&edit=true"; }

					ui.dialog.createIFrameDialog(url, {
						title: getApplicationName() + " :: Help",
						width: 980,
						height: 540,
						closeOnEscape: true,
						titlebar: true,
						closable: true,
						frames: 1
					});
				}
			}, { "type": type, "item_name": item_name, "item": item, "edit": edit });
		},
		fixTitle: function()
		{
			if (CHROME) // Anders wordt titel niet bijgewerkt (als je geen aero effecten hebt ingeschakeld).
			{
				// Lijkt erop dat dit gefixed is!!
				/*
				var o = wt;

				var w = o.outerWidth;
				var h = o.outerHeight;

				o.resizeTo(w + 1, h);
				o.resizeTo(w, h);
				*/
			}
		},

		deselectOtherFrames: function(cur, wnd)
		{
			if (typeof(wnd) == "undefined")																		{ wnd = ui.window.getTopmostFrame(); }

			// If there is only one Window object turn it into an array.
			try																									{ cur.location.href; cur = [cur]; }
			catch (ex)																							{ /* no problem */ }

			if (wnd && typeof(wnd.ui) != "undefined" && typeof(wnd.ui.window) != "undefined")
			{
				wnd.jQuery("frame, iframe").each(function(){ ui.window.deselectOtherFrames(cur, $.atsc.window.getWindowFromIFrame(this)); });

				if ($.inArray(wnd, cur) == -1)
				{
					if (ui.isTable(wnd))
					{
						wnd.ui.table.isFocused																	= false;
						wnd.ui.table.deselectCol();
					}

					if (ui.isRecord(wnd) && wnd.lastitem)
					{
						// Need to call .blur to deselect the field.

						// This does not work completely in Chrome 37, the text inside the field will remain highlighted.
						// The only fix I know of is to do item.focus()&item.blur() but this messes with focus, particularly in popups.
						// (To reproduce: Use F10 in gc1vbnhdr when standing on the "kenteken" field.

						try { wnd.lastitem.blur(); } catch (e) { /* [MT-5222] */ }

						wnd.lastitem																			= null;
						wnd.currentField																		= null;
					}
				}
			}
		},

		getSelectedFrame: function(wnd)
		{
			var wnd																								= ui.window.getTopmostFrame();
			var elem;

			while (wnd && typeof(wnd.ui) != "undefined" && typeof(wnd.ui.window) != "undefined")
			{
				elem																							= wnd.document.activeElement;

				if (elem != null && (elem.tagName == "FRAME" || elem.tagName == "IFRAME"))						{ wnd = (elem.tagName == "FRAME" ? $.atsc.window.getWindowFromFrame(elem) : $.atsc.window.getWindowFromIFrame(elem)); }
				else																							{ return wnd; }
			}

			return wnd ? wnd : window.top;
		},

		// TODO: Verwijder na 2014-02-01
		dialogExists: function()
		{
			$.atsc.log.warn("ui.window.dialogExists: Wordt nog steeds gebruikt!");

			return JSO.dialogExists();
		},

		getStatusMessage: function()
		{
			try																									{ return getStatusMessageObject().innerHTML.replace(/&nbsp;/g, " "); }
			catch(e)																							{ return ""; }
		},

		makeAlert: function(message, options)
		{
			try																									{ ui.dialog.alert(message, options); }
			catch (ex)																							{ alert(message); }
		},

		/**
		 * Usage: ui.window.getTopmostFrame()
		 * @returns A <code>Window</code> object of the topmost frame that contains the "ui.window" functions.
		 */
		getTopmostFrame: function()
		{
			if (window != JSO.getOurWindowTop())
			{
				if (wt && wt != window)
				{
					try
					{
						if (typeof(wt.ui) != "undefined" && typeof(wt.ui.window) != "undefined")				{ return wt.ui.window.getTopmostFrame(); }
					}
					catch (ex)																					{ /* no problem */ }
				}

				try
				{
					if (parent && parent != window)
					{
						try
						{
							if (typeof(parent.ui) != "undefined" && typeof(parent.ui.window) != "undefined")	{ return parent.ui.window.getTopmostFrame(); }
						}
						catch (ex)																				{ /* no problem */ }
					}
				}
				catch (ex)																						{ /* no problem */ }
			}

			return window;
		},

		/**
		 * Usage: ui.window.getTopmostWindow()
		 * @returns A <code>Window</code> object of the topmost window that we can control.
		 */
		getTopmostWindow: function()
		{
			if (JSO.getOurWindowTop() == window)																{ return window; }
			else
			{
				var fr																							= ui.window.getTopmostFrame();

				var fr2;
				do
				{
					try
					{
						fr2 = fr.parent;

						if (fr2 == fr)																			{ return fr; }

						var test;
						try																						{ test = fr2.name; }
						catch (ex)																				{ return fr; }

						if (test === undefined)																	{ return fr; }

						fr																						= fr2;
					}
					catch (ex)																					{ return fr; }
				}
				while (fr2);

				return fr2;
			}
		},

		specificTblChangeMode: function(window, new_mode, old_mode)
		{
			try																									{ ui.quickmenu.setTableMode(new_mode); }
			catch (ex)																							{ /* no problem */ }
		},

		validateKeyboardInput: function(e, wnd)
		{
			// Global keys
			switch (e.which)
			{
				case 80:
				{
					if (e.ctrlKey)
					{
						wnd.print();

						return false;
					}
					else																						{ break; }
				}

				case 112: // F1: Open the help window. CTRL-F1: Open help window (in edit mode).
				{
					if (!JSO.dialogExists())
					{
						if 		(ui.isMenu(wnd))																{ ui.window.openHelp("UIMenu", null, null, (e.ctrlKey && !e.altKey)); }
						else if (ui.isTable(wnd))																{ ui.window.openHelp("UITable", wnd.ui.table.queryid, null, (e.ctrlKey && !e.altKey)); }
						else if (ui.isRecord(wnd))																{ ui.window.openHelp("UIRecord", wnd.record_name, wnd.currentField, (e.ctrlKey && !e.altKey)); }
					}

					return false;
				}

				case 122: // CTRL-F11: Open broadcast message window.
				{
					if (!JSO.dialogExists())
					{
						if (e.ctrlKey && !e.altKey && !e.shiftKey)
						{
							e.preventDefault();

							ui.window.getTopmostWindow().ui.window.createNotification();

							return false;
						}
					}

					break; //Needs to be a break for pa1uurrgl.js functionality (concept goedgekeurd)
				}

				case 123: // CTRL-SHIFT-F12: Toggle quick menu.
				{
					if (e.ctrlKey && !e.altKey && e.shiftKey)
					{
						try																						{ getStatusWindow().toggleQuickmenu(); }
						catch (ex)																				{ /* no problem */ }

						return false;
					}
					else																						{ break; }
				}

				case 68: // CTRL-D: Toggle debug mode. CTRL-SHIFT-D: Get unique ID of current frame.
				{
					if		(e.ctrlKey && e.shiftKey)
					{
						try																						{ ui.dialog.alert("Window: " + JSO.getWindowLocation(e.view, true) + "\nUniqueID: " + e.view.ui.getUniqueID()); }
						catch (ex)																				{ /* no problem */ }
					}
					else if (e.ctrlKey && !e.altKey)
					{
						toggleDebugMode();
						return false;
					}
					else																						{ break; }
				}

				case 70: // CTRL-SHIFT-F: Search
				{
					if (e.ctrlKey && e.shiftKey)
					{
						var df																					= ui.window.getDashboardFrame();

						if (df && df.ui && df.ui.dashboard)														{ df.ui.dashboard.search(); }

						return false;
					}

					break;
				}

				case 75: // CTRL-ALT-K: Provide alternative private key.
				{
					if (e.ctrlKey && e.altKey)
					{
						var frame																				= ui.window.getTopmostFrame();

						frame.ui.dialog.createIFrameDialog("/jsp/atsc/uiform_sysautprvkey.jsp?mode=" + MODE_ADD, {
							width: 990,
							height: 420
						});

						return false;
					}
					else																						{ break; }
				}

				case 77: // CTRL-M: Show notes
				{
					if (e.ctrlKey)
					{
						var df																					= ui.window.getDashboardFrame();

						if (df && df.ui && df.ui.dashboard)														{ df.ui.dashboard.showNotes(); }

						return false;
					}
					else																						{ break; } // [MT-11653]
				}

				case 91: // WinKey
				case 92: // WinKeyGr
				case 93: // ContextKey
				{
					return true;
				}
			}

			return null;
		},
		notify: function (message, tag, icon, onClick, actions, requireInteraction)
		{
			if ('serviceWorker' in navigator && window.Notification && Notification.permission === "granted" && $("#notification", getStatusDocument()).prop("checked"))
			{
				var notificationOptions											= { "messageType": "notification", "applicationName": getApplicationName(), "body": message, "icon": icon, "requireInteraction": requireInteraction, "timestamp": new Date().getTime() };

//				if (tag != null)												{ notificationOptions.tag = tag; }	// Uitgezet voor [MT-10909]

				if (onClick != null && JSO.toString(onClick.operation) != "NONE")
				{
//					notificationOptions.actions = [{action: "performAction", title: "Weergeven in " + getApplicationName()}];

					if (actions != null)										{ notificationOptions.actions = actions; }

					notificationOptions.data = { "messageType": "notification", "applicationName": getApplicationName(), "onClick": onClick, "actions": actions };
				}

				try
				{
					navigator.serviceWorker.ready.then(function(registration) {
						if (registration.active) { registration.active.postMessage(notificationOptions); }
					});
				}
				catch (e)														{ console.log(e); }
			}
			else if (tag == "CRITICAL")											{ ui.dialog.alert(message); }
		},
		createNotification: function()
		{
			if (hasUserPermission("atsc.system.notification"))
			{
				ui.dialog.createModalDialog({
					title: "Bericht verzenden",
					html: "<p>Typ het bericht dat u wilt versturen in het vak hieronder. Dit bericht wordt naar alle aangemelde gebruikers verzonden.</p><textarea type=\"text\" class=\"messageBox\" style=\"width: 95%; height: 75px;\"></textarea>",
					closable: true,
					closeOnEscape: true,
					buttons: [
						{
							text: "Verstuur",
							hotkey: null,
							click: function(e, eui, srcWindow)
							{
								this.dialog("close");

								var message											= JSO.toString(this.find(".messageBox").val());

								if (message)
								{
									$.ajax({
										url: "/notify",
										type: "post",
										data: { "message": message }
									});
								}
							}
						},
						{
							text: "Annuleren",
							hotkey: null,
							click: function(e, eui, srcWindow)
							{
								this.dialog("close");
							}
						}
					],
					setFocus: function()
					{
						this.find(".messagebox").focus();
					},
					width: 400,
					height: 300,
					selectable: true
				});
			}
			else	{ ui.dialog.alert("U heeft onvoldoende rechten om een bericht te versturen!"); }
		}
	};

	(function($)
	{
		// Returns a list containing this frame + all child frames + their children etc.
		function getFrames(frame, skip, skipSelf)
		{
//					$.atsc.log.warn("start -> " + frame.location.href);
			var frames																		= [];

			if (frame && (!skip || $.inArray(frame, skip) == -1))
			{
				if (!skipSelf)
				{
					frames.push(frame);
				}

				skip.push(frame);

				try
				{
					var elements															= frame.document.getElementsByTagName("FRAME");

					for (var i = elements.length - 1; i >= 0; i--)
					{
						try
						{
							var l															= getFrames($.atsc.window.getWindowFromFrame(elements[i], frame), skip, false);

							for (var j = l.length - 1; j >= 0; j--)
							{
								if (l[j])													{ frames.push(l[j]); }
							}
						}
						catch (ex)															{ /* no problem */ }
					}

					elements																= frame.document.getElementsByTagName("IFRAME");

					for (var i = elements.length - 1; i >= 0; i--)
					{
						try
						{
							var l															= getFrames($.atsc.window.getWindowFromIFrame(elements[i], frame), skip, false);

							for (var j = l.length - 1; j >= 0; j--)
							{
								if (l[j])													{ frames.push(l[j]); }
							}
						}
						catch (ex)															{ /* no problem */ }
					}
				}
				catch (ex)																	{ /* no problem */ }
			}

			return frames;
		}

		function isBusyRecord(frame, mustBeChanged)											{ return ui.isRecord(frame) && !frame.isClosed && (!mustBeChanged || frame.isChanged || frame.onSave || frame.onQuit); }

		// True if the frame may not be closed without user confirmation, false if it may.
		function isFrameBusy(frame, skip, skipSelf)
		{
			var frames																		= getFrames(frame, skip ? skip.slice() : [], skipSelf);

			for (var i = 0; i < frames.length; i++)
			{
				if (isBusyRecord(frames[i], true))											{ return true; }
			}

			return false;
		}

		function processFrame(frame, method, callback, skip, skipSelf)
		{
			var frames																		= getFrames(frame, skip ? skip.slice() : [], skipSelf);

			// Keep track of which frames we have to close.
			var framesToClose																= [];

			JSO.each(frames, function(frame, process)
			{
				if (isBusyRecord(frame, false))
				{
					// Either force close or open a confirmation dialog.

					if (frame.onSave || frame.onQuit)
					{
						if (method == CHECKFRAME_CLOSE)										{ setTimeout(function(){ process(false); }, 250); }
						else																{ callback(false, method); }
					}
					else if (method == CHECKFRAME_CLOSE || !frame.isChanged)
					{
						// This frame is going to be closed, so add it to the list so it can be checked later.
						framesToClose.push(frame);

						process(true);
					}
					else
					{
						if (!frame.JSO.dialogExists())										{ frame.askQuit(); }

						callback(false, method);
					}
				}
				else																		{ process(true); }
			}, function()
			{
				JSO.each(framesToClose, function(frame, process)
				{
					frame.quit(false, function(){ process(true); }, function()
					{
						if (frame.ui.isUnloading())											{ process(true); }
						else																{ callback(false, method); }
					});
				}, function(){ callback(true); });
			});
		}

		ui.window.checkFrame = function(frame, method, callback, skip, skipSelf)
		{
			if (!callback && method != CHECKFRAME_CHECKONLY)								{ throw new Error("No callback specified and not called with CHECKFRAME_CHECKONLY."); }

			if (method == CHECKFRAME_CHECKONLY)
			{
				if (callback)																{ callback(!isFrameBusy(frame, skip, skipSelf), method); }
				else																		{ return !isFrameBusy(frame, skip, skipSelf); }
			}
			else																			{ processFrame(frame, method, callback, skip, skipSelf); }
		}
	}(jQuery));
}

if (navigator && navigator.serviceWorker)
{
	navigator.serviceWorker.onmessage = function(e) {
		try
		{
			if (e.data.messageType == "notification")
			{
				if (e.data.onClick)
				{
					if (e.data.actions && e.data.pressedAction)
					{
						var pressedAction													= null;

						e.data.actions.forEach(function(item){
							if (pressedAction == null && item.action == e.data.pressedAction)	{ pressedAction = item; }
						});

						if (pressedAction != null)
						{
							switch (pressedAction.operation)
							{
								case "NONE":		{ break; }
								case "ALERT":		{ ui.dialog.alert(pressedAction.action); break; }
								case "OPENWINDOW":	{ JSO.openDocLink(pressedAction.action); break; }
								case "OPENURL":		{ window.open(pressedAction.action, "_blank"); break; }
							}
						}
						else
						{
							switch (e.data.onClick.operation)
							{
								case "NONE":		{ break; }
								case "ALERT":		{ ui.dialog.alert(e.data.onClick.action); break; }
								case "OPENWINDOW":	{ JSO.openDocLink(e.data.onClick.action); break; }
							}
						}
					}
					else
					{
						switch (e.data.onClick.operation)
						{
							case "NONE":		{ break; }
							case "ALERT":		{ ui.dialog.alert(e.data.onClick.action); break; }
							case "OPENWINDOW":	{ JSO.openDocLink(e.data.onClick.action); break; }
						}
					}
				}
			}
		}
		catch (e) { /**/ }
	}
}

function getFrameSetWindow()
{
	var www = getFrameSetWindowR(window);

	if (www == null) {www = window;}

	return www;
}

function getFrameSetWindowR(wwindow)
{
	try
	{
		var wwd	= wwindow.document;

		if (wwd)
		{
			var ww 	= wwindow.frameSetTopPage

			if (!ww)
			{
				if (wwindow.parent && wwindow.parent != wwindow)
				{
					if (wwindow.parent.document.location) return getFrameSetWindowR(wwindow.parent);
					else return wwindow;
				}
				else return wwindow;
			}
			else return wwindow;
		}
		else {return wwindow;}
	}
	catch (e) {return wwindow;}
}

function initDragOver(bd)
{
	/*
	 * Watch out!
	 * This method will prevent jQuery UI draggables from working.
	 */
	if (bd == null) var body = document.body; else var body = bd;

	body.ondragstart = noDragDrop;
	body.ondragenter = noDragDrop;
	body.ondragover = noDragDrop;
	body.ondragleave = noDragDrop;
	body.ondragend = noDragDrop;
	body.ondrop = noDragDrop;
}

function noDragDrop()
{
	return false;
}

function askQuitApplication()
{
	// https://www.chromestatus.com/feature/5349061406228480

	if (wt.dialogArguments == null && typeof(wt.menuclose) == "undefined")
	{
		if (!ui.properties.getGlobalProperties().logout)
		{
			if (ui.isRecord(getFrame(getCurrentFrameName())) && getFrame(getCurrentFrameName()).isChanged)
			{
				return "Uw wijzigingen gaan verloren!";
			}
			else
			{
				return getApplicationName() + " wordt afgesloten!";
			}
		}
	}
}

function done()
{
}

function quitApplication(delayed)
{
	isWindowClosing														= true;

	try																	{ $.atsc.popup.closePopups(); }
	catch (ex)															{ /* no problem */ }

	try																	{ ui.events.stopListening(); }
	catch (ex)															{ /* no problem [MT-5260] */ }

// [MT-2976] The check for a popup must be wrapped in a try/catch as the $.atsc.popup.isPopup call may not be available within the current context (i.e., if the source window is gone).
	var isPopup															= false;

	try																	{ isPopup = $.atsc.popup.isPopup(window); }
	catch (ex)															{ /* no problem */ }

	if ((typeof(wt.dialogArguments) == "undefined" || wt.dialogArguments == null) && !isPopup)
	{
		try																{ ui.events.notifyOtherWindows({ "type": "session_close" }); }
		catch (ex)														{ /* no problem */ }

		$.ajax({url: "/login?action=removesession&sessionid=" + ui.notification.getSessionID() + (delayed ? "&delayed=true" : ""), type: "get", async: false, cache: false});
	}
}

var default_lookupwin_width;

function openLookup(item, url, _width)//, myWindow)
{
//	if (!myWindow)														{ myWindow = window; }

	var lookupwindow													= document.getElementById("lookupwindow");

	if (/*false &&*/ lookupwindow != null)
	{
		try
		{
//			var ddbs = document.getElementsByTagName("SELECT");
//			for (var i = 0; i<ddbs.length; i++) ddbs[i].style.display = "none";

			var lookupwin												= null;
			var fr;

			$("iframe").each(function()
			{
				var frame												= $.atsc.window.getWindowFromIFrame(this);

				try
				{
					if (JSO.getWindowLocation(frame).indexOf("lookup=true") > -1)
					{
						lookupwin										= frame;
						frame											= null;
						return false;
					}
				}
				catch (ex)												{ /* no problem */ }
			});

			if (lookupwin == null)										{ /* [MT-7046] */ stopLoading(); return false; }

			// [MT-3502]
			if (lookupwin.ui == null || lookupwin.ui.table == null)
			{
				setTimeout(function(){ openLookup(item, url, _width); }, 100);

				return false;
			}

			lookupwin.ui.table.reset();

			lookupwindow.style.display									= "block";

			//This gets the width and height of the browsers' viewport as opposed to the <body> tag,
			//which should be more reliable in this case.
			var width													= $(window).width();
			var height;

			if (MOBILE)
			{
				//When using a mobile device we use a fixed height, because the keyboard on mobile devices
				//steal height from the document resulting in an invalid value for $(window).height()
				height													= 540;
			}
			else
			{
				height													= $(window).height();

				if (!default_lookupwin_width)							{ default_lookupwin_width = $(lookupwindow).outerWidth(); }

				var w_width												= _width ? _width : default_lookupwin_width;
				var w_height											= $(lookupwindow).outerHeight();

	//			alert(width + "x" + height + " vs. " + w_width + "x" + w_height);

				if (w_width > width)									{ w_width = width - 16; }
				if (w_width < 600)										{ w_width = 600; }

				$(lookupwindow).width(w_width);

				var changed												= $(lookupwindow).data("changed") ? true : false;
				if (w_height > height)
				{
					changed												= true;
					$(lookupwindow).data("changed", true);
					w_height											= height - 16;
					$(lookupwindow).height(w_height);
				}

				if (false)
				{
					var maxrows												= lookupwin.ui.table.calculateMaxRows(true);
					url														= url.replace(/(\?|&)maxrows=\d+/g, "");
					url														= url.replace(/(\?|&)first=true/g, "");
					url														= url + "&maxrows=" + maxrows + "&first=true";
				}
				else
				{
					 // [MT-9906] [MT-9920] [MT-9972]
					var tmp													= url.match(/(maxrows=)(\d+)/);
					var maxRowsFromUrl 										= tmp != null ? tmp[2] : null;
					var maxrows												= lookupwin.ui.table.calculateMaxRows(true);

					if (!maxRowsFromUrl || maxRowsFromUrl > maxrows)
					{
						url													= url.replace(/(\?|&)maxrows=\d+/g, "");
						url													= url + "&maxrows=" + maxrows;
					}

					url														= url.replace(/(\?|&)first=true/g, "");
					url														= url + "&first=true";
				}
			}

			//[MT-926] Determine if we can select multiple records.
			lookupwin.ui.table.allowMultipleRecords						= (item.getAttribute("allowmultiplevalues") == "true");

			lookupwin.ui.table.userHasSelectedRecords					= false;

			if (lookupwin.ui.table.allowMultipleRecords)
			{
				// [MT-3316] Restore the selected items.
				var values												= toString($(item).attr("fuidvalue")).replace(/,/g, "_sep_").split("_sep_");

				lookupwin.ui.table.setSelectedRecords(values);

				lookupwin.ui.table.addRefreshCallback(function()
				{
					lookupwin.ui.table.checkSelectedRecords();
				});
			}
			else
			{
				//[MT-926] Make sure no records are selected by default.
				lookupwin.ui.table.selectedRecords						= null;
			}

			lookupwin.table.location									= url;

//			alert("New size: " + w_width + "x" + w_height);

			lookupwindow.style.top										= ((height - lookupwindow.offsetHeight) / 2) + "px";
			lookupwindow.style.left										= ((width - lookupwindow.offsetWidth) / 2) + "px";

			$("iframe", lookupwindow).css("display", "block"); // calendar.js verbergt iframe's...

			var lookuptitle = document.getElementById("lookuptitle");
			try															{ lookuptitle.innerHTML = "Opzoeken '" + document.getElementById("l"+item.id).innerHTML + "'"; }
			catch (ex)													{ lookuptitle.innerHTML = "Opzoeken..."; }

			doModal();
		}
		catch (ex)														{ /* [MT-7614] */  }
	}
	// [MT-4424] Voorbereiding op MT-4424
//	else if (!ISCMS)
//	{
//		if (!wt.parent || wt.parent == wt || $(window).width() > 600)
//		{
//			ui.dialog.createIFrameDialog("/jsp/atsc/UITable.jsp?lookup=true", {
//				noFrameset: true,
//				afterLoad: function(srcWindow, pelem)
//				{
//					myWindow.stopLoading();
//					srcWindow.table.location = url;
//					srcWindow.focus();
//					srcWindow.ui.table.focusTable();
//				}
//			});
//		}
//		else
//		{
//			wt.parent.openLookup(item, url, width, window);
//		}
//	}
	else
	{
		ui.lookup.open(item, url, width);
	}
}

function doModal()
{
	try
	{
		var modal = document.getElementById("modal");
		if (modal.style.visibility != "")
		{
			modal.style.visibility	= "visible";

			//wt.focus(); // TH: Dit werkt niet goed als je in updateAfterTabSwitch save aanroept
		}
		if (modal.style.display == "none")
		{
			modal.style.display = "block";
		}

		ui.quickmenu.disableQuickmenuItems();
	}
	catch (e) {}
}

function undoModal(focusitem)
{
	try
	{
		var modal 								= window.parent.document.getElementById("modal");

		if (modal == null) 						{ modal = document.getElementById("modal"); }

		modal.style.display 					= "none";

		ui.quickmenu.enableQuickmenuItems();

		if (lastitem != null && focusitem) 		{ lastitem.focus(); }

		return true;
	}
	catch (e) {return false;}
}

function closeLookup(ok)
{
	try
	{
		//frame = the lookup frame
		//JB: We have to look this up here because there are two ways this function can be called:
		//it can be called through the onKeyDown of the table when the user presses the Escape key (in which case window = UITable.jsp of the lookup frame)
		//or it can be called when the user clicks on the 'X' of the lookup window (in which case window = the record which the lookup frame is on)

		/*
		 * Throughout this function there are two possibilities for the lookup window:
		 * Case 1) It's within an iframe on *this* window.
		 * Case 2) It's in a dialog window separate from the main program.
		 */

		/*
		 * Determine the frame that the lookup table (UITable.jsp) is loaded in.
		 * This can either be the current window (case 1) or an iframe within the current window (case 2).
		 */
		var frame																														= window;

		if (JSO.getWindowLocation(window).indexOf("lookup=true") == -1)																	{ frame = $.atsc.window.getWindowFromIFrame($("#iframelookup")); }

		/*
		 * Determine the parent frame (this is the document which opened the iframe).
		 * Case 1: The parent frame is the parent frame.
		 * Case 2: The window that opened this one is the parent frame.
		 */
		var recordframe																													= frame.parent;

		// voor als een lookup i.c.m. een record met MODE_SELECT gebruikt wordt:
		if (recordframe.ui.record.startmode == MODE_SELECT)
		{
			var pfff																													= null;

			try																															{ pfff = ui.dialog.getParentWindow(window.parent); }
			catch (e)																													{ /* no problem */ }

			// [MT-11163] && pfff.record_name == "fa1invrgltmp" voor nu als extra conditie zodat niet eens veel meer hier doorheen komt
			if (ui.isRecord(pfff) && pfff.record_name == "fa1invrgltmp") 																{ recordframe = pfff; }
			else
			{
				while ((recordframe.ui.record == null || recordframe.ui.record.startmode == MODE_SELECT) && recordframe.parent != null && recordframe != recordframe.parent)
				{
					recordframe = recordframe.parent;
				}
			}
		}

		try
		{
			if (window.parent.opener.vArguments[3] != null)																				{ recordframe = frame.parent.opener.vArguments[3]; }
		}
		catch (ex)																														{ /* no problem */ }

		/*
		 * [MT-1606] It's possible to close the lookup before it has finished loading (especially on slower connections).
		 * This variable determines if the lookup hasn't finished loading. If wasLoading=true then we shouldn't touch the item and assume a cancel.
		 */
		var wasLoading																													= !frame.ui.initialized || frame.ui.table.isFrameLoading();

		/*
		 * A holder for the affected items. All "lookup keys" are considered affected items and will be modified immediately. Lookup fields will be
		 * modified using a second ajax lookup, which does not take place here.
		 */
		var affectedItems																												= [];

		if (wasLoading)
		{
			/*
			 * The frame is stuck loading so reset the UITableIFrame.jsp to blank.
			 */
			frame.ui.table.destroyData();

			try																															{ frame.table.src = BLANKURL; }
			catch (e)																													{ /* [MT-4904] */ }
		}
		else
		{
			/*
			 * Make a list of items that will be affected by this operation (the "lookup keys" or "lkeys").
			 */

			function parseItems(items)
			{
				var item;
				var names																												= items.split("_<split>_");

				for (var i = 0; i < names.length; i++)
				{
					item																												= recordframe.document.getElementById(names[i]);
					if (item != null)																									{ affectedItems.push(item); }
				}

				if (affectedItems.length <= 0)																							{ throw new Error(""); }
			}

			/*
			 * Case 1 (see first comment for this method)
			 */
			try																															{ parseItems(frame.ui.table.target); }
			catch (ex)
			{
				/*
				 * Case 2 (see first comment for this method)
				 */
				try																														{ parseItems(frame.parent.opener.vArguments[1]); }
				catch (ex2)																												{ /* no problem */ }
			}
		}

		/*
		 * The lookup table must be explicitly deselected to prevent focus problems. (Otherwise the table may receive focus even when it's not visible.)
		 */
		ui.window.deselectOtherFrames(recordframe);

		/*
		 * Legacy target support (where only the first target is set)
		 */
		var target																														= "";
		try																																{ target = affectedItems[0].id; }
		catch (ex)																														{ /* no problem */ }

		try																																{ frame.parent.specificCloseLookupBegin(target, ok); }
		catch (ex)																														{ /* no problem */ }

		/*
		 * Call the event system.
		 */
		recordframe.ui.events.call("ui.window.specificCloseLookupBegin", window, function(e){ _closeLookup(frame, affectedItems, wasLoading, ok, target, recordframe); }, { "frame": frame, "target": target, "item": (affectedItems != null && affectedItems.length > 0 ? affectedItems[0] : null), "affectedItems": affectedItems, "ok": ok, "": "" }, true, false);
	}
	catch (ex)																															{ $.atsc.log.warn(ex); }
}

function _closeLookup(frame, affectedItems, wasLoading, ok, target, recordframe)
{
	try
	{
		/*
		 * From this point forward the 'item' variable references the first item in the list. (the first lkey)
		 */
		var item																														= affectedItems != null && affectedItems.length > 0 ? affectedItems[0] : null;
		var fkey																														= $(item).attr("special-fkey");

		/*
		 * Grabs the div element that contains the current table and hides it.
		 * Case 1: The div element is on the current page (contains iframe w/ UITable.jsp)
		 * Case 2: The div element is in the dialog window
		 */
		var lookupwindow;

		try																																{ lookupwindow = recordframe.document.getElementById("lookupwindow"); }
		catch (ex)																														{ /* no problem */ }

		if (lookupwindow != null) 																										{ lookupwindow.style.display = "none"; }

		undoModal(false);

		frame.zoom																														= false;

		/*
		 * We need to set the inner iframe (containing UITableIFrame.jsp) to a blank url. Unfortunately, IE throws "Access denied" if the frame
		 * is pointing to an external domain (even if the containing iframe is on our own domain), so this is wrapped in a try/catch.
		 */
		try																																{ $("body", frame.table.document).hide(); }
		catch (ex)																														{ /* no problem */ }

		/*
		 * Reset the status message and the focus.
		 */
		setStatusMessage("");
		recordframe.focusCurrentFrame(true);

		if (wasLoading)
		{
			/*
			 * The frame never finished loading, so stop here.
			 */
			stopLoading();
		}
		else
		{
			/*
			 * If there are no items we throw an error. Most likely the lkey is not pointing to a valid item, or none have been provided.
			 */
			if (!item)
			{
				var itemid;
				try																														{ itemid = frame.ui.table.target; }
				catch (ex)																												{ /* no problem */ }

				if (!itemid)
				{
					try																													{ itemid = frame.parent.opener.vArguments[1]; }
					catch (ex)																											{ /* no problem */ }
				}

				$.atsc.log.error("BUG: No item found! Tried to get item \"" + itemid + "\" but it wasn't found on the page. Perhaps you forgot to define a key field for the lookup field?");
				return;
			}

			/*
			 * If an item was selected we'll modify the appropriate lkey fields immediately.
			 */
			if (ok)
			{
				var sourceList																											= frame.ui.table.source.split("_<split>_");
				var kitem;

				var itemFUIDValueIsSet																									= false;

				for (var i = 0; i < affectedItems.length; i++)
				{
					/*
					 * For every lkey field: Set the value to the appropriate value for that field.
					 */
					kitem																												= affectedItems[i];

					if (sourceList[i] == ui.table.table_name + "_unid")																	{ kitem.value = frame.ui.table.getCell(0, frame.ui.table.row).parent().attr("unid"); }
					else
					{
						var v																											= frame.ui.table.getValueFromID(sourceList[i], frame.ui.table.row);

						if (v === null)
						{
							/*
							 * [MT-1781] Don't continue if the value is null.
							 */
							continue;
						}

						if (kitem.getAttribute("allowmultiplevalues") == "true")
						{
							/*
							 * [MT-926] If the field allows multiple values but only one value is selected we'll append it to the list of selected values.
							 * If multiple values are selected, the values are replaced in its entirety.
							 */

							if (frame.ui.table.allowMultipleRecords && frame.ui.table.selectedRecords != null && frame.ui.table.selectedRecords.length > 0)
							{
								/*
								 * Selecting multiple records (i.e., value + uniqueid/fuidvalue)
								 */

								var uniqueids																							= "";
								var first																								= true;

								for (var i = 0; i < frame.ui.table.selectedRecords.length; i++)
								{
									if (!first)																							{ uniqueids += "_<sep>_"; }
									uniqueids																							= uniqueids + frame.ui.table.selectedRecords[i];

									first																								= false;
								}

								$.ajax({
									url: IOSERVLETURL,
									type: "get",
									data: {"action": GETSOURCEVALUES, "queryid": frame.ui.table.queryid, "uniqueids": uniqueids, "fieldName":  frame.ui.table.source},
									dataType: "xml",
									async: false, //[MT-1114] This must be a synchronous request.
									success: function(xmlDoc, textStatus, jqXHR)
									{
										var fuidvalues																					= [];
										var values																						= [];
										var itemValue																					= "";
										var sep																							= recordframe.ui.form.getArraySeparator(kitem);

										$(xmlDoc).find("record").each(function()
										{
											var uniqueid																				= $(this).attr("uniqueid");
											var value																					= $(this).attr("value");

											if ($.inArray(value, values) == -1)
											{
												fuidvalues.push(uniqueid);
												values.push(value);

												if (itemValue == "")																	{ itemValue = value; }
												else																					{ itemValue = itemValue + sep + " " + value; }
											}
										});

										var itemValues																					= "";
										var first																						= true;
										for (var i = 0; i < values.length; i++)
										{
											if (!first)																					{ itemValues += "_sep_"; }
											itemValues																					= itemValues + values[i];
											first																						= false;
										}

										var itemFuidvalues																				= "";
										first																							= true;
										for (var i = 0; i < fuidvalues.length; i++)
										{
											if (!first)																					{ itemFuidvalues += "_sep_"; }
											itemFuidvalues																				= itemFuidvalues + fuidvalues[i];
											first																						= false;
										}

										kitem.setAttribute("values", itemValues);
										kitem.setAttribute("fuidvalue", itemFuidvalues);
										kitem.value																						= itemValue;

										if (kitem.id == item.id)																		{ itemFUIDValueIsSet = true; }
									},
									error: function(jqXHR, textStatus, errorThrown)
									{
										alert(MSG_CONNECTION_SERVER_LOST + "\n - Status: " + textStatus);
									}
								});
							}
							else
							{
								/*
								 * Selecting values only (no fuidvalue)
								 */

//								if (kitem.value != "")
//								{
//									kitem.setAttribute("values", kitem.getAttribute("values") + "_sep_" + v);
//									kitem.value																							= kitem.value + ", " + v;
//								}
//								else
								{
									kitem.setAttribute("values", v);
									kitem.value																							= v;
								}
							}
						}
						else
						{
							/*
							 * This field does not support multiple values, so just set the value directly.
							 *
							 * We don't need to set the fuidvalue unless frame.ui.table.moreresults=true (if it's false it will be looked up afterwards).
							 */

							var cont;

							try																											{ cont = recordframe.isQmapLookupAllowed(item, kitem.id, true, v); }
							catch (ex)																									{ cont = true; }

							if (cont)
							{
								kitem.value																								= v;

								if (i > 0)
								{
									kitem.setAttribute("oldQmapValue", "_<null>_");
									kitem.setAttribute("fuidvalue", "");

									//lookup needs to be false, or checkItem won't be able to do its job
									var oldLookup																						= recordframe.lookup;
									recordframe.lookup																					= false;
									recordframe.checkItem(kitem);
									recordframe.lookup																					= oldLookup;
								}
							}
						}
					}
				}

				if (frame.ui.table.moreresults)
				{
					/*
					 * If there was more than one result (and the user has just chosen the specific item they want) set the unique id and
					 * perform set the qmap fields manually.
					 */

					var errorFields																										= [];
					var vars																											= frame.ui.table.getValues(frame.ui.table.row);

					if (!itemFUIDValueIsSet)																							{ item.setAttribute("fuidvalue", frame.ui.table.getUNIDFromRow(frame.ui.table.row)); }

					var qMapFields																										= getAttribute(item, "qmapping").split("_<split>_");
					var elem;
					var v;
					var cont;

					for (var i = 2; i < qMapFields.length; i += 2)
					{
						elem																											= recordframe.document.getElementById(qMapFields[i + 1]);

						if (elem != null)
						{
							v																											= vars[qMapFields[i]];

							// [MT-7393]
							if (v == null)																								{ v = ""; }

							if (v != null)
							{
								v																										= specialCharCheck(v);

								try																										{ cont = recordframe.isQmapLookupAllowed(item, qMapFields[i + 1], true, v); }
								catch (ex)																								{ cont = true; }

								if (cont)
								{
									elem.value																							= v;

									elem.setAttribute("oldQmapValue", "_<null>_");
	//								elem.setAttribute("qmapDoneByCloseLookup", "true");
									elem.setAttribute("fuidvalue", "");

									//lookup needs to be false, or checkItem won't be able to do its job
									var oldLookup																						= recordframe.lookup;
									recordframe.lookup																					= false;
									recordframe.checkItem(elem);
									recordframe.lookup																					= oldLookup;
								}
							}
							else																										{ errorFields.push(qMapFields[i]); }
						}
					}

					if (errorFields.length > 0)
					{
						var msg																											= "Er is een fout opgetreden! De lookup kon niet voltooid worden omdat de volgende velden niet opgevraagd konden worden:";
						for (var i = 0; i < errorFields.length; i++)																	{ msg += "\n    - " + errorFields[i]; }
						alert(msg);
					}

					destroyTable																										= false;
				}
			}

			/*
			 * Tell the frame that there is no longer a lookup window present.
			 */
			recordframe.lookup																											= false;
			recordframe.lookupKey																										= "";

			if (ok)
			{
				/*
				 * If the request was successful we'll order the qmap fields for all items to be updated appropriately.
				 * (This is only guaranteed to work so long as frame.ui.table.moreresults is false, otherwise it may skip elements.)
				 */
				var kitem;

				for (var i = 0; i < affectedItems.length; i++)
				{
					kitem																												= affectedItems[i];

					if (frame.ui.table.moreresults)
					{
						/*
						 * If there was more than one result we've already filled out the fields with valid ones retrieved from the server,
						 * so mark the field as being valid.
						 */

						if (kitem.getAttribute("allowmultiplevalues") == "true")														{ kitem.setAttribute("oldQmapValue", getAttribute(kitem, "values")); }
						else																											{ kitem.setAttribute("oldQmapValue", kitem.value); }

						kitem.setAttribute("valid", "true");
						kitem.setAttribute("_closelookup", "true");
					}
					else																												{ kitem.setAttribute("oldQmapValue", "_<null>_"); }
				}

				for (var i = 0; i < affectedItems.length; i++)
				{
					kitem																												= affectedItems[i];

					$(kitem).attr("isChanged", "true");

					recordframe.checkItem(kitem);
				}

				$(item).attr("isChanged", "true");

				processNext(item, recordframe);
			}

			/*
			 * Tell the frame that we're completely done with the lookup.
			 */
			recordframe.zoom																											= false;
			recordframe.stopLoading();

			/*
			 * [MT-926] Deselect all rows (if any were selected) and jump back to the first column and row to prevent flickering.
			 */
			if (frame.ui.table.allowMultipleRecords && frame.ui.table.selectedRecords != null && frame.ui.table.selectedRecords.length > 0)
			{
				var rows;
				for (var i = 0; i < frame.ui.table.selectedRecords.length; i++)
				{
					rows																												= frame.ui.table.getRowsByUniqueId(frame.ui.table.selectedRecords[i]);
					for (var r = 0; r < rows.length; r++)																				{ frame.ui.table.unHighlightRow(rows[r]); }
				}

				frame.ui.table.selectedRecords																							= null;
			}

			frame.ui.table.focusCell(0, frame.ui.table.row_start);

			/*
			 * Call the event system.
			 */
			recordframe.ui.events.call("ui.window.specificCloseLookupEnd", window, null, { "frame": frame, "target": target, "item": item, "fkey": fkey, "affectedItems": affectedItems, "ok": ok }, true, false);

			try																															{ if (typeof(frame.parent.specificCloseLookupEnd) != "undefined" && isFunction(frame.parent.specificCloseLookupEnd)){ frame.parent.specificCloseLookupEnd(target, ok); } }
			catch (ex)																													{ $.atsc.log.warn(ex); }
		}

		//[MT-1733] Remove the special-fkey attribute.
		$(item).removeAttr("special-fkey");
	}
	catch (ex)																															{ $.atsc.log.error(ex); }
	finally
	{
		try
		{
			frame.ui.table.destroyData();

			frame.table.src																												= BLANKURL;
		}
		catch (ex)																														{ /* no problem */ }
	}
}

function processNext(item, wwp)
{
	try
	{
		item.blur();

		if (!wwp.JSO.dialogExists()) // Indien een checkSpecial (door de item.blur()) een dialog aanmaakt niet doorgaan
		{
			//IE8 requires a try/catch statement here in order to avoid issues
			//Focus is blurred and reset here in order to select everything in the text field after choosing a date.
			try {item.focus();} catch (e) {}

			if ((typeof(cal_defer_keydown) == "undefined" || !cal_defer_keydown) && item.id != wwp.ui.tab.getLastInputOnTab(wwp.ui.tab.activeTab).id)
			{
				wwp.focusNextElement(item, null, true);
			}
		}
	}
	catch (e)
	{
		try
		{
			var next = wwp.getNextValidElement(item);
			next.focus();
		}
		catch (e) {}
	}
}

function isLookupOpen()
{
	var isopen		 		= false;

	try
	{
		var lookupwindow 	= document.getElementById("lookupwindow");

		if (!isopen && lookupwindow != null) isopen = lookupwindow.style.display != "none";
		if (!isopen && cal != null) 		 isopen = !cal.hidden;

		var gpdwindow 		= $.atsc.window.getWindowFromFrame("gpd");

		if (gpdwindow == null && window.parent) gpdwindow = window.parent.jQuery.atsc.window.getWindowFromFrame("gpd");

		if (!isopen && gpdwindow != null)	 isopen = gpdwindow.document.getElementById("gpdDialog").style.display != "none";

		if (!isopen)
		{
			isopen = ui.lookup.isOpen();
		}
	}
	catch (e) {}

	return isopen;
}

function focusLookup()
{
	try
	{
		$("#iframelookup")[0].contentWindow.ui.table.focusTable();
 	}
 	catch (e) {}
}

function getStatusWindow()
{
	// Note: log messages in this function must set the isStatusDocumentException parameter to 'true' to avoid an infinite loop

	try
	{
		var wtt				= JSO.getOurWindowTop();
		var fr;

		try					{ fr = wtt.getFrame("wstatus", true); }
		catch (ex)			{ fr = null; }

		if (fr == null && wtt.window.opener && wtt.window.opener != wtt)
		{
			wtt				= wtt.window.opener.JSO.getOurWindowTop();
			try				{ fr = wtt.getFrame("wstatus", true); }
			catch (ex)		{ fr = null; }
		}

		return fr ? fr : null;
	}
	catch (ex)
	{
//		try					{ $.atsc.log.warn("Unable to get status document. Topmost window location: " + JSO.getWindowLocation(JSO.getOurWindowTop()), ex, false, true); }
//		catch (ex2)			{ $.atsc.log.warn("Unable to get status document. Topmost window location: unknown", ex, false, true); } // ex instead of ex2 is deliberate
	}
}

function getStatusDocument()
{
	try						{ return getStatusWindow().document; }
	catch (ex)
	{
		try					{ return document.implementation.createHTMLDocument(); }
		catch (ex)			{ return null; }
	}
}

function setStatusMessage(msg, force)
{
	try
	{
		if (isLoading() && !force)				{ nextmsg = msg; }
		else
		{
			var o								= getStatusMessageObject();

			if (o) // may not exist if within a popup
			{
				msg								= msg.replace("<marquee>", "").replace("</marquee>", "");

				o.innerHTML						= "";

				// For performance reasons, if message is greater than N characters check if we need to do a marquee.
				if (msg.length > 25)
				{
					var nextElement				= $(o).next();
					var offsetLeft				= nextElement.offset().left;

					o.innerHTML					= msg.replace(/\s/g, "&nbsp;");

					if (offsetLeft != nextElement.offset().left)
					{
						o.innerHTML				= "<marquee width=\"" + $(o).width() + "\">" + o.innerHTML + "</marquee>";
					}
				}
				else							{ o.innerHTML = msg.replace(/\s/g, "&nbsp;"); }

				nextmsg							= "";
			}
		}
	}
	catch (ex)
	{
		if (!isAccessDeniedError(ex))			{ $.atsc.log.warn(ex); }
	}
}

function getStatusMessageObject()
{
	try											{ return getStatusDocument().getElementById("msg"); }
	catch (ex)									{ return null; }
}

function getStatusMessage()
{
	try {return getStatusMessageObject().innerHTML;} catch(e) {return "";}
}

var userPermissions = null;

function getUserPermissions(callback)
{
	if (!userPermissions)
	{
		$.ajax({
			url: IOSERVLETURL,
			data: { "action": PERMISSIONS },
			cache: false,
			dataType: "json",
			async: false,
			success: function(data)
			{
				userPermissions = data;
			}
		});
	}

	return userPermissions;
}

function hasUserPermission(permissions)
{
	if (typeof(permissions) == "string")											{ permissions = [permissions]; }

	var perms																		= getUserPermissions();
	var found;

	for (var i = 0; i < permissions.length; i = i + 1)
	{
		if ($.inArray(permissions[i], perms) == -1)									{ return false; }
	}

	return true;
}

var userModules = null;

function getUserModules(callback)
{
	if (!userModules)
	{
		$.ajax({
			url: IOSERVLETURL,
			data: { "action": USERMODULES },
			cache: false,
			dataType: "json",
			async: false,
			success: function(data)
			{
				userModules = data;
			}
		});
	}

	return userModules;
}

function hasUserModule(modules)
{
	if (typeof(modules) == "string")												{ modules = [modules]; }

	var mods																		= getUserModules();

	for (var i = 0; i < modules.length; i = i + 1)
	{
		if ($.inArray(modules[i], mods) >= 0)										{ return true; }
	}

	return false;
}

function getUser()
{
	try							{ return getSpecialUser(); }
	catch(e)					{ return USERNAME; }
}

function getUserDisplayName()
{
	try							{ return getSpecialUserDisplayName(); }
	catch (ex)					{ return USERDISPLAYNAME; }
}

function getUserCode()
{
	try							{ return getSpecialUserCode(); }
	catch (ex)					{ return USERCODE; }
}

function getUserUnid()
{
	try							{ return getSpecialUserUnid(); }
	catch (e)					{ return USERUNID; }
}

function getApplicationName()	{ return APPLICATION_NAME; }
function getContextName()		{ return CONTEXT_NAME; }
function isCMS()				{ return ISCMS; }
function getAdminCod()			{ return ADMINCOD; }

function getOrganisationName()	{ return ORGANISATIONNAME; }
function getOrganisationUNID()	{ return ORGANISATIONUNID; }
function getOrganisationCode()	{ return ORGANISATIONCODE; }

function getWareHouse()
{
	try							{ return getStatusDocument().getElementById("warehouse").innerHTML; }
	catch(e)					{ return USERWAREHOUSENAME; }
}

function getWareHouseUNID()
{
	try							{ return getStatusDocument().getElementById("warehouse").getAttribute("unid"); }
	catch(e)					{ return USERWAREHOUSEUNID; }
}

function getWareHouseCod()
{
	try							{ return getStatusDocument().getElementById("warehouse").getAttribute("cod"); }
	catch(e)					{ return USERWAREHOUSECODE; }
}

function getWorkstation()
{
	try							{ return getStatusDocument().getElementById("workstation").innerHTML; }
	catch (e)					{ return ""; }
}

var mtDebugMode = null;
function isDebugMode()
{
	if (mtDebugMode == null)
	{
		try			{ mtDebugMode = getStatusDocument().getElementById("debugmode") != null && getStatusDocument().getElementById("debugmode").innerHTML == "Debug"; }
		catch (e)	{ mtDebugMode = ISDEBUG; }
	}

	return mtDebugMode;
}

function toggleDebugMode()
{
	$.ajax({
		url: IOSERVLETURL,
		type: "post",
		data: {"action": DEBUGMODE, "value": (!isDebugMode() ? "true" : "false")},
		cache: false,
		success: function(xmlDoc, textStatus, jqXHR)
		{
			try
			{
				new_val				= ($(xmlDoc).find("newValue").text() == "true");

				var elem			= getStatusDocument().getElementById("debugmode");
				elem.innerHTML		= (new_val ? "Debug" : "");
				elem.style.display	= new_val ? "table-cell" : "none";

				mtDebugMode			= new_val;
			}
			catch (ex)				{ /* no problem */ }
		},
		error: function(jqXHR, textStatus, errorThrown)
		{
			/* empty */
		}
	});
}

function rejectEveryThing(event)
{
	try {wt.focus();} catch (e) {}
	try {event.keyCode 	= 0;} catch (e) {}
	try {event.cancelBubble	= true;} catch (e) {}
	try {event.returnValue	= false;} catch (e) {}

	return false;
}

function refreshCurrentFrame(reload, event)
{
	if (reload)
	{
		setStatusMessage("");
		if (window.request)
		{
			wt.focus(); // Focus naar bekend object zetten (anders problemen met F5)
			request();
		}
		else
		{
			startLoading();
			getFrame(getCurrentFrameName()).location.reload(true);
		}
	}

	return false;
}

function focusCurrentFrame(focusWindow)
{
	if (focusWindow || ui.hasFocus(window))
	{
		if (JSO.dialogExists())							{ JSO.focusDialog(); } // [MT-11402]
		else
		{
			var df										= ui.window.getDashboardFrame();
			var tab										= null;

			// MT-6700
			if (df && df.ui && df.ui.dashboard)			{ tab = df.ui.dashboard.getTabByWindow(window); }

			if (df == null || tab == null || tab.getWindow() == df.ui.dashboard.getCurrentFrame())
			{
				if (focusWindow)
				{
					if	(JSO.isFrameAccessible(wt.parent))
					{
						try
						{
							// [MT-5467] Make sure the parent (i)frame element has focus. window.focus will not always set the focus properly
							var elem					= wt.parent.jQuery.atsc.window.getFrameFromWindow(wt);

							if (!elem)					{ elem = wt.parent.jQuery.atsc.window.getIFrameFromWindow(wt); }

							if (elem && elem.length > 0){ elem.focus(); }
						}
						catch (e) { /* no problem */ }
					}

					window.focus();
				}

				_focusCurrentFrame();
			}
		}
	}

	// [MT-3403] Track the selected frame within a UIWrapperComponent.
	if (parent && ui.isComponent(parent))				{ parent.ui.component.currentFrame = wt.name; }
}

function _focusCurrentFrame(checkLoading, iframedoc)
{
	if (!JSO.dialogExists())
	{
		try
		{
			// [MT-1869] Make sure that we're not stealing the focus from another frame.

			var frame									= wt.parent;

			if ((!frame || !ui.isComponent(frame)) && ui.isComponent(wt))
			{
				frame = wt;
			}

			// [MT-4243] Added "!frame.ui.window.isInCurrentFrame(wt)" because UIWrapperComponents are permitted to have dialog windows now.
			if (frame && ui.isComponent(frame) && !frame.ui.window.isInCurrentFrame(wt))
			{
				if (frame.ui.component.currentFrame)
				{
					frame								= $.atsc.window.getWindowFromIFrame(frame.document.getElementById(frame.ui.component.currentFrame));

					if (frame != wt)
					{
//						$.atsc.log.warn("UIWrapperComponent: Focus has been overruled by " + JSO.getWindowLocation(frame) + " (current frame)");
						frame.focusCurrentFrame(true);

						wt.focusCurrentFrameDone		= true;
						return;
					}
				}
				else
				{
					try
					{
						frame							= frame.jQuery.atsc.window.getWindowFromIFrame(frame.jQuery("iframe:first"));

						if (frame != wt)
						{
//							$.atsc.log.warn("UIWrapperComponent: Focus has been overruled by " + JSO.getWindowLocation(frame) + " (first frame)");
							frame.focusCurrentFrame(true);

							wt.focusCurrentFrameDone	= true;
							return;
						}
					}
					catch (ex)							{ /* no problem */ }
				}
			}
		}
		catch (ex)									{ /* no problem */ }

		ifd = iframedoc;
		if (!isLoading())
		{
			var focusok = 0;

			try
			{
				//If our parent is the frameset of the FA component (the page "/jsp/fa/index.jsp")
				//then we need to reset the focus to the selected tab. [MT-932]
				if (ui.isFaUIFrameset(parent))
				{
					var frame			= $.atsc.window.getWindowFromFrame("wmain", parent);
					frame.ui.wrapper.focusCurrentTab();

					focusok				= 11;
				}
				else if (ui.isWidgetDashboard(getFrame(getCurrentFrameName())))
				{
					getFrame(getCurrentFrameName()).focusActiveWidget();

					focusok				= 12;
				}
				else
				{
					throw new Exception("");
				}
			}
			catch (ex)
			{
				var frame = getFrame(getCurrentFrameName());

				// Proberen: IFrame focus te geven.
				try
				{
					var ff = frame.frames;
					var ffl = ff.length;
					var ffk;

					if (!frame.isLookupOpen()) // Als er geen lookup open staat probeer dan iframe focus te geven.
					{
						for (var k = 0; k < ffl; k++)
						{
							ffk = ff[k];

							if (iframedoc != null)
							{
								if (ffk.document == iframedoc)
								{
									var t = 0;

									var grandparent = iframedoc.frames[0].parent.parent;

									try {t = grandparent.ui.tab.activeTab;} catch (e) {}

									var focusiframe = false;

									if (t == 0)
									{
										try
										{
											focusiframe = grandparent.focusiframe;
											grandparent.focusiframe = true;
										}
										catch (e) {}
									}

									if (mode == MODE_READ || t != 0 || focusiframe) // Om iframe op eerste tab geen focus te geven bij openen record in wijzigen of toevoeg modus.
									{
										ffk.focus();
										focusok = 1;
									}
								}
							}
							else
							{
								var iFrame = null;
								// Even kijken of het iframe wel op een actieve tab zit anders moet body focus krijgen
								try
								{
									if (document.ui.tab.tabPanels == null)
									{
										if (ui.isTable(ffk))
										{
											iFrame = ffk;
										}
									}
									else
									{
										var tabIframes = ui.tab.tabPanels[ui.tab.activeTab].getElementsByTagName("IFRAME");
										var l = tabIframes.length;

										for (var r = 0; r< l; r++)
										{
											if (ff[tabIframes[r].name].document.table.document.getElementById("name").value == ffk.name)
											{
												iFrame = ff[tabIframes[r].name];
											}
										}
									}
								}
								catch(e) {}

								if (iFrame != null)
								{
									iFrame.focus();
									focusok = 2;
								}
							}

							try													{ ffk.initTitle(); }
							catch (ex)
							{
								var title										= ui.isComponent(wt) && wt.document.title ? wt.document.title : ffk.document.title;

								// [MT-3225]
								if (title != "")
								{
									wt.document.title							= title;
									ui.events.call("ui.window.specificInitTitle", wt);

									if (ui.isComponent(wt))
									{
										wt.parent.window.document.title = title;
										ui.events.call("ui.window.specificInitTitle", wt.parent);
									}
								}
							}

							try													{ ffk.initStatus(); }
							catch (ex)											{ /* no problem */ }
						}
					}
					else // Als er een lookup open staat deze altijd focus geven.
					{
						var lookupwindow										= frame.document.getElementById("lookupwindow");

						if (lookupwindow && lookupwindow.style.display != "none")
						{
							var lookupframe										= frame.jQuery.atsc.window.getWindowFromIFrame("iframelookup");

							lookupframe.ui.table.focusTable();
							lookupframe.initStatus();

							focusok												= 8;

							wt.focusCurrentFrameDone = true;
						}
						else
						{
							ff[ffl-1].focus();
							focusok = 8;
						}
					}
				}
				catch(e) {}

				if (focusok != 8)
				{
					try			{ frame.initTitle(); }
					catch (ex)
					{
						try
						{
							wt.document.title									= frame.document.title

							ui.events.call("ui.window.specificInitTitle", wt);
						}
						catch(ex)												{ /* no problem */ }
					}
					try {frame.initStatus();} catch(e) {}
				}

				if (focusok != 1 && focusok != 8)
				{
//					try
//					{
//						var tableframe			= ui.window.getCurrentTable();
//						if (tableframe != null)
//						{
//							tableframe.ui.table.focusTable();
//							focusok				= 9;
//						}
//					}
//					catch (ex)					{ /* no problem */ }

//					if (focusok != 9)
//					{
//						try
//						{
//							var ed				= ui.window.getCurrentTinyMCEEditor();
//							if (ed != null)
//							{
//								ui.window.setTinyMCE(ed);
//								focusok			= 10;
//							}
//						}
//						catch (ex)				{ /* no problem */ }
//					}

					if (focusok != 9 && focusok != 10)
					{
						// Andere browsers hebben problemen met het zetten van focus als het huidige frame geen focus heeft
						try					{ frame.focus(); }
						catch (ex)			{ /* no problem */ }

						try
						{
							if (frame.currentField != null)
							{
								if (isContentEditable(frame.currentField) || $(frame.currentField).hasClass("UL"))
								{
									var tinymceid = $("#tinymce", frame.currentField).attr("data-id");

									if (tinymceid) // [MT-10583]
									{
										// Proberen: TinyMCE veld focus te geven
										tinyMCE.get(tinymceid).focus();
									}
									else
									{
										// Proberen: veld waar vanaf vertokken is (m.b.v. F4) focus geven.
										frame.currentField.focus();
									}

									focusok = 3;
								}
							}
							else
							{
								try
								{
									//Proberen: laatste tabel focus te geven
									if (frame.ui.record.last_table_iframe != null)
									{
										frame.focusFirstElement();
										focusok = 5.1;
									}
									else
									{
										throw "noLastTableFrame";
									}
								}
								catch (ex)
								{
									// Proberen: eerste veld focus te geven.
									try
									{
										frame.specificFocusFirstElement();
										focusok = 4;
									}
									catch(e)
									{
										try
										{
											frame.focusFirstElement();
											focusok = 5;
										}
										catch(e){}
									}
								}
							}
						}
						catch(e) {}
					}
				}

				if (focusok == 0)
				{
					if (ui.isTable(frame))
					{
						frame.ui.table.focusTable();
						focusok									= 6;
					}
					else if (ui.isDashboard(frame))
					{
						frame.ui.dashboard.focusCurrentTab();
						focusok									= 13;
					}
					else
					{
						// Proberen: document body focus te geven.
						try
						{
							frame.document.body.focus();
							focusok								= 7;
						}
						catch (ex)								{ /* no problem */ }

						if (focusok == 0)
						{
							try	{frame.focus(); focusok = 8;} catch(e) {}
						}
					}
				}
			}

//			console.log("UIWindow.js, focusCurrentFrame: focus: " + focusok + "; uri: " + JSO.getWindowLocation(frame, true) + "; currentFrame: " + getCurrentFrameName() + "; window: " + JSO.getWindowLocation(window));
//			alert(focusok);
//			$.atsc.log.println("UIWindow.js, focusCurrentFrame: focus: " + focusok + "; uri: " + JSO.getWindowLocation(frame, true) + "; currentFrame: " + getCurrentFrameName() + "; window: " + JSO.getWindowLocation(window));

			ui.window.fixTitle();

			wt.focusCurrentFrameDone = true;
		}
		else
		{
			setTimeout(function(){ _focusCurrentFrame(checkLoading, ifd); }, RETRY_DELAY);
		}
	}
}

function getPreviousFrameName()
{
	return getFrameTags()[getCurrentFrameName()].getAttribute("previousFrame");
}

function showPreviousFrame(reload, newURL)
{
	if (!isLoading())
	{
		if (previousWindowURL != "")
		{
			JSO.setWindowLocation(window, previousWindowURL);

			previousWindowURL = "";
		}
		else
		{
			try
			{
				specificShowPreviousFrame(reload);
			}
			catch (e)
			{
				if (!previousbreadcrumb())
				{
					try
					{
						var cfn = getCurrentFrameName();

						if (cfn != "w0")
						{
							wt.showFrame(null, getFrameTags()[cfn].getAttribute("previousFrame"), reload, newURL, cfn);
						}
						else
						{
							if (ui.isDashboard(wt.parent))
							{
								if (newURL)
								{
									wt.parent.focus();
									wt.parent.document.body.focus();

									wt.processUserFiles();

									JSO.setWindowLocation(window, newURL);
								}
								else
								{
									var frame				= ui.window.getDashboardFrame();
									var tab					= frame.ui.dashboard.getTabByWindow(wt);

									wt.pushUserFilesTo(frame.parent);

									frame.parent.processUserFiles(undefined, true);

									if (ui.isRecord(wt.getFrame("w0")))
									{
										if (tab)			{ tab.close(); }
										else if ((!tab || !tab.closing) && ui.isRecord(wt.getFrame("w0")))
										{
											wt.parent.focus();
											wt.parent.document.body.focus();

											wt.location.reload(false);
										}
									}
								}
							}
							else
							{
								try {wt.gotoHomePage(getFrame("w0"));} catch (e) {}
							}
						}
					}
					catch (e) {window.close();}
				}
			}
		}
	}
}

function emptyFrame(cfn)
{
	ui.window.setFrameLocation(getFrame(cfn), BLANKURL);
}

function isFrameLoading(frameName)
{
	try
	{
		var framedoc = getFrame(frameName).document;

		return (framedoc == null || framedoc.readyState != "complete");
	}
	catch (e) {return false;}
}

function showFrameOrOpenTab(url, showIfExists, forceUseGivenURL)
{
	var fr											= ui.window.getDashboardFrame();

	if (fr == null)
	{
		window.focus();

		showFrame(url);
	}
	else
	{
		// [MT-4497] Reset the focus so that we can't spam the Enter key.
		fr.focus();
		fr.document.body.focus();

		if (showIfExists)
		{
			var tab									= fr.ui.dashboard.getTabByURL(url);

			if (tab != null)
			{
				if (forceUseGivenURL)				{ tab.setUrl(url); }
				else
				{
					try								{ tab.getWindow().getFrame("w0").document.body.scrollTop = 0; }
					catch (ex)						{ /* no problem */ }

					try
					{
						// [MT-6728] Specific override for wf1act
						if (tab.getWindow().getFrame('w0').hasBeenModified)
						{
							tab.getWindow().getFrame('w0').jQuery('#btnRefresh').trigger('click');
						}
					}
					catch (ex)						{ /* no problem */ }
				}

				tab.show();

				return;
			}
		}

		// [MT-4497] Don't show the record immediately to prevent interaction with elements on the record.
		fr.ui.dashboard.newTab().setUrl(url).show();
	}
}

function showFrame(url, frameName, reload, newURL, emptyFrameName)
{
	if (!isLoading())
	{
		try
		{
			if (wt.parent.wt && ui.isWidgetDashboard(wt.parent.wt.getFrame(wt.getCurrentFrameName())))
			{
				JSO.openDocLink(url);
				return;
			}
		}
		catch (ex)									{ $.atsc.log.warn(ex); }

		wt.focusCurrentFrameDone = false;

		ui.window.deselectOtherFrames(window);

		try											{ ui.quickmenu.disableQuickmenuItems(); }
		catch (ex)									{ }

		startLoading();
		try
		{
			var cfn = getCurrentFrameName();

			if (frameName==null) frameName = getRelativeFrameName(cfn, +1);

			ui.window.allocateFrames(parseInt(frameName.substring(1)) + 1, function()
			{
				if (!isFrameLoading(frameName)) // Synchronizeren van showFrame
				{
					wt.currentFrameName = frameName; // TH: DIT STOND VOOR DE !isFrameLoading(frameName) if

					var nextFrameName = frameName;
					var previousFrameName = cfn;
					var pf = getFrameTags();

					if (url != null)
					{
						if (pf == null) window.open(url, "_parent");
						else
						{
							var pfnf = pf[nextFrameName];
							pfnf.setAttribute("previousFrame", previousFrameName);

							var nf = getFrame(nextFrameName);
							ui.window.updateFrameURL(nextFrameName);

							// [MT-3853] Append the current frame name to the URL (_pfn).
							if (url.indexOf("?") > -1)			{ url = url + "&"; }
							else								{ url = url + "?"; }

							// Determine the currentFrameName based on the current Window object (the caller of this method).
							var wnd								= window;
							var curfr;

							while (wnd && !curfr)
							{
								wt.jQuery("frame").each(function()
								{
									if ($.atsc.window.getWindowFromFrame(this) == wnd)
									{
										curfr					= this;
										return false;
									}
								});

								if (wnd == wnd.parent)			{ break; }
								else							{ wnd = wnd.parent; }
							}

							url									= url + "_pfn=" + (curfr ? $(curfr).attr("name") : cfn);
							// [MT-3853] End of MT-3853

							ui.window.setFrameLocation(nf, url);
						}
					}
					else
					{
						try
						{
							var cfr = getFrame(cfn);
							var nfr = getFrame(nextFrameName);

							if (reload)
							{
								ui.window.updateFrameURL(nextFrameName);

								if (doCloseRecord(cfr, nfr))
								{
									nfr.jQuery(nfr.form).data("isSaved", true);
									nfr.jQuery("body").hide();
									nfr.quit(true);
								}
								else
								{
									var nfrs = nfr.frames;
									var nfrsl = nfrs.length

									if (nfrsl > 0) // IFrame(s) reloaden
									{
										var frame;
										for (k=0; k<nfrsl; k++)
										{
											frame		= nfrs[k];

											//It's possible for the iframe to be UITableIFrame.jsp (if we're going back
											//to a table), so we make sure frame = UITable.jsp here.
											if(frame.parent && ui.isTable(frame.parent))		{ frame = frame.parent; }

											if(ui.isTable(frame))
											{
												//If the table is inside a record, make sure the record is marked as being
												//changed.
												if(frame.parent && ui.isRecord(frame.parent))	{ frame.parent.ui.record.setIsChanged(true); }

												//The table may only be refreshed if it isn't in look-up mode, because
												//lookups are invisible.
												if(!frame.ui.table.lookup_mode)					{ frame.ui.table.forceRefresh(false); }
											}
										}
									}
								}
							}
							else
							{
								if (doCloseRecord(cfr, nfr))
								{
									nfr.jQuery("body").hide();
									nfr.quit(true);
								}
							}
						}
						catch(e) { }
					}

					setStatusMessage("");

					if (newURL == null)
					{
						wt.updateFrameSet(nextFrameName, emptyFrameName);
					}
					else
					{
						stopLoading();
						showFrame(newURL);
					}
				}
				else {stopLoading();}
			}, function()
			{
				// [MT-6825] Temporary fix. Remove *after* release of MT5.12
				if (url.startsWith("/jsp/wf/uiform/uiform_wf1act.jsp"))			{ window.open(url, "_blank") }
			});
		}
		catch(e) { $.atsc.log.warn(e); }
	}
}

function doCloseRecord(cfr, nfr) // [MT-4442]
{
	return ui.isRecord(cfr) && ui.isRecord(nfr) && nfr.mode == MODE_READ && cfr.record_name == nfr.record_name;
}

function updateFrameSet(frameName, emptyFrameName)
{
	if (wt.doUpdateFrameSet)
	{
		try
		{
			var frame													= getFrame(frameName);
			var cont													= ui.window.getFrameReadyState(frame) == "complete";

			if (cont)
			{
				try														{ cont = !ui.isRecord(frame) || !frame.ui.record.loading; }
				catch (ex)												{ cont = true; $.atsc.log.warn(ex); }
			}

			if (cont)
			{
				var errormsg											= frame.document.getElementById("errormsg");
				var pf													= getFrameTags();

				// in FF is errormsg null (doordat de readyState te vroeg 'complete' geeft) en in IE niet bij het printen (van een PDF) (voorbeeld: printen van factuur 5-1; Alt-P; [enter])
				// JB: is dit nog van toepassing?

				if (errormsg == null)
				{
					getFrameSet().cols									= pf[frameName].getAttribute("framecols");
					wt.processUserFiles();
				}
				else
				{
					var msg												= $(errormsg).html().trim();
					// [MT-7520]
					if (frameName != "w0" && getFrameSet().cols === pf[frameName].getAttribute("framecols"))
					{
						var pfn											= pf[frameName].getAttribute("previousFrame");

						getFrameSet().cols								= pf[pfn].getAttribute("framecols");
					}

					wt.emptyFrame(wt.getCurrentFrameName()); // [MT-11596]

					wt.currentFrameName									= getPreviousFrameName();

					wt.focusCurrentFrame(true); // [MT-11596]

					wt.focusCurrentFrameDone							= true;

					if (msg != "")
					{
						var eventFrame					= getFrame(getCurrentFrameName()); // [MT-4276]
						var kdEvent						= eventFrame.ui.events.call("ui.window.specificFrameErrorMessage", eventFrame, null, { "msg": msg }, false);

						if (!kdEvent.isDefaultPrevented())
						{
							// Detecteren of jquery UI aanwezig is. Zo niet: alsnog de alert geven!
							// JB: Wanneer komt dit voor??
							if ($.ui.version != undefined)	{ JSO.showMessage(msg); }
							else 							{ alert(msg); }
						}
					}

					getFilesToPrint();
				}

				if (emptyFrameName)
				{
					if (getFrameSet().cols.split(",")[getFrameNumber(emptyFrameName)] == 0)
					{
						wt.emptyFrame(emptyFrameName);
					}
				}

				stopLoading();

				try {frame.specificRefresh();} catch(e) {}
			}
			else
			{
				if (!isLoading()) {startLoading();}

				setTimeout(function(){ updateFrameSet(frameName, null) }, RETRY_DELAY);
			}
		}
		catch (ex)
		{
			stopLoading();
		}
	}
}

function getFilesToPrint()
{
	JSO.lastActionHero("getFilesToPrint", function(processEvent) // [MT-11359]
	{
		processXMLDoc(IOSERVLETURL + "?action=" + GETFILESTOPRINT, "processUserFiles", undefined, true);
		processEvent();
	});
}

// TH: Wie gebruikt dit?
function isCorrect(x)
{
	return x != "undefined" && x != null && x != "";
}

function startLoading()
{
	if (!wt.loading)
	{
		wt.loading = true;

		if (CHROME) // Bij openen lookup (F4) wordt anders geen blur op huidige element aangeroepen.
		{
			try
			{
				var frame = ui.window.getCurrentFrame();

				if (ui.isRecord(frame))
				{
					if (frame.lastitem != null) frame.lastitem.blur();
				}
			}
			catch (e) {}
		}

		try			{ ui.quickmenu.disableQuickmenuItems(); }
		catch(ex)	{}

		wt.focus(); // Set focus to top window, to prevent key strokes on page that is loading
		setLoadingMessage("Bezig met laden", true);
	}
}

function stopLoading()
{
	if (wt.loading)
	{
		try			{ ui.quickmenu.enableQuickmenuItems(); }
		catch(ex)	{}

		wt.loading = false;
		setStatusMessage(nextmsg);

		ui.events.call("ui.window.specificStopLoading", window);
	}
}

function setLoadingMessage(defaultmsg, showdefaultmsg)
{
	if (isLoading())
	{
		if (showdefaultmsg) setStatusMessage(defaultmsg, true);
		else
		{
			var msg	= getStatusMessage();
			var ml	= msg.length;

			if (ml == 0 || ml >= (defaultmsg.length + 45))	{ setStatusMessage(defaultmsg, true); }
			else											{ setStatusMessage(msg + " .", true); }
		}

		setTimeout(function(){ setLoadingMessage(defaultmsg, false); }, RETRY_DELAY);
	}
}

var last_frame_accessible_url1;
var last_frame_accessible_url2;
var last_frame_accessible_count = 0;
function isFrameAccessible(frame)
{
	try
	{
		var test							= undefined;
		test								= frame.document;
		if (test == undefined)				{ throw new Exception(); }
		else								{ return true; }
	}
	catch (ex)								{ return false; }
}

function isLoading()
{
	try
	{
		if (wt.loading == null)							{ wt.loading = false; }

		//Test if we have access to the frames.
		if (wt.loading)
		{
			var l										= wt.frames.length;

			for (var f = 0; f < l; f++)
			{
				if (!isFrameAccessible(wt.frames[f]))	{ throw new Exception(); }
			}
		}

		return wt.loading;
	}
	catch (e)											{ return false; }
}

function getFrame(frameName, allowNull)
{
	if (typeof(allowNull) == "undefined")				{ allowNull = false; }

	try
	{
		var result										= ui.window.getFrame(frameName);

		if (!result && !allowNull)						{ return window; }

		return result;
	}
	catch (e)
	{
		// [MT-9076]
		if (allowNull)									{ return null; }
		else											{ return window; }
	}
}

function getFrameSet()
{
	return wt.document.getElementById("windows");
}

function getFrameTags()
{
	try												{ return getFrameSet().getElementsByTagName("frame"); }
	catch (e)										{}
}

function getCurrentFrameName()
{
	if (wt == undefined)							{ return "w0"; } // [MT-8115]
	else
	{
		if (wt.currentFrameName == null)			{ wt.currentFrameName = "w0"; }

		return wt.currentFrameName;
	}
}

function getRelativeFrameName(frameName, pos)
{
	return "w" + (getFrameNumber(frameName) + pos);
}

function getFrameNumber(frameName)
{
	if (!frameName || frameName == "") frameName = "w0";
	return parseInt(frameName.substring(1, frameName.length));
}

function getFrameName(window)
{
	try									{ return $(window.frameElement).attr("name") }
	catch (ex)							{ return ""; }
}

function rejectFocus()
{
	if (!isLoading())
	{
		if (window.parent.isRecord)
		{
			if (window.parent.isLookupOpen())
			{
				$("#iframelookup")[0].contentWindow.focus();
			}
			else
			{
				getFrame(getCurrentFrameName()).focus();
			}
		}
		else getFrame(getCurrentFrameName()).focus();

		focusCurrentFrame(true);
	}

	return false;
}

function download(file)
{
	var wnd										= JSO.getOurWindowTop();

	try
	{
		if (wnd && wnd.frames["wtemp"])			{ JSO.setWindowLocation(wnd.frames["wtemp"], file); }
		else									{ throw new Exception(""); }
	}
	catch (ex)
	{
		if (ui.isFrameset(wnd))
		{
			/*
			 * last resort: this only works if the popup blocker is disabled, may not work
			 * in app mode since you can't disable the popup blocker in it (and it seems to
			 * come pre-enabled), in app mode it switches to another (tabbed) browser window.
			 */

			window.open(file);
		}
		else
		{
			// [MT-3320] Implemented to allow PDF files to be downloaded when window.open is not possible (due to popup blocker)
			// Tested in IE10/IE11, Chrome 34, Firefox 29, Safari 5

			var elem							= $("#downloadIFrame", wnd.document.body);

			if (elem.length <= 0)
			{
				elem							= $("<iframe src=\"" + file + "\" style=\"position: absolute; left: -9001px;\" name=\"downloadIFrame\" id=\"downloadIFrame\"></iframe>");

				$(wnd.document.body).append(elem);
			}
			else								{ elem.attr("src", file); }
		}
	}
}

function launchWindow(url, name, height, width)
{
	var str = "height=" + (height - 30);
	str += ",width=" + (width - 10);
	if (window.screen)
	{
		var ah = screen.availHeight - 30;
		var aw = screen.availWidth - 10;

		var xc = (aw - (width - 10)) / 2;
		var yc = (ah - (height - 30)) / 2;

		str += ",left=" + xc + ",screenX=" + xc;
		str += ",top=" + yc + ",screenY=" + yc;
	}
	return window.open(url, name, str + ", resize=no, menubar=no", true);
}

function getNowDate()
{
	var date;

	try							{ date = getStatusDocument().getElementById("nowdate").innerHTML; }
	catch (ex)					{ date = toDutchDate(new Date()); }

	return date;
}

function getDbNowDate()			{ return toDate(setDutchDate(getNowDate())); }

function getNowYear()
{
	var year;

	try							{ year = getStatusDocument().getElementById("nowyear").innerHTML; }
	catch (ex)					{ year = new Date().getUTCFullYear(); }

	return year;
}

function getNowQuarter()
{
	var quarter;

	try							{ quarter = getStatusDocument().getElementById("nowquarter").innerHTML; }
	catch (ex)					{ quarter = JSO.toInt(new Date().getMonth() / 3) + 1; }

	return quarter;
}

function getNowMonth()
{
	var month;

	try							{ month = getStatusDocument().getElementById("nowmonth").innerHTML; }
	catch (ex)					{ month = (new Date().getMonth()) + 1; }

	return month;
}

function getNowWeek()
{
	var week;

	try							{ week = getStatusDocument().getElementById("nowweek").innerHTML; }
	catch (ex)					{ week = getNowYear() + "," + ((new Date().getWeek())); }

	return week;
}

function getPreviousYear()
{
	var year;

	try							{ year = parseInt(getStatusDocument().getElementById("nowyear").innerHTML) - 1; }
	catch (ex)					{ year = parseInt(new Date().getUTCFullYear() - 1); }

	return year;
}

function getPreviousMonth()
{
	var month;

	try							{ month = parseInt(getStatusDocument().getElementById("nowmonth").innerHTML) - 1; }
	catch (ex)					{ month = parseInt(new Date().getMonth() - 1); }

	if (month == 0)				{ month = 12; }

	return month;
}

function getPreviousMonthYear()
{
	if (getPreviousMonth() == 12) return getPreviousYear(); else return getNowYear();
}

var processUserFilesXmlDocs	= null;
function addXmlDocToProcess(xmlDoc)
{
	try
	{
		if(processUserFilesXmlDocs == null)
		{
			processUserFilesXmlDocs = [];
		}

		processUserFilesXmlDocs.push(JSO.xmlToString(xmlDoc));
	}
	catch (ex)				{ /* no problem */ }
}

function pushUserFilesTo(wnd)
{
	if (processUserFilesXmlDocs != null && processUserFilesXmlDocs.length > 0)
	{
		for(var i = 0; i < processUserFilesXmlDocs.length; i++)
		{
			if (wnd.processUserFilesXmlDocs == null)
			{
				wnd.processUserFilesXmlDocs = [];
			}

			wnd.processUserFilesXmlDocs.push(processUserFilesXmlDocs[i]);
		}

		processUserFilesXmlDocs = null;
	}
}

function processUserFiles(xmlDoc, skipFocus)
{
	if (typeof(xmlDoc) == "undefined" || xmlDoc == null)
	{
		if (processUserFilesXmlDocs != null && processUserFilesXmlDocs.length > 0)
		{
			var xd;
			for(var i = 0; i < processUserFilesXmlDocs.length; i++)
			{
				xd						= JSO.stringToXml(processUserFilesXmlDocs[i]);

				if(xd == null)
				{
					$.atsc.log.debug("WARNING: null xmlDoc found in processUserFilesXmlDocs");
					continue;
				}

				processUserFiles(xd, true);
			}
			processUserFilesXmlDocs		= null;
		}

		if (!skipFocus)					{ focusCurrentFrame(true); }

		return;
	}

	var processed						= false; // [MT-11573]

	if (xmlDoc != null)
	{
		processed						= processed || processMessagesInXMLMessage(xmlDoc);
		processed						= processed || processFilesInXMLMessage(xmlDoc);
	}

	if (processed && !skipFocus)		{ focusCurrentFrame(true); }
}

function previousbreadcrumb()
{
	var pbc = document.getElementById("previousbreadcrumb");

	if (pbc != null)
	{
		try
		{
			JSO.setWindowLocation(window, pbc.href);
			return true;
		}
		catch (e) {return false;}
	}
	else
	{
		try
		{
			specificpreviousbreadcrumb();

			return true;
		}
		catch (e) {return false;}
	}
}

function openEclipse(uri)
{
	try	{ download("atsc://" + uri); } catch (e) {}
}

// TH: Waar wordt dit gebruikt?
function getVersion()
{
	if (typeof(version) == "undefined")
	{
		return 1;
	}
	else
	{
		return version;
	}
}

function processFilesInXMLMessage(xmlDoc)
{
	xmlDoc																		= $(xmlDoc);
	var files																	= xmlDoc.find("files");

	if (files.length > 0)
	{
		var	popupFiles															= [];
		var downloadFiles														= [];
		var printFiles															= [];

		files																	= files.find("file");
		files.each(function(){
			var path															= $(this).attr("path");
			var sysfls_unid														= $(this).attr("sysfls_unid");
			var action															= $(this).attr("action");
			var data															= {"path": path.replace(/\\/g, "/"), "sysfls_unid": sysfls_unid, "url": ""};


			if		(data["path"])												{ data["url"] = data["path"]; }
			else if	(data["sysfls_unid"])										{ data["url"] = "/file/options/forcedownload/db/" + data["sysfls_unid"]; }

			data["url"]															= data["url"].replace(/#/g, "%23");

			switch (action)
			{
				case "print":
					printFiles.push(data);
					break;

				case "download":
					downloadFiles.push(data);
					break;

				case "popup":
					popupFiles.push(data);
					break;

				default:
					$.atsc.log.debug("ERROR: File with an invalid action (\"" + action + "\")!");
					break;
			}
		});

//		alert(popupFiles.length);

		if (popupFiles.length == 1)
		{
			downloadFiles.push(popupFiles[0]);

			popupFiles															= [];
		}
//		alert(downloadFiles.length);

		if (downloadFiles.length > 1)
		{
			for (var i = 0; i < downloadFiles.length; i++)						{ popupFiles.push(downloadFiles[i]); }

			downloadFiles														= [];
		}

//		alert(printFiles.length);
/* Indien print als download wordt aangeboden zul je enkel de eerste download zien. Meerdere files tegelijkertijd downloaden is niet toegestaan in Chrome.
		if (printFiles.length > 1)
		{
			for (var i = 0; i < printFiles.length; i++)							{ popupFiles.push(printFiles[i]); }

			printFiles															= [];
		}
*/
		if (popupFiles.length > 0)
		{
			var popupHTML														= "<div id=\"popupmsg\"><span>Klik op de betreffende knop om het bestand te downloaden</span><br /><div style=\"width: 100%; height: 200px; margin-top: 8px; overflow: auto;\">";
			var fileData;
			for (var i = 0; i < popupFiles.length; i++)
			{
				fileData														= popupFiles[i];

				popupHTML														= popupHTML + "<br /><button class=\"atsc-button\" id=\"downloadButton" + i + "\" onclick=\"download('"
																				+  fileData["url"] + "'); return false;\">";

				if (fileData["name"])											{ popupHTML = popupHTML + fileData["name"]; }
				else if (fileData["path"])										{ popupHTML = popupHTML + fileData["path"].backwardsright("/"); }
				else if(fileData["sysfls_unid"])								{ popupHTML = popupHTML + fileData["sysfls_unid"] }

				popupHTML														+= "</button><br />";
			}

			popupHTML															+= "</div></div>";

			var dialog = ui.dialog.createModalDialog({
				title:			getApplicationName(),
				html:			popupHTML,
				width:			600,
				buttons:		[
									{
										text: "Sluiten",
										click: function(){
											this.dialog("close");
										}
									}
								],
				closable:		true,
				closeOnEscape:	true,
				open:			function(e, ui)
				{
					var elems													= this.find(".atsc-button");

					elems.css("position", "static"); // [MT-1102]

					elems.on("keydown", function(e)
					{
						if (e.which == 37)
						{
							// Prevent the Left Arrow Key from jumping to the first item in the list.

							e.preventDefault();
							return false;
						}

						//             Up arrow         Down arrow
						if (e.which == 38 || e.which == 40)
						{
							e.preventDefault();

							var elems											= $(this).parent().find(".atsc-button");
							var index											= elems.index(this);
							var i												= index;

							if (e.which == 38) //up arrow
							{
								i												= i -1;

								if (i < 0)										{ i = elems.length - 1; }
							}
							else if(e.which == 40) //down arrow
							{
								i												= i + 1;

								if (i >= elems.length)							{ i = 0; }
							}

							var elem											= elems.eq(i);

							$(this).blur();
							elem.focus();

							return false;
						}

						if (e.which == 13)
						{
							e.preventDefault();
							$(this).trigger("click");
							return false;
						}

						return true;
					});
				},
				setFocus:		function()
				{
					$(":focus").blur();
					$(".atsc-button", this).first().focus();
				},
				onKeyDown:		function(e)
				{
					if (e.which == 37)
					{
						ui.dialog.focus(this);
						return false;
					}

					return true;
				}
			});
		}

		if (downloadFiles.length > 0)
		{
			var fileData;
			for (var i = 0; i < downloadFiles.length; i++)
			{
				fileData														= downloadFiles[i];
				download(fileData["url"]);
			}
		}

		if (printFiles.length > 0)
		{
			var fileData;
			for (var i = 0; i < printFiles.length; i++)
			{
				fileData														= printFiles[i];
				download(fileData["url"]);//, ((fileData["path"] && fileData["path"].indexOf(".pdf") > -1) ? "_self" : "_blank"));
			}
		}

		return popupFiles.length > 0 || downloadFiles.length > 0 || printFiles.length > 0;
	}

	return false;
}

function processMessagesInXMLMessage(xmlDoc)
{
	xmlDoc																		= $(xmlDoc);
	var messages																= xmlDoc.find("infomessages");

	if (messages.length > 0)
	{
		var found																= false;

		messages																= messages.find("infomessage");
		messages.each(function(){
			found																= true;
			var message															= $(this).text();

			JSO.showMessage(message);
		});

		return found;
	}
	else																		{ return false; }
}

function focusFrame(frame)
{
	frame.focus();
	try { frame.ui.quickmenu.updateQuickmenu(); } catch (ex){}
}

(function()
{
	try
	{
		// If this window is itself a popup redirect the $.atsc.popup functionality.
		if (window.opener)
		{
			$.atsc.popup														= window.opener.jQuery.atsc.popup;
			return;
		}
	}
	catch (ex)																	{ /* no problem */ }

	try
	{
		// Redirect the $.atsc.popup functionality to the topmost window as to share all popups throughout the program.
		var wnd																	= ui.window.getTopmostFrame();

		if (wnd != window)
		{
			$.atsc.popup														= wnd.jQuery.atsc.popup;
			return;
		}
	}
	catch (ex)																	{ /* no problem */ }

	// The code below is executed only once for the parent frameset of the entire program.

	var prefix																	= "";

	if (prefix == "")
	{
		try
		{
			var wnd																= ui.window.getTopmostFrame();

			if (wnd && wnd.name.indexOf("program") > -1)						{ prefix = wnd.name.substring(wnd.name.indexOf("program") + 7); }
		}
		catch (ex)																{ /* no problem */ }
	}

	if (prefix == "")															{ prefix = Math.round(Math.random() * 10000); }

	var popups																	= [];
	var popupId																	= 0;
	var creators																= [];

	function getPopupName(id)													{ return "popup_" + prefix + "_" + id; }

	/**
	 * Usage: $.atsc.popup.getPopup(id)
	 *
	 * Returns the Window object for the given popup by its id.
	 *
	 * Arguments:
	 *   id: The unique id of the popup, provided after a call to $.atsc.popup.createPopup.
	 *
	 * Returns:
	 *   The Window object for the given popup, or null if the popup couldn't be found.
	 *
	 */
	function getPopup(id)
	{
		var popupName															= getPopupName(id);

		for (var i = 0; i < popups.length; i++)
		{
			try
			{
				if (popups[i].name == popupName)								{ return popups[i]; }
			}
			catch (ex)															{ /* no problem */ }
		}

		return null;
	}

	/**
	 * Usage: $.atsc.popup.getCreator(id)
	 *
	 * Returns the Window object for the creator of a given popup by its id.
	 *
	 * Arguments:
	 *   id: The unique id of the popup, provided after a call to $.atsc.popup.createPopup.
	 *
	 * Returns:
	 *   The Window object for the creator of the given popup, or null if the popup couldn't be found.
	 *
	 */
	function getCreator(id)
	{
		return creators[getPopupName(id)];
	}

	/**
	 * Usage: $.atsc.popup.createPopup(url)
	 * Usage: $.atsc.popup.createPopup(url, options)
	 *
	 * Attempts to spawn a new popup with the provided URL. The URL is automatically wrapped in an UIWrapperFrameset.
	 *
	 * Arguments:
	 *   url: The start URL of the popup.
	 *   options: A list of options to overrule when creating the popup.
	 *
	 * Returns:
	 *   A unique id for the new popup, or -1 if the popup could not be created (popup blocker?)
	 *
	 */
	function createPopup(callingWindow, url, options)
	{
		try
		{
			if (!options)														{ options = {}; }

			var settings														= $.fn.extend({
				rx: 0,						// X coordinate (relative to center)
				ry: 0,						// Y cooordinate (relative to center)
				x: null,					// X coordinate (absolute)
				y: null,					// Y coordinate (absolute)
				disableWrapper: false,		// Don't wrap in an UIWrapperFrameset
				width: 640,					// The initial width of the popup
				height: 480					// The initial height of the popup
			}, options);

			var id																= ++popupId;
			var popupName														= getPopupName(id);

			var x;
			var y;

			if (settings["x"])													{ x = settings["x"]; }
			if (settings["y"])													{ y = settings["y"]; }

			if (!x || !y)
			{
				var centerX;
				var centerY;

				if 		(window.outerWidth)
				{
					centerX														= screen.width / 2 - window.outerWidth / 2;
					centerY														= screen.height / 2 - window.outerHeight / 2;
				}
				else if (document.body.clientWidth)
				{
					centerX														= screen.width / 2 - document.body.clientWidth / 2;
					centerY														= screen.height / 2 - document.body.clientHeight / 2;
				}

				if (!x)															{ x = centerX + settings["rx"]; }
				if (!y)															{ y = centerY + settings["ry"]; }
			}

			if (!settings["disableWrapper"])									{ url = "/jsp/atsc/UIWrapperFrameset.jsp?popup=true&startPage=" + encodeURIComponent(url); }

			var wnd																= window.open(url, popupName, "toolbar=no,titlebar=no,location=no,width=" + settings["width"] + ",height=" + settings["height"]);

			if (wnd)
			{
				try																{ wnd.dlgParent = window; }
				catch (ex)														{ $.atsc.log.warn(ex); }

				try																{ wnd.moveTo(x, y); }
				catch (ex)														{ $.atsc.log.warn(ex); }

				try																{ wnd.popupId = id; }
				catch (ex)														{ $.atsc.log.warn(ex); }

				popups.push(wnd);

				creators[popupName]												= callingWindow;

				return id;
			}
			else																{ throw ""; }
		}
		catch (ex)																{ return -1; } //9 out of 10 times this is the popup blocker
	}

	/**
	 * Usage: $.atsc.popup.closePopups()
	 *
	 * Closes all popups created by this instance of the program.
	 *
	 */
	function closePopups()
	{
		for (var i = 0; i < popups.length; i++)
		{
			try																	{ popups[i].logout = true; }
			catch (ex)															{ /* no problem */ }

			try																	{ popups[i].close(); }
			catch (ex)															{ /* no problem */ }
		}
	}

	function isPopup(wnd)														{ return typeof(wnd.wt.dlgParent) != "undefined" && wnd.wt.dlgParent != null }

	function getPrefix()														{ return prefix; }

	function createGlobalPopup(popupName, callingWindow, url)
	{
		var popupId																= createPopup(callingWindow, url);

		if (popupId > -1)
		{
			ui.properties.getGlobalProperties()[popupName]						= getPopup(popupId);

			var onUnload;

			onUnload															= function ()
			{
				try																{ closePopups(); }
				catch (ex)														{ /* no problem */ }
			};

			$(callingWindow).on("unload", onUnload);

			var eventId;

			eventId																= ui.events.bind("ui.window.specificPopupClose", function (e, processEvent, srcWindow)
			{
				if (srcWindow == ui.properties.getGlobalProperties()[popupName])
				{
					ui.properties.getGlobalProperties()[popupName]				= undefined;

					ui.events.unbind("ui.window.specificPopupClose", eventId);

					$(window).off("unload", onUnload);
				}

				processEvent();
			});
		}
	}

	function getGlobalPopup(popupName)											{ return ui.properties.getGlobalProperties()[popupName]; }

	$.atsc.popup																= {};
	$.atsc.popup.getPopup														= getPopup;
	$.atsc.popup.getCreator														= getCreator;
	$.atsc.popup.createPopup													= createPopup;
	$.atsc.popup.closePopups													= closePopups;
	$.atsc.popup.isPopup														= isPopup;
	$.atsc.popup.getPrefix														= getPrefix;
	$.atsc.popup.createGlobalPopup												= createGlobalPopup;
	$.atsc.popup.getGlobalPopup													= getGlobalPopup;
})($);

$(document).ready(function()
{
	/*
	 * Referenced frames
	 * -----------------
	 *
	 * In short: frames that are dependent on other frames. i.e., tables that specify the foreignvalues of other tables,
	 * records that load based on a value selected in a table, etc.
	 *
	 * This code is disabled if there are no iframe elements with the class "ref" on the current frame.
	 */

	var elements																		= $("iframe.ref");

	if (elements.length > 0)
	{
		var blockCellCheck																= false; //user managed
		var initialized																	= false;
		var loadedFrames																= [];
		var currentFrame																= null;
		var isFrameLoading																= false;
		var loading																		= [];
		var disabled																	= [];

		function getFrame(elem)
		{
			if (JSO.toString(elem.attr("data-ref-direct")) == "true")					{ return $.atsc.window.getWindowFromIFrame(elem); }
			else																		{ return $.atsc.window.getWindowFromIFrame(elem).getFrame("w0"); }
		}

//		function waitUntilLoaded(wnd, callback)
//		{
//			if (wnd.ui.table.isFrameLoading())											{ $.atsc.log.println("wait! " + wnd.ui.table.queryid); setTimeout(waitUntilLoaded.bind(this, wnd, callback), 100); }
//			else																		{ callback(); }
//		}

		/*
		 * Retrieves the value of a given property.
		 * Used to get the field values for fields specified with data-ref-foreignUNIDValue / data-ref-uniqueid
		 *
		 * wnd = UITable to get the value from
		 * field = name of the field
		 * row = the row to get the field from, if you don't specify this the selected row is used
		 */
		function getValue(wnd, field)
		{
			var value																	= "";
			var row																		= wnd.ui.table.row;

			if (wnd.ui.table.getCell(0, row, false).length <= 0)						{ return null; }

			if (wnd.ui.table.getUnidName() == field)									{ value = wnd.ui.table.getUNIDFromRow(row); }
			else
			{
				var vars																= wnd.ui.table.getValues(row);

				if (typeof(vars[field]) == "undefined")
				{
					value																= wnd.ui.table.getValueFromID(field, row);

					if (isNull(value))
					{
						vars															= wnd.ui.table.getForeignFields();

						if (isNull(vars[field]))										{ value = ""; }
						else															{ value = vars[field]; }
					}
				}
				else																	{ value = vars[field]; }
			}

			try																			{ value = value.replace(/,/g, "_<comma>_"); }
			catch (ex)																	{ /* no problem */ }

			return value;
		}

		/*
		 * True if the data-ref-foreignUNIDValue component or data-ref-uniqueid is a reference to a UITable frame.
		 * False to treat as literal.
		 */
		function isReferencedValue(value)
		{
			// Value contains a dot, so treat as a reference to another table's values.
			return value.indexOf(".") > -1;
		}

		/*
		 * True if the iframe is disabled (does not auto-update), false if auto updating is acceptable.
		 *
		 * "true" = set manually by other code to disable updating, i.e., if the iframe is hidden from the user
		 * "save1"/"save2" = set automatically. prevents the iframe from being refreshed until the record within is saved or closed
		 * "temp" = set during update procedure to prevent tables that are reloading from refreshing the current table
		 *
		 * For manual uses: "true" if you want to exclude the iframe from this system, "once" if you want to set the location of a record to a value not present
		 * in the table (i.e., MODE_ADD)
		 */
		function isDisabled(elem)
		{
			if ($.inArray($(elem).attr("id"), disabled) > -1)							{ return true; }
			else
			{
				var val																	= toString($(elem).attr("data-ref-disabled"));

				return val == "true" || val == "temp";
			}
		}

		/*
		 * Closes any records that may be present on the frame or any of its children.
		 */
		function closeFrame(elem, callback)
		{
			try																			{ ui.window.checkFrame(window, CHECKFRAME_CLOSE, callback); }
			catch (ex)																	{ /* no problem */ }
		}

		/*
		 * Checks all (i)frames on the current window and their children to see if any of them
		 * may not be closed.
		 */
		function checkFrames(callback)
		{
			var skip																	= [];
			var cont																	= true;

			elements.each(function()
			{
				var frame																= $.atsc.window.getWindowFromIFrame($(this));

				try
				{
					if (ui.isFrameset(frame))
					{
						// If any frame is not on the main window, stop immediately as we can't close it.
						if (frame.getCurrentFrameName() != "w0")
						{
							if (callback)												{ callback(false); }

							cont														= false;

							if (!callback)
							{
								var cframe												= frame.getFrame(frame.getCurrentFrameName());

								if (cframe)												{ cframe.jQuery("body").fadeOut(10).fadeIn(10); }
							}

							return false;
						}
					}
				}
				catch (ex)																{ /* no problem */ }

				// Disabled records may always be skipped.
				if (isDisabled(this))													{ skip.push(frame); }
			});

			if (cont)
			{
				if (callback)
				{
					ui.window.checkFrame(window, CHECKFRAME_NORMAL, function(success)
					{
						if (success)
						{
							var skip													= [];
							elements.each(function()
							{
								if (isDisabled(this))									{ skip.push($.atsc.window.getWindowFromIFrame($(this))); }
							});

							ui.window.checkFrame(window, CHECKFRAME_CLOSE, function()
							{
								callback(false);
							}, skip, true);
						}
						else															{ callback(false); }
					}, skip, true);
				}
				else																	{ return ui.window.checkFrame(window, CHECKFRAME_CHECKONLY, null, skip, true); }
			}
			else																		{ return false; }
		}

		function getUniqueID(uniqueid)
		{
			if (isReferencedValue(uniqueid))
			{
				var ielem																= $("#" + uniqueid.left("."));
				var field																= uniqueid.right(".");
				var frame																= getFrame(ielem);

				uniqueid																= getValue(frame, field);

				if (uniqueid === null)													{ uniqueid = -1; }
			}

			return uniqueid;
		}

		function getQueryID(elem)
		{
			var value																	= toString(elem.attr("data-ref-queryid-field"));

			if (value)
			{
				if (isReferencedValue(value))
				{
					var ielem															= $("#" + value.left("."));
					var frame															= getFrame(ielem);

					value																= getValue(frame, value.right("."));
				}

				if (value !== null)
				{
					var values															= toString(elem.attr("data-ref-queryid-values")).split(",");
					var spl;

					for (var i = 0; i < values.length; i++)
					{
						spl																= values[i].split("|");

						if (spl[0] == value)											{ return spl[1]; }
					}
				}
			}

			return toString(elem.attr("data-ref-queryid"));
		}

		function waitForTableFrames()
		{
			// Once all UITable frames have been initialized prevent code from running if the UITable frames aren't all loaded.
			// i.e., prevents a concurrent table refresh and getValue/getQueryID/getUniqueID from taking place [MT-5444]

			var defer																	= jQuery.Deferred();

			if (initialized)
			{
				JSO.each(elements, function(item, process, i)
				{
					try
					{
						var fr															= getFrame($(item));

						if (fr && ui.isTable(fr) && fr.ui.table.isFrameLoading())		{ setTimeout(process.bind(null, false), 100); }
						else															{ process(true); }
					}
					catch (ex)
					{
						// Continue (i + 1) on frame error, likely won't fix itself.
						// Occurs if UIWrapperFrameset.jsp is loading or 'w0' is not yet available (getFrame call will fail).

						process(true);
					}
				}, function(){ defer.resolve(); });
			}
			else																		{ defer.resolve(); }

			return defer.promise();
		}

		function updateFrame(elem, reload)
		{
			waitForTableFrames().done(function()
			{
				var disabled															= toString(elem.attr("data-ref-disabled"));

				if (!isDisabled(elem))
				{
					var type															= toString(elem.attr("data-ref-type"));
					var recordParams													= toString(elem.attr("data-ref-recordparams"));

					if (recordParams != "")
					{
						var m;

						while ((m = recordParams.match(/\[([^\]]*)\]/)) != null)
						{
							if (isReferencedValue(m[1]))
							{
								// Assumption: The requested table is already loaded.

								var ielem												= $("#" + m[1].left("."));
								var field												= m[1].right(".");
								var value												= "";

								if (ielem.length > 0)									{ value = getValue(getFrame(ielem), field); }

								if (value === null)										{ recordParams = recordParams.replace(new RegExp(m[0].replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1")), "_<noresult>_"); }
								else													{ recordParams = recordParams.replace(new RegExp(m[0].replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1")), encodeURIComponent(value)); }
							}
							else														{ recordParams = recordParams.replace(new RegExp(m[0].replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1")), m[1]); }
						}
					}

					if (type == "table")
					{
						var queryid														= getQueryID(elem);

						if (reload)
						{
							var mode													= toString(elem.attr("data-ref-mode"));
							var foreignUNIDName											= toString(elem.attr("data-ref-foreignUNIDName"));
							var foreignUNIDValue										= toString(elem.attr("data-ref-foreignUNIDValue"));
							var values													= foreignUNIDValue.split(",");

							foreignUNIDValue											= "";

							var fields;

							for (var i = 0; i < values.length; i++)
							{
								if (i > 0)												{ foreignUNIDValue = foreignUNIDValue + ","; }

								fields													= values[i].split("|");

								for (var j = 0; j < fields.length; j++)
								{
									if (j > 0)											{ foreignUNIDValue = foreignUNIDValue + "|"; }

									if (isReferencedValue(fields[j]))
									{
										// Assumption: The requested table is already loaded.

										var ielem										= $("#" + fields[j].right(">_").left("."));
										var field										= fields[j].right(".");
										var value										= "";
										var prefix										= fields[j].indexOf(">_") > -1 ? fields[j].left(">_") + ">_" : "";

										if (ielem.length > 0)							{ value = getValue(getFrame(ielem), field); }

										if (value === null)								{ foreignUNIDValue = foreignUNIDValue + "_<noresult>_"; }
										else
										{
											// [MT-3104] Add encodeURIComponent // [MT-11615] & vervangen
											foreignUNIDValue							= foreignUNIDValue + prefix + encodeURIComponent(value.replace(/&/g, "_<ampercent>_"));
										}
									}
									else
									{
										// [MT-3104] Add encodeURIComponent // [MT-11615] & vervangen
										foreignUNIDValue								= foreignUNIDValue + encodeURIComponent(fields[j].replace(/&/g, "_<ampercent>_"));
									}
								}
							}

							addLoading(elem);

							foreignUNIDName												= encodeURIComponent(foreignUNIDName); // [MT-11521]

							var url														= "/jsp/atsc/UITable.jsp?queryid=" + queryid + "&mode=" + mode + "&foreignUNIDName=" + foreignUNIDName + "&foreignUNIDValue=" + foreignUNIDValue;

							if (recordParams != "")										{ url = url + "&recordParams=" + recordParams; }

							var ext														= toString(elem.attr("data-ref-ext"));

							if (ext)													{ url = url + ext; }

							if (JSO.toString(elem.attr("data-ref-direct")) == "true")	{ elem.attr("src", JSO.convertWindowLocation(window, url)); }
							else														{ elem.attr("src", JSO.convertWindowLocation(window, "/jsp/atsc/UIWrapperFrameset.jsp?startPage=" + encodeURIComponent(url.replace(/"/g, "&quot;")))); }
						}
						else
						{
							var frame													= getFrame(elem);

							var foreignUNIDName											= toString(elem.attr("data-ref-foreignUNIDName")).split(",");
							var values													= toString(elem.attr("data-ref-foreignUNIDValue")).split(",");
							var fields;
							var tmp;
							var cont;

							for (var i = 0; i < values.length; i++)
							{
								fields													= values[i].split("|");
								tmp														= "";
								cont													= false;

								for (var j = 0; j < fields.length; j++)
								{
									if (j > 0)											{ tmp = tmp + "|"; }

									if (isReferencedValue(fields[j]))
									{
										cont											= true;

										var ielem										= $("#" + fields[j].right(">_").left("."));
										var field										= fields[j].right(".");
										var value										= "";
										var prefix										= fields[j].indexOf(">_") > -1 ? fields[j].left(">_") + ">_" : "";

										if (ielem.length > 0)							{ value = getValue(getFrame(ielem), field); }

										if (value === null)								{ tmp = tmp + "_<noresult>_"; }
										else											{ tmp = tmp + prefix + value; }
									}
									else												{ tmp = tmp + fields[j]; }
								}

								frame.ui.table.setForeignField(foreignUNIDName[i], tmp);
							}

							if (frame.ui.table.queryid != queryid)
							{
								frame.ui.table.queryid									= queryid;
								frame.ui.table.isChanged								= true;
							}

							if (JSO.toString(recordParams) != JSO.toString(frame.ui.table.recordParams))
							{
								frame.ui.table.recordParams								= recordParams;
								frame.ui.table.isChanged								= true;
							}

							if (frame.ui.table.isChanged)
							{
								frame.ui.table.search_queries							= null;
								frame.ui.table.selectedRecords							= null;
								frame.ui.table.selectRowMode							= null;

								// [MT-4688] Apply extra parameters after the queryid changes.
								var ext													= toString(elem.attr("data-ref-ext"));

								if (ext)												{ frame.ui.table.processQueryString(ext); }

								addLoading(elem);
							}

							frame.ui.table.refreshIfChanged(true);
						}
					}
					else if (type == "record")
					{
						if (elem.attr("data-ref-disabled") == "save1" || (elem.attr("data-ref-disabled") == "save2" && !ui.window.checkFrame($.atsc.window.getWindowFromIFrame(elem), CHECKFRAME_CHECKONLY)))
						{
							return false;
						}

						var jspFile														= toString(elem.attr("data-ref-jsp"));
						var mode														= toString(elem.attr("data-ref-mode"));
						var uniqueid													= toString(elem.attr("data-ref-uniqueid"));
						var url															= "/jsp/atsc/blank.html";

						if (mode != MODE_ADD)
						{
							uniqueid													= getUniqueID(uniqueid);

							if (uniqueid && uniqueid != "0" && uniqueid != "-1")
							{
								url														= jspFile + "?mode=" + mode + "&uniqueid=" + uniqueid;

								try
								{
									var frame											= getFrame(elem);

									if (ui.isRecord(frame))
									{
										if (frame.startmode != MODE_ADD && frame.uniqueid == uniqueid)
										{
											refreshRecord(elem);

											return false;
										}
									}
									else if (JSO.getWindowLocation(frame).indexOf("/jsp/atsc/blank.html") == -1 && JSO.getWindowLocation(frame).indexOf("/jsp/error-uirecord.jsp") == -1)
									{
										// [MT-5482] Prevent refresh while the record is loading.
										return false;
									}
								}
								catch (ex)												{ /* no problem */ }
							}
						}
						else															{ return false; }

						addLoading(elem);

						closeFrame(elem, function(success)
						{
							if (success)												{ elem.attr("src", JSO.convertWindowLocation(window, "/jsp/atsc/UIWrapperFrameset.jsp?startPage=" + encodeURIComponent(url.replace(/"/g, "&quot;")))); }
						});
					}
				}

				if (disabled == "temp")													{ removeTemp(elem); }
			});
		}

		function removeTemp(elem)
		{
			var i																		= elem.data("ref-skip-length") - 1;

			elem.data("ref-skip-length", i)

			if (i <= 0 && toString(elem.attr("data-ref-disabled")) == "temp")
			{
				elem.attr("data-ref-disabled", "false");
			}
		}

		function loadFrame(elem)
		{
			// This method is only called once to load the frame initially. Frames may not be loaded twice.
			if ($.inArray(elem.attr("id"), loadedFrames) == -1 && !checkIsFrameLoading(elem))
			{
				addLoading(elem);

				updateFrame(elem, true);
			}
		}

		function addLoading(elem)
		{
			isFrameLoading																= true;
			if (!checkIsFrameLoading(elem))												{ loading.push(elem.attr("id")); }
		}

		function getElementFromEvent(e, srcWindow, allowChildFrames)
		{
			var elem																	= srcWindow.wt == wt ? $.atsc.window.getIFrameFromWindow(srcWindow) : $.atsc.window.getIFrameFromWindow(srcWindow.wt);

			if (elem != null && elem.length > 0 && elem.hasClass("ref"))
			{
				var frame																= getFrame(elem);

				var valid																=	!initialized ||
																							JSO.getWindowLocation(frame).indexOf("/jsp/atsc/blank.html") > -1 ||
																							JSO.getWindowLocation(frame).indexOf("/jsp/error-uirecord.jsp") > -1 ||
																							srcWindow == frame ||
																							(allowChildFrames && srcWindow.wt != wt && srcWindow.wt.getFrame("w1") == srcWindow);

				return valid ? elem : null;
			}
			else { return null; }
		}

		function checkLoading(elem)
		{
			var index																	= $.inArray(elem.attr("id"), loading);
			if (index > -1)
			{
				loading.splice(index, 1);
				isFrameLoading															= loading.length > 0;

				if (!isFrameLoading && (window.document.activeElement == null || window.document.activeElement.tagName == "BODY"))
				{
					$.atsc.window.getWindowFromIFrame(elem).focusCurrentFrame(true);
				}
			}
		}

		function checkIsFrameLoading(elem)												{ return $.inArray(elem.attr("id"), loading) > -1; }

		// Prevent Escape from aborting requests when loading frames.
		$(document).on("keydown", function(e)
		{
			if (isFrameLoading && e.which == 27)										{ e.preventDefault(); return true; }
		});

		ui.events.bind("ui.record.specificAfterLoad", function(e, processEvent, srcWindow)
		{
			var elem																	= getElementFromEvent(e, srcWindow);

			if (elem != null && toString(elem.attr("data-ref-type")) == "record" && getFrame(elem) == srcWindow)
			{
				if (elem.attr("data-ref-disabled") == "save1")							{ elem.attr("data-ref-disabled", "save2"); }
			}

			processEvent();
		});

		// On initialization of referenced records: If we expected this one to load set isFrameLoading to false so we can use the tables again.
		ui.events.bind("ui.afterFrameLoad", function(e, processEvent, srcWindow)
		{
			var elem																	= getElementFromEvent(e, srcWindow);

			if (elem != null && toString(elem.attr("data-ref-type")) == "record")
			{
				checkLoading(elem);

				// [MT-2653]
				if (toString(elem.attr("data-ref-disabled")) == "temp")					{ removeTemp(elem); }
			}

			processEvent();
		});

		// On initialization of referenced tables: Add them to the "loaded frames list" and load the next frame(s) as needed.
		ui.events.bind("ui.table.afterInitFrame", function(e, processEvent, srcWindow)
		{
			var elem																	= getElementFromEvent(e, srcWindow);

			if (elem != null)
			{
				checkLoading(elem);

				if (initialized)
				{
					// [MT-2653]
					if (toString(elem.attr("data-ref-disabled")) == "temp")				{ removeTemp(elem); }

					// [MT-4410] If no errors by 01-01-2015 remove this comment + the line below.
					// Previously used to reload records when they were being saved. This is now being averted.
//					srcWindow.ui.table.focusCell(srcWindow.ui.table.col, srcWindow.ui.table.row);
				}
				else
				{
					if (elem != null)
					{
						if (!elem.attr("id"))											{ $.atsc.log.warn("WARNING: Element does not have an ID: " + elem.attr("src")); }
						if ($.inArray(elem.attr("id"), loadedFrames) == -1)				{ loadedFrames.push(elem.attr("id")); }

						initialized														= (loadedFrames.length >= elements.filter("[data-ref-type=table]").length);

						$("iframe.ref").each(function()
						{
							if ($.inArray($(this).attr("id"), loadedFrames) == -1 && !checkIsFrameLoading($(this)))
							{
								// Ordering is available but optional. In theory referenced tables work without any specified ordering.
								var order												= parseInt(toString($(this).attr("data-ref-order")));

								if (isNaN(order) || order <= (loadedFrames.length + 1))
								{
									var dependencies									= $(this).data("ref-dependencies");
									var cont											= true;

									if (dependencies)
									{
										for (var i = 0; i < dependencies.length; i++)
										{
											if ($.inArray(dependencies[i], loadedFrames) == -1)	{ cont = false; break; }
										}
									}

									if (cont)											{ loadFrame($(this)); }
								}
							}
						});
					}
				}
			}

			processEvent();
		});

		ui.events.bind("ui.table.specificTblNavigationStart", function(e, processEvent, srcWindow)
		{
			var elem																	= getElementFromEvent(e, srcWindow);

			if (elem != null && toString(elem.attr("data-ref-type")) == "table")
			{
				currentFrame															= elem.attr("id");
				blockCellCheck															= true;
			}

			processEvent();
		});

		ui.events.bind("ui.table.specificTblNavigationStop", function(e, processEvent, srcWindow)
		{
			var elem																	= getElementFromEvent(e, srcWindow);

			if (elem != null && toString(elem.attr("data-ref-type")) == "table")
			{
				blockCellCheck															= false;

				// This is needed to trigger the specificAfterFocusCell event below.
				srcWindow.ui.table.focusCell(srcWindow.ui.table.col, srcWindow.ui.table.row);
			}

			processEvent();
		});

		ui.events.bind("ui.table.specificOnClick", function(e, processEvent, srcWindow)
		{
			if (blockCellCheck)
			{
				var elem																= getElementFromEvent(e, srcWindow);

				if (elem != null)
				{
					currentFrame														= elem.attr("id");
					blockCellCheck														= false;
				}
			}

			processEvent();
		});

		// [MT-4633]
		function isWaiting(elem)
		{
			try
			{
				if (getFrame(elem).ui.table.isFrameLoading())							{ return true; }
			}
			catch (ex)																	{ /* no problem */ }

			var dependencies															= elem.data("ref-dependencies");
			var frame;

			for (var i = 0; i < dependencies.length; i = i + 1)
			{
				frame																	= getFrame($("#" + dependencies[i]));

				try
				{
					if (frame.ui.table.isFrameLoading())								{ return true; }
				}
				catch (ex)																{ /* no problem */ }
			}

			return false;
		};

		ui.events.bind("ui.table.specificTblBeforeExecute", function(e, processEvent, srcWindow)
		{
			var elem																	= getElementFromEvent(e, srcWindow);

			if (elem != null && toString(elem.attr("data-ref-type")) == "table" && (!initialized || isFrameLoading || isWaiting(elem) || !checkFrames()))
			{
				e.preventDefault();
			}

			processEvent();
		});

		ui.events.bind("ui.table.specificBeforeFocusCell", function(e, processEvent, srcWindow)
		{
			var elem																	= getElementFromEvent(e, srcWindow);

			if (elem != null && toString(elem.attr("data-ref-type")) == "table")
			{
				if (!initialized || isFrameLoading || isWaiting(elem))
				{
					if (!e.data.override_init)											{ e.preventDefault(); }
				}
				else if (!checkFrames())
				{
					// Note that this may create a "maximum call stack size exceeded" error message if the synchronous version returns false and the async version keeps returning true

					e.preventDefault();

					checkFrames(function(success)
					{
						if (success)													{ srcWindow.ui.table.focusCell(e.data.col, e.data.row); }
					});
				}
			}

			processEvent();
		});

		ui.events.bind("ui.table.specificAfterFocusCell", function(e, processEvent, srcWindow)
		{
			if (initialized && !blockCellCheck)
			{
				var elem																= getElementFromEvent(e, srcWindow);

				if (elem != null && toString(elem.attr("data-ref-type")) == "table")
				{
					var main_dependencies												= elem.data("ref-dependencies");
					var main_depended_by												= elem.data("ref-depended-by");

					elements.each(function()
					{
						if ($.inArray($(this).attr("id"), main_depended_by) > -1)
						{
							var local_depended_by										= $(this).data("ref-depended-by");
							var local_dependencies										= $(this).data("ref-dependencies");
							var cont													= true;

							for (var i = 0; i < local_dependencies.length; i++)
							{
								if (local_dependencies[i] != elem.attr("id") &&					// Current element != elem
									$.inArray(local_dependencies[i], main_depended_by) > -1 &&	// Current element needs elem to function
									$.inArray(local_dependencies[i], main_dependencies) == -1)	// elem does not need current element to function
								{
									cont												= false;
								}
							}

							if (cont)													{ updateFrame($(this), false); }
						}
					});
				}
			}

			processEvent();
		});

		function refreshRecord(elem)
		{
			if (toString(elem.attr("data-ref-type") == "record"))
			{
				var frame																= getFrame(elem);

				if ((frame.wt == wt || frame.getCurrentFrameName() == "w0") && ui.isRecord(frame) && frame.isClosed)
				{
					if (document.activeElement == null || document.activeElement == $(elem)[0])
					{
						window.focus();
					}

					if (frame.startmode == MODE_ADD)
					{
						if (frame.isSaved)
						{
							addLoading(elem);

							var new_url													= JSO.getWindowLocation(frame);
							new_url														= new_url.replace("?mode=" + MODE_ADD, "?mode=" + MODE_EDIT);
							new_url														= new_url.replace("&mode=" + MODE_ADD, "&mode=" + MODE_EDIT);
							new_url														= new_url.replace(/\?uniqueid=\d+/, "");
							new_url														= new_url.replace(/\&uniqueid=\d+/, "");
							new_url														= new_url + "&uniqueid=" + frame.uniqueid;

 							JSO.setWindowLocation(frame, new_url);
						}
					}
					else
					{
						addLoading(elem);

						frame.location.reload(false);
					}
				}
			}
		}

		var checkUpdateCounter																= 0;

		function checkUpdate(elem, requiresFullUpdate)
		{
			var refreshSelf																	= false;

			if (!checkIsFrameLoading(elem) && !isDisabled(elem) && elem.attr("data-ref-type") == "record")
			{
				var frame																	= getFrame(elem);

				// [MT-4410] Always refresh the record if it's closed.
				if (frame.isClosed)															{ refreshRecord(elem); }

				// [MT-4410] And don't bother refreshing other iframes if this one hasn't been saved yet.
				if (!frame.isSaved)
				{
					// [MT-4883] Special case when aborting MODE_ADD: Force refresh
					if (frame.startmode == MODE_ADD)										{ refreshSelf = true; }
					else																	{ return; }
				}
			}

			if (disabled.length > 0)
			{
				var c																		= ++checkUpdateCounter;

				setTimeout(function()
				{
					if (c == checkUpdateCounter)											{ checkUpdate(elem, requiresFullUpdate); }
				}, 500);
			}
			else
			{
				var update																	= toString(elem.attr("data-ref-update")).split(",");

				for (var i = update.length - 1; i >= 0; i = i - 1)
				{
					if (!update[i] || $("#" + update[i]).length <= 0)
					{
						update.splice(i, 1);
					}
				}

				if (update.length > 0)
				{
					// Prevent the current iframe from being reloaded by the frames we're going to reload.
					// Unless refreshSelf is true, in that case we allow it.
					if (!isDisabled(elem) && !refreshSelf)									{ disabled.push(elem.attr("id")); }

					// Prevent the frames we're going to reload from being reloaded by the other frames we're going to reload.
					for (var i = 0; i < update.length; i = i + 1)
					{
						if (update[i])
						{
							if (!isDisabled($("#" + update[i])))							{ disabled.push(update[i]); }
						}
					}

					var totalFrames															= update.length;
					var refreshedFrames														= 0;

					function onRefresh(b, srcWindow)
					{
						refreshedFrames														= refreshedFrames + 1;

//						$.atsc.log.println("refreshed " + refreshedFrames + " of " + totalFrames + " frames: " + srcWindow.ui.table.queryid);

						if (refreshedFrames == totalFrames)									{ disabled = []; }
					}

//					$.atsc.log.println("tables locked; refreshing " + totalFrames + " frames...");

					var ielem;
					var frame;

					for (var i = 0; i < update.length; i = i + 1)
					{
						if (update[i])
						{
							try
							{
								ielem														= $("#" + update[i]);
								frame														= getFrame(ielem);

								frame.ui.table.forceRefresh(requiresFullUpdate, undefined, onRefresh);
							}
							catch (ex)														{ /* no problem */ }
						}
					}
				}
			}
		}

		ui.events.bind("ui.table.specificKeyDown", function(e, processEvent, srcWindow)
		{
			try
			{
				var elem																= getElementFromEvent(e, srcWindow);

				if (elem != null)
				{
					if (!checkFrames())
					{
						e.preventDefault();
					}
				}
			}
			catch (ex)																	{ /* no problem */ }

			processEvent();
		});

		ui.events.bind("ui.table.specificOnKeyDown", function(e, processEvent, srcWindow)
		{
			try
			{
				var elem																= getElementFromEvent(e, srcWindow);

				if (elem != null)
				{
					if (!e.data.deferUpdate && checkFrames())
					{
						e.callbacks.push(function(){ checkUpdate(elem, false); });
					}
				}
			}
			catch (ex)																	{ /* no problem */ }

			processEvent();
		});

		function tableUpdateEvent(e, processEvent, srcWindow)
		{
			try
			{
				var elem																= getElementFromEvent(e, srcWindow);

				if (elem != null)														{ checkUpdate(elem, false); }
			}
			catch (ex)																	{ $.atsc.log.warn(ex); }

			processEvent();
		}

		ui.events.bind("ui.table.specificTblAfterDelete", tableUpdateEvent);
		ui.events.bind("ui.table.specificFocusCellAutoAction", tableUpdateEvent);

		function afterSaveOrClose(e, processEvent, srcWindow, isCloseEvent)
		{
			try
			{
				var elem																= getElementFromEvent(e, srcWindow, true);

				if (elem != null)
				{
					if (getFrame(elem) == srcWindow)
					{
						if (toString(elem.attr("data-ref-type")) == "record")
						{
							var disabled												= toString(elem.attr("data-ref-disabled"));
							var isRefDisabledSave										= disabled == "save1" || disabled == "save2";

							if (isRefDisabledSave || isCloseEvent)
							{
								if (isRefDisabledSave)									{ elem.attr("data-ref-disabled", "false"); }

								// [MT-2753] If the record is being closed don't call the update functions but wait for the close event to be called first.
								// This is needed because the postClose may still modify the current record, or records which are shown in tables that need to be updated.
								if (isCloseEvent || !e.data.closeWindow)
								{
									if		(srcWindow.isSaved)							{ checkUpdate(elem, srcWindow.startmode == MODE_ADD && srcWindow.isSaved); }
									else if (srcWindow.isClosed && !checkIsFrameLoading(elem) && !isDisabled(elem))
									{
										if (srcWindow.startmode == MODE_ADD)			{ checkUpdate(elem, true); }
										else											{ refreshRecord(elem); }
									}
								}
							}
						}
						else if (toString(elem.attr("data-ref-type")) == "table")		{ checkUpdate(elem, srcWindow.startmode == MODE_ADD && srcWindow.isSaved); }
					}
					else if (srcWindow.wt != wt && srcWindow.wt.getFrame("w1") == srcWindow)
					{
						if (srcWindow.isSaved && srcWindow.isClosed)					{ checkUpdate(elem, false); }
					}
				}
			}
			catch (ex)																	{ $.atsc.log.warn(ex); }

			processEvent();
		}

		ui.events.bind("ui.record.specificAfterSave", afterSaveOrClose);
		ui.events.bind("ui.record.specificAfterClose", function(e, processEvent, srcWindow){ afterSaveOrClose(e, processEvent, srcWindow, true); });

		// For each iframe element: Create array of dependencies, load all frames that have unset or 1st level ordering and are not dependent of any other tables.
		elements.each(function()
		{
			var type																	= toString($(this).attr("data-ref-type"));

			var dependencies															= [];

			if (type == "table")
			{
				var foreignUNIDValue													= toString($(this).attr("data-ref-foreignUNIDValue"));
				var order																= toString($(this).attr("data-ref-order"));

				if (order == "")														{ order = "1"; }

				var values																= foreignUNIDValue.split(",");
				var tableName;

				for (var i = 0; i < values.length; i++)
				{
					if (isReferencedValue(values[i]))
					{
						tableName														= values[i].right(">_").left(".");
						if ($.inArray(tableName, dependencies) == -1)					{ dependencies.push(tableName); }
					}
				}

				// Load tables that are not dependent on another.
				if (order == "1" && foreignUNIDValue.indexOf(".") == -1)				{ loadFrame($(this)); }
			}
			else if (type == "record")
			{
				var uniqueid															= toString($(this).attr("data-ref-uniqueid"));

				if (isReferencedValue(uniqueid) && $.inArray(uniqueid.left("."), dependencies) == -1)
				{
					dependencies.push(uniqueid.left("."));
				}
			}

			var elem = $(this);
			$(this).data("trySetLocation", function(newURL)
			{
				elem.attr("data-ref-disabled", "true");

				ui.window.checkFrame($.atsc.window.getWindowFromIFrame(elem), CHECKFRAME_NORMAL, function(success)
				{
					if (success)
					{
						elem.attr("data-ref-disabled", "save1");

						if (JSO.toString(elem.attr("data-ref-direct")) == "true")		{ elem.attr("src", JSO.convertWindowLocation(window, newURL)); }
						else															{ elem.attr("src", JSO.convertWindowLocation(window, "/jsp/atsc/UIWrapperFrameset.jsp?startPage=" + encodeURIComponent(newURL))); }
					}
					else																{ elem.attr("data-ref-disabled", "false"); }
				});
			});

			$(this).data("refreshFrame", function()
			{
				var framesToRefresh														= toString(elem.attr("data-ref-update")).split(",");

				framesToRefresh.push(elem.attr("id"));

				if (framesToRefresh)
				{
					var elementToRefresh;
					var frameToRefresh;

					var refreshCounters													= {};
					var dependencies;

					for (var i = 0; i < framesToRefresh.length; i = i + 1)
					{
						elementToRefresh												= $("#" + framesToRefresh[i]);

						if (elementToRefresh.length > 0 && (!isDisabled(elementToRefresh) || elementToRefresh.attr("data-ref-disabled") == "temp"))
						{
							if (!refreshCounters[elementToRefresh.attr("id")])			{ refreshCounters[elementToRefresh.attr("id")] = 0; }
							refreshCounters[elementToRefresh.attr("id")]				= refreshCounters[elementToRefresh.attr("id")] + 1;

							dependencies												= elementToRefresh.data("ref-depended-by");

							if (dependencies)
							{
								for (var j = 0; j < dependencies.length; j = j + 1)
								{
									if (!refreshCounters[dependencies[j]])				{ refreshCounters[dependencies[j]] = 0; }
									refreshCounters[dependencies[j]]					= refreshCounters[dependencies[j]] + 1;
								}
							}
						}
					}

					for (var id in refreshCounters)
					{
						elementToRefresh												= $("#" + id);

						if (!elementToRefresh.data("ref-skip-length"))					{ elementToRefresh.data("ref-skip-length", 0); }

						var j															= elementToRefresh.data("ref-skip-length") + refreshCounters[id];

						elementToRefresh.data("ref-skip-length", j);
						elementToRefresh.attr("data-ref-disabled", "temp");
					}

					for (var i = 0; i < framesToRefresh.length; i = i + 1)
					{
						elementToRefresh												= $("#" + framesToRefresh[i]);

						if (elementToRefresh.length > 0 && (!isDisabled(elementToRefresh) || elementToRefresh.attr("data-ref-disabled") == "temp"))
						{
							if (elementToRefresh.attr("data-ref-type") == "table")
							{
								frameToRefresh											= getFrame(elementToRefresh);

								frameToRefresh.ui.table.refreshTableAndFocusCurrentRecord();
							}
							else														{ /* empty */ }
						}
					}
				}
			});

			$(this).data("ref-dependencies", dependencies);
		});

		elements.each(function()
		{
			var elem																	= $(this);
			var depended_by																= [];

			elements.each(function()
			{
				var dependencies														= $(this).data("ref-dependencies");

				if ($.inArray(elem.attr("id"), dependencies) > -1)						{ depended_by.push($(this).attr("id")); }
			});

			elem.data("ref-depended-by", depended_by);
		});
	}
});

(function($)
{
	/*
	 * Referenced containers (DIVs)
	 * -----------------
	 *
	 * DIV elements surrounding referenced frames. If an item in one of the frames is opened the current div will gain focus.
	 *
	 * Positioning is defiend in the JSP file. When a record is opened the frame will become 'full screen'.
	 */

	var originalProperties																= {};
	var currentDiv;

	function setScreen(elem)
	{
		if (elem == null)
		{
			currentDiv																	= null;

			for (var id in originalProperties)											{ $("#" + id).css(originalProperties[id]).show().find("iframe.ref").removeAttr("data-ref-disabled"); }

			if (ui.isComponent(window))													{ ui.component.inheritTitle(null); }
		}
		else
		{
			currentDiv																	= elem.attr("id");

			for (var id in originalProperties)											{ $("#" + id).hide().find("iframe.ref").attr("data-ref-disabled", "true"); }

			$(elem).css({ "position": "absolute", "left": "0px", "right": "0px", "top": "0px", "bottom": "0px", "width": "auto", "height": "inherit", "z-index": "2" }).show().find("iframe.ref").removeAttr("data-ref-disabled");

			if (ui.isComponent(window))													{ ui.component.inheritTitle(elem.find("iframe").first().attr("id")); }
		}

		if (IE == 10) // MT-1308
		{
			$("iframe").css("width", "0%");

			setTimeout(function() {$("iframe").css("width", "100%");}, 0);
		}
	}

	function getElement(wnd)
	{
		var elem																		= null;

		for (var id in originalProperties)
		{
			$("#" + id).find("iframe").each(function()
			{
				if ($.atsc.window.getWindowFromIFrame(this) == wnd.wt)					{ elem = $(this); return false; }
			});

			if (elem)																	{ return elem; }
		}

		return $();
	}

	ui.events.bind("ui.component.specificInit", function(e, processEvent, srcWindow)
	{
		if (srcWindow == window)
		{
			e.preventDefault();

			var id;

			$("div.ref").each(function(i)
			{
				if (!$(this).attr("id"))												{ $(this).attr("id", "div_ref_" + i); }

				id																		= $(this).attr("id");

				originalProperties[id]													= { "position": this.style.position, "top": this.style.top, "left": this.style.left, "right": this.style.right, "bottom": this.style.bottom, "width": this.style.width, "height": this.style.height, "z-index": this.style.zIndex };
			});
		}

		processEvent();
	});

	ui.events.bind("ui.window.specificStopLoading", function(e, processEvent, srcWindow)
	{
		var elem																		= getElement(srcWindow);

		if (elem.length > 0)
		{
			elem																		= elem.parents("div.ref").first();

			if (elem.length > 0)
			{
				if (currentDiv)
				{
					if (currentDiv == elem.attr("id") && srcWindow.getCurrentFrameName() == "w0")
					{
						setScreen(null);
					}
				}
				else if (srcWindow.getCurrentFrameName() != "w0")
				{
					setScreen(elem);
				}
			}
		}

		processEvent();
	});
})(jQuery);

/**
 * Cross-window messaging
 */
(function($)
{
	if ($.localStorageSupported())
	{
		if (window == ui.window.getTopmostFrame())
		{
			var crossWindowEventCount															= toInt($.localStorage("mtNotifyEventCount"));
			var responseContainer																= {};
			var listening																		= true;

			function notifyOtherWindows(req, callback, recipient, maxLatency)
			{
				try
				{
					if (typeof(maxLatency) == "undefined" || !maxLatency)						{ maxLatency = (CHROME ? 1500 : 3000); }

					if (recipient && '' + recipient === '' + FRAME_UNIQUEID)
					{
						// Can't send a message to yourself.
						if (callback)															{ callback(null); }
					}
					else if (toInt($.localStorage("mtNotifyEventTimer")) >= new Date().getTime())
					{
						setTimeout(function(){ notifyOtherWindows(req, callback); }, 100);
					}
					else
					{
						$.localStorage("mtNotifyEventTimer", (new Date().getTime() + 100));

						req["source"]															= FRAME_UNIQUEID;
						req["expectsResponse"]													= (callback ? true : false);
						req["destination"]														= (recipient ? recipient : null);

						var newValue															= toInt($.localStorage("mtNotifyEventCount")) + 1;

						crossWindowEventCount													= newValue;

						$.localStorage("mtNotifyEvent", JSON.stringify(req));
						$.localStorage("mtNotifyEventCount", newValue);

						if (callback)
						{
							var latency															= 0;
							var WAIT_LATENCY													= 50;

							function checkResponse()
							{
								if (responseContainer[newValue])								{ callback(responseContainer[newValue]); }
								else if (latency > maxLatency)									{ callback(null); }
								else
								{
									latency														= latency + WAIT_LATENCY;

									setTimeout(checkResponse, WAIT_LATENCY);
								}
							}

							checkResponse();
						}

						// JSON.stringify() and JSON.parse()
					}
				}
				catch (ex)																		{ $.atsc.log.warn(ex); }
			}

			/**
			 * Usage: ui.events.notifyOtherWindows(data, callback)
			 *
			 * Fires the "ui.events.onWindowMessage" event on all other windows that have the same origin as this one.
			 */
			ui.events.notifyOtherWindows = function(data, callback){ notifyOtherWindows({ "type": "request", "requestData": data }, callback); }
			ui.events.notifySpecificWindow = function(id, data, callback){ notifyOtherWindows({ "type": "request", "requestData": data }, callback, id); }

			ui.events.stopListening = function()
			{
				listening																		= false;
			}

			ui.events.windowExists = function(id, callback){ notifyOtherWindows({ "type": "exists", "requestData": {} }, function(data){ callback((data ? true : false)); }, id); }

			$(function()
			{
				$(window).on("storage", function(e)
				{
					if (listening && $.localStorage("mtNotifyEventCount") !== "" && toInt($.localStorage("mtNotifyEventCount")) !== crossWindowEventCount)
					{
						var eventSource															= toInt($.localStorage("mtNotifyEventCount"));

						crossWindowEventCount													= eventSource;

						var data																= JSON.parse($.localStorage("mtNotifyEvent"));

						if (!data.destination || ('' + data.destination) === ('' + FRAME_UNIQUEID))
						{
							data["responseData"]												= null;

							if (data.type === "exists") // Moved "data.destination" condition inside the if (otherwise it could print "Unknown notify type: exists" in the else
							{
								if (data.destination)											{ notifyOtherWindows({ "type": "response", "requestData": {}, "sourceEvent": eventSource }, undefined, data.source); }
							}
							else if (data.type === "response")									{ responseContainer[data.sourceEvent] = data; }
							else if (data.type === "request")
							{
								ui.events.call("ui.events.onWindowMessage", window, function(e)
								{
									if (data.expectsResponse && e.data["responseData"] != null)
									{
										notifyOtherWindows({ "type": "response", "requestData": e.data["responseData"], "sourceEvent": eventSource });
									}
								}, data, true, false);
							}
							else																{ $.atsc.log.warn("Unknown notify type: " + data.type); }
						}
					}
				});
			});
		}
		else
		{
			// Expose API

			ui.events.notifyOtherWindows														= ui.window.getTopmostFrame().ui.events.notifyOtherWindows;
			ui.events.notifySpecificWindow														= ui.window.getTopmostFrame().ui.events.notifySpecificWindow;
			ui.events.stopListening																= ui.window.getTopmostFrame().ui.events.stopListening;
			ui.events.windowExists																= ui.window.getTopmostFrame().ui.events.windowExists;
		}
	}
})(jQuery);

ui.events.bind("ui.events.onWindowMessage", function(e, processEvent, srcWindow)
{
	try
	{
		$("frame").each(function()
		{
			try																					{ $.atsc.window.getWindowFromFrame(this).ui.events.call("ui.events.onWindowMessage", window, null, e.data, true, false); }
			catch (ex)																			{ /* no problem */ }
		});

		$("iframe").each(function()
		{
			try																					{ $.atsc.window.getWindowFromIFrame(this).ui.events.call("ui.events.onWindowMessage", window, null, e.data, true, false); }
			catch (ex)																			{ /* no problem */ }
		});
	}
	catch (ex)																					{ $.atsc.log.warn(ex); }

	processEvent();
});

(function($)
{
	function trySetFocus(elements, startIndex, inverse, srcWindow)
	{
		var index																		= startIndex;
		var elem;

		do
		{
			try
			{
				if (inverse)															{ index = index - 1; }
				else																	{ index = index + 1; }

				if (index < 0)															{ index = elements.length - 1; }
				else if (index >= elements.length)										{ index = 0; }

				if (index != startIndex)
				{
					if (startIndex == -1)												{ startIndex = 0; }

					elem																= elements.eq(index);

					var frame															= $.atsc.window.getWindowFromIFrame(elem);

					// UIWrapperFrameset
					if (ui.isFrameset(frame))											{ frame = frame.getFrame(frame.getCurrentFrameName()); }

					var isTable															= ui.isTable(frame);
					var isRecord														= ui.isRecord(frame);

					if (isTable || isRecord)
					{
						if (ui.isRecord(srcWindow))
						{
							if (srcWindow.currentField)
							{
								srcWindow.currentField.blur();
								srcWindow.currentField = null;
							}

							srcWindow.lastitem											= null;
						}

						ui.window.deselectOtherFrames(window);
					}

					if (isTable)
					{
						frame.ui.table.focusTable();

						return true;
					}
					else if (isRecord)
					{
						if (ui.isComponent(frame.wt.parent))							{ frame.wt.parent.ui.component.currentFrame = frame.wt.name; }

						frame.focus();

						if (!inverse || !setRecordFocus(frame, true))
						{
							ui.window.deselectOtherFrames(frame);
							frame.ui.record.setLastTableIFrame(null);

							if (frame.lastitem)											{ frame.lastitem.focus(); }
							else														{ frame.gotoFirst(); }
						}

						return true;
					}
				}
			}
			catch (ex)																	{ /* no problem */ }
		}
		while (index != startIndex);

		return false;
	}

	function setRecordFocus(wnd, inverse)
	{
		var elements																	= wnd.jQuery("iframe:visible");

		if (elements.length > 0)														{ return trySetFocus(elements, -1, inverse, wnd); }
		else																			{ return false; }
	}

	function recordKeyDown(e, processEvent, srcWindow)
	{
		if (!e.data.skipFocusCheck && srcWindow == window)
		{
			//																		   Down
			if (e.data.event.ctrlKey && !e.data.event.altKey && e.data.event.which == 40 && setRecordFocus(window, false))
			{
				e.data.skipFocusCheck													= true;

				e.preventDefault();
				e.stopPropagation();
			}
		}

		processEvent();
	}

	function keyDown(e, processEvent, srcWindow)
	{
		if (!e.data.skipFocusCheck)
		{
			var elem																	= $.atsc.window.getIFrameFromWindow(srcWindow);

			// UIWrapperFrameset
			if (elem == null || elem.length <= 0)										{ elem = $.atsc.window.getIFrameFromWindow(srcWindow.wt); }

			if (elem != null && elem.length > 0)
			{
				//																		   Up                          Down
				if (e.data.event.ctrlKey && !e.data.event.altKey && (e.data.event.which == 38 || e.data.event.which == 40))
				{
					var elements												= $("iframe:visible");

					if (elements.length > 0)
					{
						var startIndex											= elements.index(elem);

						if (startIndex > -1)
						{
							if (trySetFocus(elements, startIndex, e.data.event.which == 38, ui.isRecord(window) ? window : srcWindow))
							{
								e.data.skipFocusCheck							= true;

								e.preventDefault();
							}
							else if (ui.isRecord(window) && startIndex == 0 && e.data.event.which == 38)
							{
								e.data.skipFocusCheck							= true;

								ui.window.deselectOtherFrames(window);
								ui.record.setLastTableIFrame(null);

								if (ui.isComponent(window.wt.parent))			{ window.wt.parent.ui.component.currentFrame = window.wt.name; }

								window.focus();
								gotoFirst();

								if (ui.record.getElements(ui.record.getPanel()).length > 0)
								{
									e.preventDefault();
									e.stopPropagation();
								}
							}
						}
					}
				}
			}
		}

		processEvent();
	}

	ui.events.bind("ui.table.specificKeyDown", keyDown);
	ui.events.bind("ui.record.specificKeyDown", recordKeyDown);
	ui.events.bind("ui.record.specificKeyDown", keyDown);

	// DON'T allow the Alt+Left/Alt+Right keys to be pressed to go back and forth in the history.
	$(window).on("keydown", function(e)
	{
		if (e.altKey && (e.which == 37 || e.which == 39))
		{
			try																		{ e.originalEvent.cancelBubble = true; }
			catch (ex)																{ /* no problem */ }

			try																		{ e.originalEvent.returnValue = false; }
			catch (ex)																{ /* no problem */ }

			e.preventDefault();
		}

		if (e.which == 8) // Backspace
		{
			if (!$(e.target).is("input,textarea"))
			{
				e.preventDefault();
			}
		}
	});
})(jQuery);

function getMenuOptions()
{
	if (ui.isMenu(window))
	{
		var options																	= [];
		var mc, mi;

		for (var i = 0; i < menucells.length; i++)
		{
			mc																		= menucells[i];

			for (var j = 0; j < mc.length; j++)
			{
				mi																	= mc.item(j);

				if (mi.className == "selmenucolor" && isVisible(mi))
				{
					if ($(mi).attr("data-opties"))									{ options = options.concat($(mi).attr("data-opties").split(",")); }
				}
			}
		}

		return options;
	}
	else
	{
		var frame																	= ui.window.getDashboardFrame();

		if (frame == null)
		{
			frame																	= getFrame("w0");

			if (ui.isMenu(frame))													{ return frame.getMenuOptions(); }
			else																	{ return []; }
		}
		else
		{
			var tab																	= null;
			// [MT-7691]
			if (frame.ui && frame.ui.dashboard)										{ tab = frame.ui.dashboard.getTabByWindow(wt); }

			if (tab != null)														{ return tab.getOptions(); }
			else																	{ return []; }
		}
	}
}

ui.events.bind("ui.notification.onMessage", function(e, processEvent, srcWindow)
{
	if (srcWindow == window)
	{
		if (e.data.messageType == "notification")
		{
			var m																								= e.data.message;

			if (m != "")
			{
				var tag																							= e.data.tag;
				var icon																						= e.data.icon;
				var author																						= e.data.author;
				var onclick																						= e.data.onclick;
				var created																						= e.data.created;
				var requireInteraction																			= e.data.requireInteraction;
				var forceDashboard																				= e.data.forceDashboard;
				var actions																						= e.data.actions;

				if (tag == "CRITICAL")																			{ m = m + "\n" + "Geplaatst door: " + author + "\nDatum: " + created; }

				if (isDebugMode())																				{ m = m + "\n" + window.location.hostname; }

				if (requireInteraction)																			{ tag = null; }// tag + "_" + new Date().getTime().toFixed(0); }
				// Zie voor "tag" gerelateerde zaken Jira issue [MT-10909]; ook in ui.window.notify

				if (forceDashboard && !ui.window.isDashboard())													{ onclick = null; }

				ui.window.notify(m, tag, icon, onclick, actions, requireInteraction);
			}
		}
	}

	processEvent();
});

/*
	JSCookMenu v1.4.4.  (c) Copyright 2002-2005 by Heng Yuan

	Permission is hereby granted, free of charge, to any person obtaining a
	copy of this software and associated documentation files (the "Software"),
	to deal in the Software without restriction, including without limitation
	the rights to use, copy, modify, merge, publish, distribute, sublicense,
	and/or sell copies of the Software, and to permit persons to whom the
	Software is furnished to do so, subject to the following conditions:

	The above copyright notice and this permission notice shall be included
	in all copies or substantial portions of the Software.

	THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS
	OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
	ITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
	AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
	LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING
	FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER
	DEALINGS IN THE SOFTWARE.
*/

// Globals
var _cmIDCount = 0;
var _cmIDName = 'cmSubMenuID';		// for creating submenu id

var _cmTimeOut = null;			// how long the menu would stay
var _cmCurrentItem = null;		// the current menu item being selected;

var _cmNoAction = new Object ();	// indicate that the item cannot be hovered.
var _cmNoClick = new Object ();		// similar to _cmNoAction but does not respond to mouseup/mousedown events
var _cmSplit = new Object ();		// indicate that the item is a menu split

var _cmItemList = new Array ();		// a simple list of items

// default node properties
var _cmNodeProperties =
{
  	mainFolderLeft: '',
  	mainFolderRight: '',
	mainItemLeft: '',
	mainItemRight: '',
	folderLeft: '',
	folderRight: '',
	itemLeft: '',
	itemRight: '',
	mainSpacing: 0,
	subSpacing: 0,
	delay: 500,
	clickOpen: 1
};

function cmNewID ()
{
	return _cmIDName + (++_cmIDCount);
}

function cmActionItem (item, prefix, isMain, idSub, orient, nodeProperties)
{
	var clickOpen = _cmNodeProperties.clickOpen;
	if (nodeProperties.clickOpen)
		clickOpen = nodeProperties.clickOpen;

	// var index = _cmItemList.push (item) - 1;
	_cmItemList[_cmItemList.length] = item;
	var index = _cmItemList.length - 1;
	idSub = (!idSub) ? 'null' : ('\'' + idSub + '\'');
	orient = '\'' + orient + '\'';
	prefix = '\'' + prefix + '\'';
	var onClick = (clickOpen == 3) || (clickOpen == 2 && isMain);
	var returnStr;
	if (onClick)
		returnStr = ' onmouseover="cmItemMouseOver (this,' + prefix + ',' + isMain + ',' + idSub + ',' + index + ')" onmousedown="cmItemMouseDownOpenSub (this,' + index + ',' + prefix + ',' + orient + ',' + idSub + ')"';
	else
		returnStr = ' onmouseover="cmItemMouseOverOpenSub (this,' + prefix + ',' + isMain + ',' + idSub + ',' + orient + ',' + index + ')" onmousedown="cmItemMouseDown (this,' + index + ')"';

	return returnStr + ' onfocus=rejectFocus(); onmouseout="cmItemMouseOut (this,' + nodeProperties.delay + ')" onmouseup="cmItemMouseUp (this,' + index + ')"';
}

function cmNoClickItem (item, prefix, isMain, idSub, orient, nodeProperties)
{
	// var index = _cmItemList.push (item) - 1;
	_cmItemList[_cmItemList.length] = item;
	var index = _cmItemList.length - 1;
	idSub = (!idSub) ? 'null' : ('\'' + idSub + '\'');
	orient = '\'' + orient + '\'';
	prefix = '\'' + prefix + '\'';
	return ' onmouseover="cmItemMouseOver (this,' + prefix + ',' + isMain + ',' + idSub + ',' + index + ')" onmouseout="cmItemMouseOut (this,' + nodeProperties.delay + ')"';
}

function cmNoActionItem (item, prefix)
{
	return item[1];
}

function cmSplitItem (prefix, isMain, vertical)
{
	var classStr = 'cm' + prefix;
	if (isMain)
	{
		classStr += 'Main';
		if (vertical)
			classStr += 'HSplit';
		else
			classStr += 'VSplit';
	}
	else
		classStr += 'HSplit';
	return eval (classStr);
}

function cmDrawSubMenu (subMenu, prefix, id, orient, nodeProperties)
{
	var str = '<div class="' + prefix + 'SubMenu" id="' + id + '"><table summary="sub menu" cellspacing="' + nodeProperties.subSpacing + '" class="' + prefix + 'SubMenuTable">';
	var strSub = '';

	var item;
	var idSub;
	var hasChild;

	var i;

	var classStr;

	for (i = 5; i < subMenu.length; ++i)
	{
		item = subMenu[i];
		if (!item)
			continue;

		if (item == _cmSplit)
			item = cmSplitItem (prefix, 0, true);

		hasChild = (item.length > 5);
		idSub = hasChild ? cmNewID () : null;

		str += '<tr class="' + prefix + 'MenuItem"';
		if (item[0] != _cmNoClick)
			str += cmActionItem (item, prefix, 0, idSub, orient, nodeProperties);
		else
			str += cmNoClickItem (item, prefix, 0, idSub, orient, nodeProperties);
		str += '>'

		if (item[0] == _cmNoAction || item[0] == _cmNoClick)
		{
			str += cmNoActionItem (item, prefix);
			str += '</tr>';
			continue;
		}

		classStr = prefix + 'Menu';
		classStr += hasChild ? 'Folder' : 'Item';

		str += '<td class="' + classStr + 'Left">';

		if (item[0] != null && item[0] != "")
			str += item[0];
		else
			str += hasChild ? nodeProperties.folderLeft : nodeProperties.itemLeft;

		str += '</td><td class="' + classStr + 'Text">' + item[1];

		str += '</td><td class="' + classStr + 'Right">';

		if (hasChild)
		{
			str += nodeProperties.folderRight;
			strSub += cmDrawSubMenu (item, prefix, idSub, orient, nodeProperties);
		}
		else
			str += nodeProperties.itemRight;
		str += '</td></tr>';
	}

	str += '</table></div>' + strSub;
	return str;
}

function cmDraw (id, menu, orient, nodeProperties, prefix)
{
	var obj = cmGetObject (id);

	if (!nodeProperties)
		nodeProperties = _cmNodeProperties;
	if (!prefix)
		prefix = '';

	var str = '<table summary="main menu" class="' + prefix + 'Menu" cellspacing="' + nodeProperties.mainSpacing + '">';
	var strSub = '';

	if (!orient)
		orient = 'hbr';

	var orientStr = String (orient);
	var orientSub;
	var vertical;

	// draw the main menu items
	if (orientStr.charAt (0) == 'h')
	{
		// horizontal menu
		orientSub = 'v' + orientStr.substr (1, 2);
		str += '<tr>';
		vertical = false;
	}
	else
	{
		// vertical menu
		orientSub = 'v' + orientStr.substr (1, 2);
		vertical = true;
	}

	var i;
	var item;
	var idSub;
	var hasChild;

	var classStr;

	for (i = 0; i < menu.length; ++i)
	{
		item = menu[i];

		if (!item)
			continue;

		str += vertical ? '<tr' : '<td';
		str += ' class="' + prefix + 'MainItem"';

		hasChild = (item.length > 5);
		idSub = hasChild ? cmNewID () : null;

		str += cmActionItem (item, prefix, 1, idSub, orient, nodeProperties) + '>';

		if (item == _cmSplit)
			item = cmSplitItem (prefix, 1, vertical);

		if (item[0] == _cmNoAction || item[0] == _cmNoClick)
		{
			str += cmNoActionItem (item, prefix);
			str += vertical? '</tr>' : '</td>';
			continue;
		}

		classStr = prefix + 'Main' + (hasChild ? 'Folder' : 'Item');

		str += vertical ? '<td' : '<span';
		str += ' class="' + classStr + 'Left">';

		str += (item[0] == null) ? (hasChild ? nodeProperties.mainFolderLeft : nodeProperties.mainItemLeft)
					 : item[0];
		str += vertical ? '</td>' : '</span>';

		str += vertical ? '<td' : '<span';
		str += ' class="' + classStr + 'Text">';
		str += item[1];

		str += vertical ? '</td>' : '</span>';

		str += vertical ? '<td' : '<span';
		str += ' class="' + classStr + 'Right">';

		str += hasChild ? nodeProperties.mainFolderRight : nodeProperties.mainItemRight;

		str += vertical ? '</td>' : '</span>';

		str += vertical ? '</tr>' : '</td>';

		if (hasChild)
			strSub += cmDrawSubMenu (item, prefix, idSub, orientSub, nodeProperties);
	}
	if (!vertical)
		str += '</tr>';
	str += '</table>' + strSub;
	obj.innerHTML = str;
	//document.write ("<xmp>" + str + "</xmp>");
}

function cmDrawFromText (id, orient, nodeProperties, prefix)
{
	var domMenu = cmGetObject (id);
	var menu = null;
	for (var currentDomItem = domMenu.firstChild; currentDomItem; currentDomItem = currentDomItem.nextSibling)
	{
		if (!currentDomItem.tagName || currentDomItem.tagName.toLowerCase () != 'ul')
			continue;
		menu = cmDrawFromTextSubMenu (currentDomItem);
		break;
	}
	if (menu)
		cmDraw (id, menu, orient, nodeProperties, prefix);
}

function cmDrawFromTextSubMenu (domMenu)
{
	var items = new Array ();
	for (var currentDomItem = domMenu.firstChild; currentDomItem; currentDomItem = currentDomItem.nextSibling)
	{
		if (!currentDomItem.tagName || currentDomItem.tagName.toLowerCase () != 'li')
			continue;
		if (currentDomItem.firstChild == null)
		{
			items[items.length] = _cmSplit;
			continue;
		}
		var item = new Array ();
		var currentItem = currentDomItem.firstChild;
		for (; currentItem; currentItem = currentItem.nextSibling)
		{
			// scan for span tag
			if (!currentItem.tagName || currentItem.tagName.toLowerCase () != 'span')
				continue;
			if (!currentItem.firstChild)
				item[0] = null;
			else
				item[0] = currentItem.innerHTML;
			break;
		}
		if (!currentItem)
			continue;
		for (; currentItem; currentItem = currentItem.nextSibling)
		{
			// scan for span tag
			if (!currentItem.tagName || currentItem.tagName.toLowerCase () != 'a')
				continue;
			item[1] = currentItem.innerHTML;
			item[2] = currentItem.href;
			item[3] = currentItem.target;
			item[4] = currentItem.title;
			if (item[4] == '')
				item[4] = null;
			break;
		}

		for (; currentItem; currentItem = currentItem.nextSibling)
		{
			// scan for span tag
			if (!currentItem.tagName || currentItem.tagName.toLowerCase () != 'ul')
				continue;
			var subMenuItems = cmDrawFromTextSubMenu (currentItem);
			for (i = 0; i < subMenuItems.length; ++i)
				item[i + 5] = subMenuItems[i];
			break;
		}
		items[items.length] = item;
	}
	return items;
}

function cmItemMouseOver (obj, prefix, isMain, idSub, index)
{
	clearTimeout (_cmTimeOut);

	if (!obj.cmPrefix)
	{
		obj.cmPrefix = prefix;
		obj.cmIsMain = isMain;
	}

	var thisMenu = cmGetThisMenu (obj, prefix);

	// insert obj into cmItems if cmItems doesn't have obj
	if (!thisMenu.cmItems)
		thisMenu.cmItems = new Array ();
	var i;
	for (i = 0; i < thisMenu.cmItems.length; ++i)
	{
		if (thisMenu.cmItems[i] == obj)
			break;
	}
	if (i == thisMenu.cmItems.length)
	{
		//thisMenu.cmItems.push (obj);
		thisMenu.cmItems[i] = obj;
	}

	// hide the previous submenu that is not this branch
	if (_cmCurrentItem)
	{
		// occationally, we get this case when user
		// move the mouse slowly to the border
		if (_cmCurrentItem == obj || _cmCurrentItem == thisMenu)
		{
			var item = _cmItemList[index];
			cmSetStatus (item);
			return;
		}

		var thatPrefix = _cmCurrentItem.cmPrefix;
		var thatMenu = cmGetThisMenu (_cmCurrentItem, thatPrefix);

		if (thatMenu != thisMenu.cmParentMenu)
		{
			if (_cmCurrentItem.cmIsMain)
				_cmCurrentItem.className = thatPrefix + 'MainItem';
			else
				_cmCurrentItem.className = thatPrefix + 'MenuItem';
			if (thatMenu.id != idSub)
				cmHideMenu (thatMenu, thisMenu, thatPrefix);
		}
	}

	// okay, set the current menu to this obj
	_cmCurrentItem = obj;

	// just in case, reset all items in this menu to MenuItem
	cmResetMenu (thisMenu, prefix);

	var item = _cmItemList[index];
	var isDefaultItem = cmIsDefaultItem (item);

	if (isDefaultItem)
	{
		if (isMain)
			obj.className = prefix + 'MainItemHover';
		else
			obj.className = prefix + 'MenuItemHover';
	}

	cmSetStatus (item);
}

function cmItemMouseOverOpenSub (obj, prefix, isMain, idSub, orient, index)
{
	cmItemMouseOver (obj, prefix, isMain, idSub, index);

	if (idSub)
	{
		var subMenu = cmGetObject (idSub);
		cmShowSubMenu (obj, prefix, subMenu, orient);
	}
}

function cmItemMouseOut (obj, delayTime)
{
	if (!delayTime)
		delayTime = _cmNodeProperties.delay;
	_cmTimeOut = window.setTimeout ('cmHideMenuTime ()', delayTime);
	window.defaultStatus = '';
}

function cmItemMouseDown (obj, index)
{
	if (cmIsDefaultItem (_cmItemList[index]))
	{
		if (obj.cmIsMain)
			obj.className = obj.cmPrefix + 'MainItemActive';
		else
			obj.className = obj.cmPrefix + 'MenuItemActive';
	}
}

function cmItemMouseDownOpenSub (obj, index, prefix, orient, idSub)
{
	cmItemMouseDown (obj, index);

	if (idSub)
	{
		var subMenu = cmGetObject (idSub);
		cmShowSubMenu (obj, prefix, subMenu, orient);
	}
}

function cmItemMouseUp (obj, index)
{
	var item = _cmItemList[index];

	var link = null, target = '_self';

	if (item.length > 2)
		link = item[2];
	if (item.length > 3 && item[3])
		target = item[3];

	if (link != null && link != "")
	{
		if (link == "_close_")
		{
			askQuit();
		}
		else
		{
			try { eval(correctDropDownAction(link)); } catch (e) { alert("kan : " + link + " niet uitvoeren:\n" + e.message); }
		}
	}

	var prefix = obj.cmPrefix;
	var thisMenu = cmGetThisMenu (obj, prefix);

	var hasChild = (item.length > 5);
	if (!hasChild)
	{
		if (cmIsDefaultItem (item))
		{
			if (obj.cmIsMain)
				obj.className = prefix + 'MainItem';
			else
				obj.className = prefix + 'MenuItem';
		}
		cmHideMenu (thisMenu, null, prefix);
	}
	else
	{
		if (cmIsDefaultItem (item))
		{
			if (obj.cmIsMain)
				obj.className = prefix + 'MainItemHover';
			else
				obj.className = prefix + 'MenuItemHover';
		}
	}
}

function correctDropDownAction(action)
{
	action = replaceAll(action, "dbnowdate", getDbNowDate());
	action = replaceAll(action, "nowdate", getNowDate());
	action = replaceAll(action, "nowyear", getNowYear());
	action = replaceAll(action, "nowquarter", getNowQuarter());
	action = replaceAll(action, "nowmonth", getNowMonth());
	action = replaceAll(action, "organisationUNID", getOrganisationUNID());
	action = replaceAll(action, "userUNID", getUserUnid());

	return action;
}

function cmMoveSubMenu (obj, subMenu, orient)
{
	var mmode = String (orient);
	var p = subMenu.offsetParent;
	var subMenuWidth = cmGetWidth (subMenu);
	var horiz = cmGetHorizontalAlign (obj, mmode, p, subMenuWidth);
	if (mmode.charAt (0) == 'h')
	{
		if (mmode.charAt (1) == 'b')
			subMenu.style.top = (cmGetYAt (obj, p) + cmGetHeight (obj)) + 'px';
		else
			subMenu.style.top = (cmGetYAt (obj, p) - cmGetHeight (subMenu)) + 'px';
		if (horiz == 'r')
			subMenu.style.left = (cmGetXAt (obj, p)) + 'px';
		else
			subMenu.style.left = (cmGetXAt (obj, p) + cmGetWidth (obj) - subMenuWidth) + 'px';
	}
	else
	{
		if (horiz == 'r')
			subMenu.style.left = (cmGetXAt (obj, p) + cmGetWidth (obj)) + 'px';
		else
			subMenu.style.left = (cmGetXAt (obj, p) - subMenuWidth) + 'px';
		if (mmode.charAt (1) == 'b')
			subMenu.style.top = (cmGetYAt (obj, p)) + 'px';
		else
			subMenu.style.top = (cmGetYAt (obj, p) + cmGetHeight (obj) - cmGetHeight (subMenu)) + 'px';
	}
}

function cmGetHorizontalAlign (obj, mmode, p, subMenuWidth)
{
	var horiz = mmode.charAt (2);
	if (!(document.body))
		return horiz;
	var body = document.body;
	var browserLeft;
	var browserRight;
	if (window.innerWidth)
	{
		// DOM window attributes
		browserLeft = window.pageXOffset;
		browserRight = window.innerWidth + browserLeft;
	}
	else if (body.clientWidth)
	{
		// IE attributes
		browserLeft = body.clientLeft;
		browserRight = body.clientWidth + browserLeft;
	}
	else
		return horiz;
	if (mmode.charAt (0) == 'h')
	{
		if (horiz == 'r' && (cmGetXAt (obj) + subMenuWidth) > browserRight)
			horiz = 'l';
		if (horiz == 'l' && (cmGetXAt (obj) + cmGetWidth (obj) - subMenuWidth) < browserLeft)
			horiz = 'r';
		return horiz;
	}
	else
	{
		if (horiz == 'r' && (cmGetXAt (obj, p) + cmGetWidth (obj) + subMenuWidth) > browserRight)
			horiz = 'l';
		if (horiz == 'l' && (cmGetXAt (obj, p) - subMenuWidth) < browserLeft)
			horiz = 'r';
		return horiz;
	}
}

function cmShowSubMenu (obj, prefix, subMenu, orient)
{
	if (!subMenu.cmParentMenu)
	{
		// establish the tree w/ back edge
		var thisMenu = cmGetThisMenu (obj, prefix);
		subMenu.cmParentMenu = thisMenu;
		if (!thisMenu.cmSubMenu)
			thisMenu.cmSubMenu = new Array ();
		//thisMenu.cmSubMenu.push (subMenu);
		thisMenu.cmSubMenu[thisMenu.cmSubMenu.length] = subMenu;
	}

	// position the sub menu
	cmMoveSubMenu (obj, subMenu, orient);
	subMenu.style.visibility = 'visible';
}

function cmResetMenu (thisMenu, prefix)
{
	if (thisMenu.cmItems)
	{
		var i;
		var str;
		var items = thisMenu.cmItems;
		for (i = 0; i < items.length; ++i)
		{
			if (items[i].cmIsMain)
				str = prefix + 'MainItem';
			else
				str = prefix + 'MenuItem';
			if (items[i].className != str)
				items[i].className = str;
		}
	}
}

function cmHideMenuTime ()
{
	if (_cmCurrentItem)
	{
		var prefix = _cmCurrentItem.cmPrefix;
		cmHideMenu (cmGetThisMenu (_cmCurrentItem, prefix), null, prefix);
		_cmCurrentItem = null;
	}
}

function cmHideMenu (thisMenu, currentMenu, prefix)
{
	try
	{
		var str = prefix + 'SubMenu';

		// hide the down stream menus
		if (thisMenu.cmSubMenu)
		{
			var i;
			for (i = 0; i < thisMenu.cmSubMenu.length; ++i)
			{
				cmHideSubMenu (thisMenu.cmSubMenu[i], prefix);
			}
		}

		// hide the upstream menus
		while (thisMenu && thisMenu != currentMenu)
		{
			cmResetMenu (thisMenu, prefix);
			if (thisMenu.className == str)
			{
				thisMenu.style.visibility = 'hidden';
				cmShowControl (thisMenu);
			}
			else
				break;
			thisMenu = cmGetThisMenu (thisMenu.cmParentMenu, prefix);
		}
	} catch (e) {}
}

function cmHideSubMenu (thisMenu, prefix)
{
	if (thisMenu.style.visibility == 'hidden')
		return;
	if (thisMenu.cmSubMenu)
	{
		var i;
		for (i = 0; i < thisMenu.cmSubMenu.length; ++i)
		{
			cmHideSubMenu (thisMenu.cmSubMenu[i], prefix);
		}
	}
	cmResetMenu (thisMenu, prefix);
	thisMenu.style.visibility = 'hidden';
	cmShowControl (thisMenu);
}

function cmHideControl (tagName, subMenu)
{
	var x = cmGetX (subMenu);
	var y = cmGetY (subMenu);
	var w = subMenu.offsetWidth;
	var h = subMenu.offsetHeight;

	var i;
	for (i = 0; i < document.all.tags(tagName).length; ++i)
	{
		var obj = document.all.tags(tagName)[i];
		if (!obj || !obj.offsetParent)
			continue;

		// check if the object and the subMenu overlap

		var ox = cmGetX (obj);
		var oy = cmGetY (obj);
		var ow = obj.offsetWidth;
		var oh = obj.offsetHeight;

		if (ox > (x + w) || (ox + ow) < x)
			continue;
		if (oy > (y + h) || (oy + oh) < y)
			continue;

		// if object is already made hidden by a different
		// submenu then we dont want to put it on overlap list of
		// of a submenu a second time.
		// - bug fixed by Felix Zaslavskiy
		if(obj.style.visibility == "hidden")
			continue;

		//subMenu.cmOverlap.push (obj);
		subMenu.cmOverlap[subMenu.cmOverlap.length] = obj;
		obj.style.visibility = "hidden";
	}
}

function cmShowControl (subMenu)
{
	if (subMenu.cmOverlap)
	{
		var i;
		for (i = 0; i < subMenu.cmOverlap.length; ++i)
			subMenu.cmOverlap[i].style.visibility = "";
	}
	subMenu.cmOverlap = null;
}

function cmGetThisMenu (obj, prefix)
{
	var str1 = prefix + 'SubMenu';
	var str2 = prefix + 'Menu';
	while (obj)
	{
		if (obj.className == str1 || obj.className == str2)
			return obj;
		obj = obj.parentNode;
	}
	return null;
}

function cmIsDefaultItem (item)
{
	if (item == _cmSplit || item[0] == _cmNoAction || item[0] == _cmNoClick)
		return false;
	return true;
}

function cmGetObject (id)
{
	if (document.all)
		return document.all[id];
	return document.getElementById (id);
}

function cmGetWidth (obj)
{
	var width = obj.offsetWidth;
	if (width > 0 || !cmIsTRNode (obj))
		return width;
	if (!obj.firstChild)
		return 0;

	return obj.lastChild.offsetLeft - obj.firstChild.offsetLeft + cmGetWidth (obj.lastChild);
}

function cmGetHeight (obj)
{
	var height = obj.offsetHeight;
	if (height > 0 || !cmIsTRNode (obj))
		return height;
	if (!obj.firstChild)
		return 0;
	// use the first child's height
	return obj.firstChild.offsetHeight;
}

function cmGetX (obj)
{
	var x = 0;

	do
	{
		x += obj.offsetLeft;
		obj = obj.offsetParent;
	}
	while (obj);
	return x;
}

function cmGetXAt (obj, elm)
{
	var x = 0;

	while (obj && obj != elm)
	{
		x += obj.offsetLeft;
		obj = obj.offsetParent;
	}
	if (obj == elm)
		return x;
	return x - cmGetX (elm);
}

function cmGetY (obj)
{
	var y = 0;
	do
	{
		y += obj.offsetTop;
		obj = obj.offsetParent;
	}
	while (obj);
	return y;
}

function cmIsTRNode (obj)
{
	var tagName = obj.tagName;
	return tagName == "TR" || tagName == "tr" || tagName == "Tr" || tagName == "tR";
}

function cmGetYAt (obj, elm)
{
	var y = 0;

	if (!obj.offsetHeight && cmIsTRNode (obj))
	{
		var firstTR = obj.parentNode.firstChild;
		obj = obj.firstChild;
		y -= firstTR.firstChild.offsetTop;
	}

	while (obj && obj != elm)
	{
		y += obj.offsetTop;
		obj = obj.offsetParent;
	}

	if (obj == elm)
		return y;
	return y - cmGetY (elm);
}

function cmSetStatus (item)
{
	var descript = '';
	if (item.length > 4)
		descript = (item[4] != null) ? item[4] : (item[2] ? item[2] : descript);
	else if (item.length > 2)
		descript = (item[2] ? item[2] : descript);

	window.defaultStatus = descript;
}

function cmGetProperties (obj)
{
	if (obj == undefined)
		return 'undefined';
	if (obj == null)
		return 'null';

	var msg = obj + ':\n';
	var i;
	for (i in obj)
		msg += i + ' = ' + obj[i] + '; ';
	return msg;
}

var cmThemeOfficeBase = "/images/atsc/";

try
{
	if (myThemeOfficeBase)
	{
		cmThemeOfficeBase = myThemeOfficeBase;
	}
}
catch (e)
{
}

var cmThemeOffice =
{
  	mainFolderLeft: '&nbsp;',
  	mainFolderRight: '&nbsp;',
	mainItemLeft: '&nbsp;',
	mainItemRight: '&nbsp;',
	folderLeft: '<img alt="" src="' + cmThemeOfficeBase + 'blank.gif">',
	folderRight: '<img alt="" src="' + cmThemeOfficeBase + 'arrow.gif">',
	itemLeft: '<span>&nbsp;</span>',
	itemRight: '<span>&nbsp;</span>',
	mainSpacing: 5,
	subSpacing: 0,
	delay: 500
};

// for horizontal menu split
var cmThemeOfficeHSplit = [_cmNoClick, '<td class="ThemeOfficeMenuItemLeft"></td><td colspan="2"><div class="ThemeOfficeMenuSplit"></div></td>'];
var cmThemeOfficeMainHSplit = [_cmNoClick, '<td class="ThemeOfficeMainItemLeft"></td><td colspan="2"><div class="ThemeOfficeMenuSplit"></div></td>'];
var cmThemeOfficeMainVSplit = [_cmNoClick, '|'];

function initMenus()
{
	if (window.myMenu != null) cmDraw ('finalmenu', myMenu, 'hbr', cmThemeOffice, 'ThemeOffice');
}

function saveAndQuit()
{
	if (!isChanged) ui.record.setIsChanged(true);

	save(true, undefined, undefined, true);
}

function isProperDate(argDate) {
	var tmpDay = getDay(argDate)
	var tmpMon = getMonth(argDate)
	var tmpYear = getYear(argDate)

	return isProperDay(tmpDay, tmpMon, tmpYear) && isProperMonth(tmpMon) && isProperYear(tmpYear)
}

function isProperMY(argMY) {

	argMY = "01-" + argMY;

	var tmpMon = getMonth(argMY)
	var tmpYear = getYear(argMY)

	return isProperMonth(tmpMon) && isProperYear(tmpYear)
}

function isWhiteSpace(argWhiteSpace) {
	argWs = argWhiteSpace.toString()

	for (var intI=0; intI < argWs.length; intI++)
		if (argWs.charAt(intI) != ' ' && argWs.charAt(intI) != '\t')
			return false

	return true
}

function isLeapYear(argYear) {
	return ((argYear % 4 == 0) && (argYear % 100 != 0)) || (argYear % 400 == 0)
}

function daysInMonth(argMonth, argYear) {
	switch (Number(argMonth)) {
		case 1:		// Jan
		case 3:		// Mar
		case 5:		// May
		case 7:		// Jul
		case 8:		// Aug
		case 10:		// Oct
		case 12:		// Dec
			return 31;
			break;

		case 4:		// Apr
		case 6:		// Jun
		case 9:		// Sep
		case 11:		// Nov
			return 30;
			break;

		case 2:		// Feb
			if (isLeapYear(argYear))
				return 29
			else
				return 28
			break;

		default:
			return 0;
	}
}

function getDateSeparator(argDate) {
	// Are there invalid separators?
	if ((argDate.indexOf('-') > 0) && (argDate.indexOf('/') > 0))
		return ' '

	if (argDate.indexOf('-') > 0)
		return '-'
	else
		if (argDate.indexOf('/') > 0)
			return '/'
		else
			return ' '
}

function getYear(argDate) {
	var dateSep = getDateSeparator(argDate)

	if (dateSep == ' ')
		return 0

	if(argDate.split(dateSep).length == 3)
		return argDate.split(dateSep)[2]
	else
		return 0
}

function getDay(argDate) {
	var dateSep = getDateSeparator(argDate)

	if (dateSep == ' ')
		return 0

	if(argDate.split(dateSep).length == 3)
		return argDate.split(dateSep)[0]
	else
		return 0
}

function getMonth(argDate) {
	var dateSep = getDateSeparator(argDate)

	if (dateSep == ' ')
		return 0

	if(argDate.split(dateSep).length == 3)
		return argDate.split(dateSep)[1]
	else
		return 0
}

function isProperDay(argDay, argMonth, argYear) {
	if ((isWhiteSpace(argDay)) || (argDay == 0))
		return false

	if ((argDay > 0) && (argDay < daysInMonth(argMonth, argYear) + 1))
		return true
	else
		return false
}

function isProperMonth(argMonth) {
	if ((isWhiteSpace(argMonth)) || (argMonth == 0))
		return false

	if ((argMonth > 0) && (argMonth < 13))
		return true
	else
		return false
}

//------------------------------------------------------------------------------------
//           Function to tell whether the given Year is a valid one
//           Remove comments to accept yy and yyyy formats.
//------------------------------------------------------------------------------------
function isProperYear(argYear) {
	if ((isWhiteSpace(argYear)) || (argYear.toString().length > 4) || (argYear.toString().length == 3))
		return false

	switch (argYear.toString().length) {
		/*
		case 1:
			if (argYear >=0 && argYear < 10)
				return true
			else
				return false

		case 2:
			if (argYear >=0 && argYear < 100)
				return true
			else
				return false
	    */
		case 4:
			if ((argYear >= 1900) && (argYear < 3000))
				return true
			else
				return false

		default:
			return false
	}
}

// Deze functie geeft een datumobject terug van de meegegeven parameter
function setDutchDate(date)
{
	try
	{
		var dateArray = date.split("-");

		return new Date(Date.UTC(parseInt(dateArray[2]), parseInt(parseInt(dateArray[1].replace(/[0](\d)/, "$1"))-1), parseInt(dateArray[0].replace(/[0](\d)/, "$1")), 0, 0, 0));
	}
	catch (ex)
	{
		return null;
	}
}

function setDutchTime(date, withSeconds)
{
	return setDutchDateTime(toDutchDate(new Date()) + " " + date, withSeconds)
}

function setDutchDateTime(date, withSeconds)
{
	try
	{
		if (typeof(withSeconds) == "undefined"){ withSeconds = true; }

		var dateTimeArray = date.split(" ");
		var dateArray = dateTimeArray[0].split("-");
		var timeArray = dateTimeArray[1].split(":");

		if (withSeconds)
		{
			return new Date(parseInt(dateArray[2]), parseInt(parseInt(dateArray[1].replace(/[0](\d)/, "$1"))-1), parseInt(dateArray[0].replace(/[0](\d)/, "$1")), parseInt(timeArray[0].replace(/[0](\d)/, "$1")), parseInt(timeArray[1].replace(/[0](\d)/, "$1")), parseInt(timeArray[2].replace(/[0](\d)/, "$1")));
		}
		else
		{
			return new Date(parseInt(dateArray[2]), parseInt(parseInt(dateArray[1].replace(/[0](\d)/, "$1"))-1), parseInt(dateArray[0].replace(/[0](\d)/, "$1")), parseInt(timeArray[0].replace(/[0](\d)/, "$1")), parseInt(timeArray[1].replace(/[0](\d)/, "$1")), 0);
		}
	}
	catch (ex)
	{
		return null;
	}
}

function setOnlyTime(time)
{
	var timeArray						= time.split(":");

	var hour							= 0;
	var min								= 0;
	var sec								= 0;

	if (timeArray.length >= 1)	{ hour	= timeArray[0].replace(/[0](\d)/, "$1"); }
	if (timeArray.length >= 2)	{ min	= timeArray[1].replace(/[0](\d)/, "$1"); }
	if (timeArray.length >= 3)	{ sec	= timeArray[2].replace(/[0](\d)/, "$1"); }

	return (parseInt(hour) * 60 * 60) + (parseInt(min) * 60) + (parseInt(sec));
}

// functie om te kijken of een bepaalde datum voor of na een andere datum ligt. De parameters zijn de
// strings met de datums er in. Er wordt gecheckt of de eerste parameter ouder is dan de tweede.
function isBefore(date1, date2)
{
	var date1 = setDutchDate(date1);
	var date2 = setDutchDate(date2);

	var date1Millis = date1.getTime();
	var date2Millis = date2.getTime();

	return date1Millis < date2Millis;
}

function isAfter(date1, date2)
{
	var date1 = setDutchDate(date1);
	var date2 = setDutchDate(date2);

	var date1Millis = date1.getTime();
	var date2Millis = date2.getTime();

	return date1Millis > date2Millis;
}

// Functie om te kijken of een datum op of tussen een begin en een eventuele einddatum valt.
function dateIsInRange(date1, date2, date3)
{
	// date1 valt op of tussen date2 en date3? date2<=date1<=date3
	var date1 = setDutchDate(date1);
	var date2 = setDutchDate(date2);

	var date1Millis = date1.getTime();
	var date2Millis = date2.getTime();

	if (date3 != null)
	{
		var date3 = setDutchDate(date3);
		var date3Millis = date3.getTime();

		return (date2Millis <= date1Millis && date1Millis <= date3Millis);
	}
	else return date2Millis <= date1Millis;
}

//functie om het verschil in dagen tussen twee datumvelden te berekenen
//Parameters zijn de id's van de velden
function getDateDifference(ingdat, enddat)
{
	var date1 = setDutchDate(document.getElementById(ingdat).value);
	var date2 = setDutchDate(document.getElementById(enddat).value);

	return (Date.parse(date2) - Date.parse(date1))/86400000; // 86400000 is het aantal milliseconden in een dag
}

function now()
{
	return new Date();
}

function yesterday()
{
	return new Date(now().getTime() - (1000 * 60 * 60 * 24));
}

function tomorrow()
{
	return new Date(now().getTime() + (1000 * 60 * 60 * 24));
}

function addDays(date, nDays)
{
	var date1 = setDutchDate(date);
	date1.setDate(date1.getDate() + nDays);
	return toDutchDate(date1);
}
function dutchDateToISODateFormat(dDate){
	var  parts 													= dDate.split('-');

	return parts[2] + "-" + parts[1] + "-" + parts[0];
}

function toDutchDate(d)
{
	var y	= d.getUTCFullYear();
	var m	= d.getMonth() + 1;
	var d 	= d.getDate();

	if (m < 10) m = "0" + m;
	if (d < 10) d = "0" + d;

	return d + "-" + m + "-" + y;
}

function toDutchDateTime(d, withSeconds)
{
	return toDutchDate(d) + " " + toTime(d, withSeconds);
}

function toDate(d)
{
	var y	= d.getUTCFullYear();
	var m	= d.getMonth() + 1;
	var d 	= d.getDate();

	if (m < 10) m = "0" + m;
	if (d < 10) d = "0" + d;

	return y + "-" + m + "-" + d;
}

function toTime(d, withSeconds)
{
	var h	= d.getHours();
	var m	= d.getMinutes();
	var s 	= d.getSeconds();

	if (h < 10) h = "0" + h;
	if (m < 10) m = "0" + m;
	if (s < 10) s = "0" + s;

	return h + ":" + m + (withSeconds ? ":" + s : "");
}

function getDutchDayOfWeek(d)
{
/*
	Dagnummering is gelijk aan DutchCalendar.get(Calendar.DAY_OF_WEEK)
	Dit betekent dat Zondag dag 1 is.
*/
	return (d.getDay() + 1);
}

/**
 * Returns the week number for this date.  dowOffset is the day of week the week
 * "starts" on for your locale - it can be from 0 to 6. If dowOffset is 1 (Monday),
 * the week returned is the ISO 8601 week number.
 *
 * dowOffset is 1 by default, so the week returned is ISO 8601 by default.
 *
 * @param int dowOffset
 * @return int
 */
Date.prototype.getWeek = function (dowOffset) {
/*getWeek() was developed by Nick Baicoianu at MeanFreePath: http://www.meanfreepath.com */

    dowOffset = typeof(dowOffset) == 'int' ? dowOffset : 1; //default dowOffset to zero
    var newYear = new Date(this.getFullYear(),0,1);
    var day = newYear.getDay() - dowOffset; //the day of week the year begins on
    day = (day >= 0 ? day : day + 7);
    var daynum = Math.floor((this.getTime() - newYear.getTime() -
    (this.getTimezoneOffset()-newYear.getTimezoneOffset())*60000)/86400000) + 1;
    var weeknum;
    //if the year starts before the middle of a week
    if(day < 4) {
        weeknum = Math.floor((daynum+day-1)/7) + 1;
        if(weeknum > 52) {
            nYear = new Date(this.getFullYear() + 1,0,1);
            nday = nYear.getDay() - dowOffset;
            nday = nday >= 0 ? nday : nday + 7;
            /*if the next year starts before the middle of
              the week, it is week #1 of that year*/
            weeknum = nday < 4 ? 1 : 53;
        }
    }
    else {
        weeknum = Math.floor((daynum+day-1)/7);
    }
    return weeknum;
};

